:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.clickable {
  cursor: pointer;
  color: #0568ae;
  word-break: break-all;
  display: inline-block;
}

.tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
}

.icon {
  width: 20px;
  fill: #0568ae;
  margin: 0 10px 0 0;
  cursor: pointer;
  height: 20px;
}

.icon>g>path {
  fill: #0568ae;
}

.icon:last-child {
  margin-right: 0;
}

.icon.disabled {
  cursor: not-allowed;
}

.icon.disabled path {
  fill: #959595;
}

.icon.disabled>g>path {
  fill: #959595;
}

.notes {
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
}

.icon_wrapper {
  display: flex;
  align-items: center;
  color: #0568ae;
}

.icon_wrapper .icon {
  width: 20px;
  height: 20px;
  fill: #0568ae;
  margin-right: 10px;
}

.icon_wrapper .icon.disabled {
  color: #959595;
}

.icon_wrapper:hover {
  cursor: pointer;
}

