@import "~brands/default/styles/globals";
.wrapper_grid_card {
	height: 100%;
	width: 97%;

	.wider_card {
		height: 86%;
		width: 100%;
		display: flex;

		& > :nth-child(1) {
			width: 75%;
		}
		& > :nth-child(2) {
			width: 25%;
		}
		& > :only-child {
			width: 100%;
		}
	}

	.account_overview {
		height: 83%;
	}
}

.carousel_frames {
	height: 88%;
}

.wrapper_modal_card {
	height: 255px;
	margin-bottom: 15px
}

.wrapper_modal_card_account_overview {
	height: 375px;
}

.form__wrapper {
	visibility: hidden;
	opacity: 0;
	max-height: 0;
	transition: opacity 0.2s linear;
	position: absolute;
	right: 0;
	top: 45px;
	z-index: 100;
}

.form__active {
	visibility: visible;
	opacity: 1;
	max-height: 100%;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: relative;
	padding: 10px;

	.title {
		font-family: $primary-font-regular;
		font-size: 20px;
		line-height: 28px;
		letter-spacing: 0.75px;
		color: $color8;
		position: relative;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		.pending {
			position: absolute;
			top: -2px;
			right: -20px;
			height: 14px;
			width: 14px;

			svg {
				width: 100%;
				cursor: pointer;

				path {
					fill: $color9;
				}
			}
		}
	}

	.icons_wrapper {
		display: flex;
		flex-direction: row;
	}

	.navigation {
		fill: $color14;
		transform: rotate(90deg);
		width: 34px;
		height: 34px;

		svg {
			cursor: pointer;

			path {
				fill: $color14;
			}
		}

		&.disabled {
			cursor: not-allowed;
		}
	}
}

.loader {
	height: 100%;
}

.modal_loader {
	min-height: 270px;
}

.indicator_dots_wrapper {
	position: absolute;
	width: 100%;
	z-index: 100;
	bottom: 0;
	text-align: center;
}

.indicator_dot {
	display: inline-block;
	height: 12px;
	width: 12px;
	border-radius: 10px;
	background-color: $color14;
	margin: 7px 5px;
	opacity: 0.3;
	transition-duration: 300ms;

	&_selected {
		opacity: 1;
	}
}

.iconWrapper {
	position: absolute;
	left: 10px;
	top: 50%;
	z-index: 100;
	touch-action: none;

	.dragIcon {
		width: 20px;
		height: 20px;
		fill: $color14;
	}
}

.as_of {
	display: flex;
	font-family: $primary-font-light;
	font-weight: 300;
	padding: 0 5px;
	font-size: 12px;
	justify-content: center;

	> span {
		padding: 0 5px;
	}

	.uppercase {
		text-transform: uppercase;
	}
}

.footer_margin {
	margin-top: 15px;
}