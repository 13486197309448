@import "~brands/default/styles/globals";
.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  border-radius: 6px;
  background-color: $color3;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 13px;

  &:not(:nth-child(4)) {
    margin-right: 10px;
  }

  .circle {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-top: 20px;
    background-color: $color7;

    &.active,
    &.activated {
      background-color: $active;
    }

    &.retired {
      background-color: $color17;
    }

    &.deactived,
    &.inventory,
    &.pause,
    &.inactive {
      background-color: $suspended;
    }

    &.test_ready,
    &.test {
      background-color: $provisioned;
    }

    &.terminated,
    &.terminate {
      background-color: $deactive;
    }

    &.purged {
      background-color: $color6;
    }

    &.suspend, &.suspended {
      background-color: $suspended;
    }

    &.deactive {
      background-color: $deactive;
    }

    &.provisioned {
      background-color: $provisioned;
    }
  }

  >span {
    font-family: $primary-font-medium;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    letter-spacing: 0.6px;
    text-align: center;
    color: $color8;
    margin-top: 20px;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.selected {
    border-color: $color14;
  }
}
