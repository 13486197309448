:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.dropzone {
  margin: 15px auto 0 auto;
  width: 100%;
  height: 120px;
  border-radius: 12px;
  border: 2px solid #f2f2f2;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}

.dropzone .text {
  font-family: "attalecksans_w_regular";
  color: #959595;
  font-size: 12px;
}

.dropzone .text .title {
  text-align: center;
}

.dropzone.error {
  border-color: #cf2a2a;
}

.dropzone.error .text {
  color: #cf2a2a;
}

.dropzone .upload_icon {
  width: 42.5px;
  height: 42.5px;
}

.dropzone .upload_icon g path {
  fill: #959595;
}

.dropzone:hover {
  border: 2px dashed #0568ae;
}

.dropzone:hover .text {
  color: #0568ae;
}

.dropzone:hover .upload_icon g path {
  fill: #0568ae;
}

.field_info {
  position: relative;
}

.field_info .file_name_wrapper {
  display: flex;
  color: #4ca90c;
  justify-content: space-between;
  margin-top: 5px;
}

.field_info .file_name_wrapper p {
  margin: 0;
}

.field_info .file_name_wrapper span svg {
  position: relative;
}

.field_info .file_name_wrapper span svg path {
  fill: #0568ae;
}

.field_info .file_name_wrapper span svg path g {
  fill: #0568ae;
}

.fail {
  color: #cf2a2a;
  margin-bottom: 5px;
  margin-top: 5px;
}

.remove_icon_wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  color: #0568ae;
  align-items: center;
}

.remove_icon_wrapper :first-child {
  margin-right: 5px;
}

.restrictions {
  display: flex;
  flex-direction: column;
}

.restrictions span {
  margin-bottom: 10px;
  color: #5a5a5a;
}

