:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.main_wrapper {
  width: 100%;
  min-height: 100vh;
  background-image: url("../../brands/default/images/illustration.png"),linear-gradient(45deg, #0568ae 0%, #009fdb 100%);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-left: 101px;
  padding-top: 67px;
}

@media only screen and (max-width: 641px) {
  .main_wrapper {
    padding: 20px;
  }
}

.message {
  font-family: "attalecksans_w_regular";
  font-size: 24px;
  color: #fff;
  margin-top: 3px;
  width: 423px;
}

@media only screen and (max-width: 641px) {
  .message {
    width: 100%;
  }
}

