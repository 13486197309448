@import "~brands/default/styles/globals";
.block {
	padding: 15px;
}

.section_title {
	font-size: 35px;
	margin: 0 0 5px 0;
	font-weight: normal;
}

.section_subtitle {
	p {
		font-size: 16px;
		color: #5b5e6c;
		margin: 0;
		letter-spacing: 0.5px;
	}
}