:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.field_wrapper {
  width: 320px;
  margin: 0 30px 30px 0;
  display: inline-table;
}

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.info {
  font-size: 14px;
}

.headline {
  font-family: "attalecksans_w_medium";
  font-size: 12px;
  letter-spacing: .6px;
  text-transform: uppercase;
  margin: 0 0 20px 0;
}

.sub_headline {
  font-family: "attalecksans_w_light";
  font-size: 14px;
  letter-spacing: .4px;
  margin: 0 0 40px 0;
}

