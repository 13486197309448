@import "~brands/default/styles/globals";
.data_wrap {
  display: flex;
  flex-direction: row;

  &.overview{
    flex-direction: column;
  }

  .row {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    margin-right: 15px;
    flex-wrap: wrap;
    border-bottom: 1px solid rgb(210,210,210);
    margin-bottom: 30px;

    .data {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      width: 320px;
      margin-right: 40px;

      >span {

        &:first-child {
          font-family: $primary-font-regular;
          font-weight: 700;
          height: 14px;
          font-size: 14px;
          line-height: 1;
          color: $color8;
          margin-bottom: 19px;
        }

        &.status_span,
        &.reimsi_span {
          
          &.status_span {
            margin-bottom: 0;

            &.entitlement{
              margin-bottom: 19px;
              font-family: $primary-font-regular;
              font-weight: 700;
            }
          }

          &.reimsi_span+span {
            text-transform: capitalize;
          }

          position: relative;
          align-self: flex-start;
        }

        &:nth-child(2) {
          height: 16px;
          font-family: $primary-font-regular;
          font-size: 16px;
          line-height: 1;
          text-align: left;
          color: $color8;
          word-break: break-all;
          word-break: break-word;
        }
      }
		}

		&.field_column_block{
      padding-right: 15px;

			@include tablet-portrait-and-below{
        padding-left:0;
        padding-right: 0;
			}
		}
	}
  
  .lastrow{
    border-bottom: none;
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 15px;

    .data {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;

      >span {

        &:first-child {
          font-family: $primary-font-regular;
          font-weight: 700;
          height: 14px;
          font-size: 14px;
          line-height: 1;
          color: $color8;
          margin-bottom: 19px;
        }

        &.status_span,
        &.reimsi_span {

          &.status_span {
            margin-bottom: 0;

            &.entitlement{
              margin-bottom: 19px;
            }
          }

          &.reimsi_span+span {
            text-transform: capitalize;
          }

          position: relative;
          align-self: flex-start;
        }

        &:nth-child(2) {
          height: 16px;
          font-family: $primary-font-regular;
          font-size: 16px;
          line-height: 1;
          text-align: left;
          color: $color8;
          word-break: break-all;
          word-break: break-word;
        }
      }
		}

		&.field_column_block{
      padding-right: 15px;

			@include tablet-portrait-and-below{
        padding-left:0;
        padding-right: 0;
			}
		}
  }

  .remove_left_padding{
    padding-left: 0;
  }
  
  .inline_column{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .inline_input_wrapper{
      width: 300px;
      margin-right: 30px;
      margin-bottom: 20px;
    }
  }
}

.entitlement_span {
  display: flex;
  flex-direction: row;
}

.entitlement_icon_wrap {
  display: flex;
  align-items: center;
  position: relative;
}

.entitlement_icon {
  height: $icon-xl;
  width: $icon-xl;
  margin-left: 5px;
  cursor: pointer;

  path {
    fill: $color14;
  }
}

.entitlement_icon_disabled {
  height: $icon-xl;
  margin-left: 5px;
  cursor: not-allowed;

  path {
    fill: $color4;
  }
}

div {

  &.status_col {
    text-transform: capitalize;
    min-width: 100px;
    width: 166px;
    padding-left: 4px;

    @include desktop-and-below {
      min-width: 115px;
    }

    &.status_col::before {
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      border-radius: 100%;
      position: relative;
      top: 10px;
      z-index: 1;
      top: 26px;
      left: 7px;
      background-color: $color8;
      visibility: hidden;
    }

    &.status_col.active::before,
    &.status_col.activated::before {
      background-color: $active;
    }

    &.status_col.retired::before {
      background-color: $color17;
    }

    &.status_col.deactived::before,
    &.status_col.inventory::before,
    &.status_col.pause::before,
    &.status_col.inactive::before {
      background-color: $suspended;
    }

    &.status_col.terminated::before,
    &.status_col.terminate::before {
      background-color: $deactive;
    }

    &.status_col.purged::before {
      background-color: $color6;
    }

    &.status_col.suspend::before, &.status_col.suspended::before {
      background-color: $suspended;
    }

    &.status_col.deactive::before {
      background-color: $deactive;
    }

    &.status_col.provisioned::before,
    &.status_col.test_ready::before,
    &.status_col.test::before {
      background-color: $provisioned;
    }

    &.status_col.inactive::before {
      border: 1px solid $color5;
    }
  }
}

.pending {
  position: absolute;
  top: -2px;
  right: -20px;
  height: 14px;
  width: 14px;

  svg {
    width: 100%;
    cursor: pointer;

    path {
      fill: $color9;
    }
  }
}

.oem_tooltip{
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-left: 10px;
  
  svg {
    width: 100%;
    cursor: pointer;

    path {
      fill: $color9;
    }
  }
}

.refreshPendingStatus{
  position: absolute;
  top: -2px;
  right: -116px;
  height: 14px;
  width: 14px;

  svg {
    width: 100%;
    cursor: pointer;

    path {
      fill: $color14;
    }
  }
}
.entitlement_tooltip {
  position: absolute;
  top: 0px;
  height: 14px;
  width: 14px;

  svg {
    width: 100%;
    cursor: pointer;

    path {
      fill: $color9;
    }
  }
}

.input_wrapper{
  width: 255px;
  position: relative;

  .action{
    width: 35px;
    height: 34px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: $color3;
    border-left: 1px solid $color4;
    position: absolute;
    right: 1px;
    top: 27px;
    z-index: 1;

    svg {
      width: 20px;
      height: 20px;

      path{

        &:nth-child(2){
          fill: $color10;
        }
      }
    }

    &_check{

      svg{

        path{

          &:nth-child(2){
            fill: green;
          }
        }
      }
    }

    &_save{
      opacity: 0.4;
      cursor: default;
    }

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.position_relative{
  position:relative;
  display:inline-block;
}

.tooltip {
	box-shadow:0 5px 12px 0.2px rgba(0, 0, 0, 0.2)
}

input::-ms-clear {
	display: none;
}
