:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
}

td {
  padding: 16px;
}

.attachments_table_wrapper {
  height: 100%;
}

.attachments_table {
  width: 100%;
  border-collapse: collapse;
}

.header_row {
  background-color: #f2f2f2;
}

.header_row th {
  width: 50%;
  font-weight: bold;
  text-transform: uppercase;
  padding: 16px;
}

.file_name {
  display: flex;
  align-items: center;
  width: 50%;
}

.file_name .clickable {
  display: flex;
  cursor: pointer;
  color: #0568ae;
  align-items: center;
}

.file_name .clickable .attachment_name {
  width: 20pc;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file_name svg {
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 5px;
}

.file_name svg path {
  fill: #0568ae;
}

.file_name svg path g {
  fill: #0568ae;
}

.description_td {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 50%;
}

.description_td .description_icons {
  display: flex;
  align-items: center;
}

.description_td .description_icons svg {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative;
}

.description_td .description_icons .edit_icon {
  margin-right: 10px;
}

.invoice_name {
  display: flex;
  align-items: center;
  width: 50%;
}

.invoice_name .clickable {
  color: #0568ae;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.invoice_name .clickable .name {
  width: 20pc;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.invoice_name .clickable svg {
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 5px;
}

.invoice_name .clickable svg path {
  fill: #0568ae;
}

.invoice_name .clickable svg path g {
  fill: #0568ae;
}

