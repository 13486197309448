@import "~brands/default/styles/globals";
.reports_wrapper {
	padding: 0 15px 0 45px;

	.reports_container {
		display: flex;
		margin-bottom: 20px;
		flex-wrap: wrap;

		.reports_column {
			width: 100%;

			.first_row {
				display: flex;
				height: 50px;
				align-items: center;

				& > :first-child {
					width: 400px;
					font-weight: bold;
				}

				.sfg_iotc {
					width: 50px;
					height: 50px;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					padding-bottom: 10px;

					> span {
						margin-bottom: 10px
					}
				}

				& > :last-child {
					width: 305px;
					display: flex;
					font-weight: bold;
					margin-right: 30px;
				}
			}

			.row {
				display: flex;
				height: 50px;
				align-items: center;

				& > :first-child {
					width: 400px;
				}

				& > :nth-child(2) {
					width: 50px
				}

				& > :nth-child(3) {
					width: 50px;
				}

				& > :last-child {
					width: 305px;
					display: flex;

					& > div > :first-child {
						margin-bottom: 0;
						display: flex;

						& > :last-child {
							width: 105px;
						}

						& > :first-child {
							width: 200px;
							margin-right: 5px;
						}
					}
				}
			}
		}
	}
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-end;
	margin: 0 0 20px 0;
	border-top: 1px $color4 solid;
	padding-top: 10px;

	:not(:last-child) {
		margin-right: 15px;
	}
}

.headline {
	font-family: $primary-font-regular;
	font-size: 16px;
	color: $color8;
	display: flex;
	border-bottom: 1px $color4 solid;
	min-height: 25px;
	align-items: center;
	margin-bottom: -5px;
}

.underline {
	margin-bottom: 70px;
	margin-top: 15px;

	.link {
		text-decoration: none;
		color: $color14;
	}
}

.divider {
	height: 1px;
	background: $color4;
	box-shadow: none;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
	border: 0;
}
