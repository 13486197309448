@import "~brands/default/styles/globals";
.detail_wrapper {
	padding-top: 18px;

	.form_wrap {
		max-width: 654px;
		margin-left: 42px;
	}

	.select_field {
		width: 312px;
		position: relative;

		&.display_flex {
			width: 100%;
			display: flex;
			justify-content: space-between;

			&.flex_start {
				justify-content: flex-start;
			}
			&.flex_wrap {
				flex-wrap: wrap;
			}
		}
	}

	.divider {
		width: 100%;
		height: 1px;
		background-color: $color4;
		margin-bottom: 10px;
		position: relative;

		&.has_child {
			margin-bottom: 24px;
			margin-top: 27px;

			&.mt15 {
				margin-top: 15px;
			}
		}

		.condition {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 24px;
			border: 1px solid $color13;
			border-radius: 50%;
			left: 0;
			top: 50%;
			margin-top: -12px;
			position: absolute;
			background-color: $color1;
			padding: 0 5px;

			&.if {
				min-width: 24px;
			}

			&.then {
				min-width: 52px;
				border-radius: 12px;
			}

			span {
				text-transform: uppercase;
				font-family: $primary-font-medium;
				font-size: 14px;
				color: $color13;
			}
		}
	}

	.custom_select_width {
		&_first {
			width: 312px;
			position: relative;

			&.ml30 {
				margin-left: 30px;
			}
		}

		&.custom_margin {
			margin-left: 30px;
		}

		&.width303 {
			width: 303px;
		}
	}
	.mb20 {
		margin-bottom: 20px;
	}

	.mt24 {
		margin-top: 24px;
	}

	.label {
		font-family: $primary-font-medium;
		font-size: 14px;
		font-weight: 500;
		color: $color8;
		margin-bottom: 8px;
		white-space: nowrap;

		&.filter {
			margin-top: 27px;
		}
	}

	.buttons {
		display: flex;
		justify-content: space-between;
		.request_deletion_button {
			margin-left: 24px;
			padding: 15px 0 15px 15px;
		}

		.buttons_wrapper {
			display: flex;
			justify-content: flex-end;
			// max-width: 654px;
			margin-right: 42px;
			padding: 15px 0 15px 15px;
		}
	}
}

.message_text {
	display: flex;

	> div,
	> div > div {
		width: 140px;
	}

	&.location_update {
		> div,
		> div > div {
			width: 100%;
		}
	}

	> span {
		margin-top: 10px;
		margin-left: 10px;
		position: relative;
	}
}

.loader {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 35px;
	width: 20px;
	padding-left: 20px;
	position: absolute;
	right: 0;
	top: 25px;
	right: -15px;
}

.custom_filter_fields {
	width: 312px;
}

.follow_up_with {
	margin-bottom: 15px;
}

.add_another {
	font-size: 18px;
	color: $color14;
	display: flex;
	align-items: center;
	margin-bottom: 19px;
	cursor: pointer;
}

.trigger__icon {
	height: 2px;
	width: 10px;
	background: $gradient_default_first_color;
	position: relative;
	display: inline-block;
	margin-right: 7px;

	&:after {
		transition: all 0.4s ease-in-out;
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: $gradient_default_first_color;
		transform: rotate(90deg);
	}
}

.remove_item_from_array {
	height: 22px;
	width: 22px;
	cursor: pointer;
	position: absolute;
	right: 0;
	top: -4px;

	path {
		fill: $color1;

		&:nth-child(2) {
			fill: $color14;
		}
	}
}

.cancel_button {
	margin-right: 15px;
}

.flex_wrap {
	flex-wrap: wrap;
}

.width100 {
	width: 100%;
}

div.custom_input {
	input {
		&.custom_input {
			height: 38px;
		}
	}
}

.ml10 {
	margin-left: 10px;
}


