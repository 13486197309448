:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.custom_option {
  position: relative;
  padding-left: 11px;
}

.custom_option::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: 3px;
  left: -3px;
  background-color: #191919;
}

.custom_option.select_single_value {
  position: absolute;
  padding-left: 20px;
}

.custom_option.select_single_value::before {
  top: 50%;
  margin-top: -5px;
  left: 6px;
}

.pending::before {
  background-color: #ea7400;
}

.received::before {
  background-color: #4ca90c;
}

.shipped::before {
  background-color: #0568ae;
}

.completed::before {
  background-color: #222;
}

.order_status {
  margin-bottom: 0;
  font-family: "attalecksans_w_medium";
  font-size: 12px;
  text-transform: uppercase;
}

.order_status.pending {
  color: #ea7400;
}

.order_status.received {
  color: #4ca90c;
}

.order_status.shipped {
  color: #0568ae;
}

.order_status.completed {
  color: #222;
}

.icon_expedited {
  text-align: left;
}

.icon_expedited svg {
  width: 22px;
  height: 28px;
  right: 5px;
  position: relative;
}

.icon_expedited svg g {
  fill: #4ca90c;
}

.icon_expedited svg g path {
  fill: #4ca90c;
}

.clickable {
  cursor: pointer;
  color: #0568ae;
  word-break: break-all;
}

