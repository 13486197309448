:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.data {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 320px;
  margin-right: 30px;
}

.data>span:first-child {
  font-family: "attalecksans_w_regular";
  font-weight: 700;
  height: 14px;
  font-size: 14px;
  line-height: 1;
  color: #191919;
  margin-bottom: 19px;
}

.data>span.status_span {
  margin-bottom: 10px;
  height: 25px;
  display: inline-block;
  position: relative;
}

.data>span.status_span.entitlement {
  margin-bottom: 5px;
}

.data>span:nth-child(2) {
  height: 16px;
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  line-height: 1;
  text-align: left;
  color: #191919;
  word-break: break-word;
}

.apns_container {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  padding-top: 30px;
}

.entitlement_data {
  margin-bottom: 0;
  width: auto;
}

.container {
  background: #f2f2f2;
  border-radius: 5px;
  margin-bottom: 30px;
}

.container>div {
  border-top: none;
}

.container:global(__contentOuter) {
  position: relative;
}

.container:global(__contentInner) {
  display: flex;
  flex-direction: column;
}

.container:global(__contentInner)::before {
  content: "";
  border-bottom: 1px solid gray;
  width: 99%;
  margin-left: 10px;
}

.text {
  display: flex;
  flex-direction: column;
}

.text>span {
  margin-bottom: 3px;
}

.apns {
  display: flex;
  flex-wrap: wrap;
}

.content {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}

.apn_content {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.item {
  display: inline-block;
}

.grayedout {
  opacity: .4;
}

.tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
}

.label_tooltip {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-left: 10px;
}

.label_tooltip svg {
  width: 100%;
  cursor: pointer;
}

.label_tooltip svg path {
  fill: #0568ae;
}

