:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.input_wrapper_mobile {
  width: 100%;
}

.input_wrapper {
  width: 255px;
  position: relative;
}

.input_wrapper .action {
  width: 35px;
  height: 34px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #f2f2f2;
  border-left: 1px solid #d2d2d2;
  position: absolute;
  right: 1px;
  top: 27px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_wrapper .action svg {
  width: 20px;
  height: 20px;
}

.input_wrapper .action svg path:nth-child(2) {
  fill: #cf2a2a;
}

.input_wrapper .action_check svg path:nth-child(2) {
  fill: green;
}

.input_wrapper .action_save {
  opacity: .4;
  cursor: default;
}

.inline_input_wrapper {
  width: 300px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

.column.field_column_block {
  padding-right: 15px;
}

@media only screen and (max-width: 768px) {
  .column.field_column_block {
    padding-left: 0;
    padding-right: 0;
  }
}

.remove_left_padding {
  padding-left: 0;
}

.inline_column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.inline_column .inline_input_wrapper {
  width: 300px;
  margin-right: 30px;
  margin-bottom: 20px;
}

