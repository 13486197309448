:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.content_block {
  margin-left: 45px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.section_title {
  text-transform: uppercase;
  font-family: "attalecksans_w_medium";
  color: #8a8b8a;
  font-size: 16px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 0;
}

.section_title:first-of-type {
  border-bottom: 0px;
}

.section_title:not(:first-of-type):last-of-type {
  padding-bottom: 13px;
}

.columns_holder {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 40px;
}

.column {
  max-width: 312px;
  flex: 0 0 312px;
}

.column:first-of-type {
  max-width: 187px;
  flex: 0 0 187px;
  margin-right: 95px;
}

.column:nth-child(3) {
  margin-left: 32px;
}

.mask_field_wrapper {
  display: flex;
  padding-bottom: 15px;
}

.mask_field_wrapper .component_wrapper_left {
  width: 120px;
}

.mask_field_wrapper .component_wrapper_right {
  width: calc(100% - 120px);
  position: relative;
}

.mask_field_wrapper .component_wrapper_right div div div:nth-of-type(2) {
  position: absolute;
  width: calc(100% + 120px);
  margin-left: -120px;
}

.mask_field_wrapper .component_wrapper_right .custom_input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.assigned_accounts_table {
  border-collapse: collapse;
  font-size: 14px;
  margin-top: 40px;
}

.assigned_accounts_table thead th {
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  text-transform: uppercase;
  font-weight: normal;
}

.assigned_accounts_table thead th:last-child {
  border-right: 0px;
  text-align: center;
}

.assigned_accounts_table thead .accounts {
  width: 250px;
}

.assigned_accounts_table thead .allow_access {
  width: 125px;
}

.assigned_accounts_table tbody tr {
  border-bottom: 1px solid #dcdcdc;
}

.assigned_accounts_table tbody td {
  border-right: 1px solid #dcdcdc;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 5px;
  text-transform: capitalize;
}

.assigned_accounts_table tbody td:last-child {
  border-right: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assigned_accounts_table tbody td .disabled {
  cursor: not-allowed;
  width: 15px;
  height: 15px;
  margin-left: 10px;
}

.assigned_accounts_table tbody td .disabled g {
  fill: #959595;
}

.assigned_accounts_table tbody td .disabled g path:nth-child(2) {
  fill: #959595;
}

.assigned_accounts_table tbody .subaccount div {
  display: inline-block;
}

.option {
  display: flex;
  font-size: 14px;
  align-items: center;
}

.option span {
  margin-right: 5px;
}

.legal_information {
  font-size: 16px;
  width: 90%;
}

.address_column_margin {
  margin-top: 74px;
}

.horizontal_separator {
  margin: 0;
  background-color: #d2d2d2;
  border: 0px;
  height: 1px;
}

.delete_icon {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  cursor: pointer;
}

.delete_icon g {
  fill: #0568ae;
}

.delete_icon g path:nth-child(2) {
  fill: #0568ae;
}

.tooltip {
  background-color: #0568ae !important;
  color: #fff !important;
}

.tooltip:after {
  border-top-color: #0568ae !important;
}

.remove_icon_wrapper {
  position: absolute;
  right: 15px;
}

.button_margin {
  margin-top: 15px;
  margin-bottom: 15px;
}

.icon_wrapper {
  font-size: 16px;
  color: #0568ae;
  cursor: pointer;
  display: inline-block;
}

.icon_wrapper svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 3px;
  margin: 0;
}

.icon_wrapper svg path {
  fill: #0568ae;
}

.icon_wrapper svg path g {
  fill: #0568ae;
}

.icon_wrapper.disabled {
  cursor: not-allowed;
  color: #959595;
}

.icon_wrapper.disabled g {
  fill: #959595;
}

.icon_wrapper.disabled g path:nth-child(2) {
  fill: #959595;
}

.disabledRow {
  cursor: not-allowed;
  color: #959595;
}

.disabledRow svg path {
  fill: #959595;
}

.disabledRow svg path g {
  fill: #959595;
}

.button_without_data {
  margin-top: 10px;
}

.table_without_data {
  margin-top: 0;
}

.assigned_accounts {
  margin-top: 5px;
  text-transform: capitalize;
}

.assigned_accounts:first-of-type {
  margin-top: 20px;
}

.center_text {
  text-align: center;
}

.alert {
  width: 58px;
  height: 52px;
  margin-right: 20px;
  margin-top: 5px;
}

.alert path {
  fill: #c82b35;
}

.alert path g {
  fill: #c82b35;
}

.delete_profile_section {
  max-width: 640px;
  display: flex;
  margin-top: 30px;
}

.field_holder {
  min-width: 250px;
  margin-bottom: 30px;
}

.field_holder button {
  margin-top: 25px;
}

.delete {
  color: #c82b35;
  text-transform: uppercase;
}

.message {
  font-family: "attalecksans_w_medium";
  color: #191919;
  margin-bottom: 5px;
}

.delete_profile {
  font-size: 16px;
}

.button_wrapper {
  display: flex;
  justify-content: flex-end;
}

.highlight_licence {
  color: #0568ae;
  cursor: pointer;
}

.required_field label::after {
  content: "*";
  font-weight: bold;
  color: #cf2a2a;
  margin-left: 2px;
}

.field_wrapper {
  margin: 0 0px 10px 0;
}

.assigned_accounts_container {
  margin-top: 20px;
}

.image_holder {
  width: 174px;
  height: 174px;
  border-radius: 50%;
}

