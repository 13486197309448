@import "~brands/default/styles/globals";
.jumbotrone {
	display: flex;
	align-items: center;
	margin: 31px 0 50px 43px;
}

.oval {
	width: 131px;
	height: 131px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		border-radius: 100%;
	}

	svg {
		width: 131px;
		height: 131px;
		border-radius: 100%;
	}
}

.company_name {
	font-size: 35px;
	margin-left: 26px;
	color: #000;
}
