.custom_labels_field_header {
	font-size: 12px;
	font-weight: bold;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

.content_wrapper {
	display: flex;
}

.item {
	padding-bottom: 25px;
}

.field_icon_row {
  width: 100%;
	display: inline-table;
	align-items: center;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.line {
  height: 1px;
  background-color: $color4;
  width: 100%;
  max-width: 640px;
  margin-top: 16px;
}

.cancel_button {
  margin-right: 15px;
}
