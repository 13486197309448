@import "~brands/default/styles/globals";
.modal_body {
	height: 280px;
	padding-left: 30px;
	padding-right: 30px;
}

.grat_accesss_body {
	margin: 30px 0 0 30px;
	padding-right: 80px;
	.modal_note{
		font-size: 14px;
		font-family: $primary-font-light;
	}
}

.column { 
	max-width: 185px;
	flex: 0 0 185px;
}