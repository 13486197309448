:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.reports_wrapper {
  padding: 0 15px 0 45px;
}

.reports_wrapper .reports_column {
  margin-right: 100px;
  margin-bottom: 30px;
  width: 100%;
}

.reports_wrapper .reports_column .first_row {
  display: flex;
  height: 50px;
  align-items: center;
}

.reports_wrapper .reports_column .first_row>:first-child {
  width: 400px;
  font-weight: bold;
}

.reports_wrapper .reports_column .first_row .sfg_iotc {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.reports_wrapper .reports_column .first_row .sfg_iotc>span {
  margin-bottom: 10px;
}

.reports_wrapper .reports_column .first_row>:last-child {
  font-weight: bold;
}

.reports_wrapper .reports_column .row {
  display: flex;
  height: 50px;
  align-items: center;
}

.reports_wrapper .reports_column .row>:first-child {
  width: 400px;
}

.reports_wrapper .reports_column .row>:nth-child(2) {
  width: 50px;
}

.reports_wrapper .reports_column .row>:nth-child(3) {
  width: 50px;
}

.reports_wrapper .reports_column .row>:last-child {
  width: 305px;
  display: flex;
}

.reports_wrapper .reports_column .row>:last-child>div>:first-child {
  margin-bottom: 0;
  display: flex;
}

.reports_wrapper .reports_column .row>:last-child>div>:first-child>:last-child {
  width: 105px;
}

.reports_wrapper .reports_column .row>:last-child>div>:first-child>:first-child {
  width: 200px;
  margin-right: 5px;
}

.block {
  display: block;
}

.headline {
  font-family: "attalecksans_w_regular";
  font-size: 12px;
  color: #191919;
  display: flex;
  border-bottom: 1px #d2d2d2 solid;
  min-height: 25px;
  align-items: center;
}

.divider {
  height: 1px;
  background: #d2d2d2;
  box-shadow: none;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  border: 0;
}

.section_headline {
  font-family: "attalecksans_w_medium";
  font-size: 18px;
  color: #191919;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #d2d2d2 solid;
  min-height: 25px;
  width: 787px;
  font-weight: 500;
}

.section_headline>span {
  margin: 0 0 10px 0;
}

.subtitle {
  margin-bottom: 15px;
  color: #5a5a5a;
}

.section_wrapper {
  position: relative;
  max-width: 787px;
}

.button_wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.button_wrapper>:nth-child(2) {
  border-left: 1px solid #d2d2d2;
}

.button_wrapper svg {
  width: 38px;
  height: 28px;
  cursor: pointer;
}

.button_wrapper svg path {
  fill: #0568ae;
}

