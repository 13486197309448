@import "~brands/default/styles/globals";
.diagnostics_wrapper{
	.header {
		padding: 0 30px 30px;
		border-bottom: 1px solid $color4;
	}
	.main {
		display: flex;
		flex-flow: row wrap;
		margin: 30px 15px;
	}
}

.button_wrapper {
		> Button {
			margin: 5px;
		}

}
