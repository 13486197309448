:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.title {
  font-size: 12px;
  font-family: "attalecksans_w_medium";
  line-height: 1.17;
  letter-spacing: .6px;
  color: #191919;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.title.satellite {
  border-top: 1px solid #d2d2d2;
  padding-top: 30px;
  margin-top: 30px;
}

.aside_padding {
  padding-left: 30px;
}

.content_wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.item {
  width: 50%;
  padding-bottom: 25px;
}

.item:nth-child(odd) {
  padding-right: 15px;
}

.item:nth-child(even) {
  padding-left: 15px;
}

.number_of_columns {
  color: #0568ae;
}

.clear_all {
  float: left;
}

.modal .modalBody {
  height: 350px;
  width: 850px;
}

