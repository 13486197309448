:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.mobile_wrapper .header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.mobile_wrapper .header .title {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #d2d2d2;
  width: 92%;
}

.mobile_wrapper .header .title>span {
  font-size: 24px;
}

.mobile_wrapper .header .dropdown_wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 96%;
}

.mobile_wrapper .header .dropdown_wrapper .dropdown {
  width: 100%;
  margin-bottom: -15px;
}

.mobile_wrapper .header .dropdown_wrapper .usage_text {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

.mobile_wrapper .header .date_icons {
  display: flex;
  padding: 10px;
  width: 98%;
}

.mobile_wrapper .header .date_icons [class=range-picker-wrapper] {
  flex-direction: column;
}

.mobile_wrapper .header .date_icons .picker_icon {
  display: flex;
  align-items: center;
}

.mobile_wrapper .header .date_icons .picker_icon svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.mobile_wrapper .header .date_icons .picker_icon svg path {
  fill: #009fdb;
}

.mobile_wrapper .data {
  width: 200px;
}

.no_data_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no_data_wrapper .message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

