:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

div.custom_option {
  position: relative;
  padding-left: 11px;
}

div.custom_option::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: 3px;
  left: -3px;
  background-color: #191919;
}

div.custom_option.select_single_value {
  position: absolute;
  padding-left: 20px;
}

div.custom_option.select_single_value::before {
  top: 50%;
  margin-top: -5px;
  left: 6px;
}

div.pending::before {
  background-color: #ea7400;
}

div.received::before {
  background-color: #4ca90c;
}

div.shipped::before {
  background-color: #0568ae;
}

div.completed::before {
  background-color: #222;
}

.form_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 15px 0;
}

.form_container .section {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 42px 15px;
}

.form_container .section .section_m2m {
  flex: 0 0 624px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form_container .section .section_m2m .m2m_account {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_m2m .m2m_account_id {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_order {
  flex: 0 0 624px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form_container .section .section_order .order_number_id {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_order .order_status {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_shipping {
  flex: 0 0 624px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form_container .section .section_shipping .order_confirmation_number {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_shipping .shipping_number {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_sku_total {
  width: 1248px;
  padding: 28px 33px 10px;
  margin-bottom: 10px;
  background-color: #f2f2f2;
}

.form_container .section .section_sku_total .section_sku {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #f2f2f2;
}

.form_container .section .section_sku_total .section_sku .sku_wrapper {
  display: flex;
  flex-direction: row;
  width: 60%;
}

.form_container .section .section_sku_total .section_sku .sku_wrapper .sku {
  width: 50%;
  padding: 5px;
}

.form_container .section .section_sku_total .section_sku .sku_wrapper .sku_platform {
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_sku_total .section_sku .total_wrapper {
  width: 40%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.form_container .section .section_sku_total .section_sku .total_wrapper .unit_price {
  max-width: 156px;
  padding: 5px;
}

.form_container .section .section_sku_total .section_sku .total_wrapper .quantity {
  max-width: 156px;
  padding: 5px;
}

.form_container .section .section_sku_total .section_sku .total_wrapper .total {
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_sku_total .section_sku .total_wrapper .total label {
  padding-left: 10px;
}

.form_container .section .section_sku_total .sku_description {
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_contact_po {
  flex: 0 0 624px;
  max-width: 624px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form_container .section .section_contact_po .contact {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_contact_po .purchase_order_number {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_tel_tid {
  flex: 0 0 624px;
  max-width: 624px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form_container .section .section_tel_tid .telephone_number {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_tel_tid .target_implementation_date {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_title {
  flex: 0 0 100%;
  padding: 5px;
  font-family: "attalecksans_w_medium";
  text-transform: uppercase;
}

.form_container .section .section_address {
  flex: 0 0 312px;
  max-width: 312px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form_container .section .section_address .address_line_1 {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_address .address_line_2 {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_address .address_city {
  flex: 0 0 312px;
  max-width: 312px;
  padding: 5px;
}

.form_container .section .section_address .address_state {
  flex: 0 0 156px;
  max-width: 156px;
  padding: 5px;
}

.form_container .section .section_address .address_zip_code {
  flex: 0 0 156px;
  max-width: 156px;
  padding: 5px;
}

.form_container .section .section_notes {
  flex: 0 0 312px;
  max-width: 312px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 23px;
}

.form_container .section .section_notes .shipping_notes {
  padding: 5px;
}

.form_container .section .section_notes .shipping_notes .shipping_notes_message textarea {
  min-height: 206px;
}

.form_container .section .section_notes .expedited_shipping {
  padding: 5px;
  align-self: flex-end;
}

.form_container .section .section_buttons {
  flex: 0 0 100%;
  max-width: 1248px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-end;
}

.form_container .section .section_buttons :not(:last-child) {
  margin-right: 15px;
}

.form_container .section .separator {
  flex: 0 0 100%;
  max-width: 1248px;
  margin: 15px 0;
  border-top: 1px solid #d2d2d2;
}

.form_field {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
}

.form_field.label_tooltip {
  position: relative;
}

.form_field .label {
  font-family: "attalecksans_w_medium";
  font-size: 14px;
  color: #191919;
  margin-bottom: 8px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.form_field .form_label {
  margin-bottom: 8px;
}

.form_field .field {
  flex: 0 1 100%;
}

.form_field .textarea {
  flex: 0 0 100%;
}

.form_field .textarea .ds-field-group-wrapper .form-group .block-wrapper .form-control {
  min-height: 200px;
}

.form_field .label_below {
  font-family: "attalecksans_w_italic";
  font-size: 10px;
  color: #959595;
}

.form_field .static {
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  color: #191919;
  padding: 10px 0;
}

.form_field .static.bold {
  font-family: "attalecksans_w_bold";
}

.form_field .static.medium {
  font-family: "attalecksans_w_medium";
}

.form_field .static.uppercase {
  text-transform: uppercase;
}

.form_field .static~.label_below {
  margin-top: -5px;
}

.form_field .static.pending {
  color: #ea7400;
}

.form_field .static.received {
  color: #4ca90c;
}

.form_field .static.shipped {
  color: #0568ae;
}

.form_field .static.completed {
  color: #222;
}

.order_status_select {
  font-family: "attalecksans_w_medium";
  margin-bottom: 0;
}

.order_status_select label {
  display: none;
}

.order_status_select .pending::before {
  background-color: #ea7400;
}

.order_status_select .received::before {
  background-color: #4ca90c;
}

.order_status_select .shipped::before {
  background-color: #0568ae;
}

.order_status_select .completed::before {
  background-color: #222;
}

.order_status_static {
  font-family: "attalecksans_w_medium";
  display: block;
  width: 100%;
  height: 36px;
  padding: 8px 0 7px 0;
  font-size: 16px;
}

.order_status_static.pending {
  color: #ea7400;
}

.order_status_static.received {
  color: #4ca90c;
}

.order_status_static.shipped {
  color: #0568ae;
}

.order_status_static.completed {
  color: #222;
}

.total_label {
  padding-left: 10px;
  font-family: "attalecksans_w_bold";
  font-size: 30px;
  color: #191919;
  border-left: 2px solid #d2d2d2;
}

.tooltip_wrapper {
  position: absolute;
  right: 0;
}

.tooltip_wrapper .tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
  font-weight: normal;
}

.tooltip_wrapper svg {
  width: 15px;
  height: 15px;
  position: relative;
  cursor: pointer;
  margin: 0 0 0 5px;
}

.tooltip_wrapper svg g path {
  fill: #0568ae;
}

.status {
  display: block;
  position: relative;
  padding-left: 15px;
}

.status::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: 3px;
  left: -3px;
  background-color: #191919;
}

.status.pending::before {
  background-color: #ea7400;
}

.status.received::before {
  background-color: #4ca90c;
}

.status.shipped::before {
  background-color: #0568ae;
}

.status.completed::before {
  background-color: #222;
}

