@import "~brands/default/styles/globals";
.modal_edit {
	width: 680px;
}

.modal_add {
	height: 359px;
	width: 680px;
}

.modal_body {
	width: 100%;
}