:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.action_wrap {
  margin-top: 15px;
}

.title {
  padding-bottom: 16px;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: .6px;
  text-transform: uppercase;
}

:global .modal-aside--padding {
  padding-left: 30px;
}

.no_filter_data {
  color: #cf2a2a;
}

.disabled_link {
  pointer-events: none;
}

.disabled_link>a>span {
  color: #5a5a5a;
}

.scrollable_ul {
  height: 100%;
  overflow-y: auto;
}

.modal .modalBody {
  height: 400px;
}

.modal_filters {
  height: 85px;
}

.modal_tabs {
  height: 315px;
}

.loading_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

