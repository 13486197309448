@import "~brands/default/styles/globals";
.add_invoice {
	display: flex;
	flex-direction: column;
	margin-left: 3em;
	margin-top: 2em;
  margin-bottom: 2em;
  margin-right: 3em;
  height: 100%;
}

.add_invoice_body {
  width: 620px;
}

.titles {
  width: 620px;
  margin-bottom: 3em;
  display: flex;
  flex-direction: column;
}

.confirmation_text {
  margin-bottom: 1em;
  font-family: $primary-font-medium;
  font-size: 24px;
  letter-spacing: 0.6px;
}

.subtitle {
	font-weight: 500;
  font-family: $primary-font-medium;
	font-size: 18px;
	color: $color8;
  border-bottom: 1px solid $color4;
  padding-bottom: 0.5em;
}


.buttons {
	display: flex;
	justify-content: flex-end;
  margin-top: 3em;
  border-top: 1px solid $color4;
  padding-top: 2em;
}

.left_button {
	margin-right: 20px;
}

.add-invoice-content {
	display: flex;
	flex-direction: column;
}
