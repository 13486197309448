@import "~brands/default/styles/globals";
.clickable {
	cursor: pointer;
	color: $color14;
  word-break: break-all;
  display: inline-block;
}

.tooltip {
	box-shadow: 0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
}

.icon {
  width: $icon-lg;
  fill: $color14;
  margin: 0 10px 0 0;
  cursor: pointer;
  height: 20px;

  > g {
    > path {
      fill: $color14;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &.disabled {
    cursor: not-allowed;
    path {
      fill: $color5;
    }
    > g {
      > path {
        fill: $color5;
      }
    }
  }
}

.notes {
	white-space: nowrap;
	overflow: hidden;
	max-width: 200px;
	text-overflow: ellipsis;
}

.icon_wrapper {
	display: flex;
	align-items: center;
	color: $color14;
	.icon {
		width: $icon-lg;
		height: $icon-lg;
		fill: $color14;
		margin-right: 10px;

		&.disabled {
			color: $color5;
		}
	}
	&:hover {
		cursor: pointer;
	}
}