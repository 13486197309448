.divider {
	background-color: $color4;
	border: 0;
	height: 1px;
	margin: 20px 20px 30px 20px;
}

.row_wrapper {
	margin: 15px;
	display: flex;
}

.border_right {
	border-right: 1px solid $color4;
}

.row {
	flex-basis: 25%;
	margin-right: 10px;
	padding-left: 5px;
	min-width: 200px;

	.title_row {
		font-size: 14px;
		font-family: $primary-font-medium;
		margin-bottom: 5px;
	}

	.detail_holder {
		font-size: 16px;
		font-family: $primary-font-regular;
	}

	.title {
		font-size: 12px;
		font-family: $primary-font-medium;
		margin-bottom: 20px;
		margin-top: 0;
	}

	.to_uppercase {
		text-transform: uppercase;
	}

	.title_margin {
		margin-bottom: 15px;
	}

	.title_edit {
		display: flex;
		justify-content: space-between;

		svg {
			width: 20px;
			height: 20px;
			position: relative;
			cursor: pointer;
			margin-right: 5px;

			path {
				fill: $color14;

				g {
					fill: $color14;
				}
			}
		}
	}
}

.block_message {
	margin-bottom: 15px;
	padding-right: 15px;

	.block_title {
		font-weight: bold;
		margin-bottom: 10px;
	}

	.block_data {
		font-size: 16px;
	}

	> div {
		word-break: break-word;
		word-wrap: break-word;
		width: 100%;
	}
}

.column {
	min-width: 150px;
}

.notes_column {
	color: $color14;
	min-width: 150px;
	cursor: pointer;

	pre {
		background-color: inherit;
		padding: 0;
		border: 0;
		margin-top: 10px;
		font-size: 14px;
		font-family: $primary-font-regular;
	}
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
}

.dropzone_trigger {
	display: none;
	&.dropzone__active {
		display: block;
	}
}

.existing_files {
	height: 25px;
	display: flex;
	justify-content: space-between;
	color: $color14;

	.existing_files_name {
		display: flex;
		cursor: pointer;
		
		svg {
			width: 15px;
			height: 15px;
			position: relative;
			margin-right: 5px;
		}
	}

	.existing_files_button {
		svg {
			width: 20px;
			height: 20px;
			position: relative;
			cursor: pointer;

			path {
				fill: $color14;

				g {
					fill: $color14;
				}
			}
		}
	}
}

.tooltip_wrapper {
	.tooltip {
		box-shadow: 0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
		font-weight: normal;
	}

	svg {
		width: 15px;
		height: 15px;
		position: relative;
		cursor: pointer;
		margin: 0 0 0 5px;

		g {
			path {
				fill: $color14;
			}
		}
	}
}
