@import "~brands/default/styles/globals";
.reports_wrapper {
  padding: 0 15px 0 45px;

	.reports_column {
		margin-right: 100px;
		margin-bottom: 30px;
		width: 100%;

		.first_row {
			display: flex;
			height: 50px;
			align-items: center;

			& > :first-child {
				width: 400px;
				font-weight: bold;
			}

			.sfg_iotc {
				width: 50px;
				height: 50px;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				padding-bottom: 10px;

				> span {
					margin-bottom: 10px
				}
			}

			& > :last-child {
				font-weight: bold;
			}
		}

		.row {
			display: flex;
			height: 50px;
			align-items: center;

			& > :first-child {
				width: 400px;
			}

			& > :nth-child(2) {
				width: 50px
			}

			& > :nth-child(3) {
				width: 50px;
			}

			& > :last-child {
				width: 305px;
				display: flex;

				& > div > :first-child {
					margin-bottom: 0;
					display: flex;

					& > :last-child {
						width: 105px;
					}

					& > :first-child {
						width: 200px;
						margin-right: 5px;
					}
				}
			}
		}
	}
}
.block {
	display: block;
}

.headline {
	font-family: $primary-font-regular;
	font-size: 12px;
	color: $color8;
	display: flex;
	border-bottom: 1px $color4 solid;
	min-height: 25px;
	align-items: center;
}

.divider {
	height: 1px;
	background: $color4;
	box-shadow: none;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
	border: 0;
}

.section_headline {
	font-family: $primary-font-medium;
	font-size: 18px;
	color: $color8;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px $color4 solid;
	min-height: 25px;
	width: 787px;
	font-weight: 500;

	> span {
		margin: 0 0 10px 0;
	}
}

.subtitle{
	margin-bottom: 15px;
	color: $color6;
}

.section_wrapper {
  position: relative;
  max-width: 787px;
}

.button_wrapper {
	position: absolute;
	top: 0;
	right: 0;

	> :nth-child(2) {
		border-left: 1px solid $color4
	}

	svg {
		width: 38px;
		height: 28px;
		cursor: pointer;

		path {
			fill: $color14;
		}
	}
}