@import "~brands/default/styles/globals";
.tooltip {
	box-shadow: 0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
}

td {
	padding: 16px;
}

.attachments_table_wrapper {
	height: 100%;
}

.attachments_table {
	width: 100%;
	border-collapse: collapse;
}

.header_row {
	background-color: $color3;
	th {
		width: 50%;
		font-weight: bold;
		text-transform: uppercase;
		padding: 16px;
	}
}

.file_name {
	display: flex;
	align-items: center;
	width: 50%;
	.clickable {
		display: flex;
		cursor: pointer;
		color: $color14;
    align-items: center;
		.attachment_name {
			width: 20pc;
			overflow: hidden;
			display: inline-block;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	svg {
		width: 20px;
		height: 20px;
		position: relative;
		margin-right: 5px;
		path {
			fill: $color14;

			g {
				fill: $color14;
			}
		}
	}
}

.description_td {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-basis: 50%;
	.description_icons {
		display: flex;
		align-items: center;
		svg {
			cursor: pointer;
			width: 20px;
			height: 20px;
			position: relative;
		}
		.edit_icon {
			margin-right: 10px;
		}
	}
}

.invoice_name {
	display: flex;
  align-items: center;
  width: 50%;
	.clickable {
    color: $color14;
    display: flex;
    align-items: center;
    cursor: pointer;
    .name {
      width: 20pc;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
		svg {
			width: 20px;
			height: 20px;
			position: relative;
			margin-right: 5px;
			path {
				fill: $color14;

				g {
					fill: $color14;
				}
			}
		}
	}
}
