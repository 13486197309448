$padding: 5px;
$max-width: 312px;

div.custom_option {
	position: relative;
	padding-left: 11px;
	&::before {
		content: '';
		width: 10px;
		height: 10px;
		border-radius: 100%;
		position: absolute;
		top: 3px;
		left: -3px;
		background-color: $color8;
	}
	&.select_single_value {
		position: absolute;
		padding-left: 20px;
	}
	&.select_single_value::before {
		top: 50%;
		margin-top: -5px;
		left: 6px;
	}
}

div.pending {
	&::before {
		background-color: $pending;
	}
}


div.received {
	&::before {
		background-color: $received;
	}
}

div.shipped {
	&::before {
		background-color: $shipped;
	}
}

div.completed {
	&::before {
		background-color: $completed;
	}
}

.form_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 15px 0;

	.section {
		flex: 0 0 auto;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0 42px 15px;

		.section_m2m {
			flex: 0 0 ($max-width * 2);

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.m2m_account {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}

			.m2m_account_id {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}
		}

		.section_order {
			flex: 0 0 ($max-width * 2);

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.order_number_id {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}

			.order_status {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}
		}

		.section_shipping {
			flex: 0 0 ($max-width * 2);

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.order_confirmation_number {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}

			.shipping_number {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}
		}

		.section_sku_total {
			width: ($max-width * 4);
			padding: 28px 33px 10px;
			margin-bottom: 10px;

			background-color: $color3;

			.section_sku {
				display: flex;
				justify-content: space-between;
				flex-direction: row;
				flex-wrap: wrap;

				background-color: $color3;

				.sku_wrapper {
					display: flex;
          flex-direction: row;
          width: 60%;
          
					.sku {
            width: 50%;
						padding: $padding;
					}

					.sku_platform {
						max-width: $max-width;
						padding: $padding;
					}
				}

				.total_wrapper {
					width: 40%;
					display: flex;
					justify-content: space-between;
					flex-direction: row;

					.unit_price {
						max-width: ($max-width / 2);
						padding: $padding;
					}

					.quantity {
						max-width: ($max-width / 2);
						padding: $padding;
					}

					.total {
						max-width: $max-width;
						padding: $padding;

						label {
							padding-left: 10px;
						}
					}
				}
			}

			.sku_description {
				max-width: $max-width;
				padding: $padding;
			}
		}

		.section_contact_po {
			flex: 0 0 ($max-width * 2);
			max-width: ($max-width * 2);

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.contact {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}
			.purchase_order_number {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}
		}

		.section_tel_tid {
			flex: 0 0 ($max-width * 2);
			max-width: ($max-width * 2);

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.telephone_number {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}
			.target_implementation_date {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}
		}

		.section_title {
			flex: 0 0 100%;
			padding: $padding;

			font-family: $primary-font-medium;
			text-transform: uppercase;
		}

		.section_address {
			flex: 0 0 $max-width;
			max-width: $max-width;

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.address_line_1 {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}
			.address_line_2 {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}
			.address_city {
				flex: 0 0 $max-width;
				max-width: $max-width;
				padding: $padding;
			}
			.address_state {
				flex: 0 0 ($max-width / 2);
				max-width: ($max-width / 2);
				padding: $padding;
			}
			.address_zip_code {
				flex: 0 0 ($max-width / 2);
				max-width: ($max-width / 2);
				padding: $padding;
			}
		}

		.section_notes {
			flex: 0 0 $max-width;
			max-width: $max-width;

			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: space-between;

			padding-bottom: 23px;

			.shipping_notes {
				padding: $padding;

				.shipping_notes_message {
          textarea {
            min-height: 206px;
          }
				}
			}
			.expedited_shipping {
				padding: $padding;
				align-self: flex-end;
			}
		}

		.section_buttons {
			flex: 0 0 100%;
			max-width: ($max-width * 4);

			display: flex;
			flex-direction: row;
			flex-flow: wrap;
			justify-content: flex-end;

			:not(:last-child) {
				margin-right: 15px;
			}
		}

		.separator {
			flex: 0 0 100%;
			max-width: ($max-width * 4);
			margin: 15px 0;
			border-top: 1px solid $color4;
		}
	}
}

.form_field {
	flex: 0 0 100%;
	display: flex;
	flex-direction: column;

	&.label_tooltip {
		position: relative;
	}

	.label {
		font-family: $primary-font-medium;
		font-size: 14px;
		color: $color8;
		margin-bottom: 8px;

		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
		hyphens: auto;
	}

	.form_label {
		margin-bottom: 8px;
	}

	.field {
		flex: 0 1 100%;
	}

	.textarea {
		flex: 0 0 100%;

		.ds-field-group-wrapper {
			.form-group {
				.block-wrapper {
					.form-control {
						min-height: 200px;
					}
				}
			}
		}
	}

	.label_below {
		font-family: $primary-font-italic;
		font-size: 10px;
		color: $color5;
	}

	.static {
		font-family: $primary-font-regular;
		font-size: 16px;
		color: $color8;
		padding: 10px 0;

		&.bold {
			font-family: $primary-font-bold;
		}
		&.medium {
			font-family: $primary-font-medium;
		}
		&.uppercase {
			text-transform: uppercase;
		}

		& ~ .label_below {
			margin-top: -5px;
		}

		&.pending {
			color: $pending;
		}

		&.received {
			color: $received;
		}

		&.shipped {
			color: $shipped;
		}

		&.completed {
			color: $completed;
		}
	}
}

.order_status_select {
	font-family: $primary-font-medium;
	margin-bottom: 0;

	label {
		display: none;
	}

	.pending {
		&::before {
			background-color: $pending;
		}
	}

	.received {
		&::before {
			background-color: $received;
		}
	}

	.shipped {
		&::before {
			background-color: $shipped;
		}
	}

	.completed {
		&::before {
			background-color: $completed;
		}
	}
}

.order_status_static {
	font-family: $primary-font-medium;
	display: block;
	width: 100%;
	height: 36px;
	padding: 8px 0 7px 0;
	font-size: 16px;

	&.pending {
		color: $pending;
	}

	&.received {
		color: $received;
	}

	&.shipped {
		color: $shipped;
	}

	&.completed {
		color: $completed;
	}
}

.total_label {
	padding-left: 10px;
	font-family: $primary-font-bold;
	font-size: 30px;
	color: $color8;
	border-left: 2px solid $color4;
}

.tooltip_wrapper {
	position: absolute;
	right: 0;

	.tooltip {
		box-shadow: 0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
		font-weight: normal;
	}

	svg {
		width: 15px;
		height: 15px;
		position: relative;
		cursor: pointer;
		margin: 0 0 0 5px;

		g {
			path {
				fill: $color14;
			}
		}
	}
}

.status {
	display: block;
	position: relative;
	padding-left: 15px;

	&::before {
		content: '';
		width: 10px;
		height: 10px;
		border-radius: 100%;
		position: absolute;
		top: 3px;
		left: -3px;
		background-color: $color8;
	}

	&.pending::before {
		background-color: $pending;
	}

	&.received::before  {
		background-color: $received;
	}

	&.shipped::before  {
		background-color: $shipped;
	}

	&.completed::before  {
		background-color: $completed;
	}
}
