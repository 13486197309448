@import "~brands/default/styles/globals";
.info {
  display: flex;
  flex-direction: row;

  .text_initial {
    text-transform: initial;
    font-weight: normal;
  }

  svg {
      height: 14px;
      width: 14px;
      margin-left: 5px;

      path {
        fill: $color14;

        g {
          fill: $color14;
        }
      }
    }
}