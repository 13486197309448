@import "~brands/default/styles/globals";
.history_status {
	position: relative;
	padding-right: 15px;
	text-transform: capitalize;

	&:before {
		content: '';
		display: inline-block;
		top: 0;
		left: 0;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $color8;
		position: relative;
		margin-right: 15px;
	}

	&.error:before {
		background-color: $color10;
	}
	&.completed:before {
		background-color: $color13;
	}
	&.submitted:before {
		background-color: $color17;
	}
	&.in_progress:before {
		background-color: $color19;
	}
	&.scheduled:before {
		background-color: $color6;
	}
	> span {
		&.tooltip_data {
			position: relative;

			> svg {
				width: 12px;
				height: 12px;
				margin-left: 6px;
				cursor: pointer;

				path {
					fill: $color9;
				}
			}

			.tooltip {
				box-shadow: 0 3px 4px 3px rgba(0, 0, 0, 0.15);
			}
		}
	}
}

.color {
  display: flex;
  align-items: center;
  color: $color14;
  cursor: pointer;

  &.disabled {
    color: $color5;
    cursor: not-allowed;
  }

  @media only screen and (max-width: 641px) {
    width: 50%;
    padding: 10px;
  }
}

.icon {
  width: $icon-md;
  height: $icon-md;
  margin-right: 10px;
  fill: $color14;

  &.disabled {
    color: $color5;
  }
}
