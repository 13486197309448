:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

svg.addIconPlus {
  width: 22px;
  height: 28px;
  right: 5px;
  position: relative;
}

svg.addIconPlus g {
  fill: #fff;
}

svg.addIconPlus g path {
  fill: #fff;
}

.dropmenu {
  width: 175px;
  background: #fff;
  position: absolute;
  right: 5px;
  top: -15px;
  z-index: 1;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,.1);
  border: 1px solid rgba(149,149,149,.2);
  font-size: 14px;
  font-family: "attalecksans_w_regular";
  text-transform: none;
  color: #333;
}

.dropmenu .menuBottom {
  padding: 15px;
  margin: 0;
  cursor: pointer;
  color: #0568ae;
}

.dropmenu>:last-child {
  color: #cf2a2a;
}

