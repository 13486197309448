:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.reports_wrapper {
  padding: 0 15px 0 45px;
}

.reports_wrapper .reports_container {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.reports_wrapper .reports_container .reports_column {
  width: 100%;
}

.reports_wrapper .reports_container .reports_column .first_row {
  display: flex;
  height: 50px;
  align-items: center;
}

.reports_wrapper .reports_container .reports_column .first_row>:first-child {
  width: 400px;
  font-weight: bold;
}

.reports_wrapper .reports_container .reports_column .first_row .sfg_iotc {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.reports_wrapper .reports_container .reports_column .first_row .sfg_iotc>span {
  margin-bottom: 10px;
}

.reports_wrapper .reports_container .reports_column .first_row>:last-child {
  width: 305px;
  display: flex;
  font-weight: bold;
  margin-right: 30px;
}

.reports_wrapper .reports_container .reports_column .row {
  display: flex;
  height: 50px;
  align-items: center;
}

.reports_wrapper .reports_container .reports_column .row>:first-child {
  width: 400px;
}

.reports_wrapper .reports_container .reports_column .row>:nth-child(2) {
  width: 50px;
}

.reports_wrapper .reports_container .reports_column .row>:nth-child(3) {
  width: 50px;
}

.reports_wrapper .reports_container .reports_column .row>:last-child {
  width: 305px;
  display: flex;
}

.reports_wrapper .reports_container .reports_column .row>:last-child>div>:first-child {
  margin-bottom: 0;
  display: flex;
}

.reports_wrapper .reports_container .reports_column .row>:last-child>div>:first-child>:last-child {
  width: 105px;
}

.reports_wrapper .reports_container .reports_column .row>:last-child>div>:first-child>:first-child {
  width: 200px;
  margin-right: 5px;
}

.section_buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-end;
  margin: 0 0 20px 0;
  border-top: 1px #d2d2d2 solid;
  padding-top: 10px;
}

.section_buttons :not(:last-child) {
  margin-right: 15px;
}

.headline {
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  color: #191919;
  display: flex;
  border-bottom: 1px #d2d2d2 solid;
  min-height: 25px;
  align-items: center;
  margin-bottom: -5px;
}

.underline {
  margin-bottom: 70px;
  margin-top: 15px;
}

.underline .link {
  text-decoration: none;
  color: #0568ae;
}

.divider {
  height: 1px;
  background: #d2d2d2;
  box-shadow: none;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  border: 0;
}

