@import "~brands/default/styles/globals";
.reports_date{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 25px;

  > div{

    &:first-child{
      width: 145px;
      margin-right: 15px;
    }
  }

  @media only screen and (max-width: 641px) {
    width: 100%;

    > div{

      &:first-child{
        width: 50%;
        margin-right: 0;
        padding-right: 10px;
      }
    }
  }
}

.spinner__wrapper {
  min-height: 20px;
}

.reports_select{
  margin-bottom: 0;
  flex: 1;

  @media only screen and (max-width: 641px) {
    width: 145px;
    flex: 0 1 145px;
  }
}

.color {
  display: flex;
  align-items: center;
  color: $color14;
  cursor: pointer;

  &.disabled {
    color: $color5;
    cursor: not-allowed;
  }

  @media only screen and (max-width: 641px) {
    width: 50%;
    padding: 10px;
  }
}

.icon {
  width: $icon-md;
  height: $icon-md;
  margin-right: 10px;
  fill: $color14;

  &.disabled {
    color: $color5;
  }
}
