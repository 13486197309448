@import "~brands/default/styles/globals";
.clickable {
	max-width: 200px;
	cursor: pointer;
	color: $color14;
}

.check_icon {
	display: table;
	width: 25px;
	height: 25px;

	g {
		path {
			fill: $color20;
		}
	}
}

.description {
	max-height: 60px; 
	overflow-y: auto;
}

.rate_plan_name {
	max-width: 200px
}

.button_width {
  min-width: 300px;
}