@import "~brands/default/styles/globals";
.modal{
	height: 615px;
	overflow: visible;
	.modal_body_notf{
		background: $color1;
		position: static;
		height: 460px;
		@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			overflow-y: inherit;
			overflow-x: inherit;
		}
		overflow-y: unset;
		overflow-x: unset;
	}//wrapper around modal body
}

.modal_notification_wrapper{
	margin-bottom: 20px;
}

.modal_tab_content{
	overflow: visible;
}
