:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.note_span {
  padding-bottom: 10px;
  font-family: "attalecksans_w_medium";
  font-size: 14px;
  font-weight: 500;
  color: #191919;
}

.text_area_wrapper {
  margin-top: 10px;
}

.text_area_wrapper .text_area_field textarea {
  min-height: 200px;
}

.modal_body_custom {
  background: #fff;
  position: static;
  margin-bottom: 75px;
  height: auto;
}

.custom {
  height: 350px;
  overflow: visible;
}

.step_h2 {
  font-family: "attalecksans_w_medium";
  font-size: 12px;
  line-height: 1.17;
  letter-spacing: .6px;
  color: #191919;
  text-transform: uppercase;
  font-weight: 500;
}

p.description {
  font-family: "attalecksans_w_light";
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: .3px;
  color: #191919;
  padding-bottom: 0;
  margin-bottom: 20px;
}

p.description>span {
  font-family: "attalecksans_w_medium";
}

.select_label {
  font-family: "attalecksans_w_medium";
  font-size: 14px;
  line-height: 1;
  letter-spacing: .6px;
  color: #191919;
  margin-bottom: 10px;
  position: relative;
}

.select_label>svg {
  width: 12px;
  height: 12px;
  margin-left: 6px;
  cursor: pointer;
}

.select_label>svg path {
  fill: #0568ae;
}

.modal_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

div.tip {
  font-family: "attalecksans_w_light";
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: .3px;
  color: #191919;
  margin-top: auto;
  padding-bottom: 0;
}

.step_first {
  margin-bottom: 20px;
  height: 400px;
  overflow: auto;
  margin-right: -27px;
  padding-right: 27px;
}

.step_first h2 {
  font-family: "attalecksans_w_medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: .6px;
  text-align: left;
  color: #191919;
}

.step_first p {
  font-family: "attalecksans_w_light";
  font-size: 14px;
  font-weight: 300;
  line-height: 1.14;
  letter-spacing: .3px;
  text-align: left;
  color: #191919;
}

.step_first p>span {
  text-transform: capitalize;
}

.step_first .status_list {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
  width: 100%;
}

.step_first .change_rules {
  width: 100%;
  border-top: 1px solid #d2d2d2;
}

.step_first .change_rules .view {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 15px;
}

.step_first .change_rules .view>svg {
  width: 18px;
  margin-left: auto;
}

.step_first .change_rules .view>svg path {
  fill: #0568ae;
}

.step_first .change_rules .view>span {
  font-family: "attalecksans_w_light";
  font-size: 18px;
  font-weight: 300;
  line-height: .89;
  letter-spacing: .3px;
  color: #0568ae;
}

.step_first .change_rules .rules_list {
  display: flex;
  flex-direction: column;
}

.step_first .change_rules .rules_list .rule_data {
  margin-top: 17px;
  height: 0px;
  overflow: hidden;
  transition: all .2s linear;
}

.step_first .change_rules .rules_list .rule_data_show {
  height: 85px;
  border-bottom: 1px solid #d2d2d2;
}

.step_first .change_rules .rules_list .rule_data span {
  text-transform: capitalize;
  font-family: "attalecksans_w_light";
  font-size: 14px;
  font-weight: 300;
  line-height: 1.29;
  letter-spacing: .2px;
  color: #191919;
}

.step_second {
  overflow: visible;
}

.step_third {
  height: auto;
}

.please_select {
  height: 320px;
}

.please_select h2 {
  padding: 0 30px;
  font-family: "attalecksans_w_medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: .6px;
  text-align: left;
  color: #191919;
}

.current_state {
  font-weight: 500;
  text-transform: uppercase;
  font-family: "attalecksans_w_bold";
}

.endpoints_result {
  margin-top: 10px;
}

.endpoints_result>li {
  display: flex;
  font-family: "attalecksans_w_regular";
  height: 21px;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: .3px;
  text-align: left;
  color: #191919;
  align-items: center;
}

.endpoints_result>li .endpoint {
  width: auto;
}

.endpoints_result>li .loader {
  margin-left: 30px;
  margin-right: 5px;
}

.endpoints_result>li svg {
  width: 17px;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 4px;
}

.endpoints_result>li svg path {
  fill: #cf2a2a;
}

.endpoints_result>li.start {
  color: #d2d2d2;
}

.endpoints_result>li.success svg path {
  fill: #4ca90c;
}

.endpoints_result>li .notification_wrap .data {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #cf2a2a;
}

.endpoints_result>li .notification_wrap .tooltip {
  max-width: 300px;
  background-color: #cf2a2a;
}

.notification {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: center;
}

.notification svg {
  height: 45px;
}

.notification svg path {
  fill: #007a3e;
}

.notification.error svg path {
  fill: #cf2a2a;
}

.notification.error .text {
  color: #cf2a2a;
}

.notification .text {
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  letter-spacing: .8px;
  text-align: center;
  color: #007a3e;
  padding: 7px;
}

.notification .schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notification .schedule>.schedule_text {
  font-family: "attalecksans_w_regular";
  font-size: 11px;
  letter-spacing: .6px;
  text-align: center;
  color: #191919;
}

.custom {
  overflow: visible;
}

.touppercase {
  text-transform: uppercase;
}

.states_message {
  font-size: 18px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.step_third {
  height: auto;
}

.result_wrap {
  max-height: 417px;
  min-height: 417px;
  overflow-y: auto;
  overflow-x: hidden;
}

