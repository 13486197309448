svg.add_icon_plus {
	width: 22px;
	height: 28px;
	right: 5px;
	position: relative;
	cursor: pointer;

	g {
		fill: $color1;
		path {
			fill: $color1;
		}
	}
}

.content_wrapper {
	padding: 20px 20px 0 45px;
	
	article {
		margin: 15px 0 20px 0;
	}
	
	ul > li {
		list-style-type: disc;
		margin: 0 0 0 45px;
		padding: 0 0 0 10px;
	}
	
}

.subtitle {
	letter-spacing: 0.6px;
	color: $color8;
	margin: 0 0 35px 0;
	margin: 20px 15px 35px 45px;
}

.headline {
	font-family: $primary-font-medium;
	font-size: 18px;
	color: $color8;
	display: flex;
	justify-content: space-between;
	min-height: 25px;
	font-weight: 500;
	margin: 0 15px 10px 0;
	align-items: center;
}

.release_version {
	display: flex;
	align-items: center;

	> div {
		margin: 0 0 0 15px;
	}
}

.release_title {
	font-family: $primary-font-medium;
	font-size: 18px;
	color: $color14;
	font-weight: 500;
	margin: 25px 0 30px 0;
}

.headline_action {
	margin: 10px 0;

	.action_buttons {
		display: flex;
		flex-direction: row;
		flex-flow: wrap;
		justify-content: flex-end;
	
		:not(:last-child) {
			margin-right: 15px;
		}
	}
}

.border {
	border-bottom: 1px $color4 solid;
	margin: 15px 15px 20px 45px;
}

.known_issues {
  margin: 0 0 20px 0;

  .issue_type {
		font-family: $primary-font-medium;
		font-style: italic;
		margin: 0 0 0 10px;

    &::before {
      content: " - ";
		}
		
		&.fixed {
			color: $color19;
		}

		&.enhanced {
			color: $color13
		}
  }
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-end;
	margin: 0 15px 20px 0;

	:not(:last-child) {
		margin-right: 15px;
	}
}

.button_width {
  min-width: 300px;
}