:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

#wrapper {
  font-size: 15px;
  line-height: 1.6;
}

#wrapper a {
  color: #0568ae;
  text-decoration: none;
}

#wrapper em {
  font-weight: bold;
  font-style: italic;
}

#wrapper #wrapper>*:first-child {
  margin-top: 0 !important;
}

#wrapper #wrapper>*:last-child {
  margin-bottom: 0 !important;
}

#wrapper #wrapper>*:last-child h2 {
  color: #959595 !important;
}

#wrapper h3,
#wrapper h3,
#wrapper h4,
#wrapper h5,
#wrapper h6 {
  padding-left: 25px !important;
  color: #5a5a5a !important;
}

#wrapper p,
#wrapper blockquote,
#wrapper table,
#wrapper pre {
  margin: 35px 0 0 25px;
}

#wrapper ul {
  padding-left: 45px !important;
  list-style: unset;
}

#wrapper ol {
  padding-left: 45px !important;
}

#wrapper ol li {
  list-style: decimal;
}

#wrapper ol li ul:first-of-type {
  margin-top: 0;
}

#wrapper hr {
  background: rgba(0,0,0,0) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAECAYAAACtBE5DAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OENDRjNBN0E2NTZBMTFFMEI3QjRBODM4NzJDMjlGNDgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OENDRjNBN0I2NTZBMTFFMEI3QjRBODM4NzJDMjlGNDgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4Q0NGM0E3ODY1NkExMUUwQjdCNEE4Mzg3MkMyOUY0OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4Q0NGM0E3OTY1NkExMUUwQjdCNEE4Mzg3MkMyOUY0OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqqezsUAAAAfSURBVHjaYmRABcYwBiM2QSA4y4hNEKYDQxAEAAIMAHNGAzhkPOlYAAAAAElFTkSuQmCC) repeat-x 0 0;
  border: 0 none;
  color: #ccc;
  height: 4px;
  margin: 15px 0;
  padding: 0;
}

#wrapper #wrapper>h2:first-child {
  margin-top: 0;
  padding-top: 0;
}

#wrapper #wrapper>h1:first-child {
  margin-top: 0;
  padding-top: 0;
}

#wrapper #wrapper>h1:first-child+h2 {
  margin-top: 0;
  padding-top: 0;
}

#wrapper #wrapper>h3:first-child,
#wrapper #wrapper>h4:first-child,
#wrapper #wrapper>h5:first-child,
#wrapper #wrapper>h6:first-child {
  margin-top: 0;
  padding-top: 0;
}

#wrapper a:first-child h1,
#wrapper a:first-child h2,
#wrapper a:first-child h3,
#wrapper a:first-child h4,
#wrapper a:first-child h5,
#wrapper a:first-child h6 {
  margin-top: 0;
  padding-top: 0;
}

#wrapper h1+p,
#wrapper h2+p,
#wrapper h3+p,
#wrapper h4+p,
#wrapper h5+p,
#wrapper h6+p,
#wrapper ul li>:first-child,
#wrapper ol li>:first-child {
  margin-top: 0;
}

#wrapper dl {
  padding: 0;
}

#wrapper dl dt {
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  padding: 0;
  margin: 15px 0 5px;
}

#wrapper dl dt:first-child {
  padding: 0;
}

#wrapper dl dt>:first-child {
  margin-top: 0;
}

#wrapper dl dt>:last-child {
  margin-bottom: 0;
}

#wrapper dl dd {
  margin: 0 0 15px;
  padding: 0 15px;
}

#wrapper dl dd>:first-child {
  margin-top: 0;
}

#wrapper dl dd>:last-child {
  margin-bottom: 0;
}

#wrapper blockquote {
  border-left: 4px solid #d2d2d2;
  padding: 0 15px;
  color: #5a5a5a;
}

#wrapper blockquote>:first-child {
  margin-top: 0;
}

#wrapper blockquote>:last-child {
  margin-bottom: 0;
}

#wrapper table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
  font: inherit;
}

#wrapper table th {
  font-weight: bold;
  border: 1px solid #ccc;
  padding: 6px 13px;
}

#wrapper table td {
  border: 1px solid #ccc;
  padding: 6px 13px;
}

#wrapper table tr {
  border-top: 1px solid #ccc;
  background-color: #fff;
}

#wrapper table tr:nth-child(2n) {
  background-color: #f8f8f8;
}

#wrapper img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 35px -3px rgba(0,0,0,.21);
}

#wrapper code,
#wrapper tt {
  margin: 0 2px;
  padding: 0 5px;
  white-space: nowrap;
  border: 1px solid #f2f2f2;
  background-color: #f8f8f8;
  border-radius: 3px;
  font-family: Consolas,"Liberation Mono",Courier,monospace;
  font-size: 12px;
  color: #333;
}

#wrapper pre>code {
  margin: 0;
  padding: 0;
  white-space: pre;
  border: none;
  background: rgba(0,0,0,0);
}

#wrapper .highlight pre {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

#wrapper pre {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  font-size: 14px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
  margin: 26px 0;
}

#wrapper pre code,
#wrapper pre tt {
  background-color: rgba(0,0,0,0);
  border: none;
}

#wrapper sup,
#wrapper sub {
  font-size: 1.4ex;
  height: 0;
  line-height: 1;
  vertical-align: super;
  position: relative;
}

#wrapper sub {
  vertical-align: sub;
  top: -1px;
}

@media print {
  #wrapper body {
    background: #fff;
  }

  #wrapper img,
  #wrapper pre,
  #wrapper blockquote,
  #wrapper table,
  #wrapper figure {
    page-break-inside: avoid;
  }

  #wrapper #wrapper {
    background: #fff;
    border: none;
  }

  #wrapper pre code {
    overflow: visible;
  }
}

@media screen {
  #wrapper ::selection {
    background: rgba(157,193,200,.5);
  }

  #wrapper h1::selection {
    background-color: rgba(45,156,208,.3);
  }

  #wrapper h2::selection {
    background-color: rgba(90,182,224,.3);
  }

  #wrapper h3::selection,
  #wrapper h4::selection,
  #wrapper h5::selection,
  #wrapper h6::selection,
  #wrapper li::selection,
  #wrapper ol::selection {
    background-color: rgba(133,201,232,.3);
  }

  #wrapper code::selection {
    background-color: rgba(0,0,0,.7);
    color: #f2f2f2;
  }

  #wrapper code span::selection {
    background-color: rgba(0,0,0,.7) !important;
    color: #f2f2f2 !important;
  }

  #wrapper a::selection {
    background-color: rgba(255,230,102,.2);
  }

  #wrapper td::selection,
  #wrapper th::selection,
  #wrapper caption::selection {
    background-color: rgba(180,237,95,.5);
  }
}

.download_link {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 30px;
  color: #0568ae;
}

