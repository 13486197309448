:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.details {
  width: 65%;
  margin-right: 16px;
}

.subscription {
  width: 31%;
}

.mt22 {
  margin-top: 22px;
}

.divider {
  height: 1px;
  background-color: #959595;
  margin-top: 30px;
  margin-bottom: 30px;
}

.row_stats {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .row_stats {
    flex-direction: column;
  }
}

.row_stats .stats_column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row_stats .stats_column .label_header {
  font-size: 14px;
}

.row_stats .stats_column .value {
  font-size: 38px;
  color: #009fdb;
}

.row_stats .stats_column .sub {
  font-size: 18px;
}

.row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .row {
    flex-direction: column;
  }
}

.row .label_header {
  font-size: 14px;
  font-weight: 700;
}

.row .value {
  margin-top: 5px;
  font-size: 16px;
}

.row .data {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 150px;
}

.row .data:first-child {
  width: 100%;
  max-width: 213px;
}

.row .charges_wrapper {
  display: flex;
  margin-top: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 16px;
}

.row .charges_wrapper .label_header {
  width: 50%;
}

.row .charges_wrapper .value {
  font-weight: bold;
}

.row .charges_wrapper .label_header_value {
  width: 50%;
}

.row .charges_wrapper .total_value {
  color: #4ca90c;
  font-size: 34px;
}

.row .charges_wrapper .sup {
  font-size: 18px;
}

.row .header {
  font-size: 24px;
  letter-spacing: .6px;
  font-weight: normal;
}

@media only screen and (max-width: 768px) {
  .row .header {
    margin-top: 20px;
  }
}

.column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
}

.card {
  width: 100%;
  align-items: unset;
}

.details_wrapper_first {
  width: 100%;
  min-width: 70%;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .details_wrapper_first {
    flex-wrap: wrap;
  }
}

.details_wrapper_first>* {
  height: 580px;
  margin: 22px 0px 5px 16px;
}

@media only screen and (max-width: 768px) {
  .details_wrapper_first>* {
    margin: 22px 15px 5px 16px;
  }
}

@media only screen and (max-width: 1024px) {
  .details_wrapper_first>* {
    margin: 22px 15px 5px 16px;
  }
}

.details_wrapper_first strong {
  display: none;
}

.details_wrapper_second {
  width: 100%;
  min-width: 30%;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .details_wrapper_second {
    flex-wrap: wrap;
  }
}

.details_wrapper_second>* {
  height: 580px;
  margin: 22px 15px 5px 16px;
}

.wrapper_row {
  width: 100%;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 768px) {
  .wrapper_row {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1024px) {
  .wrapper_row {
    flex-direction: column;
  }
}

.details {
  width: 70%;
  min-width: 50%;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .details {
    flex-wrap: wrap;
  }
}

.subscription_charges {
  width: 30%;
  min-width: 50%;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .subscription_charges {
    flex-wrap: wrap;
  }
}

.wrapper .endpoint_stats {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20;
}

@media only screen and (max-width: 768px) {
  .wrapper .endpoint_stats {
    flex-wrap: wrap;
  }
}

.wrapper .endpoint_stats>* {
  min-height: 172px;
  margin: 16px;
  justify-content: center;
}

.total_charge {
  font-weight: bold;
}

.total_charge_explanation {
  font-size: 14px;
  margin-top: 2px;
}

