:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.chart_wrapper_wide {
  height: 100%;
  width: 100%;
}

.chart_wrapper {
  height: 95%;
  width: 100%;
}

.main {
  display: flex;
  height: 95%;
  align-items: center;
}

.main .side_unit {
  transform: rotate(-90deg);
  width: 3%;
  height: 3%;
  letter-spacing: 2px;
  padding-left: 20px;
}

.customTooltipMain {
  background: #fff;
  border-radius: 2px;
  box-shadow: rgba(0,0,0,.25) 0px 1px 2px;
  padding: 5px 9px;
}

.customTooltip {
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  word-spacing: normal;
}

.customTooltip .customTooltipBlock {
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 7px;
}

.customTooltip>:not(:first-child) {
  word-spacing: normal;
}

.legend {
  height: 10%;
  margin: -5px 40px;
  font-size: 10px;
  width: 85%;
}

.legend .legend_item {
  display: inline-block;
}

.legend .legend_item .legend_dot {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px 0 10px;
}

