:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.page_wrapper {
  margin: 0 0 0 45px;
}

@media (max-width: 480px) {
  .page_wrapper {
    margin: 0 0 0 20px;
  }
}

.form_wrapper {
  max-width: 787px;
  width: 100%;
}

.form_headline {
  font-family: "attalecksans_w_medium";
  font-size: 24px;
  letter-spacing: .6px;
  margin: 25px 0;
}

.form_logo {
  display: flex;
}

@media (max-width: 480px) {
  .form_logo {
    display: block;
  }
}

.form_logo .logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section_buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-end;
  margin: 0 15px 20px 0;
}

@media (max-width: 480px) {
  .section_buttons {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.section_buttons :not(:last-child) {
  margin-right: 15px;
}

@media (max-width: 480px) {
  .section_buttons :not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.preferred_language_info {
  display: flex;
  margin-top: 10px;
}

.preferred_language_info svg {
  margin-right: 5px;
}

.support_type_wrapper {
  margin: 0 10px 30px 0;
  font-size: 16px;
  line-height: 1.4;
}

@media only screen and (max-width: 768px) {
  .support_type_wrapper {
    margin: 0 25px 30px 0;
  }
}

.support_type_wrapper .bold {
  font-weight: bold;
}

