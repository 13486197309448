:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.wrapper_grid_card {
  height: 100%;
  width: 97%;
}

.wrapper_grid_card .wider_card {
  height: 86%;
  width: 100%;
  display: flex;
}

.wrapper_grid_card .wider_card>:nth-child(1) {
  width: 75%;
}

.wrapper_grid_card .wider_card>:nth-child(2) {
  width: 25%;
}

.wrapper_grid_card .wider_card>:only-child {
  width: 100%;
}

.wrapper_grid_card .account_overview {
  height: 83%;
}

.carousel_frames {
  height: 88%;
}

.wrapper_modal_card {
  height: 255px;
  margin-bottom: 15px;
}

.wrapper_modal_card_account_overview {
  height: 375px;
}

.form__wrapper {
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  transition: opacity .2s linear;
  position: absolute;
  right: 0;
  top: 45px;
  z-index: 100;
}

.form__active {
  visibility: visible;
  opacity: 1;
  max-height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 10px;
}

.header .title {
  font-family: "attalecksans_w_regular";
  font-size: 20px;
  line-height: 28px;
  letter-spacing: .75px;
  color: #191919;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header .title .pending {
  position: absolute;
  top: -2px;
  right: -20px;
  height: 14px;
  width: 14px;
}

.header .title .pending svg {
  width: 100%;
  cursor: pointer;
}

.header .title .pending svg path {
  fill: #ffb81c;
}

.header .icons_wrapper {
  display: flex;
  flex-direction: row;
}

.header .navigation {
  fill: #0568ae;
  transform: rotate(90deg);
  width: 34px;
  height: 34px;
}

.header .navigation svg {
  cursor: pointer;
}

.header .navigation svg path {
  fill: #0568ae;
}

.header .navigation.disabled {
  cursor: not-allowed;
}

.loader {
  height: 100%;
}

.modal_loader {
  min-height: 270px;
}

.indicator_dots_wrapper {
  position: absolute;
  width: 100%;
  z-index: 100;
  bottom: 0;
  text-align: center;
}

.indicator_dot {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 10px;
  background-color: #0568ae;
  margin: 7px 5px;
  opacity: .3;
  transition-duration: 300ms;
}

.indicator_dot_selected {
  opacity: 1;
}

.iconWrapper {
  position: absolute;
  left: 10px;
  top: 50%;
  z-index: 100;
  touch-action: none;
}

.iconWrapper .dragIcon {
  width: 20px;
  height: 20px;
  fill: #0568ae;
}

.as_of {
  display: flex;
  font-family: "attalecksans_w_light";
  font-weight: 300;
  padding: 0 5px;
  font-size: 12px;
  justify-content: center;
}

.as_of>span {
  padding: 0 5px;
}

.as_of .uppercase {
  text-transform: uppercase;
}

.footer_margin {
  margin-top: 15px;
}

