@import "~brands/default/styles/globals";
.modal__body {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
	max-height: 200px;
	padding: 0 30px 20px;
  svg {
		width: 125px;
		height: 125px;
  }

	.modal_title{
		font-family:$primary-font-medium;
	}
}

.body {
	width:80%;
	padding-right:10px;
	.modal_message{
		font-size: 18px;
		font-family: $primary-font-regular;
		line-height: 22px;
	}
	.modal_text_block{
		margin-top:15px;
	}
}// prompt body
