:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.trigger__icon {
  height: 2px;
  width: 10px;
  background: #0568ae;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.trigger__icon:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  width: 20px;
  height: 20px;
}

.trigger__icon:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #0568ae;
  transform: rotate(90deg);
}

.trigger__icon.no_subplatforms {
  pointer-events: none;
  background: rgba(0,0,0,0);
}

.trigger__icon.no_subplatforms:after {
  background: rgba(0,0,0,0);
}

.trigger__icon.open::after {
  transform: rotate(0deg);
}

.m2m_account_name {
  display: flex;
  align-items: center;
}

.accounts_wrapper {
  margin: 15px 0 0;
}

.check_icon {
  display: table;
  width: 25px;
  height: 25px;
}

.check_icon g path {
  fill: #007a3e;
}

.divider {
  height: 1px;
  background: #d2d2d2;
  box-shadow: none;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  border: 0;
}

.section_buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-end;
  margin: 0 15px 20px 0;
}

.section_buttons :not(:last-child) {
  margin-right: 15px;
}

.wrapper {
  padding: 15px 0;
}

