@import "~brands/default/styles/globals";
.modal_body {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	max-height: 300px;
	padding: 0 30px 20px;
	svg {
		width: 125px;
		height: 125px;
	}
}

.body {
	width: 80%;
	padding-right: 10px;
	.modal_message {
		font-size: 18px;
		font-family: $primary-font-regular;
		line-height: 22px;
		margin-top: 15px;
	}
} // prompt body
