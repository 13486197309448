@import "~brands/default/styles/globals";
.feedback_trigger {
	background: $color13;
	color: $color3;
	padding: 25px 15px;
	line-height: 50px;
	position: fixed;
	top: 76%;
	right: 0;
	z-index: 99;
	overflow: hidden;
	width: 140px;
	margin-top: 10px;
	border-top-left-radius: 50em;
	border-bottom-left-radius: 50em;
	border: 0;
	outline: 0;
	box-shadow: 3px 3px 20px gray;
	transform: translateX(65%);
	will-change: transform;
	transition: transform 0.35s 0s;
	cursor: pointer;

	&.trigger_active {
		display: none;
	}

	@include desktop-only {
		top: 86% !important;
	}

	&:hover {
		transform: translateX(20%) !important;

		.icon {
			opacity: 0;
			transform: translateX(0%);
		}

		.right {
			opacity: 1;
			transform: translateY(50%);
		}
	}

	.icon,
	.right {
		display: block;
		position: absolute;
		left: 10%;
		top: 0;
	}

	.icon {
		will-change: transform;
		opacity: 1;
		transform: translateX(0);
		transition: opacity 0.4s ease-in-out, transform 0.35s ease-in;

		svg {
			stroke-width: 24px;
			height: 24px;
			vertical-align: middle;
			stroke-width: 2;
			width:24px;

			path {
				fill: $color1;
			}
		}
	}

	.right {
		will-change: transform;
		transition: opacity 0.4s ease-in-out, transform 0.3s ease-out;
		opacity: 0;
		transform: translateY(10%);
	}

	.title,
	.subtitle {
		text-align: left;
	}

	.title {
		font-size: 14px;
		font-weight: 500;
		font-family: $primary-font-medium;
		color: $color1;
		line-height: 14px;
	}

	.subtitle {
		font-size: 12px;
		line-height: 12px;
		font-family: $primary-font-light;
		font-weight: 300;
	}
}
