:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

button,
hr,
input {
  overflow: visible;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

progress,
sub,
sup {
  vertical-align: baseline;
}

[type=checkbox],
[type=radio],
legend {
  box-sizing: border-box;
  padding: 0;
}

body,
ol,
ul {
  margin: 0;
}

html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

code,
kbd,
pre,
samp {
  font-family: monospace,monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0,0,0,0);
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #191919;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
select {
  text-transform: none;
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: ButtonText dotted 1px;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

[hidden],
template {
  display: none;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

@font-face {
  font-family: "attalecksans_w_regular";
  src: url("../../../brands/default/styles/fonts/ATTAleckSans_W_Rg.eot");
  src: url("../../../brands/default/styles/fonts/ATTAleckSans_W_Rg.eot") format("embedded-opentype"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_Rg.woff2") format("woff2"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_Rg.woff") format("woff"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_Rg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "attalecksans_w_medium";
  src: url("../../../brands/default/styles/fonts/ATTAleckSans_W_Md.eot");
  src: url("../../../brands/default/styles/fonts/ATTAleckSans_W_Md.eot") format("embedded-opentype"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_Md.woff2") format("woff2"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_Md.woff") format("woff"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_Md.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "attalecksans_w_light";
  src: url("../../../brands/default/styles/fonts/ATTAleckSans_W_Lt.eot");
  src: url("../../../brands/default/styles/fonts/ATTAleckSans_W_Lt.eot") format("embedded-opentype"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_Lt.woff2") format("woff2"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_Lt.woff") format("woff"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_Lt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "attalecksans_w_bold";
  src: url("../../../brands/default/styles/fonts/ATTAleckSans_W_Bd.eot");
  src: url("../../../brands/default/styles/fonts/ATTAleckSans_W_Bd.eot") format("embedded-opentype"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_Bd.woff2") format("woff2"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_Bd.woff") format("woff"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_Bd.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "attalecksans_w_italic";
  src: url("../../../brands/default/styles/fonts/ATTAleckSans_W_It.eot");
  src: url("../../../brands/default/styles/fonts/ATTAleckSans_W_It.eot") format("embedded-opentype"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_It.woff2") format("woff2"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_It.woff") format("woff"),url("../../../brands/default/styles/fonts/ATTAleckSans_W_It.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "icoPrimary";
  src: url("../../../brands/default/styles/fonts/icoPrimary.eot");
  src: url("../../../brands/default/styles/fonts/icoPrimary.eot") format("embedded-opentype"),url("../../../brands/default/styles/fonts/icoPrimary.woff2") format("woff2"),url("../../../brands/default/styles/fonts/icoPrimary.woff") format("woff"),url("../../../brands/default/styles/fonts/icoPrimary.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:global .icon-badgealert:before {
  content: "";
}

:global .icon-misc-alarmL:before {
  content: "";
}

:global .icon-misc-alarm:before {
  content: "";
}

:global .icon-misc-awardL:before {
  content: "";
}

:global .icon-misc-award:before {
  content: "";
}

:global .icon-misc-barrelL:before {
  content: "";
}

:global .icon-misc-barrel:before {
  content: "";
}

:global .icon-misc-brushL:before {
  content: "";
}

:global .icon-misc-bulbL:before,
:global .icon-misc-ideationL:before {
  content: "";
}

:global .icon-misc-bulb:before,
:global .icon-misc-ideation:before {
  content: "";
}

:global .icon-misc-businessL:before {
  content: "";
}

:global .icon-misc-business:before {
  content: "";
}

:global .icon-misc-businesscontinuityL:before {
  content: "";
}

:global .icon-misc-businesscontinuity:before {
  content: "";
}

:global .icon-misc-calendarL:before {
  content: "";
}

:global .icon-misc-calendar:before {
  content: "";
}

:global .icon-misc-clothespinL:before {
  content: "";
}

:global .icon-misc-clothespin:before {
  content: "";
}

:global .icon-misc-colorpalette:before {
  content: "";
}

:global .icon-misc-customersupportL:before {
  content: "";
}

:global .icon-misc-customersupport:before {
  content: "";
}

:global .icon-misc-datetimeL:before {
  content: "";
}

:global .icon-misc-datetime:before {
  content: "";
}

:global .icon-misc-dimmer:before {
  content: "";
}

:global .icon-misc-dimmeroff:before {
  content: "";
}

:global .icon-misc-environmentalL:before {
  content: "";
}

:global .icon-misc-environmental:before {
  content: "";
}

:global .icon-misc-eraserL:before {
  content: "";
}

:global .icon-misc-farmingL:before {
  content: "";
}

:global .icon-misc-farming:before {
  content: "";
}

:global .icon-misc-featherL:before {
  content: "";
}

:global .icon-misc-feather:before {
  content: "";
}

:global .icon-misc-filter:before {
  content: "";
}

:global .icon-misc-handgunL:before {
  content: "";
}

:global .icon-misc-handgun:before {
  content: "";
}

:global .icon-misc-hotwaterheater:before {
  content: "";
}

:global .icon-misc-housework:before {
  content: "";
}

:global .icon-misc-integrationL:before {
  content: "";
}

:global .icon-misc-integration:before {
  content: "";
}

:global .icon-misc-lawenforcementL:before {
  content: "";
}

:global .icon-misc-lawenforcement:before {
  content: "";
}

:global .icon-misc-legaldepartmentL:before {
  content: "";
}

:global .icon-misc-legaldepartment:before {
  content: "";
}

:global .icon-misc-lightsoffL:before {
  content: "";
}

:global .icon-misc-lightsoff:before {
  content: "";
}

:global .icon-misc-lightson:before {
  content: "";
}

:global .icon-misc-magnetL:before {
  content: "";
}

:global .icon-misc-magnet:before {
  content: "";
}

:global .icon-misc-maintenanceL:before {
  content: "";
}

:global .icon-misc-maintenance:before {
  content: "";
}

:global .icon-misc-mortarboardL:before {
  content: "";
}

:global .icon-misc-mortarboard:before {
  content: "";
}

:global .icon-misc-motiongraphics:before {
  content: "";
}

:global .icon-misc-mugL:before {
  content: "";
}

:global .icon-misc-mug:before {
  content: "";
}

:global .icon-misc-oilandgasL:before {
  content: "";
}

:global .icon-misc-oilandgas:before {
  content: "";
}

:global .icon-misc-operationsL:before {
  content: "";
}

:global .icon-misc-operations:before {
  content: "";
}

:global .icon-misc-party:before {
  content: "";
}

:global .icon-misc-penL:before {
  content: "";
}

:global .icon-misc-pen:before {
  content: "";
}

:global .icon-misc-piechartL:before {
  content: "";
}

:global .icon-misc-piechart:before {
  content: "";
}

:global .icon-misc-puzzleL:before {
  content: "";
}

:global .icon-misc-puzzle:before {
  content: "";
}

:global .icon-misc-stopwatchL:before {
  content: "";
}

:global .icon-misc-stopwatch:before {
  content: "";
}

:global .icon-misc-targetL:before {
  content: "";
}

:global .icon-misc-target:before {
  content: "";
}

:global .icon-misc-tempwatersensorL:before {
  content: "";
}

:global .icon-misc-tempwatersensor:before {
  content: "";
}

:global .icon-misc-time:before {
  content: "";
}

:global .icon-misc-timeL:before {
  content: "";
}

:global .icon-misc-timeandmoneyL:before {
  content: "";
}

:global .icon-misc-timeandmoney:before {
  content: "";
}

:global .icon-misc-timetrigger:before {
  content: "";
}

:global .icon-misc-toolsL:before {
  content: "";
}

:global .icon-misc-tools:before {
  content: "";
}

:global .icon-misc-trashL:before {
  content: "";
}

:global .icon-misc-trash:before {
  content: "";
}

:global .icon-misc-treeL:before {
  content: "";
}

:global .icon-misc-tree:before {
  content: "";
}

:global .icon-misc-trophyL:before {
  content: "";
}

:global .icon-misc-trophy:before {
  content: "";
}

:global .icon-misc-utilitiesL:before {
  content: "";
}

:global .icon-misc-utilities:before {
  content: "";
}

:global .icon-misc-verified:before {
  content: "";
}

:global .icon-misc-waterL:before {
  content: "";
}

:global .icon-misc-water:before {
  content: "";
}

:global .icon-misc-waterbottleL:before {
  content: "";
}

:global .icon-misc-waterbottle:before {
  content: "";
}

:global .icon-misc-waterleakL:before {
  content: "";
}

:global .icon-misc-waterleak:before {
  content: "";
}

:global .icon-misc-watermainL:before {
  content: "";
}

:global .icon-misc-watermain:before {
  content: "";
}

:global .icon-misc-pets:before {
  content: "";
}

:global .icon-misc-petsL:before {
  content: "";
}

body {
  font-size: 14px;
  font-family: "attalecksans_w_regular";
  overflow-x: hidden;
}

body {
  font-size: 14px;
  font-family: "attalecksans_w_regular";
  overflow-x: hidden;
}

.logo {
  position: relative;
  display: flex;
  align-items: center;
  height: 58px;
  cursor: pointer;
  width: 30px;
}

.logo>path {
  fill: #009fdb;
}

.profile_avatar {
  width: 36px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profile_avatar svg {
  fill: none;
  width: 24px;
  height: 24px;
}

.profile_avatar svg path {
  fill: #fff;
  stroke: #fff;
}

.global_container {
  display: flex;
  margin-top: 58px;
}

.global_container .main_right {
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
  transition: all .5s linear;
  position: relative;
}

@media (max-width: 640px) {
  .global_container .main_right {
    width: 100%;
  }
}

.global_container .main_right.no_sidebar {
  width: 100%;
}

.global_container .main_right.collapsed {
  width: calc(100% - 85px);
}

.link_icon {
  width: 16px;
  height: 16px;
  fill: #d2d2d2;
  position: relative;
  top: 7px;
}

.link_icon path {
  fill: #d2d2d2;
}

.additional_offset {
  margin-top: 116px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

