:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.submit_column {
  max-width: 654px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.submit_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}

.submit_wrapper .field_wrapper {
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
}

.submit_wrapper .field_wrapper .field_label {
  margin-bottom: 5px;
}

.submit_wrapper .success_ticket {
  color: #007a3e;
  font-size: 21px;
  font-weight: 700;
  width: 100%;
  text-align: center;
}

.submit_wrapper .fail_ticket {
  color: #cf2a2a;
  font-size: 21px;
  font-weight: 700;
  width: 100%;
  text-align: center;
}

.submit_wrapper .icon_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.submit_wrapper .icon_wrapper .success_icon {
  width: 68px;
  height: 68px;
}

.submit_wrapper .icon_wrapper .fail_icon {
  width: 85px;
  height: 79px;
}

.submit_wrapper .icon_wrapper .fail_icon path {
  fill: #cf2a2a;
}

