@import "~brands/default/styles/globals";
.diagnostics_wrapper{
  .header {
		padding: 0 30px 30px;
		border-bottom: 1px solid $color4;
  }

  .main {
		display: flex;
		flex-flow: row wrap;
		margin: 30px 15px;
  }

  .diagnostics_details {
		flex: 2;
		padding: 0 30px;
		@media (max-width: 901px) {
			padding: 0;
    }

    .test_wrapper{
      display: flex;

      & + .test_wrapper{
        margin-top: 45px;
      }

      .graphics{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .succes{
          height: 80px;

          path{
            fill: $color19;
          }
        }

        .orange{
          height: 80px;

          path{
            fill: $color11;
          }
        }

        .dots{
          height: 35px;
          margin-top:55px;

          circle{
            fill: $color19;
          }

          &.dots_error{

            circle{
              fill: $color11;
            }
          }
        }

        .error{
          height: 80px;

          path{
            fill: $color11;
          }
        }
      }

      .data{
        flex: 2;
        display: flex;
        flex-direction: column;

        .title{
          text-transform: uppercase;
          font-family: $primary-font-medium;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.17;
          letter-spacing: 0.6px;
          text-align: left;
          color: $color8;
          width: 100%;
          border-bottom: 1px solid $color19;
          padding: 0 28px 13px 28px;
          margin-bottom: 17px;

          &.error{
            border-bottom: 1px solid $color11;
          }

          &.orange{
            border-bottom: 1px solid $color11;
          }
        }

        .data_name{
          display: flex;
          width: 100%;
          justify-content: space-between;
          position: relative;
          padding:0 28px;
          margin-bottom: 15px;
          overflow: hidden;

          &:before{
            content: '';
            position: absolute;
            bottom: 6px;
            width: calc(100% - 56px);
            line-height: 0;
            border-bottom: 1px dashed $color5;
          }

          span{
            font-family: $primary-font-regular;
            font-size: 16px;
            color: $color8;
            padding-right: 20px;
            background-color: $color1;
            z-index: 1;
          }

          svg{
            height: 18px;
						width: 28px;
            background-color: $color1;
            z-index: 1;
            padding-left: 10px;
						margin-left: auto;

            path{
              fill: $color19;
            }

            &.ncheck{
              cursor: pointer;

              path{
                fill: $color11;
              }
            }

            &.badge_alert{
              cursor: pointer;

              path{
                fill: $color11;
              }
            }
          }

          .tooltip_data{
            display: flex;
            width: 358px;
            flex-direction: column;

            .data_title{
              font-family: $primary-font-medium;
              color: $color8;
              margin-bottom: 10px;
            }

            pre{
              background: $color1;
              border-left: none;
              font-family: $primary-font-light;
              font-size:14px;
              color: $color8;
              line-height: 1.29;
              letter-spacing: 0.3px;
            }
          }

          .tooltip {
            box-shadow:0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
            opacity: 1;
          }
        }

        .retry_test{
          display: flex;
          align-items: center;
          height: 26px;
          padding-left: 32px;
          margin-top: 18px;
          cursor: pointer;

          svg{
            height: 19px;
            margin-right: 4px;

            path{
              fill: $color14;
            }
          }

          span{
            font-family: $primary-font-regular;
            font-size: 18px;
            color: $color14;
            line-height: 0.94;
          }
        }

        button.align_self {
          align-self: flex-start;
        }
      }
    }

	}

  .registration_details {
		flex: 1;
		align-self: flex-start;
    padding: 15px;
    position: relative;

		.rd_title {
			font-size: 12px;
			font-weight: bold;
			line-height: 1.17;
			text-transform: uppercase;
			letter-spacing: 0.6px;
			color: $color8;
			padding-bottom: 10px;
    }

		.rd_fields {

			.rd_field {
				font-size: 14px;
				font-weight: bold;
        color: $color8;

				&_value {
					font-size: 16px;
					padding-top: 20px;
					padding-bottom: 25px;
					font-weight: normal;
        }

				&:last-child {

					.rd_field_value {
						padding-bottom: 15px;
					}
				}
			}
    }

    .tablet_icon{
      position: absolute;
      bottom: 12px;
      right: 8px;
      height: 90px;

      path{
        fill: $color4;
      }
    }
	}
}

.tooltip {
	box-shadow:0 5px 12px 0.2px rgba(0, 0, 0, 0.2)
}

.section_title{
  text-transform: uppercase;
  font-family: $primary-font-medium;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.06px;
  color: $color8;
  border-bottom: 1px solid $color4;
  width: 87%;
  margin: 23px 0 23px 13%;
  padding:0 15px 14px;
}

.no_data{
	flex: 6;
}
