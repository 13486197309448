@import "~brands/default/styles/globals";
.dashboard_modal_body {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
	max-height: 200px;
	padding: 0 30px;

  svg {
		width: 125px;
		height: 125px;
	}
	
	&.modal_body_only{
		margin-bottom: 100px;
	}

	.modal_title{
		font-family:$primary-font-medium;
	}

  &_required{
    svg{
      path{
        fill: $color10;
      }
    }
  }
}

.prompt_body {
	width:80%;
	padding-right:10px;

	.modal_message{
		font-size: 18px;
		font-family: $primary-font-regular;
		line-height: 22px;
	}

	.modal_text_block{
		margin-top:15px;
	}
}

.modal_prompt_buttons{
	text-align: right;
	display: inline;
}