:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.usage_trend_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85%;
  padding-left: 5px;
}

.wide_card_border_line {
  border-left: 1px solid #d2d2d2;
  padding-left: 0;
  margin-top: 25px;
}

.usage_trend_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
}

.usage_trend_header {
  text-transform: uppercase;
  color: #d2d2d2;
  font-size: calc(14px + 2*(100vw - 300px)/1637);
  line-height: 24px;
}

.usage_trend_header_modal {
  text-transform: uppercase;
  color: #d2d2d2;
  font-size: calc(14px + 0*(100vw - 300px)/1637);
  line-height: 24px;
}

.usage_trend_item {
  font-weight: bold;
  font-size: calc(30px + 20*(100vw - 300px)/1637);
}

.usage_trend_item :nth-child(2) {
  font-size: calc(15px + 5*(100vw - 300px)/1637);
}

.modal_usage_trend_item {
  font-size: calc(30px + 0*(100vw - 300px)/1637);
}

.usage_trend_index {
  font-weight: bold;
  font-size: calc(30px + 0*(100vw - 300px)/1637);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.usage_trend_index>:nth-child(2) {
  margin-right: 5px;
  border-left: 9px solid rgba(0,0,0,0);
  border-right: 9px solid rgba(0,0,0,0);
}

.usage_trend_index_modal {
  font-size: calc(25px + 0*(100vw - 300px)/1637);
}

.arrow_up {
  border-bottom: 8px solid red;
}

.arrow_down {
  border-top: 8px solid green;
}

