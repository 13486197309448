@import "~brands/default/styles/globals";
.chart_wrapper{
	.main {
		flex-direction: column;
		align-items: center;
		display: flex;

		.data_usage_icon {
			width: 96px;
			height: 96px;
			margin-top: 70px;
			margin-bottom: 70px;
			path {
				fill: $color14;
			}
		}
		.content {
			display: flex;
			flex-flow: column wrap;
			align-items: center;
			font-size: 11px;
			font-weight: 300;
			color: $color8;
			&__total {
				font-size: 24px;
			}
		}
	}
}
