@import "~brands/default/styles/globals";
.clickable {
	cursor: pointer;
	color: $color14;
	word-break: break-all;
	max-width: 202px;
	display: inline-block;
}

.disabled {
	cursor: not-allowed;
	color: $color5;
	word-break: break-all;
	max-width: 202px;
	display: inline-block;
}

.status {
	display: block;
	position: relative;
	padding-left: 15px;

	&::before {
		content: '';
		width: 10px;
		height: 10px;
		border-radius: 100%;
		position: absolute;
		top: 3px;
		left: -3px;
		background-color: $color8;
	}

	&.success::before {
		background-color: $not-success-bg;
	}

	&.error::before {
		background-color: $not-error-bg;
	}
}

.actions {
	display: flex;

	div {
		color: $color14;
		cursor: pointer;
	}
}

.button_width {
	min-width: 250px;
}

.wide_clickable_column {
	min-width: 190px;
	color: $color14;
	cursor: pointer;
	word-break: break-word;
	max-width: 202px;
}
.icon {
	width: $icon-lg;
	fill: $color14;
	margin: 0 10px 0 0;
	cursor: pointer;
	height: 20px;

	> g {
		> path {
			fill: $color14;
		}
	}

	&:last-child {
		margin-right: 0;
	}

	&.disabled {
		cursor: not-allowed;
		path {
			fill: $color5;
		}
		> g {
			> path {
				fill: $color5;
			}
		}
	}
}
.tooltip {
	box-shadow: 0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
}
