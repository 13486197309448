:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

div.custom_option {
  position: relative;
  padding-left: 18px !important;
}

div.custom_option::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 12px;
  background-color: #000;
}

div.custom_option.select_single_value {
  padding-left: 15px !important;
  transform: translateY(0);
}

div.custom_option.select_single_value::before {
  top: 50%;
  margin-top: -5px;
  left: 2px;
}

div.custom_option.pending::before {
  background-color: #ffb81c;
}

div.custom_option.not_applicable::before {
  background-color: #5a5a5a;
}

div.custom_option.received::before {
  background-color: #0568ae;
}

div.custom_option.implemented::before {
  background-color: #4ca90c;
}

div.custom_option.conflicted::before {
  background-color: #cf2a2a;
}

div.custom_option.pending_for_deletion::before {
  background-color: #ea7400;
}

.select_action,
.select_action:focus {
  outline: none !important;
  color: #191919 !important;
  background-color: #f2f2f2 !important;
  font-weight: 900 !important;
  padding-left: 4px !important;
}

.paddingOption {
  padding-left: 8px !important;
}

