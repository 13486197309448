@import "~brands/default/styles/globals";
.modal {
	width: 521px;
	height: 479px;
}

.modal_body {
	padding: 10px 20px;
	width: 100%;
}

.first_row {
	display: flex;
}

.first_field {
	width: 311px;
	height: 35px;
	margin-right: 14px;
}

.second_field {
	width: 155px;
	height: 35px;
}

.checkbox {
	margin-top: 60px;
	height: 20px;
}

.description {
	width: 480px;
	margin-top: 20px;
}
