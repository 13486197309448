@import "~brands/default/styles/globals";
.details {
	width: 65%;
	margin-right: 16px;
}

.subscription {
	width: 31%;
}

.mt22 {
	margin-top: 22px;
}

.divider {
	height: 1px;
	background-color: $color5;
	margin-top: 30px;
	margin-bottom: 30px;
}

.row_stats {
	display: flex;
	width: 100%;
	height: 100%;
	padding: 20px;
	justify-content: space-between;
	@media only screen and (max-width: 768px) {
		flex-direction: column;
	}

	.stats_column {
		display: flex;
		flex-direction: column;
		align-items: center;

		.label_header {
			font-size: 14px;
		}
		.value {
			font-size: 38px;
			color: $color13;
		}
		.sub {
			font-size: 18px;
		}
	}
}

.row {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;

	@media only screen and (max-width: 768px) {
		flex-direction: column;
	}

	.label_header {
		font-size: 14px;
		font-weight: 700;
	}
	.value {
		margin-top: 5px;
		font-size: 16px;
	}

	.data {
		margin-top: 24px;
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 150px;

		&:first-child {
			width: 100%;
			max-width: 213px;
		}
	}

	.charges_wrapper {
		display: flex;
		margin-top: 16px;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		font-size: 16px;

		.label_header {
			width: 50%;
		}
		.value {
			font-weight: bold;
		}

		.label_header_value {
			width: 50%;
		}
		.total_value {
			color: $color19;
			font-size: 34px;
		}
		.sup {
			font-size: 18px;
		}
	}

	.header {
		font-size: 24px;
		letter-spacing: 0.6px;
		font-weight: normal;

		@media only screen and (max-width: 768px) {
			margin-top: 20px;
		}
	}
}

.column {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 20px;
	overflow: auto;
}

.card {
	width: 100%;
	align-items: unset;
}

.details_wrapper_first {
	width: 100%;
	min-width: 70%;
	display: flex;
	@media only screen and (max-width: 768px) {
		flex-wrap: wrap;
	}

	& > * {
		height: 580px;
		margin: 22px 0px 5px 16px;
		@media only screen and (max-width: 768px) {
			margin: 22px 15px 5px 16px;
		}
		@media only screen and (max-width: 1024px) {
			margin: 22px 15px 5px 16px;
		}
	}

	strong {
		display: none;
	}
}

.details_wrapper_second {
	width: 100%;
	min-width: 30%;
	display: flex;
	@media only screen and (max-width: 768px) {
		flex-wrap: wrap;
	}

	& > * {
		height: 580px;
		margin: 22px 15px 5px 16px;
	}
}

.wrapper_row {
	width: 100%;
	display: flex;
	flex-direction: row;
	@media only screen and (max-width: 768px) {
		flex-direction: column;
	}
	@media only screen and (max-width: 1024px) {
		flex-direction: column;
	}
}

.details {
	width: 70%;
	min-width: 50%;
	display: flex;
	@media only screen and (max-width: 768px) {
		flex-wrap: wrap;
	}
}

.subscription_charges {
	width: 30%;
	min-width: 50%;
	display: flex;
	@media only screen and (max-width: 768px) {
		flex-wrap: wrap;
	}
}
.wrapper {
	.endpoint_stats {
		width: 100%;
		height: 100%;
		display: flex;
		padding: 20;
		@media only screen and (max-width: 768px) {
			flex-wrap: wrap;
		}

		& > * {
			min-height: 172px;
			margin: 16px;
			justify-content: center;
		}
	}
}

.total_charge {
  font-weight: bold;
}

.total_charge_explanation {
  font-size: 14px;
  margin-top: 2px;
}