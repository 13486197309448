:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.entitlements_title {
  font-size: 12px;
  font-family: "attalecksans_w_medium";
  line-height: 1.17;
  letter-spacing: .6px;
  color: #191919;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.entitlements_details {
  border-top: 1px solid #d2d2d2;
  padding-top: 25px;
  display: flex;
}

.entitlements_details .entitlements_details_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.entitlements_details .entitlements_details_container:nth-child(2) {
  margin-left: 31px;
}

.entitlements_details.apns {
  flex-wrap: wrap;
  padding-bottom: 25px;
}

.entitlements_details.apns .entitlements_details_container:not(:last-child) {
  border-bottom: 1px solid #d2d2d2;
}

.entitlements_details.apns .entitlements_details_container:not(:first-child) {
  padding-top: 25px;
}

.entitlements_details.apns .entitlements_details_container:nth-child(2) {
  margin-left: auto;
}

.entitlements_apn {
  border-top: 1px solid #d2d2d2;
  padding-top: 25px;
}

.entitlements_details_item {
  display: flex;
  margin-bottom: 17px;
}

.entitlements_item_title {
  font-size: 14px;
  font-weight: 500;
  font-family: "attalecksans_w_medium";
  line-height: 14px;
  text-align: left;
  color: #191919;
  width: 100%;
}

.entitlements_item_data {
  font-size: 16px;
  font-family: "attalecksans_w_regular";
  line-height: 16px;
  text-align: right;
  color: #191919;
  width: 100%;
}

.modal {
  height: 600px;
  overflow: visible;
}

.modal .modal_body_custom {
  background: #fff;
  position: static;
  height: 445px;
  overflow: visible;
}

.select_field_wrapper {
  padding-bottom: 30px;
}

.select_field_wrapper.rate_plan {
  padding-bottom: 0;
}

.select_field_wrapper.datepicker {
  margin-top: 14px;
}

.select_field_label {
  padding: 0 0 10px 0;
  font-weight: bold;
  text-transform: uppercase;
}

.select_field_label.normal_font_weight {
  font-weight: normal;
}

.select_field_label_text {
  font-family: "attalecksans_w_light";
  font-size: 14px;
  color: #191919;
  line-height: 16px;
  padding-bottom: 16px;
}

.select_field_label_text.rate_plan {
  font-family: "attalecksans_w_medium";
}

.rate_plan_list {
  display: flex;
}

.rate_plan_list .rate_plan_name {
  font-family: "attalecksans_w_medium";
  font-size: 14px;
  line-height: 1;
  color: #191919;
  padding-right: 105px;
  text-transform: uppercase;
}

.rate_plan_list .rate_plan_id {
  font-size: 16px;
  line-height: 1;
  color: #191919;
}

.custom {
  height: 400px;
  overflow: visible;
}

.custom.step_1 {
  overflow-y: auto;
}

.entitlement_info {
  padding-bottom: 30px;
}

.current_network_entitlement {
  font-size: 12px;
  color: #4ca90c;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.current_network_entitlement svg {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.current_network_entitlement svg path {
  fill: #4ca90c;
}

.schedule_initiate_message {
  padding: 0 0 30px 0;
}

.schedule_initiate_message span:nth-child(2) {
  font-weight: bold;
}

.step_first {
  text-transform: uppercase;
}

.step_first h2 {
  font-family: "attalecksans_w_medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: .6px;
  text-align: left;
  color: #191919;
}

.customFieldLabelsContainer {
  padding: 0 30px;
  overflow: auto;
}

.modal_body_error_message {
  padding: 0 30px;
}

.modal_tab_content {
  overflow: visible;
}

