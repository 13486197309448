:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.steps_container {
  height: 100%;
  overflow-y: auto;
  max-height: 300px;
}

.steps_container>* {
  margin-bottom: 5px;
}

.steps_header {
  letter-spacing: .8px;
  line-height: 14px;
  font-size: 12px;
  font-family: "attalecksans_w_medium";
}

.steps_no_data {
  padding: 10px;
}

.steps_wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  flex-wrap: wrap;
}

.margin_top_bottom_10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.fetch_data_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.m2m_account_id_loader {
  min-height: 50px;
  max-height: 50px;
}

.lastActiveFilter>div>label {
  cursor: not-allowed;
}

.date_range_picker>div>div {
  justify-content: start;
}

