:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.select_field_wrapper {
  padding-bottom: 30px;
}

.select_field_wrapper.rate_plan {
  padding-bottom: 0;
}

.select_field_wrapper.rate_plan.datepicker {
  margin-top: 14px;
}

.select_field_label {
  padding: 0 0 10px 0;
  font-weight: bold;
  text-transform: uppercase;
}

.select_field_label.normal_font_weight {
  font-weight: normal;
}

.select_field_label_text {
  font-family: "attalecksans_w_light";
  font-size: 14px;
  color: #191919;
  line-height: 16px;
  padding-bottom: 16px;
}

.select_field_label_text.rate_plan {
  font-family: "attalecksans_w_medium";
}

.step_first {
  text-transform: uppercase;
}

.step_first h2.touppercase {
  text-transform: uppercase;
  font-family: "attalecksans_w_medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: .6px;
  text-align: left;
  color: #191919;
}

.rate_plan_table {
  position: relative;
  width: 525px;
}

.rate_plan_table div {
  overflow-x: scroll;
  margin-left: 132px;
}

.rate_plan_table table {
  border-collapse: collapse;
  text-align: left;
}

.rate_plan_table th:not(:first-child) {
  border-right: 1px solid #d2d2d2;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  font-weight: normal;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.rate_plan_table th:not(:first-child).selected {
  background-color: #0568ae;
  color: #fff;
  border-color: #0568ae;
  border-bottom-color: #fff;
}

.rate_plan_table th:last-child {
  border-right: 1px solid #d2d2d2;
}

.rate_plan_table td {
  padding: 5px;
  min-width: 150px;
}

.rate_plan_table td:not(:first-child) {
  border-right: 1px solid #d2d2d2;
}

.rate_plan_table td.selected {
  background-color: #0568ae;
  color: #fff;
  border-color: #0568ae;
}

.rate_plan_table th:first-child {
  width: 131px;
  height: 27px;
  z-index: 1000;
  top: 0;
  left: 1px;
  position: absolute;
  border-right: 1px solid #d2d2d2;
}

.rate_plan_table td:first-child {
  border-left: 1px solid #d2d2d2;
  border-collapse: collapse;
  font-weight: bold;
  width: 132px;
  position: absolute;
  left: 0;
  z-index: 1000;
  min-width: 130px;
  height: 45px;
  border-right: 1px solid #d2d2d2;
}

.rate_plan_table td:last-child {
  border-right: 1px solid #d2d2d2;
}

.rate_plan_table tr:first-child td:first-child {
  border-top: 1px solid #d2d2d2;
  top: 27px;
}

.rate_plan_table tr:first-child td:last-child {
  border-right: 1px solid #d2d2d2;
}

.rate_plan_table tr:last-child td:first-child {
  border-bottom: 1px solid #d2d2d2;
}

.rate_plan_table tr:last-child td:last-child {
  border-right: 1px solid #d2d2d2;
}

.rate_plan_table tr:last-child td:not(:first-child) {
  padding-bottom: 7px;
}

.first_step {
  height: 420px;
  overflow: auto;
  margin-right: -27px;
  padding-right: 27px;
}

.custom {
  overflow: visible;
}

.notification {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: center;
}

.notification svg {
  height: 45px;
}

.notification svg path {
  fill: #007a3e;
}

.notification.error svg path {
  fill: #cf2a2a;
}

.notification.error .text {
  color: #cf2a2a;
}

.notification .text {
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  letter-spacing: .8px;
  text-align: center;
  color: #007a3e;
  padding: 7px;
}

.notification .schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notification .schedule>.schedule_text {
  font-family: "attalecksans_w_regular";
  font-size: 11px;
  letter-spacing: .6px;
  text-align: center;
  color: #191919;
}

.sims_result {
  margin-top: 10px;
}

.sims_result>li {
  display: flex;
  font-family: "attalecksans_w_regular";
  height: 21px;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: .3px;
  text-align: left;
  color: #191919;
  align-items: center;
}

.sims_result>li .sim {
  width: auto;
}

.sims_result>li .loader {
  margin-left: 30px;
  margin-right: 5px;
}

.sims_result>li svg {
  width: 17px;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 4px;
}

.sims_result>li svg path {
  fill: #cf2a2a;
}

.sims_result>li.start {
  color: #d2d2d2;
}

.sims_result>li.success svg path {
  fill: #4ca90c;
}

.sims_result>li .notification_wrap .data {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #cf2a2a;
}

.sims_result>li .notification_wrap .tooltip {
  max-width: 300px;
  background-color: #cf2a2a;
}

.step_third {
  height: auto;
}

