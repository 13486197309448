@import "~brands/default/styles/globals";

.dropzone {
	width: 100%;
	height: 105px;
	border-radius: 12px;
	background-color: $color3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 0 auto 15px;

	@include desktop-and-below{
		margin-bottom: 5px;
	}
	.text {
		font-family: $primary-font-regular;
		color: $color5;
		font-size: 12px;

		.title {
			text-align: center;
		}//title of text block

	}//wrapper around text inside dropzone

	&.error {
		border-color: $terminate-status;
		.text {
			color: $terminate-status;
		}
	}

	.uploadIcon {
		width: 42.5px;
		height: 42.5px;
		g {
			path {
				fill: $color5;

			}//dropzone icon fill color

		}

	} //dropzone upload Icon

}//dropzone

p {
	@include desktop-and-below{
		margin-bottom: 5px;
		margin-top:5px;
	}
}

.dropzone:hover {
	border: 2px dashed $gradient_default_first_color;

	.text {
		color: $gradient_default_first_color;
	} //dropzone title hover color

	.uploadIcon {
		g {
			path {
				fill: $gradient_default_first_color;
			} //hover upload color fill
		}
	}

} //dropzone bulk upload hocer color

.error{
	color: $color10;
}
