:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.modal .dashboard__modal__body {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 220px;
}

.modal .dashboard__modal__body svg {
  width: 125px;
  height: 125px;
  margin-top: -50px;
}

.modal .dashboard__modal__body .modal_title {
  font-family: "attalecksans_w_medium";
}

.modal .dashboard__modal__body_required svg path {
  fill: #cf2a2a;
}

.prompt__body {
  margin-left: 30px;
  padding-right: 80px;
}

.prompt__body .modal_message {
  font-size: 18px;
  font-family: "attalecksans_w_regular";
  line-height: 22px;
}

.prompt__body .modal_text_block {
  margin-top: 20px;
  margin-bottom: 20px;
}

