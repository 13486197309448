:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.wrapper {
  padding: 20px;
  position: relative;
}

.wrapper .faq_menu_container {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  width: 14.286em;
  float: left;
  padding-top: 6px;
}

.wrapper .faq_menu_container .faq_menu {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.wrapper .faq_menu_container .faq_menu li {
  margin: 10px 0;
  cursor: pointer;
}

.wrapper .faq_menu_container .faq_menu li span {
  font-family: "attalecksans_w_medium";
  font-size: 13px;
  letter-spacing: .5px;
  color: #191919;
  line-height: 10px;
}

.wrapper .faq_menu_container .faq_menu li span:hover {
  color: #0568ae;
}

.wrapper .main_content {
  margin-left: 14.286em;
  border-left: 1px solid #d2d2d2;
  padding-left: 20px;
}

