@import "~brands/default/styles/globals";
svg.addIconPlus {
	width: 22px;
	height: 28px;
	right:5px;
	position: relative;
	g {
		fill: $color1;
		path{
			fill: $color1;
		}
	}
}

.dropmenu {
	width: 175px;
	background: $color1;
	position: absolute;
	right: 5px;
	top: -15px;
	z-index: 1;
	border-radius: 6px;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
	border: 1px solid rgba($color5, 0.2);
	font-size: $primary-font-size;
	font-family: $primary-font-regular;
	text-transform: none;
	color: $color7;

	.menuBottom {
		padding: 15px;
		margin: 0;
		cursor: pointer;
		color: $color14;
	}

	& > :last-child {
		color: $color10;
	}
}
