@import "~brands/default/styles/globals";
.rectangle{
  width: 181px;
  height: 117px;
  border-radius: 3px;
  border: solid 2px $color3;
}

.text_container{
  height:51px;
  margin:10px;
}

.title, .title_disabled{
  font-size: 14px;
  margin:0;
}

.title{
  color: $color14;
}

.title_disabled{
  color: $color4;
}

.subtitle, .subtitle_disabled{
  font-size: 14px;
  line-height: 1.14;
  margin-top: 8px;
}

.subtitle_disabled{
  color: $color4;
}

.enabled_button, .disabled_button{
  margin: 0;
  width: 100%;
  height: 42px;
  font-size: 18px;
  border: none;
  line-height: 0.94;
}

.enabled_button{
  cursor: pointer;
  color: $color2;
  background-image: linear-gradient(to top, $color14, #087ac2);
}

.disabled_button{
  cursor: not-allowed;
}