@import "~brands/default/styles/globals";
.action_wrap{
  margin-top:15px;
}
.title {
	padding-bottom: 16px;
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 1.17;
	letter-spacing: 0.6px;
	text-transform: uppercase;
}
:global {
	.modal-aside {
		&--padding {
			padding-left: 30px;
		}
	}
}

.no_filter_data {
	color: $color10;
}

.disabled_link{
	> a {
		> span {
			color: $color6;
		}
	}
	pointer-events: none;
}
.scrollable_ul{
  height: 100%;
  overflow-y: auto;
}

.modal{

	.modalBody{
		height: 400px;
	}
}

.modal_filters{
	height: 85px;
}

.modal_tabs{
	height: 315px;
}

.loading_text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
