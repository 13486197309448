@import "~brands/default/styles/globals";
.section_headline {
	font-family: $primary-font-medium;
	font-size: 18px;
	color: $color8;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px $color4 solid;
	min-height: 25px;
	width: 100%;
	font-weight: 500;

	> span {
		margin: 0 0 10px 0;
	}
}