@import "~brands/default/styles/globals";
.modal__body {
	height: 220px;
	padding: 5px 15px 0 15px;
}

.buttons {
	display: flex;
	justify-content: flex-end;
}

.confirmation_button {
	margin-left: 32px;
}

.content_wrapper {
	margin-left: 16px;
	margin-right: 16px;
}

.content {
	display: flex;
}

.icon {
	display: flex;
	align-items: center;
	margin-right: 16px;
	svg {
		width: 40px;
		height: 40px;
		path {
			fill: $color14;

			g {
				fill: $color14;
			}
		}
	}
}

.text {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.bolded_text {
	font-weight: bold;
	margin-bottom: 16px;
	font-size: 18px;
}

.note_wrapper {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
}

.note_label {
	font-weight: bold;
	margin-bottom: 8px;
	font-size: 16px;
}

.note {
}
