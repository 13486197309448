:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.custom {
  overflow: visible;
}

div.status_col {
  text-transform: capitalize;
  min-width: 100px;
  width: 166px;
}

@media only screen and (max-width: 1382px) {
  div.status_col {
    min-width: 115px;
  }
}

div.status_col.status_col::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  position: relative;
  top: 10px;
  z-index: 1;
  top: 26px;
  left: 7px;
  background-color: #000;
}

.select_field_label {
  padding: 0 0 10px 0;
  font-weight: bold;
  text-transform: uppercase;
}

.select_field_label.normal_font_weight {
  font-weight: normal;
}

