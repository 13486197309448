@import "~brands/default/styles/globals";
.modal{
	max-width: 620px;
	width: 100%;

	.block_message{
		font-size:14px;
		font-family: $primary-font-regular;
		margin-bottom: 20px;
	}

	.notify_all{
		margin-bottom: 20px;
	}

	.checkbox_wrapper{
		margin-bottom: 10px;
		text-transform: capitalize;
	}

	.modal_body{
		width: auto;
		padding: 0 30px 20px 30px;

		.radio_button_wrapper{
			display: flex;
		}
	}
}

.clear_all {
  float: left;
}