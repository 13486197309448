@import "~brands/default/styles/globals";
.select {
	width: 200px;
	margin-left: 30px;
  height: 38px;
	div {
		-webkit-text-fill-color: $color14 !important;
  }
}

.starting {
	display: flex;
	flex-direction: row;
	margin-left: 20px;
	margin-top: 20px;
	align-items: center;
}


