@import "~brands/default/styles/globals";
.page_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.success_wrapper {
	max-width: 787px;
	width: 100%;
	@media only screen and (max-width: 768px) {
		padding-left: 45px;
		padding-right: 45px;
	}

	.success_content {
		text-align: center;
		width: 100%;
		padding-top: 90px;
	}
	.success_icon {
		width: 68px;
		height: 68px;
		margin-bottom: 30px;
		margin-top: 30px;
	}
	.success_title {
		font-family: $primary-font-regular;
		font-size: 16px;
		color: $color8;
		margin-bottom: 20px;
	}
	.success_company {
		color: $color20;
		font-family: $primary-font-medium;
		font-size: 21px;
	}
	.company_message {
		color: $color20;
		font-family: $primary-font-regular;
		font-size: 16px;
		margin-bottom: 20px;
	}
	.company_id {
		color: $color20;
		font-family: $primary-font-medium;
		font-size: 21px;
		margin-bottom: 40px;
	}
}

.container {
	max-width: 700px;
	width: 100%;
	position: relative;
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-end;
	margin: 0 15px 20px 0;
	@include mobile-only {
		margin: 0px 15px 20px 15px;
	}

	:not(:last-child) {
		margin-right: 15px;
		@include mobile-only {
			margin-bottom: 15px;
			margin-right: 0;
		}
	}
}

.label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 14px;
}

.info {
	font-size: 14px;
}

.preferred_language_info {
	display: flex;
	margin-top: 10px;
	svg {
		margin-right: 5px;
	}
}

.form_logo {
	display: flex;
	@include mobile-only {
		display: block;
	}

	.logo {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.support_type_wrapper {
	margin: 0 10px 30px 0;
	font-size: 16px;
  line-height: 1.4;
  
  .bold {
    font-weight: bold;
  }
}
