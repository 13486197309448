.page_title {
	margin: 0 15px;
	padding: 12px 0 11px 0;
	border-bottom: 1px solid $color4;
	display: flex;
	justify-content: space-between;
}

.title_container {
	display: flex;
	align-items: center;

	.push_back {
		height: $icon-xl;
		width: $icon-xl;
		margin: 0 5px 0 0;
		cursor: pointer;
	}

	.title {
		display: flex;
		align-items: center;
		font-family: $primary-font-light;
  	font-weight: 100;
	}
}

.actions_container {
	display: flex;
}
