:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.rectangle {
  width: 181px;
  height: 117px;
  border-radius: 3px;
  border: solid 2px #f2f2f2;
}

.text_container {
  height: 51px;
  margin: 10px;
}

.title,
.title_disabled {
  font-size: 14px;
  margin: 0;
}

.title {
  color: #0568ae;
}

.title_disabled {
  color: #d2d2d2;
}

.subtitle,
.subtitle_disabled {
  font-size: 14px;
  line-height: 1.14;
  margin-top: 8px;
}

.subtitle_disabled {
  color: #d2d2d2;
}

.enabled_button,
.disabled_button {
  margin: 0;
  width: 100%;
  height: 42px;
  font-size: 18px;
  border: none;
  line-height: .94;
}

.enabled_button {
  cursor: pointer;
  color: #fafafa;
  background-image: linear-gradient(to top, #0568ae, #087ac2);
}

.disabled_button {
  cursor: not-allowed;
}

