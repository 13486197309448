@import "~brands/default/styles/globals";
.clickable {
	cursor: pointer;
	color: $color14;
}

.communication_profile_name {
	max-width: 200px
}

.check_icon {
	display: table;
	width: 25px;
	height: 25px;

	g {
		path {
			fill: $color20;
		}
	}
}