@import "~brands/default/styles/globals";
.page_wrapper {
  margin: 0 0 0 45px;
  @include mobile-only {
		margin: 0 0 0 20px;
	}
}

.form_wrapper {
	max-width: 787px;
	width: 100%;
}

.form_headline {
  font-family: $primary-font-medium;
  font-size: 24px;
  letter-spacing: 0.6px;
  margin: 25px 0;
}

.form_logo {
  display: flex;
  @include mobile-only {
		display: block;
	}

	.logo {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-end;
  margin: 0 15px 20px 0;
  
  @include mobile-only {
		margin-left:10px;
		margin-right: 10px;
	}

	:not(:last-child) {
    margin-right: 15px;
    @include mobile-only {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
}

.preferred_language_info {
	display: flex;
	margin-top: 10px;
	svg {
		margin-right: 5px;
	}
}

.support_type_wrapper {
	margin: 0 10px 30px 0;
	font-size: 16px;
  line-height: 1.4;

  @include tablet-portrait-and-below {
		margin: 0 25px 30px 0;
	}
  
  .bold {
    font-weight: bold;
  }
}