@import "~brands/default/styles/globals";
.entitlement_info {
	padding: 0 0 30px;

	.current_network_entitlement {
		font-size: 12px;
		color: $color19;
		display: flex;
		align-items: center;
		text-transform: uppercase;

		svg {
			width: 15px;
			height: 15px;
			margin-right: 5px;

			path {
				fill: $color19;
			}
		}
	}
}

.entitlements_details {
	border-top: 1px solid $color4;
	padding-top: 25px;
	display: flex;

	.entitlements_details_container {
		width: 100%;
		display: flex;
		flex-direction: column;

		&:nth-child(2) {
			margin-left: 31px;
		}
	}
}

.entitlements_details_item {
	display: flex;
	margin-bottom: 17px;

	.entitlements_item_title {
		font-size: 14px;
		font-weight: 500;
		font-family: $primary-font-medium;
		line-height: 14px;
		text-align: left;
		color: $color8;
		width: 100%;
	}

	.grayed_out {
		opacity: 0.4;
	}

	.entitlements_item_data {
		font-size: 16px;
		font-family: $primary-font-regular;
		line-height: 16px;
		text-align: right;
		color: $color8;
		width: 100%;
	}
}

.apns {
	display: block;

	.apns_container {
		&:not(:first-child) {
			padding-top: 25px;
		}

		&:nth-child(2) {
			margin-left: auto;
		}

		&:not(:last-child) {
			border-bottom: 1px solid $color4;
		}
	}
}
