.right {
	display: flex;
	align-items: center;

	> div {
		height: 35px;
		display: flex;
		align-items: center;
	}

	.search_left {
		border: 1px solid $color14;
		padding: 0 20px;
		border-bottom-left-radius: 18px;
		border-top-left-radius: 18px;
		cursor: pointer;
		position: relative;
		min-width: 155px;
		display: flex;
		justify-content: space-between;
		font-family: $primary-font-regular;
		line-height: 1;
		color: $color14;
		font-size: 16px;

		> div {

			> span {

				&:nth-child(2) {
					display: inline-block;
					padding-right: 5px;
				}
			}
		}

		&.opened {
			background-color: $color3;

			.down_carret {
				transform: rotate(180deg);
			}
		}

		.down_carret {
			height: $icon-md;
			transition: all 0.1s linear;
			width: 20px;
		}

		.menu {
			position: absolute;
			top: 100%;
			right: 0;
			width: 100%;
			border-radius: 6px;
			background-color: $color1;
			box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
			border: 1px solid $color4;
			padding: 15px;
			margin-top: 5px;
			z-index: 10;

			li {
				font-family: $primary-font-light;
				line-height: 0.86;
				color: $color7;
				font-size: 14px;

				&:not(:first-child) {
					margin-top: 20px;
				}

				&:hover {
					color: $color14;
				}

				&.selected {
					font-family: $primary-font-regular;
					color: $color14;
				}
			}
		}
	}

	.search_right {
		position: relative;

		input {
			height: 35px;
			border-bottom-right-radius: 18px;
			border-top-right-radius: 18px;
			width: 155px;
			padding: 0 27px 0 10px;
			border: 1px solid grey;
			border-left: none;
			font-family: $primary-font-regular;
			font-size: 16px;
			line-height: 1;
			color: $color8;

			&::placeholder {
				font-family: $primary-font-italic;
				font-size: 16px;
				line-height: 1;
				color: $color5;
			}

			&::-moz-placeholder {
				line-height: 31px;
			}
			
			&:-moz-placeholder {
				line-height: 31px;
			}

			&:focus {
				outline: none;
			}
		}

		.search_icon {
			position: absolute;
			top: 7px;
			right: 5px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				width: 23px;
				height: 21px;

				path {
					fill: $color14;
				}
			}
		}
  }
  
  .tooltip {
    box-shadow: 0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
  }
}
