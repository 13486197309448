@import "~brands/default/styles/globals";
/*
This document has been created with Marked.app <http://marked2app.com>, Copyright 2013 Brett Terpstra
Content is property of the document author
Please leave this notice in place, along with any additional credits below.
---------------------------------------------------------------
Title: GitHub
Author: Brett Terpstra
Description: Github README style. Includes theme for Pygmentized code blocks.
*/
#wrapper {
	font-size: 15px;
	line-height: 1.6;

	a {
		color: $color14;
		text-decoration: none;
	}
	em {
	font-weight: bold;
	font-style: italic;

	}
	#wrapper > *:first-child {
		margin-top: 0 !important;
	}
	#wrapper > *:last-child {
		margin-bottom: 0 !important;

	h2 {
			color: $color5 !important;
		}
	}
		h3, h3, h4, h5, h6 {
			padding-left: 25px !important;
			color: $color6 !important;

		}

	p,
	blockquote,
	table,
	pre {
		margin: 35px 0 0 25px;
	}

	ul {
		padding-left: 45px !important;
		list-style: unset;
	}
	ol {
    padding-left: 45px !important;
  }
  ol li {
    list-style: decimal;
  }
	ol li ul:first-of-type {
		margin-top: 0;
	}

	hr {
		background: transparent
			url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAECAYAAACtBE5DAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OENDRjNBN0E2NTZBMTFFMEI3QjRBODM4NzJDMjlGNDgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OENDRjNBN0I2NTZBMTFFMEI3QjRBODM4NzJDMjlGNDgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4Q0NGM0E3ODY1NkExMUUwQjdCNEE4Mzg3MkMyOUY0OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4Q0NGM0E3OTY1NkExMUUwQjdCNEE4Mzg3MkMyOUY0OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqqezsUAAAAfSURBVHjaYmRABcYwBiM2QSA4y4hNEKYDQxAEAAIMAHNGAzhkPOlYAAAAAElFTkSuQmCC)
			repeat-x 0 0;
		border: 0 none;
		color: $text-field-label-color-secondary;
		height: 4px;
		margin: 15px 0;
		padding: 0;
	}

	#wrapper > h2:first-child {
		margin-top: 0;
		padding-top: 0;
	}
	#wrapper > h1:first-child {
		margin-top: 0;
		padding-top: 0;
	}
	#wrapper > h1:first-child + h2 {
		margin-top: 0;
		padding-top: 0;
	}
	#wrapper > h3:first-child,
	#wrapper > h4:first-child,
	#wrapper > h5:first-child,
	#wrapper > h6:first-child {
		margin-top: 0;
		padding-top: 0;
	}

	a:first-child h1,
	a:first-child h2,
	a:first-child h3,
	a:first-child h4,
	a:first-child h5,
	a:first-child h6 {
		margin-top: 0;
		padding-top: 0;
	}

	h1 + p,
	h2 + p,
	h3 + p,
	h4 + p,
	h5 + p,
	h6 + p,
	ul li > :first-child,
	ol li > :first-child {
		margin-top: 0;
	}

	dl {
		padding: 0;
	}
	dl dt {
		font-size: 14px;
		font-weight: bold;
		font-style: italic;
		padding: 0;
		margin: 15px 0 5px;
	}
	dl dt:first-child {
		padding: 0;
	}
	dl dt > :first-child {
		margin-top: 0;
	}
	dl dt > :last-child {
		margin-bottom: 0;
	}
	dl dd {
		margin: 0 0 15px;
		padding: 0 15px;
	}
	dl dd > :first-child {
		margin-top: 0;
	}
	dl dd > :last-child {
		margin-bottom: 0;
	}

	blockquote {
		border-left: 4px solid $color4;
		padding: 0 15px;
		color: $color6;
	}
	blockquote > :first-child {
		margin-top: 0;
	}
	blockquote > :last-child {
		margin-bottom: 0;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
		font-size: 100%;
		font: inherit;
	}
	table th {
		font-weight: bold;
		border: 1px solid $text-field-label-color-secondary;
		padding: 6px 13px;
	}
	table td {
		border: 1px solid $text-field-label-color-secondary;
		padding: 6px 13px;
	}
	table tr {
		border-top: 1px solid $text-field-label-color-secondary;
		background-color: $color1;
	}
	table tr:nth-child(2n) {
		background-color: $color21;
	}

	img {
		max-width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 35px -3px rgba(0,0,0,0.21);
	}

	code,
	tt {
		margin: 0 2px;
		padding: 0 5px;
		white-space: nowrap;
		border: 1px solid $color3;
		background-color: $color21;
		border-radius: 3px;
		font-family: Consolas, "Liberation Mono", Courier, monospace;
		font-size: 12px;
		color: $color7;
	}

	pre > code {
		margin: 0;
		padding: 0;
		white-space: pre;
		border: none;
		background: transparent;
	}

	.highlight pre {
		background-color: $color21;
		border: 1px solid $text-field-label-color-secondary;
		font-size: 13px;
		line-height: 19px;
		overflow: auto;
		padding: 6px 10px;
		border-radius: 3px;
	}

	pre {
		background-color: $color21;
		border: 1px solid $text-field-label-color-secondary;
		font-size: 14px;
		line-height: 19px;
		overflow: auto;
		padding: 6px 10px;
		border-radius: 3px;
		margin: 26px 0;
	}
	pre code,
	pre tt {
		background-color: transparent;
		border: none;
	}

	sup,
	sub {
		font-size: 1.4ex;
		height: 0;
		line-height: 1;
		vertical-align: super;
		position: relative;
	}

	sub {
		vertical-align: sub;
		top: -1px;
	}

	@media print {
		body {
			background: $color1;
		}

		img,
		pre,
		blockquote,
		table,
		figure {
			page-break-inside: avoid;
		}

		#wrapper {
			background: $color1;
			border: none;
		}

		pre code {
			overflow: visible;
		}
	}
	@media screen {
		::selection {
			background: rgba(157, 193, 200, 0.5);
		}

		h1::selection {
			background-color: rgba(45, 156, 208, 0.3);
		}

		h2::selection {
			background-color: rgba(90, 182, 224, 0.3);
		}

		h3::selection,
		h4::selection,
		h5::selection,
		h6::selection,
		li::selection,
		ol::selection {
			background-color: rgba(133, 201, 232, 0.3);
		}

		code::selection {
			background-color: rgba(0, 0, 0, 0.7);
			color: $color3;
		}
		code span::selection {
			background-color: rgba(0, 0, 0, 0.7) !important;
			color: $color3 !important;
		}

		a::selection {
			background-color: rgba(255, 230, 102, 0.2);
		}

		td::selection,
		th::selection,
		caption::selection {
			background-color: rgba(180, 237, 95, 0.5);
		}
	}
}

.download_link{
	text-decoration: none;
	cursor: pointer;
  display: flex;
	align-items: center;
	margin-right: 30px;
	color: $color14;
}
