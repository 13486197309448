@import "~brands/default/styles/globals";
:global {
  .confirm-upload-title {
    text-transform: uppercase;
    margin-bottom: 20px !important;
  }

  .confirm-content {
    p {
      color: $button-disabled-color;
      font-size: 16px;
      margin: 0 0 5px 0;
    }
	}
	.second-step.modal-body{
		@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			overflow-y: inherit;
			overflow-x: inherit;
		}
		overflow-x: unset;
		overflow-y: unset;
	}

	.batch-wrapper{
		.ds-last-step{
			padding:0;
		}
	}
}

.modal_body_batch_last_step{
	margin-bottom: 60px;
	overflow: auto;
	min-height: 300px;
}

