:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.desktop_wrapper .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.desktop_wrapper .header .title {
  display: flex;
  align-items: center;
}

.desktop_wrapper .header .title>span {
  font-size: 24px;
  padding: 0 20px;
}

.desktop_wrapper .header .dropdown_wrapper {
  display: flex;
  align-items: center;
}

.desktop_wrapper .header .dropdown_wrapper .dropdown {
  width: 100px;
}

.desktop_wrapper .header .dropdown_wrapper .dropdown [class=form-group] {
  margin-bottom: 0;
}

.desktop_wrapper .header .dropdown_wrapper .usage_text {
  margin-right: 10px;
  font-weight: bold;
  font-size: 16px;
}

.desktop_wrapper .header .date_icons {
  display: flex;
  height: 50px;
  margin-right: 50px;
  align-items: center;
}

.desktop_wrapper .header .date_icons .label_range {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;
  font-size: 16px;
}

.desktop_wrapper .header .date_icons .picker_icon {
  display: flex;
  align-items: center;
}

.desktop_wrapper .header .date_icons .picker_icon svg {
  width: 40px;
  height: 30px;
  cursor: pointer;
}

.desktop_wrapper .header .date_icons .picker_icon svg path {
  fill: #009fdb;
}

.desktop_wrapper .data {
  width: 200px;
}

.no_data_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.no_data_wrapper .message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader_custom_style>[class=spinner__wrapper] {
  min-height: 25px;
}

