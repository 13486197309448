:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.static_group {
  display: flex;
  flex-direction: column;
}

.static_label {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 14px;
  display: inline-block;
  max-width: 100%;
}

.static_value {
  display: block;
  min-height: 36px;
  max-height: 80px;
  padding: 8px 0 7px 0;
  font-size: 16px;
  color: #191919;
  width: 100%;
  overflow: auto;
  word-break: break-word;
}

.total_label {
  padding-left: 10px;
  font-family: "attalecksans_w_bold";
  font-size: 30px;
  color: #191919;
  border-left: 2px solid #d2d2d2;
}

