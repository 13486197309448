@import "~brands/default/styles/globals";
.modal__body {
	height: 100px;
	padding: 5px 15px 0 15px;
}

.content {
  display: flex;
  flex-direction: column;
	margin-left: 16px;
  margin-right: 16px;
}

.bolded_text {
  font-weight: bold;
}

.checkbox_part {
  margin-top: 1.5em;
  display: flex;
  align-items: center;
}

.checkbox_text {
  margin-left: 0.5em;
}