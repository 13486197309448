@import "~brands/default/styles/globals";
.modal {
	width: 704px;
	height: 289px;
}

.modal_body {
	padding: 10px 20px;
	width: 100%;
}

.modal_content {
	display: flex;
	padding: 0 16px;
}

.modal_text {
	width: 461px;
	margin-top: 40px;
	margin-right: 70px;
}

.icon_wrapper {
	width: 111px;
}
