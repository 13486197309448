@import "~brands/default/styles/globals";
.tooltip {
	box-shadow: 0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
}

.icon {
	width: $icon-lg;
	fill: $color14;
	cursor: pointer;
	height: 20px;

	> g {
		> path {
			fill: $color14;
		}
	}

	&:last-child {
		margin-right: 0;
	}

	&.disabled {
		cursor: not-allowed;

		path {
			fill: $color5;
		}

		> g {
			> path {
				fill: $color5;
			}
		}
	}
}