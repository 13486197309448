:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.chart_wrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.main {
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.main .tooltip {
  box-shadow: 0 0 10px #5a5a5a;
}

.main .tooltip>div {
  display: flex;
  align-items: center;
}

.main .tooltip>div img {
  max-height: 16px;
  margin-right: 5px;
}

.main .wrapper {
  width: 100%;
  height: 85%;
  margin: 0 auto;
  flex: 1;
}

.main .wrapper_analytics {
  height: 100%;
}

.main .chart {
  height: 100%;
  width: 100%;
}

.main .wcd_wrapper {
  margin: 0 37px;
  border-top: 1px solid #d2d2d2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main .wcd_wrapper .title {
  font-size: 11px;
  font-weight: 300;
  letter-spacing: .6px;
  color: #191919;
}

.main .wcd_wrapper .count {
  font-size: 24px;
  line-height: .83;
  letter-spacing: .6px;
  color: #191919;
}

@media only screen and (min-width: 1600px) {
  .main .wcd_wrapper .count {
    padding-right: 20px;
  }
}

.main .wcd_wrapper .total {
  padding: 20px 0;
}

.main .wcd_wrapper .countries {
  display: flex;
  flex-flow: row wrap;
}

@media only screen and (min-width: 1600px) {
  .main .wcd_wrapper .countries {
    padding-top: 20px;
  }
}

.main .wcd_wrapper .countries .country {
  padding-right: 20px;
}

