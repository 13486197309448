:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.modal_body {
  padding: 5px 15px 0 15px;
}

.icons {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icons .clickable {
  display: flex;
  align-items: center;
  color: #0568ae;
  margin-right: 16px;
  cursor: pointer;
}

.icons svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.icons svg path {
  fill: #0568ae;
}

.icons svg path g {
  fill: #0568ae;
}

.delete_modal_body {
  height: 200px;
}

.upload_invoice_modal_body {
  height: 500px;
}

.upload_attachments_modal_body {
  height: 430px;
}

.delete_body {
  font-size: 16px;
}

.delete_body .bolded_text {
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 8px;
}

.delete_body .clickable {
  color: #0568ae;
  cursor: pointer;
  font-size: 14px;
}

.edit_body .bolded_text {
  font-weight: bold;
}

.edit_body .clickable {
  color: #0568ae;
  cursor: pointer;
}

.edit_body .clickable svg {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.edit_body .clickable svg path {
  fill: #0568ae;
}

.edit_body .clickable svg path g {
  fill: #0568ae;
}

.edit_body .input {
  margin-top: 16px;
  margin-bottom: 16px;
}

.edit_body .input .bolded_text {
  margin-bottom: 16px;
}

.upload_body .bolded_text {
  font-weight: bold;
}

.upload_body .invoice_inputs {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
}

.upload_body .invoice_inputs .input {
  width: 65%;
  margin-right: 15px;
}

.upload_body .attachment_inputs {
  margin-top: 16px;
  margin-bottom: 16px;
}

.upload_body .input .bolded_text {
  margin-bottom: 16px;
}

.upload_body .input .field {
  width: 80%;
}

.upload_body .input .field_name {
  display: flex;
  flex-direction: row;
}

