@import "~brands/default/styles/globals";
.content {
  flex: 100%;
}

.change_status {

  .title {
    font-size: 12px;
    font-family: $primary-font-medium;
    line-height: 14px;
    letter-spacing: 0.6px;
    color: $color8;
    font-weight: bold;
  }
}

.asside_padding {
  padding-left: 30px;
}

.support_link_wrapper {
	margin: 35px 0 40px 15px;
	cursor: pointer;
	color: $color14;
}

.mb30{
  margin: 9px 0 30px 0;
	z-index: 101;
}
