@import "~brands/default/styles/globals";
div.custom_option {
	position: relative;
	padding-left: 18px !important;
	&::before {
		content: '';
		width: 10px;
		height: 10px;
		border-radius: 100%;
		position: absolute;
		top: 50%;
		margin-top: -5px;
		left: 12px;
		background-color: #000000;
	}
	&.select_single_value {
		padding-left: 15px !important;
		transform: translateY(0);
	}
	&.select_single_value::before {
		top: 50%;
		margin-top: -5px;
		left: 2px;
	}
	&.pending::before {
		background-color: $color9;
	}
	&.not_applicable::before {
		background-color: $color6;
	}
	&.received::before {
		background-color: $color14;
	}
	&.implemented::before {
		background-color: $color19;
	}
	&.conflicted::before {
		background-color: $color10;
	}
	&.pending_for_deletion::before {
		background-color: $color11;
	}
}

.select_action,
.select_action:focus {
	outline: none !important;
	color: $color8 !important;
	background-color: $color3 !important;
	font-weight: 900 !important;
	padding-left: 4px !important;
}

.paddingOption {
	padding-left: 8px !important;
}
