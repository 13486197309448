@import "~brands/default/styles/globals";
.modal {
	overflow: visible;
}

.modal_body {
	overflow: visible;
	max-height: 760px;
	height: 100%;
	padding: 0 30px 30px 30px;
	max-width: 1200px;
	width: 100%;
}

.modal_note {
	font-size: 20px;
	font-family: $primary-font-regular;
	margin-bottom: 15px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.modal_disclaimer_wrapper {
	font-size: 12px;
	margin-bottom: 10px;

	& > :nth-child(1) {
		font-family: $primary-font-bold;
	}

	& > :nth-child(2) {
		font-family: $primary-font-regular;
	}
}

.user_actions{
	display: flex;
	flex-direction: column;

	svg{
		height: 22px;
		width: 22px;
		cursor: pointer;
		margin-bottom: 5px;
		margin-left: 5px;

		path{
			fill: $color14;
		}
	}
}

.modal_button_check {
	g {
		path {
			fill: $color20;
		}
	}
}

.modal_button_cancel {
	g {
		path {
			fill: $color10;
		}
	}
}

.loader {
	max-height: 300px;
	min-height: 200px;
	height: 40%;
}

.body_note {
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	svg {
		width: 200px;
		height: 150px;
		padding: 30px 0;
	}
}

.name_description_input {
  display: flex;
  
  .graph_input[maxlength="50"] {
    width: 380px;
  }

  .graph_input[maxlength="250"] {
    width: 1000px;
  }
}

.name_description_label {
	display: flex;
}
