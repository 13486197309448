@import "~brands/default/styles/globals";
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.value {
  width: 300px;
}

.bolded_text {
  font-weight: bold;
  display: flex;
}

.star {
  font-weight: bold;
  color: $color10;
  margin-left: 2px;
}

.field {
  margin-top: 8px;
  margin-bottom: 16px;
}

.upload {
  display: flex;
  flex-direction: column;
  margin-top: 3em;
}

.upload_title {
  font-weight: 500;
  font-family: $primary-font-medium;
	font-size: 18px;
	color: $color8;
  border-bottom: 1px solid $color4;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  display: flex;
}

.upload_file_name {
  font-weight: bold;
  margin-top: 2em;
  margin-bottom: 1em;
}