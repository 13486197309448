:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.wrapper {
  margin: 15px;
}

.wrapper .container {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.wrapper .container h4 {
  font-weight: normal;
}

.wrapper .failover_message {
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 14px;
}

.wrapper .block {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 480px) {
  .wrapper .block {
    display: block;
  }
}

.wrapper .data {
  margin-bottom: 5px;
  font-family: "attalecksans_w_medium";
}

.wrapper .active {
  color: #007a3e;
}

@media (max-width: 480px) {
  .wrapper .switch {
    margin: 5px;
  }
}

