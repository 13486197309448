@import "~brands/default/styles/globals";
.modal_body {
	padding: 5px 15px 0 15px;
}

.icons {
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.clickable {
		display: flex;
		align-items: center;
		color: $color14;
		margin-right: 16px;
		cursor: pointer;
	}
	svg {
		width: 20px;
		height: 20px;
		margin-right: 5px;
		path {
			fill: $color14;

			g {
				fill: $color14;
			}
		}
	}
}

.delete_modal_body {
	height: 200px;
}

.upload_invoice_modal_body {
	height: 500px;
}

.upload_attachments_modal_body {
  height: 430px;
}

.delete_body {
	font-size: 16px;
	.bolded_text {
		font-weight: bold;
		margin-top: 50px;
		margin-bottom: 8px;
	}

	.clickable {
		color: $color14;
		cursor: pointer;
		font-size: 14px;
	}
}

.edit_body {
	.bolded_text {
		font-weight: bold;
	}
	.clickable {
		color: $color14;
		cursor: pointer;
		svg {
			width: 20px;
			height: 20px;
			margin-left: 5px;
			path {
				fill: $color14;

				g {
					fill: $color14;
				}
			}
		}
	}
	.input {
		margin-top: 16px;
		margin-bottom: 16px;
		.bolded_text {
			margin-bottom: 16px;
		}
	}
}

.upload_body {
	.bolded_text {
		font-weight: bold;
	}
	.invoice_inputs {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    .input {
      width: 65%;
      margin-right: 15px;
    }
  }
  .attachment_inputs {
    margin-top: 16px;
    margin-bottom: 16px;
  }
	.input {
		.bolded_text {
			margin-bottom: 16px;
		}
		.field {
			width: 80%;
		}
		.field_name {
			display: flex;
			flex-direction: row;

		}
	}
}
