/* ATTAleckSans */

@font-face {
  font-family: 'attalecksans_w_regular';
  src: url('brands/default/styles/fonts/ATTAleckSans_W_Rg.eot');
  src: url('brands/default/styles/fonts/ATTAleckSans_W_Rg.eot?#iefix') format('embedded-opentype'),
  url('brands/default/styles/fonts/ATTAleckSans_W_Rg.woff2') format('woff2'),
  url('brands/default/styles/fonts/ATTAleckSans_W_Rg.woff') format('woff'),
  url('brands/default/styles/fonts/ATTAleckSans_W_Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'attalecksans_w_medium';
  src: url('brands/default/styles/fonts/ATTAleckSans_W_Md.eot');
  src: url('brands/default/styles/fonts/ATTAleckSans_W_Md.eot?#iefix') format('embedded-opentype'),
  url('brands/default/styles/fonts/ATTAleckSans_W_Md.woff2') format('woff2'),
  url('brands/default/styles/fonts/ATTAleckSans_W_Md.woff') format('woff'),
  url('brands/default/styles/fonts/ATTAleckSans_W_Md.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'attalecksans_w_light';
  src: url('brands/default/styles/fonts/ATTAleckSans_W_Lt.eot');
  src: url('brands/default/styles/fonts/ATTAleckSans_W_Lt.eot?#iefix') format('embedded-opentype'),
  url('brands/default/styles/fonts/ATTAleckSans_W_Lt.woff2') format('woff2'),
  url('brands/default/styles/fonts/ATTAleckSans_W_Lt.woff') format('woff'),
  url('brands/default/styles/fonts/ATTAleckSans_W_Lt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'attalecksans_w_bold';
  src: url('brands/default/styles/fonts/ATTAleckSans_W_Bd.eot');
  src: url('brands/default/styles/fonts/ATTAleckSans_W_Bd.eot?#iefix') format('embedded-opentype'),
  url('brands/default/styles/fonts/ATTAleckSans_W_Bd.woff2') format('woff2'),
  url('brands/default/styles/fonts/ATTAleckSans_W_Bd.woff') format('woff'),
  url('brands/default/styles/fonts/ATTAleckSans_W_Bd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'attalecksans_w_italic';
  src: url('brands/default/styles/fonts/ATTAleckSans_W_It.eot');
  src: url('brands/default/styles/fonts/ATTAleckSans_W_It.eot?#iefix') format('embedded-opentype'),
  url('brands/default/styles/fonts/ATTAleckSans_W_It.woff2') format('woff2'),
  url('brands/default/styles/fonts/ATTAleckSans_W_It.woff') format('woff'),
  url('brands/default/styles/fonts/ATTAleckSans_W_It.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
	font-family: 'icoPrimary';
	src: url('brands/default/styles/fonts/icoPrimary.eot');
	src: url('brands/default/styles/fonts/icoPrimary.eot?#iefix') format('embedded-opentype'),
	url('brands/default/styles/fonts/icoPrimary.woff2') format('woff2'),
	url('brands/default/styles/fonts/icoPrimary.woff') format('woff'),
	url('brands/default/styles/fonts/icoPrimary.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
