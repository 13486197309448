:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.detail_wrapper {
  padding-top: 18px;
}

.detail_wrapper .form_wrap {
  max-width: 654px;
  margin-left: 42px;
}

.detail_wrapper .select_field {
  width: 312px;
  position: relative;
}

.detail_wrapper .select_field.display_flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.detail_wrapper .select_field.display_flex.flex_start {
  justify-content: flex-start;
}

.detail_wrapper .select_field.display_flex.flex_wrap {
  flex-wrap: wrap;
}

.detail_wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: #d2d2d2;
  margin-bottom: 10px;
  position: relative;
}

.detail_wrapper .divider.has_child {
  margin-bottom: 24px;
  margin-top: 27px;
}

.detail_wrapper .divider.has_child.mt15 {
  margin-top: 15px;
}

.detail_wrapper .divider .condition {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border: 1px solid #009fdb;
  border-radius: 50%;
  left: 0;
  top: 50%;
  margin-top: -12px;
  position: absolute;
  background-color: #fff;
  padding: 0 5px;
}

.detail_wrapper .divider .condition.if {
  min-width: 24px;
}

.detail_wrapper .divider .condition.then {
  min-width: 52px;
  border-radius: 12px;
}

.detail_wrapper .divider .condition span {
  text-transform: uppercase;
  font-family: "attalecksans_w_medium";
  font-size: 14px;
  color: #009fdb;
}

.detail_wrapper .custom_select_width_first {
  width: 312px;
  position: relative;
}

.detail_wrapper .custom_select_width_first.ml30 {
  margin-left: 30px;
}

.detail_wrapper .custom_select_width.custom_margin {
  margin-left: 30px;
}

.detail_wrapper .custom_select_width.width303 {
  width: 303px;
}

.detail_wrapper .mb20 {
  margin-bottom: 20px;
}

.detail_wrapper .mt24 {
  margin-top: 24px;
}

.detail_wrapper .label {
  font-family: "attalecksans_w_medium";
  font-size: 14px;
  font-weight: 500;
  color: #191919;
  margin-bottom: 8px;
  white-space: nowrap;
}

.detail_wrapper .label.filter {
  margin-top: 27px;
}

.detail_wrapper .buttons {
  display: flex;
  justify-content: space-between;
}

.detail_wrapper .buttons .request_deletion_button {
  margin-left: 24px;
  padding: 15px 0 15px 15px;
}

.detail_wrapper .buttons .buttons_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 42px;
  padding: 15px 0 15px 15px;
}

.message_text {
  display: flex;
}

.message_text>div,
.message_text>div>div {
  width: 140px;
}

.message_text.location_update>div,
.message_text.location_update>div>div {
  width: 100%;
}

.message_text>span {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 35px;
  width: 20px;
  padding-left: 20px;
  position: absolute;
  right: 0;
  top: 25px;
  right: -15px;
}

.custom_filter_fields {
  width: 312px;
}

.follow_up_with {
  margin-bottom: 15px;
}

.add_another {
  font-size: 18px;
  color: #0568ae;
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  cursor: pointer;
}

.trigger__icon {
  height: 2px;
  width: 10px;
  background: #0568ae;
  position: relative;
  display: inline-block;
  margin-right: 7px;
}

.trigger__icon:after {
  transition: all .4s ease-in-out;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #0568ae;
  transform: rotate(90deg);
}

.remove_item_from_array {
  height: 22px;
  width: 22px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -4px;
}

.remove_item_from_array path {
  fill: #fff;
}

.remove_item_from_array path:nth-child(2) {
  fill: #0568ae;
}

.cancel_button {
  margin-right: 15px;
}

.flex_wrap {
  flex-wrap: wrap;
}

.width100 {
  width: 100%;
}

div.custom_input input.custom_input {
  height: 38px;
}

.ml10 {
  margin-left: 10px;
}

