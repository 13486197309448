.action_bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px;
}

.action_bar_border {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid $color4;
  padding: 10px 15px 10px 0;
  position: relative;
}
