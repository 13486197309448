:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.action_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.action_bar_border {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
  padding: 10px 15px 10px 0;
  position: relative;
}

.action_bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 70px;
  align-items: center;
  padding: 0 15px;
  position: relative;
}

.action_bar .action_bar_active_filters {
  flex-shrink: 1;
  padding: 5px 0;
}

.action_bar .action_bar_right {
  display: flex;
  align-items: center;
  margin: 12px 0;
  flex-shrink: 0;
}

.action_bar .action_bar_right .total_number {
  padding: 0 20px;
  font-style: italic;
}

.action_bar .action_bar_icons {
  padding: 0 0 0 15px;
  display: flex;
  align-items: center;
}

.action_bar .action_bar_icons.empty {
  padding: 0;
}

.action_bar.opacity {
  opacity: .5;
}

.clickable {
  cursor: pointer;
  color: #0568ae;
  word-break: break-all;
}

.tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
}

.tooltip_blue {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
  background-color: #0568ae !important;
  color: #fff !important;
  font-size: 14px !important;
}

.tooltip_blue:after {
  border-top-color: #0568ae !important;
}

.switch_wrapper {
  display: inline-block;
}

.icon {
  width: 20px;
  fill: #0568ae;
  margin: 11px 10px 11px 0;
  cursor: pointer;
  height: 20px;
}

.icon svg path {
  fill: #0568ae;
}

.icon>g>path {
  fill: #0568ae;
}

.icon:last-child {
  margin-right: 0;
}

.icon.disabled {
  cursor: not-allowed;
}

.icon.disabled path {
  fill: #959595;
}

.icon.disabled>g>path {
  fill: #959595;
}

.loading_cover {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  cursor: not-allowed;
}

.clear_filter {
  margin-right: 10px;
}

.refresh_page_icon svg {
  width: 100%;
  cursor: pointer;
}

.refresh_page_icon svg path {
  fill: #0568ae;
}

.info_icon svg {
  width: 100%;
  cursor: pointer;
  margin-left: 5px;
}

.info_icon svg path {
  fill: #0568ae;
}

.edit_table_icon svg {
  height: 20px;
}

