:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.registration_details {
  flex: 1;
  background-color: #f2f2f2;
  align-self: flex-start;
  border-radius: 10px;
  padding: 15px;
  position: relative;
}

.registration_details .rd_title {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.17;
  text-transform: uppercase;
  letter-spacing: .6px;
  color: #191919;
  padding-bottom: 30px;
}

.registration_details .rd_fields .rd_field {
  font-size: 14px;
  font-weight: bold;
  color: #191919;
}

.registration_details .rd_fields .rd_field_value {
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 25px;
  font-weight: normal;
}

.registration_details .rd_fields .rd_field:last-child .rd_field_value {
  padding-bottom: 15px;
}

.registration_details .tablet_icon {
  position: absolute;
  bottom: 12px;
  right: 8px;
  height: 90px;
  width: 90px;
}

.registration_details .tablet_icon path {
  fill: #d2d2d2;
}

.actions_wrapper {
  margin: 45px 0 30px 0;
}

.actions_wrapper .title {
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

