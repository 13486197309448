@import "~brands/default/styles/globals";
.field_wrapper {
	width: 320px;
	margin: 0 30px 30px 0;
	display: inline-table;
}

.label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 14px;
}

.info {
	font-size: 14px;
}

.headline {
	font-family: $primary-font-medium;
	font-size: 12px;
	letter-spacing: 0.6px;
	text-transform: uppercase;
	margin: 0 0 20px 0;
}

.sub_headline {
	font-family: $primary-font-light;
	font-size: 14px;
	letter-spacing: 0.4px;
	margin: 0 0 40px 0;
}
