:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.feedback_trigger {
  background: #009fdb;
  color: #f2f2f2;
  padding: 25px 15px;
  line-height: 50px;
  position: fixed;
  top: 76%;
  right: 0;
  z-index: 99;
  overflow: hidden;
  width: 140px;
  margin-top: 10px;
  border-top-left-radius: 50em;
  border-bottom-left-radius: 50em;
  border: 0;
  outline: 0;
  box-shadow: 3px 3px 20px gray;
  transform: translateX(65%);
  will-change: transform;
  transition: transform .35s 0s;
  cursor: pointer;
}

.feedback_trigger.trigger_active {
  display: none;
}

@media only screen and (min-width: 1025px)and (max-width: 1382px) {
  .feedback_trigger {
    top: 86% !important;
  }
}

.feedback_trigger:hover {
  transform: translateX(20%) !important;
}

.feedback_trigger:hover .icon {
  opacity: 0;
  transform: translateX(0%);
}

.feedback_trigger:hover .right {
  opacity: 1;
  transform: translateY(50%);
}

.feedback_trigger .icon,
.feedback_trigger .right {
  display: block;
  position: absolute;
  left: 10%;
  top: 0;
}

.feedback_trigger .icon {
  will-change: transform;
  opacity: 1;
  transform: translateX(0);
  transition: opacity .4s ease-in-out,transform .35s ease-in;
}

.feedback_trigger .icon svg {
  stroke-width: 24px;
  height: 24px;
  vertical-align: middle;
  stroke-width: 2;
  width: 24px;
}

.feedback_trigger .icon svg path {
  fill: #fff;
}

.feedback_trigger .right {
  will-change: transform;
  transition: opacity .4s ease-in-out,transform .3s ease-out;
  opacity: 0;
  transform: translateY(10%);
}

.feedback_trigger .title,
.feedback_trigger .subtitle {
  text-align: left;
}

.feedback_trigger .title {
  font-size: 14px;
  font-weight: 500;
  font-family: "attalecksans_w_medium";
  color: #fff;
  line-height: 14px;
}

.feedback_trigger .subtitle {
  font-size: 12px;
  line-height: 12px;
  font-family: "attalecksans_w_light";
  font-weight: 300;
}

