@import "~brands/default/styles/globals";
.endpoints_wrapper {
	font-family: attalecksans_w_regular;
}

.table_wrapper {
	max-width: 100%;
	overflow-x: auto;

	table {
		th,
		td {
			&:first-child {
				background-color: $color3;
				display: table-cell;
			}
		}
	}
}

.data {
	position: relative;
	display: inline-block;
	cursor: pointer;

	.message {
		display: block;
		width: 6em;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@media only screen and (min-width: 1550px) {
	.data {
		.message {
			width: inherit;
		}
	}
}

.tooltip {
	box-shadow: 0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
}

.bottom_action_bar {
	display: flex;
	justify-content: space-between;
	width: 100%;
	min-height: 40px;
	align-items: center;

	.action_bar_active_filters {
		width: 100%;
	}

	.action_bar_right {
		display: flex;
		align-items: center;
	}

	.icon {
		width: $icon-lg;
		fill: $color14;
		margin: 0 10px 0 0;
		cursor: pointer;
		height: 20px;

		> g {
			> path {
				fill: $color14;
			}
		}

		&:last-child {
			margin-right: 0;
		}

		&.disabled {
			cursor: not-allowed;
			path {
				fill: $color5;
			}
			> g {
				> path {
					fill: $color5;
				}
			}
		}
	}

	.refreshPageIcon {
		svg {
			width: 100%;
			cursor: pointer;

			path {
				fill: $color14;
			}
		}
	}
}

.right {
	display: flex;
	align-items: center;
  justify-content: flex-end;
  margin: 10px 15px;

	.mr10 {
		margin-right: 10px;
	}
}

.switch_wrapper {
	display: inline-block;
}

.add_icon {
	fill: $color14;
	width: $icon-xl;
	height: 24px;
}

.tabs {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap-reverse;
	align-items: center;
	border-bottom: 1px solid $color4;

	.tabs_bar {
		border-bottom: none;
	}

	@include tablet-portrait-and-below {
		border-bottom: none;
	}
}
