@import "~brands/default/styles/globals";
.modal_body {
	height: 500px;
}

.body {
	padding-left: 30px;
	padding-right: 30px;
	font-size: 15px;
	padding-bottom: 50px;
}

.field_modifiction {
	padding-bottom: 20px;
}
