@import "~brands/default/styles/globals";
.notesContainer {
	margin: 5% 10% 10% 10%;
	height: 270px;

	> div {
		margin-bottom: 15px;

		> span {
			font-weight: bold;
		}
	}

	textarea[data-spec="notes-textarea"] {
		height: 180px;
	}

	.notesButton {
		direction: rtl;

		@media only screen and (max-width: 641px) {
			> button {
				min-width: 139px;
			}
		}

		> button:last-child {
			margin-right: 10px;
		}
	}

	.hidden_button {
		display: none;
	}
}

.loader {
	display: block;
	margin: auto;
}