@import "~brands/default/styles/globals";
.data_wrap {
	display: flex;
	flex-direction: row;

	.column_custom{
		max-width: 267px;
	}

	.column {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-left: 15px;

		.data {
			display: flex;
			flex-direction: column;
			margin-bottom: 25px;

			> span {

				&:first-child {
					font-family: $primary-font-regular;
					font-weight: 700;
					height: 14px;
					font-size: 14px;
					line-height: 1;
					color: $color8;
					margin-bottom: 19px;
				}

				&.status_span,
				&.reimsi_span {

					&.status_span {
						margin-bottom: 0;

						&.entitlement {
							margin-bottom: 19px;
						}
					}

					&.reimsi_span + span {
						text-transform: capitalize;
					}

					position: relative;
					align-self: flex-start;
				}

				&:nth-child(2) {
					height: 16px;
					font-family: $primary-font-regular;
					font-size: 16px;
					line-height: 1;
					text-align: left;
					color: $color8;
					word-break: break-all;
					word-break: break-word;
				}
			}
		}
	}
}