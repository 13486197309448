:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.apn_list {
  width: 100%;
  margin-bottom: 20px;
}

.apn_list .apn_details {
  margin-bottom: 30px;
}

.apn_section {
  max-height: 0;
  transition: all .3s ease-in-out;
  overflow: hidden;
}

.apn_section.show {
  max-height: 500vh;
}

.link {
  color: #0568ae;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 72px;
  transition: visibility .3s ease-in-out;
}

.link svg {
  width: 15px;
  height: 15px;
  margin-left: 10px;
}

.link svg path {
  fill: #0568ae;
}

.link svg path g {
  fill: #0568ae;
}

.link.link_margin {
  margin-top: 42px;
}

.headline {
  font-family: "attalecksans_w_medium";
  font-size: 18px;
  color: #191919;
  display: flex;
  justify-content: space-between;
  min-height: 25px;
  font-weight: 500;
  margin: 40px 15px 30px 0;
  align-items: center;
  width: 787px;
  padding-bottom: 11px;
  border-bottom: 1px #d2d2d2 solid;
}

.headline.extend_margin {
  margin-top: 70px;
}

.headline.apns_margin {
  margin-top: 20px;
}

.headline.off_state {
  margin-top: 100px;
}

.field_wrapper {
  width: 320px;
  margin: 0 30px 10px 0;
  display: inline-table;
}

.left_content {
  width: 320px;
  margin-right: 30px;
  display: inline-table;
}

.left_content.no_margin {
  margin: 0;
}

.checkbox_margin {
  margin-bottom: 20px;
}

.checkbox_margin.extend {
  margin-bottom: 30px;
}

.off_state_apns {
  margin-bottom: 100px;
}

.sub_field_wrapper {
  width: 160px;
  margin: 0 30px 10px 0;
  display: inline-table;
}

.checkbox_wrapper {
  margin: 20px 0;
}

.divider {
  height: 1px;
  background-color: #959595;
  margin-bottom: 30px;
}

.section_buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-end;
  margin: 0 30px 20px 0;
}

.section_buttons :not(:last-child) {
  margin-right: 15px;
}

