@import "~brands/default/styles/globals";
.input_wrapper_mobile {
  width: 100%;
}

.input_wrapper {
  width: 255px;
  position: relative;

  .action {
    width: 35px;
    height: 34px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: $color3;
    border-left: 1px solid $color4;
    position: absolute;
    right: 1px;
    top: 27px;
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;

      path {
        &:nth-child(2) {
          fill: $color10;
        }
      }
    }

    &_check {
      svg {
        path {
          &:nth-child(2) {
            fill: green;
          }
        }
      }
    }

    &_save {
      opacity: 0.4;
      cursor: default;
    }
  }
}

.inline_input_wrapper {
  width: 300px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 15px;

  &.field_column_block {
    padding-right: 15px;

    @include tablet-portrait-and-below {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.remove_left_padding {
  padding-left: 0;
}

.inline_column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .inline_input_wrapper {
    width: 300px;
    margin-right: 30px;
    margin-bottom: 20px;
  }
}