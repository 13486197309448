:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.data {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 33%;
}

.data>span:first-child {
  font-family: "attalecksans_w_regular";
  font-weight: 700;
  height: 14px;
  font-size: 14px;
  line-height: 1;
  color: #191919;
  margin-bottom: 19px;
}

.data>span.status_span {
  margin-bottom: 10px;
  height: 25px;
}

.data>span.status_span.plan {
  margin-bottom: 5px;
}

.data>span:nth-child(2) {
  height: 16px;
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  line-height: 1;
  text-align: left;
  color: #191919;
  word-break: break-all;
}

.plan_data {
  margin-bottom: 0;
  width: auto;
}

.container {
  background: #f2f2f2;
  border-radius: 5px;
  margin-bottom: 30px;
}

.container>div {
  border-top: none;
}

.container:global(__contentOuter) {
  position: relative;
}

.container:global(__contentInner) {
  display: flex;
  flex-direction: column;
}

.container:global(__contentInner)::before {
  content: "";
  border-bottom: 1px solid gray;
  width: 99%;
  margin-left: 10px;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px;
  width: 100%;
}

.content.content_width {
  width: 70%;
}

.top_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.additional_info {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.left_content {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left_content.left_content_width {
  width: 20%;
}

.price {
  color: #5a5a5a;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.round_price {
  text-align: center;
  font-size: 70px;
}

.not_round_price {
  font-size: 24px;
  margin-top: 10px;
}

.price_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 48px;
}

.pricing_type {
  color: #191919;
  margin-bottom: 10px;
}

.mrc_wrapper {
  font-weight: 700;
  color: #191919;
  margin-bottom: 10px;
}

svg.checked_icon {
  width: 22px;
  height: 28px;
  right: 5px;
  position: relative;
}

svg.checked_icon g {
  fill: #4ca90c;
}

svg.checked_icon g path {
  fill: #4ca90c;
}

svg.x_icon {
  width: 22px;
  height: 28px;
  right: 5px;
  position: relative;
}

svg.x_icon g {
  fill: #cf2a2a;
}

svg.x_icon g path {
  fill: #cf2a2a;
}

.left_centered {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.icon_margin {
  display: flex;
  width: 100%;
  margin-right: 20px;
}

