@import "~brands/default/styles/globals";
.color {
  display: flex;
  align-items: center;
  color: $color14;
  cursor: pointer;

  &.disabled {
    color: $color5;
    cursor: not-allowed;
  }

  @media only screen and (max-width: 641px) {
    width: 50%;
    padding: 10px;
  }
}

.icon {
  width: $icon-md;
  height: $icon-md;
  margin-right: 10px;
  fill: $color14;

  &.disabled {
    color: $color5;
  }
}

.table_wrapper {
	div {
    div[data-spec="table-wrapper"] {
      overflow: visible;
    }
  }
}