@import "~brands/default/styles/globals";
.card_wrapper {
	border-radius: 10px;
	box-shadow: $card-shadow;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 440px;
	flex-flow: column;
	height: 100%;
	width: 100%;
}
