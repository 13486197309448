@import "~brands/default/styles/globals";
$trigger-icon-height: 2px;
$trigger-icon-width: 10px;

.level {
	display: flex;
	align-items: center;
	height: 71px;
	overflow: hidden;
	transition: max-height 0.7s linear;
	background: $color21;
	&:not(:last-child) {
		border-bottom: 1px solid $color22;
	}

	.data {
		display: flex;
		flex-direction: column;

		> span {
			&:first-child {
				font-family: $primary-font-medium;
				font-weight: 500;
				height: 14px;
				font-size: 16px;
				line-height: 1;
				color: $color8;
				margin-bottom: 7px;
			}

			&:nth-child(2) {
				height: 16px;
				font-family: $primary-font-regular;
				font-weight: 300;
				font-size: 14px;
				line-height: 1;
				text-align: left;
				color: $color8;
				word-break: break-all;
				word-break: break-word;
			}
		}
	}

	.divider {
		width: 1px;
		height: 45px;
		background-color: $color22;
		margin: 0 33px;
	}
}

.trigger__icon {
	height: $trigger-icon-height;
	width: $trigger-icon-width;
	background: $color14;
	position: relative;
	margin-right: 10px;
	cursor: pointer;

	&:before {
		content: ' ';
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -10px;
		margin-top: -10px;
		width: 20px;
		height: 20px;
	}

	&:after {
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: $color14;
		transform: rotate(90deg);
	}

	&.no_subplatforms {
		pointer-events: none;
		background: transparent;

		&:after {
			background: transparent;
		}
  }
  
  &.open::after {
    transform: rotate(0deg);
  }
}

.m2m_account_name {
  display: flex;
  align-items: center;
}

.accounts_wrapper{
	margin: 15px 0 0;
}

.clickable {
	color: $color14;
	cursor: pointer;
}