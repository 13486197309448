:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.form_container {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.row_container {
  display: flex;
  margin-top: 15px;
}

.input_container {
  margin: 10px 0;
  flex: .5;
  height: 35px;
}

.input_container:nth-child(odd) {
  margin-right: 20px;
}

.input_container_one {
  margin: 10px 0;
  margin-left: 16px;
  margin-right: 32px;
  flex: .5;
  height: 35px;
}

.input_container_checkbox {
  margin: 10px 0;
  margin-right: 32px;
  flex: .5;
  height: 35px;
}

.dropdown_label {
  margin-bottom: 10px;
}

.field_array_edit {
  margin: 0 16px;
  padding: 10px 0;
  border-bottom: 1px solid #d2d2d2;
}

.field_array_edit:last-child {
  border-bottom: none;
}

.field_array_add {
  margin: 0 16px;
}

