@import "~brands/default/styles/globals";
.chart_wrapper_wide {
	height: 100%;
	width: 100%;
}

.chart_wrapper {
	height: 95%;
	width: 100%;
}

.main {
	display: flex;
	height: 95%;
	align-items: center;

	.side_unit {
		transform: rotate(-90deg);
		width: 3%;
		height: 3%;
		letter-spacing: 2px;
		padding-left: 20px;
	}
}

.customTooltipMain {
	background: white;
	border-radius: 2px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
	padding: 5px 9px;
}

.customTooltip {
	white-space: pre-wrap;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
	word-spacing: normal;

	.customTooltipBlock {
		display: block;
		width: 12px;
		height: 12px;
		margin-right: 7px;
	}

	& > :not(:first-child) {
		word-spacing: normal;
	}
}

.legend {
	height: 10%;
	margin: -5px 40px;
	font-size: 10px;
	width: 85%;

	.legend_item {
		display: inline-block;

		.legend_dot {
			height: 9px;
			width: 9px;
			border-radius: 50%;
			display: inline-block;
			margin: 0 5px 0 10px;
		}
	}
}
