:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 45px 15px;
}

.row {
  margin: 0 0 35px 0;
}

.label {
  font-size: 14px;
  font-family: "attalecksans_w_medium";
  margin: 0 0 15px 0;
}

.info {
  font-size: 16px;
}

.status {
  display: block;
  position: relative;
  padding-left: 15px;
}

.status::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: 3px;
  left: -3px;
  background-color: #191919;
}

.status.pending::before {
  background-color: #ea7400;
}

.status.received::before {
  background-color: #4ca90c;
}

.status.shipped::before {
  background-color: #0568ae;
}

.status.completed::before {
  background-color: #222;
}

.block {
  padding: 15px;
  background-color: #f8f8f8;
  margin: 0 0 35px 0;
}

.total_info {
  font-family: "attalecksans_w_medium";
  font-size: 30px;
}

.section_headline {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: .6px;
  margin: 30px 0 30px 0;
}

