:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.modal__body {
  height: 250px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.note {
  display: flex;
  flex-direction: column;
}

.details {
  display: flex;
  flex-direction: row;
}

.detail {
  display: flex;
  flex-direction: column;
}

.created_by {
  margin-right: 48px;
}

.detail_title {
  font-weight: bold;
  margin-bottom: 8px;
}

.note_content {
  margin-top: 16px;
  line-height: initial;
}

.no_data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 46px;
}

