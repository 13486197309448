@import "~brands/default/styles/globals";
.page_wrapper {
	margin: 0 0 0 45px;
	@include mobile-only {
		margin: 0 0 0 20px;
	}
}

.form_wrapper {
	max-width: 787px;
	width: 100%;
}

.form_headline {
	font-family: $primary-font-medium;
	font-size: 24px;
	letter-spacing: 0.6px;
	margin: 25px 0;
}

.form_logo {
	display: flex;
	@include mobile-only {
		display: block;
	}

	.logo {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.counter {
	position: relative;
	font-family:$primary-font-medium;
	font-size: 16px;
	font-weight: 500;
	margin: 0 0 25px 0;
	width: 670px;
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-end;
	margin: 0 15px 20px 0;

	@include mobile-only {
		margin-left:10px;
		margin-right: 10px;
	}

	:not(:last-child) {
		margin-right: 15px;
		@include mobile-only {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
}

.icon_wrapper {
	font-size: 16px;
	color: $color14;
	cursor: pointer;
	padding-bottom: 10px;
	display: block;

	svg {
		width: 25px;
		height: 25px;
		position: relative;
		top: 5px;

		path {
			fill: $color14;

			g {
				fill: $color14;
			}
		}
	}

	&.disabled {
		cursor: not-allowed;
		color: $color5;

		svg {
			path {
				fill: $color5;

				g {
					fill: $color5;
				}
			}
		}
	}
}

.remove_icon_wrapper {
	position: absolute;
	right: 0;
	top: 10px;
}

.validate_button {
	padding-top: 20px;
}

.bold {
  font-weight: bold;
}

/* .vtm_support_section{
	@media only screen and (max-width: 768px) {
		margin-right: 55px;
	}
} */