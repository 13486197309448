:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.value {
  flex-basis: 48%;
}

.bolded_text {
  font-weight: bold;
}

.field {
  margin-top: 8px;
  margin-bottom: 16px;
}

.upload {
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  margin-bottom: 10.5em;
}

.upload_title {
  font-weight: 500;
  font-family: "attalecksans_w_medium";
  font-size: 18px;
  color: #191919;
  margin-bottom: 2em;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: .5em;
}

.uploaded_file_name {
  font-weight: bold;
  margin-bottom: .5em;
}

.file_date_stamp {
  opacity: .5;
  margin-bottom: 2em;
}

.upload_file_name_title {
  font-weight: bold;
  margin-bottom: 1em;
}

