@import "~brands/default/styles/globals";
.rate_plan_wrapper {
	padding: 20px 0 0 45px;
}

.content {
	width: 787px;
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-end;
	margin: 0 20px 20px 0;
}