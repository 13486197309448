@import "~brands/default/styles/globals";
.wrapper {
	margin: 15px;

	.container {
		max-width: 500px;
		width: 100%;
		margin: 0 auto;

		h4 {
			font-weight: normal;
		}
	}

	.failover_message {
		margin-bottom: 30px;
		margin-top: 30px;
		font-size: 14px;
	}

	.block {
		display: flex;
		justify-content: space-between;
		@include mobile-only {
			display: block;
		}
	}

	.data {
		margin-bottom: 5px;
		font-family: $primary-font-medium;
	}

	.active {
		color: $color20;
	}
	.switch {
		@include mobile-only {
			margin: 5px;
		}
	}
}
