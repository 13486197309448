/* Insert your SCSS here */

.endpoint_name{
  cursor: pointer;
  color: $color14;
  word-break: break-all;
}

.endpoints_table{
  & > li{
    margin-right: 7px;
  }
}