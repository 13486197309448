:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.select_field_wrapper {
  padding-bottom: 30px;
}

.select_field_wrapper.rate_plan {
  padding-bottom: 0;
}

.select_field_wrapper.datepicker {
  margin-top: 14px;
}

.select_field_label {
  padding: 0 0 10px 0;
  font-weight: bold;
  text-transform: uppercase;
}

.select_field_label.normal_font_weight {
  font-weight: normal;
}

.select_field_label_text {
  font-family: "attalecksans_w_light";
  font-size: 14px;
  color: #191919;
  line-height: 16px;
  padding-bottom: 16px;
}

.select_field_label_text.rate_plan {
  font-family: "attalecksans_w_medium";
}

.rate_plan_list {
  display: flex;
}

.rate_plan_list .rate_plan_name {
  font-family: "attalecksans_w_medium";
  font-size: 14px;
  line-height: 1;
  color: #191919;
  padding-right: 105px;
  text-transform: uppercase;
}

.rate_plan_list .rate_plan_id {
  font-size: 16px;
  line-height: 1;
  color: #191919;
}

.custom {
  height: 360px;
  padding-right: 30px;
  overflow: visible;
}

.custom.step_1 {
  overflow-y: auto;
}

.rate_plan_table {
  margin: 10px;
  position: relative;
  width: 520px;
}

.rate_plan_table div {
  overflow-x: scroll;
  margin-left: 280px;
}

.rate_plan_table table {
  border-collapse: collapse;
  text-align: left;
}

.rate_plan_table th:not(:first-child) {
  border-left: 1px solid #d2d2d2;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  font-weight: normal;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.rate_plan_table th:not(:first-child).selected {
  background-color: #0568ae;
  color: #fff;
  border-color: #0568ae;
  border-bottom-color: #fff;
}

.rate_plan_table th:last-child {
  border-right: 1px solid #d2d2d2;
}

.rate_plan_table td {
  padding: 5px;
  min-width: 150px;
}

.rate_plan_table td:not(:first-child) {
  border-right: 1px solid #d2d2d2;
}

.rate_plan_table td.selected {
  background-color: #0568ae;
  color: #fff;
  border-color: #0568ae;
}

.rate_plan_table th:first-child {
  width: 131px;
  height: 27px;
  z-index: 1000;
  top: 5px;
  left: 0;
  position: absolute;
}

.rate_plan_table th:nth-child(2) {
  border-right: 1px solid #d2d2d2;
  width: 150px;
  height: 28px;
  z-index: 1000;
  top: 0;
  left: 131px;
  position: absolute;
  background-color: #f2f2f2;
  box-shadow: 7px 0 7px -7px rgba(0,0,0,.3);
}

.rate_plan_table td:first-child {
  border-left: 1px solid #d2d2d2;
  border-collapse: collapse;
  font-weight: bold;
  width: 132px;
  position: absolute;
  left: 0;
  z-index: 1000;
  border-right: 1px solid #d2d2d2;
  min-width: 130px;
  height: 45px;
}

.rate_plan_table td:nth-child(2) {
  border-left: 1px solid #d2d2d2;
  border-collapse: collapse;
  font-weight: bold;
  width: 150px;
  position: absolute;
  left: 131px;
  z-index: 1000;
  border-right: 1px solid #d2d2d2;
  min-width: 130px;
  height: 45px;
  background-color: #f2f2f2;
  box-shadow: 7px 0 7px -7px rgba(0,0,0,.3);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rate_plan_table td:last-child {
  border-right: 1px solid #d2d2d2;
}

.rate_plan_table tr:first-child td:first-child {
  border-top: 1px solid #d2d2d2;
  top: 27px;
}

.rate_plan_table tr:first-child td:last-child {
  border-right: 1px solid #d2d2d2;
}

.rate_plan_table tr:last-child td:first-child {
  border-bottom: 1px solid #d2d2d2;
}

.rate_plan_table tr:last-child td:nth-child(2) {
  border-bottom: 1px solid #d2d2d2;
}

.rate_plan_table tr:last-child td:last-child {
  border-right: 1px solid #d2d2d2;
}

.rate_plan_table tr:last-child td:not(:first-child) {
  padding-bottom: 7px;
}

.step {
  height: 405px;
  overflow: auto;
  padding-right: 30px;
  margin-right: -30px;
}

