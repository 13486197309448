:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.sidebar {
  width: 220px;
  transition: width .5s linear;
  overflow: hidden;
  position: sticky;
  top: 58px;
}

.sidebar .top_dropdown {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border-bottom: 1px solid #d2d2d2;
  cursor: pointer;
}

.sidebar .top_dropdown .account_detail {
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 1;
  transition: all .2s linear;
  min-width: 120px;
  cursor: pointer;
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
  .sidebar .top_dropdown .account_detail {
    transition: all 0s linear;
  }
}

.sidebar .top_dropdown .account_detail .account_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebar .top_dropdown .account_detail .account_name>span:first-child {
  font-family: "attalecksans_w_medium";
  font-size: 9px;
  letter-spacing: .5px;
  color: #191919;
  text-transform: uppercase;
  line-height: 10px;
}

.sidebar .top_dropdown .account_detail .account_name>span:nth-child(2) {
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  line-height: 13px;
  color: #5a5a5a;
  width: 120px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.sidebar .top_dropdown .carret {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
  .sidebar .top_dropdown .carret {
    margin-left: 3px;
  }
}

.sidebar .top_dropdown .carret svg {
  width: 16px;
  height: 16px;
}

.sidebar .top_dropdown .carret svg path {
  fill: #5a5a5a;
}

.sidebar .menu_wrap .menu_list>li,
.sidebar .menu_wrap .menu_list>li>ul>li,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu {
  min-height: 44px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  position: relative;
  transition: all .5s linear;
  flex-wrap: wrap;
}

.sidebar .menu_wrap .menu_list>li>a,
.sidebar .menu_wrap .menu_list>li .menu_item,
.sidebar .menu_wrap .menu_list>li>ul>li>a,
.sidebar .menu_wrap .menu_list>li>ul>li .menu_item,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu>a,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu .menu_item {
  font-family: "attalecksans_w_light";
  text-decoration: none;
  color: #0568ae;
  height: 44px;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: .3px;
  display: flex;
  align-items: center;
}

.sidebar .menu_wrap .menu_list>li>a.active,
.sidebar .menu_wrap .menu_list>li .menu_item.active,
.sidebar .menu_wrap .menu_list>li>ul>li>a.active,
.sidebar .menu_wrap .menu_list>li>ul>li .menu_item.active,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu>a.active,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu .menu_item.active {
  font-family: "attalecksans_w_medium";
  color: #191919;
}

.sidebar .menu_wrap .menu_list>li>a.active .icon svg,
.sidebar .menu_wrap .menu_list>li .menu_item.active .icon svg,
.sidebar .menu_wrap .menu_list>li>ul>li>a.active .icon svg,
.sidebar .menu_wrap .menu_list>li>ul>li .menu_item.active .icon svg,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu>a.active .icon svg,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu .menu_item.active .icon svg {
  width: 100%;
}

.sidebar .menu_wrap .menu_list>li>a.active .icon svg path,
.sidebar .menu_wrap .menu_list>li .menu_item.active .icon svg path,
.sidebar .menu_wrap .menu_list>li>ul>li>a.active .icon svg path,
.sidebar .menu_wrap .menu_list>li>ul>li .menu_item.active .icon svg path,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu>a.active .icon svg path,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu .menu_item.active .icon svg path {
  fill: #191919;
}

.sidebar .menu_wrap .menu_list>li>a.active::before,
.sidebar .menu_wrap .menu_list>li .menu_item.active::before,
.sidebar .menu_wrap .menu_list>li>ul>li>a.active::before,
.sidebar .menu_wrap .menu_list>li>ul>li .menu_item.active::before,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu>a.active::before,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu .menu_item.active::before {
  content: "";
  position: absolute;
  left: 0;
  background-color: #0568ae;
  box-shadow: 0 1px 4.8px .2px rgba(0,0,0,.2);
  width: 5px;
  height: 44px;
}

.sidebar .menu_wrap .menu_list>li>a .icon,
.sidebar .menu_wrap .menu_list>li .menu_item .icon,
.sidebar .menu_wrap .menu_list>li>ul>li>a .icon,
.sidebar .menu_wrap .menu_list>li>ul>li .menu_item .icon,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu>a .icon,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu .menu_item .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 24px;
  overflow: hidden;
}

.sidebar .menu_wrap .menu_list>li>a .icon svg,
.sidebar .menu_wrap .menu_list>li .menu_item .icon svg,
.sidebar .menu_wrap .menu_list>li>ul>li>a .icon svg,
.sidebar .menu_wrap .menu_list>li>ul>li .menu_item .icon svg,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu>a .icon svg,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu .menu_item .icon svg {
  width: 100%;
}

.sidebar .menu_wrap .menu_list>li>a .icon svg path,
.sidebar .menu_wrap .menu_list>li .menu_item .icon svg path,
.sidebar .menu_wrap .menu_list>li>ul>li>a .icon svg path,
.sidebar .menu_wrap .menu_list>li>ul>li .menu_item .icon svg path,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu>a .icon svg path,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu .menu_item .icon svg path {
  fill: #0568ae;
}

.sidebar .menu_wrap .menu_list>li>a>span,
.sidebar .menu_wrap .menu_list>li .menu_item>span,
.sidebar .menu_wrap .menu_list>li>ul>li>a>span,
.sidebar .menu_wrap .menu_list>li>ul>li .menu_item>span,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu>a>span,
.sidebar .menu_wrap .menu_list>li .menu_item .collapsed_submenu .menu_item>span {
  margin-left: 10px;
  width: 150px;
  transition: all .5s linear;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.sidebar .menu_wrap .menu_list>li>a.active::before,
.sidebar .menu_wrap .menu_list>li .menu_item.active::before,
.sidebar .menu_wrap .menu_list>li>ul>li>a.active::before,
.sidebar .menu_wrap .menu_list>li>ul>li .menu_item.active::before {
  top: 0;
}

.sidebar .menu_wrap .menu_list>li .menu_item .hidden {
  display: none;
}

.sidebar .sidebar_toggle {
  width: 219px;
  height: 40px;
  margin-top: 50px;
  background-color: #f2f2f2;
  border-top: 1px solid #d2d2d2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  transition: all .5s linear;
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
  .sidebar .sidebar_toggle {
    transition: all 0s linear;
  }
}

.sidebar .sidebar_toggle .collapsed_text {
  visibility: hidden;
}

.sidebar .sidebar_toggle>span {
  font-family: "attalecksans_w_light";
  font-size: 14px;
  line-height: 12px;
  color: #5a5a5a;
  letter-spacing: .3px;
  width: 107px;
  display: inline-block;
  transition: all .5s linear;
}

.sidebar .sidebar_toggle .icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s linear;
}

.sidebar .sidebar_toggle .icon svg {
  width: 100%;
}

.sidebar .sidebar_toggle .icon svg path {
  fill: #5a5a5a;
}

.sidebar.collapsed {
  width: 85px;
  overflow: visible;
  z-index: 1;
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
  .sidebar.collapsed {
    width: 85px !important;
  }
}

.sidebar.collapsed .top_dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sidebar.collapsed .top_dropdown .account_detail {
  position: absolute;
  opacity: 0;
  left: 60px;
}

.sidebar.collapsed .top_dropdown .carret {
  margin: 0;
}

.sidebar.collapsed .top_dropdown .account_title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.sidebar.collapsed .top_dropdown .account_title .company_logo {
  height: 40px;
  width: 40px;
  margin-right: 0;
}

.sidebar.collapsed .top_dropdown .account_title .company_logo svg {
  height: 40px;
  width: 40px;
}

.sidebar.collapsed .account_dropdown {
  position: absolute;
  width: 220px;
  transition: width .5s linear,height .2s linear;
}

.sidebar.collapsed .account_dropdown.show .account_dropdown_item {
  padding-left: 15px;
}

.sidebar.collapsed .account_dropdown.show .account_dropdown_item .account_detail {
  opacity: 1;
}

.sidebar.collapsed .account_dropdown.show .add_account_text {
  display: flex;
  font-size: 14px;
  padding: 10px 0;
  color: #0568ae;
  transition: all .5s linear;
  cursor: pointer;
  justify-content: center;
}

.sidebar.collapsed .menu_wrap {
  overflow: visible;
}

.sidebar.collapsed .menu_wrap .menu_list>li {
  padding-left: 31px;
}

.sidebar.collapsed .menu_wrap .menu_list>li>a>span {
  transform: translate(100%);
}

.sidebar.collapsed .menu_wrap .menu_list>li .submenu {
  margin-left: -31px;
  transition: all 0s linear;
}

.sidebar.collapsed .menu_wrap .menu_list>li .submenu li {
  padding-left: 0;
}

.sidebar.collapsed .sidebar_toggle {
  margin-left: -135px;
}

.sidebar.collapsed .sidebar_toggle .icon {
  transform: rotate(180deg);
  margin-left: -27px;
}

.account_dropdown {
  position: absolute;
  width: 220px;
  height: 0;
  padding: 0 10px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 5px 20px 0 rgba(0,0,0,.18);
  transition: width .5s linear,height .3s linear;
}

.account_dropdown .companies_wrapper {
  max-height: 285px;
  overflow: auto;
  display: none;
}

.account_dropdown .account_dropdown_item {
  display: flex;
  transition: all .1s linear;
  padding: 10px 0;
  cursor: pointer;
}

.account_dropdown .account_dropdown_item.active {
  display: none;
}

.account_dropdown .account_dropdown_item .account_detail {
  display: none;
}

.account_dropdown .account_dropdown_item .account_detail .account_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.account_dropdown .account_dropdown_item .account_detail .account_name>span:first-child {
  font-family: "attalecksans_w_medium";
  font-size: 9px;
  letter-spacing: .5px;
  color: #191919;
  text-transform: uppercase;
  line-height: 10px;
}

.account_dropdown .account_dropdown_item .account_detail .account_name>span:nth-child(2) {
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  line-height: 13px;
  color: #5a5a5a;
  width: 120px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.account_dropdown.show {
  position: absolute;
  width: 220px;
  height: auto;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 5px 20px 0 rgba(0,0,0,.18);
  border: solid .3px #959595;
  z-index: 2;
  padding: 10px;
  transition: width .5s linear,height .3s linear;
}

.account_dropdown.show .companies_wrapper {
  max-height: 285px;
  overflow: auto;
  display: block;
}

.account_dropdown.show .account_dropdown_item {
  display: flex;
  padding: 10px 0;
  transition: all .3s linear;
  cursor: pointer;
}

.account_dropdown.show .account_dropdown_item.active {
  width: 100%;
  height: 70px;
  border-radius: 6px;
  background-color: #f2f2f2;
}

.account_dropdown.show .account_dropdown_item .account_detail {
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 1;
  transition: all .3s linear;
  min-width: 120px;
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
  .account_dropdown.show .account_dropdown_item .account_detail {
    transition: all 0s linear;
  }
}

.account_dropdown.show .account_dropdown_item .account_detail>span:first-child {
  font-family: "attalecksans_w_medium";
  font-size: 9px;
  letter-spacing: .5px;
  color: #191919;
  text-transform: uppercase;
  line-height: 10px;
}

.account_dropdown.show .account_dropdown_item .account_detail>span:nth-child(2) {
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  line-height: 13px;
  color: #5a5a5a;
  width: 120px;
  text-transform: capitalize;
}

.account_dropdown.show .divider {
  height: 1px;
  background: #d2d2d2;
  box-shadow: none;
  width: 100%;
  border: 0;
}

.account_dropdown.show .add_account_text {
  display: flex;
  font-size: 14px;
  padding: 10px 0;
  color: #0568ae;
  transition: all .5s linear;
  cursor: pointer;
  justify-content: center;
}

.divider {
  height: 0;
  border: 0;
}

.add_account_text {
  display: none;
}

.specificLogo {
  width: 30px;
}

.link_im {
  cursor: pointer;
}

.link_with_submenu {
  cursor: pointer;
}

.submenu {
  margin-left: -14px;
  margin-top: 15px;
  max-height: 0;
  transition: all .3s linear;
  overflow: hidden;
}

.submenu.active {
  max-height: 88px;
}

.custom_menu_icon {
  margin-left: 3px;
  margin-right: 3px;
}

.account_title {
  font-family: "attalecksans_w_medium";
  font-size: 9px;
  letter-spacing: .5px;
  color: #191919;
  text-transform: uppercase;
  line-height: 10px;
}

.company_logo {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  border-radius: 50%;
  transition: all .6s linear;
}

.company_logo svg {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

