/* normalize.css */
button,
hr,
input {
	overflow: visible;
}
audio,
canvas,
progress,
video {
	display: inline-block;
}
progress,
sub,
sup {
	vertical-align: baseline;
}
[type="checkbox"],
[type="radio"],
legend {
	box-sizing: border-box;
	padding: 0;
}
body,
ol,
ul {
	margin: 0;
}
html {
	line-height: 1.15;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
	display: block;
}
h1 {
	font-size: 2em;
	margin: 0.67em 0;
}
figure {
	margin: 1em 40px;
}
hr {
	box-sizing: content-box;
	height: 0;
}
code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}
a {
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}
abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted;
}
b,
strong {
	font-weight: bolder;
}
dfn {
	font-style: italic;
}
mark {
	background-color: #ff0;
	color: $color8;
}
small {
	font-size: 80%;
}
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
}
sub {
	bottom: -0.25em;
}
sup {
	top: -0.5em;
}
audio:not([controls]) {
	display: none;
	height: 0;
}
img {
	border-style: none;
}
svg:not(:root) {
	overflow: hidden;
}
button,
input,
optgroup,
select,
textarea {
	font-family: sans-serif;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
}
button,
select {
	text-transform: none;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
	-webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
	border-style: none;
	padding: 0;
}
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
	outline: ButtonText dotted 1px;
}
fieldset {
	padding: 0.35em 0.75em 0.625em;
}
legend {
	color: inherit;
	display: table;
	max-width: 100%;
	white-space: normal;
}
textarea {
	overflow: auto;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}
[type="search"] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}
::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}
summary {
	display: list-item;
}
[hidden],
template {
	display: none;
}
*,
:after,
:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
ol,
ul {
	list-style: none;
	padding: 0;
}
/* normalize.css */

@import "~brands/default/styles/fonts.scss";
// TODO: Check if this will be needed here;
@import "~brands/default/styles/generic/icons.scss";

body {
	font-size: $primary-font-size;
  font-family: $primary-font-regular;
  overflow-x: hidden;
}
