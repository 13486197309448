:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.custom {
  overflow: visible;
}

div.status_col {
  margin-top: 17px;
  width: 140px;
}

.select_field_label {
  padding: 0 0 10px 0;
  font-weight: bold;
  text-transform: uppercase;
}

.select_field_label.normal_font_weight {
  font-weight: normal;
}

div.custom-option {
  position: relative;
  padding-left: 18px;
}

div.custom-option::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 4px;
  background-color: #191919;
}

div.custom-option.select-single-value {
  padding-left: 15px;
  transform: translateY(0);
}

div.custom-option.select-single-value::before {
  top: 50%;
  margin-top: -5px;
  left: 2px;
}

div.custom-option.active::before,
div.custom-option.activated::before {
  background-color: #4ca90c;
}

div.custom-option.retired::before {
  background-color: #702f8a;
}

div.custom-option.deactived::before,
div.custom-option.inventory::before,
div.custom-option.pause::before,
div.custom-option.inactive::before {
  background-color: #d2d2d2;
}

div.custom-option.test_ready::before,
div.custom-option.test::before {
  background-color: #ffb81c;
}

div.custom-option.terminated::before,
div.custom-option.terminate::before {
  background-color: #cf2a2a;
}

div.custom-option.purged::before {
  background-color: #5a5a5a;
}

div.custom-option.suspend::before,
div.custom-option.suspended::before {
  background-color: #d2d2d2;
}

div.custom-option.deactive::before {
  background-color: #cf2a2a;
}

div.custom-option.provisioned::before {
  background-color: #ffb81c;
}

div.custom-option.inactive::before {
  border: 1px solid #959595;
}

