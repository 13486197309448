@import "~brands/default/styles/globals";
.chart_wrapper {
	height: 100%;
	width: 100%;

	.main {
		height: 98%;
		display: flex;
		align-items: center;
	}

	.main_analytics {
		height: 95%;
		display: flex;
		align-items: center;
	}

	.side_unit {
		transform: rotate(-90deg);
		width: 15px;
		height: 3%;
		letter-spacing: 2px;
		margin-bottom: 50px;
	}

	.bcl_wrapper {
		padding: 8px 37px;
		display: flex;
		flex-direction: column;
		width: 100%;

		.title {
			font-size: 11px;
			font-weight: 300;
			letter-spacing: 0.6px;
			color: $color8;
		}

		.count {
			font-size: 24px;
			line-height: 0.83;
			letter-spacing: 0.6px;
			color: $color8;
		}

		.total {
			border-top: 1px solid $color4;
			padding: 5px 0;
		}
	}
}

.not_wide {
	height: 90%;
}

.customTooltipMain {
	background: white;
	border-radius: 2px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
	padding: 5px 9px;
}

.customTooltip {
	white-space: pre-wrap;
	display: flex;
	align-items: center;

	.customTooltipBlock {
		display: block;
		width: 12px;
		height: 12px;
		margin-right: 7px;
	}

	.analytics_tooltip {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.medium_font > span:first-child {
			font-family: $primary-font-medium;
			margin-right: 2px;
		}
	}
}

.legend {
	height: 10%;
	margin: -5px 40px;
	font-size: 12px;
	width: 85%;

	.legend_item {
		display: inline-block;
		margin-bottom: 5px;

		.legend_dot {
			height: 9px;
			width: 9px;
			border-radius: 50%;
			display: inline-block;
			margin: 0 5px 0 10px;
		}
	}
}
