@import "~brands/default/styles/globals";
.tabs_wrapper{
	display:flex;

	.tabs_sidebar{
		width: 240px;
		margin-right:10px;
	}
	.tabs_content{
		background-color: $color3;
		width:calc(100% - 240px);
		overflow: auto;

		code {
			background-color: $color3;
			display:flex;
			height: 100%;
		}
	}
}

.below_tab {
	pre {
		background-color: $color3;
		padding: 15px;
		border-left: 2px solid $color19;
		margin-top: 0 !important;
	}
}

wrapper ul {
list-style: square outside none !important;
padding: 0 0 0 25px;

}
.image{
	max-width:100px;
}

p:not(:first-of-type):last-of-type {
    padding-bottom: 40px;
}
p {line-height:normal;}
