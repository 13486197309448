@import "~brands/default/styles/globals";
.recredential_wrapper{
  display: flex;
  width: 100%;
  border-bottom: 1px solid $color4;
  padding: 0 15px;
  align-items: center;
  margin-bottom: 30px;
	flex-wrap: wrap;

  .data {
    display: flex;
    flex-direction: column;
		margin-right: 40px;
		margin-bottom: 30px;
		width: 320px;

    >span {

      &:first-child {
        font-family: $primary-font-regular;
        font-weight: 700;
        height: 14px;
        font-size: 14px;
        line-height: 1;
        color: $color8;
        margin-bottom: 19px;
      }

      &:nth-child(2) {
        height: 16px;
        font-family: $primary-font-regular;
        font-size: 16px;
        line-height: 1;
        text-align: left;
        color: $color8;
      }

      &.tooltip_data{
        position: relative;

        .tooltip {
          box-shadow: 0 5px 12px 0.2px rgba(0, 0, 0, 0.2)
        }

        > svg {
          width: 12px;
          margin-left: 6px;
          cursor: pointer;
      
          path {
            fill: $color14;
          }
        }
      }
    }
  }
}
