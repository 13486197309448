@import "~brands/default/styles/globals";
.chart_wrapper {
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}

.main {
	flex-direction: column;
	//display: flex;
	height: 100%;
	width: 100%;

	.tooltip {
		box-shadow: 0 0 10px $color6;
		> div {
			display: flex;
			align-items: center;
			img {
				max-height: $icon-md;
				margin-right: 5px;
			}
		}
	}

	.wrapper{
		width: 100%;
		height: 85%;
		//max-width: 1000px;
		margin: 0 auto;
		flex: 1;
	}

	.wrapper_analytics {
		height: 100%;
	}

	.chart {
		height: 100%;
		width: 100%;
	}

	.wcd_wrapper {
		margin: 0 37px;
		border-top: 1px solid $color4;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.title {
			font-size: 11px;
			font-weight: 300;
			letter-spacing: 0.6px;
			color: $color8;
		}
		.count {
			font-size: 24px;
			line-height: 0.83;
			letter-spacing: 0.6px;
			color: $color8;
			@media only screen and (min-width: 1600px) {
				padding-right:20px;
			}
		}
		.total {
			padding: 20px 0;
		}
		.countries {
			display: flex;
			flex-flow: row wrap;
			@media only screen and (min-width: 1600px) {
				padding-top:20px;
			}
			.country {
				padding-right: 20px;
			}
		}
	}
}
