:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  border-radius: 6px;
  background-color: #f2f2f2;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,0);
  padding: 15px;
  cursor: pointer;
  margin-bottom: 13px;
}

.status:not(:nth-child(4)) {
  margin-right: 10px;
}

.status .circle {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin-top: 20px;
  background-color: #333;
}

.status .circle.active,
.status .circle.activated {
  background-color: #4ca90c;
}

.status .circle.retired {
  background-color: #702f8a;
}

.status .circle.deactived,
.status .circle.inventory,
.status .circle.pause,
.status .circle.inactive {
  background-color: #d2d2d2;
}

.status .circle.test_ready,
.status .circle.test {
  background-color: #ffb81c;
}

.status .circle.terminated,
.status .circle.terminate {
  background-color: #cf2a2a;
}

.status .circle.purged {
  background-color: #5a5a5a;
}

.status .circle.suspend,
.status .circle.suspended {
  background-color: #d2d2d2;
}

.status .circle.deactive {
  background-color: #cf2a2a;
}

.status .circle.provisioned {
  background-color: #ffb81c;
}

.status>span {
  font-family: "attalecksans_w_medium";
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  letter-spacing: .6px;
  text-align: center;
  color: #191919;
  margin-top: 20px;
}

.status.disabled {
  opacity: .3;
  cursor: not-allowed;
}

.status.selected {
  border-color: #0568ae;
}

