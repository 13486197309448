@import "~brands/default/styles/globals";
.modal_body {
    padding: 10px 30px;
    height: auto;
}
.cards {
    display: flex;
    justify-content: space-between;
}
.address_card {
    margin-top: 20px;
    padding: 20px;
    border: 0.5px solid #959595;
    border-radius: 10px;
    p {
        margin: 5px 0;
    }
}
.address_card_active {
    border: 1.5px solid #0568AE;
}
.right_card {
    min-height: calc(100% - 20px);
}
.invalid_address_label{
    display: block;
    margin-top: 20px;
}
.address_card_wrapper {
    width: calc(50% - 10px);
}
.address_card_title {
    font-weight: 800;
    text-transform: uppercase;
}
.suggested_address_card_title {
    color: $color20;
}
.address_card_data {
    margin-top: 20px;
}
.loader {
    min-height: auto;
    height: 100%;
}