@import "~brands/default/styles/globals";
.usage_trend_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 85%;
	padding-left: 5px;
}

.wide_card_border_line {
	border-left: 1px solid $color4;
	padding-left: 0;
	margin-top: 25px;
}

.usage_trend_wrapper {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	align-items: center;
}

.usage_trend_header {
	text-transform: uppercase;
	color: $color4;
	font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1937 - 300)));
	line-height: 24px;
}

.usage_trend_header_modal {
	text-transform: uppercase;
	color: $color4;
	font-size: calc(14px + (14 - 14) * ((100vw - 300px) / (1937 - 300)));
	line-height: 24px;
}

.usage_trend_item {
	font-weight: bold;
	//font-size: 62px;
	font-size: calc(30px + (50 - 30) * ((100vw - 300px) / (1937 - 300)));

	:nth-child(2) {
		//font-size: 30px;
		font-size: calc(15px + (20 - 15) * ((100vw - 300px) / (1937 - 300)));
	}
}

.modal_usage_trend_item {
	//font-size: 40px;
	font-size: calc(30px + (30 - 30) * ((100vw - 300px) / (1937 - 300)));
}

.usage_trend_index {
	font-weight: bold;
	//font-size: 39px;
	font-size: calc(30px + (30 - 30) * ((100vw - 300px) / (1937 - 300)));
	display: flex;
	flex-direction: row-reverse;
	align-items: center;

	& > :nth-child(2) {
		margin-right: 5px;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
	}
}

.usage_trend_index_modal {
	font-size: calc(25px + (25 - 25) * ((100vw - 300px) / (1937 - 300)));
}

.arrow_up {
	border-bottom: 8px solid red;
}

.arrow_down {
	border-top: 8px solid green;
}
