@import "~brands/default/styles/globals";
$trigger-icon-height: 2px;
$trigger-icon-width: 10px;

.trigger__icon {
	height: $trigger-icon-height;
	width: $trigger-icon-width;
	background: $color14;
	position: relative;
	margin-right: 10px;
	cursor: pointer;

	&:before {
		content: ' ';
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -10px;
		margin-top: -10px;
		width: 20px;
		height: 20px;
	}

	&:after {
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: $color14;
		transform: rotate(90deg);
	}

	&.no_subplatforms {
		pointer-events: none;
		background: transparent;

		&:after {
			background: transparent;
		}
  }
  
  &.open::after {
    transform: rotate(0deg);
  }
}

.m2m_account_name {
  display: flex;
  align-items: center;
}

.accounts_wrapper{
	margin: 15px 0 0;
}

.check_icon {
	display: table;
	width: 25px;
	height: 25px;

	g {
		path {
			fill: $color20;
		}
	}
}

.divider {
	height: 1px;
	background: $color4;
	box-shadow: none;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
	border: 0;
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-end;
	margin: 0 15px 20px 0;

	:not(:last-child) {
		margin-right: 15px;
	}
}

.wrapper {
	padding: 15px 0;
}