@import "~brands/default/styles/globals";
.selected_action_note {
	font-family: $primary-font-regular;
	font-size: 12px;
	color: $gradient_dark_third_color;
	display: flex;
	flex-direction: row;
	font-weight: 500;
	span {
		margin-left: 3px;
		font-family: $primary-font-bold;
	}
}

.restrictions {
  color: $color6;
}

.dropzone {
	margin: 15px auto 0 auto;
	width: 100%;
	height: 120px;
	border-radius: 12px;
	border: 2px solid $color3;
	background-color: $color3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-bottom: 15px;

	.text {
		font-family: $primary-font-regular;
		color: $color5;
    font-size: 12px;
    text-align: center;
	}

	&.error {
		border-color: $terminate-status;
		.text {
			color: $terminate-status;
		}
	}

	.upload_icon {
		width: 42.5px;
		height: 42.5px;
		g {
			path {
				fill: $color5;
			}
		}
	}
}

.dropzone:hover {
	border: 2px dashed $gradient_default_first_color;

	.text {
		color: $gradient_default_first_color;
	}

	.uploadIcon {
		g {
			path {
				fill: $gradient_default_first_color;
			}
		}
	}
}

.template__url {
	color: $color14;
	cursor: pointer;
	opacity: 1;
	transition: opacity 0.3s ease-out;

	&:hover {
		opacity: 0.7;
	}
}

.download_template {
  margin-right: 8px;
}
