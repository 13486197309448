@import "~brands/default/styles/globals";
.submit_column {
	max-width: 654px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}

.submit_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 30px;

	.field_wrapper {
		margin-bottom: 30px;
		width: 100%;
		text-align: center;

		.field_label {
			margin-bottom: 5px;
		}
	}

	.success_ticket {
		color: $color20;
		font-size: 21px;
		font-weight: 700;
		width: 100%;
		text-align: center;
	}

	.fail_ticket {
		color: $color10;
		font-size: 21px;
		font-weight: 700;
		width: 100%;
		text-align: center;
	}

	.icon_wrapper {
		display: flex;
		justify-content: center;
		margin-top: 30px;

		.success_icon {
			width: 68px;
			height: 68px;
		}

		.fail_icon {
			width: 85px;
			height: 79px;

			path {
				fill: $color10;
			}
		}
	}
}