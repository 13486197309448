@import "~brands/default/styles/globals";
.modal__body {
	padding: 5px 15px 0 30px;
	width: 600px;
	height: 200px;
}

.content {
	display: flex;
	flex-direction: column;
}

.row {
  display: flex;
  margin-top: 16px;
}

.filter_name {
	width: 150px;
	font-weight: bold;
}
