:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.top_container {
  padding: 20px;
}

.add_buttons_container {
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d2d2d2;
}

svg.add_icon_plus {
  width: 22px;
  height: 28px;
  right: 5px;
  position: relative;
}

svg.add_icon_plus g {
  fill: #0568ae;
}

svg.add_icon_plus g path {
  fill: #0568ae;
}

.button_container {
  display: flex;
  align-items: center;
}

.button_container>button:not(:last-child) {
  margin-right: 20px;
}

.add_container {
  display: flex;
  align-items: center;
}

.add_container:hover {
  cursor: pointer;
}

.add_container_disabled {
  display: flex;
  align-items: center;
}

.add_container_disabled:hover {
  cursor: not-allowed;
}

.add_text {
  color: #0568ae;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: .94;
  letter-spacing: normal;
}

