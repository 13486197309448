@import "~brands/default/styles/globals";
:global {
  .ds-last-step {
    position: relative;
    padding: 50px 0;

    .last-step-header {
      .header-icon {
        text-align: center;
        margin-bottom: 35px;

        .svg-icn {
          margin: 20px auto;

          svg {
            height: 70px;
          }

          &.svg-icn-error {
            svg {
              path {
                fill: $color10;
              }
            }
          } //icon error

          &.svg-icn-approval {
            svg {
              path {
                fill: $color20;
              }
            }
          }
        } //header icon svg
      } //step header

      .header-title {
        font-family: $primary-font-medium;
        text-align: center;
        font-size: 1rem;

        &.error {
          color: $color10;
        }
        &.approval {
          color: $color20;
        }
      }
    }
    .last-step-content {
      font-family: $primary-font-regular;
      text-align: center;
      max-width: 64ch;
      margin: 0 auto;
    }
    .last-step-error-list {
      text-align: left;
      padding: 20px 0 0 60px;
      list-style: disc;

      li {
        margin-bottom: 5px;
        span {
          color: $color10;
        }
      }
    }
  }
}
