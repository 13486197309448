:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.selected_dropdown::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: #0568ae;
  box-shadow: 0 1px 4.8px .2px rgba(0,0,0,.2);
  width: 5px;
  height: 800px;
}

.selected_dropdown {
  overflow: hidden;
}

.selected_dropdown .active span {
  font-family: "attalecksans_w_medium";
  color: #191919;
}

.selected_dropdown .active .icon svg {
  width: 100%;
}

.selected_dropdown .active .icon svg path {
  fill: #191919;
}

.selected_dropdown .active::before {
  content: "";
  position: absolute;
  left: 0;
  background-color: #0568ae;
  box-shadow: 0 1px 4.8px .2px rgba(0,0,0,.2);
  width: 5px;
  height: 44px;
}

.selected_dropdown_collapsed::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: #0568ae;
  box-shadow: 0 1px 4.8px .2px rgba(0,0,0,.2);
  width: 5px;
  height: 44px;
}

.lead_div {
  display: contents;
  cursor: pointer;
}

.menu_item {
  margin: 0;
  font-family: "attalecksans_w_light";
  text-decoration: none;
  color: #0568ae;
  height: 44px;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: .3px;
  display: flex;
  align-items: center;
}

.menu_item .active span {
  font-family: "attalecksans_w_medium";
  color: #191919;
}

.menu_item .active .icon svg {
  width: 100%;
}

.menu_item .active .icon svg path {
  fill: #191919;
}

.menu_item .active::before {
  content: "";
  position: absolute;
  left: 0;
  background-color: #0568ae;
  box-shadow: 0 1px 4.8px .2px rgba(0,0,0,.2);
  width: 5px;
  height: 44px;
}

.menu_item .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 24px;
  overflow: hidden;
}

.menu_item .icon svg {
  width: 100%;
}

.menu_item .icon svg path {
  fill: #0568ae;
}

.menu_item>span {
  margin-left: 10px;
  width: 140px;
  transition: all .5s linear;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.menu_item .hidden {
  display: none;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 24px;
  overflow: hidden;
}

.icon svg {
  width: 100%;
}

.icon svg path {
  fill: #0568ae;
}

.submenu_indent {
  margin-right: 10px;
}

.carret_right {
  margin-left: 5px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
  .carret_right {
    margin-left: 3px;
  }
}

.carret_right svg {
  width: 10px;
  height: 10.7px;
}

.carret_right svg path {
  fill: #5a5a5a;
}

.carret_right_uncollapsed {
  position: absolute;
  right: 20px;
  top: 16px;
}

.carret_right_uncollapsed svg {
  width: 15px;
  height: 15px;
}

.carret_right_uncollapsed svg path {
  fill: #959595;
}

.collapsed_submenu {
  background-color: #f2f2f2;
  width: 170px;
  z-index: 1020;
  box-shadow: 0 2px 13px 0 rgba(0,0,0,.12);
  min-height: 44px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  position: relative;
  transition: all .5s linear;
  flex-wrap: wrap;
}

.collapsed_submenu a {
  padding: 3px;
  margin: 3px;
  font-family: "attalecksans_w_light";
  text-decoration: none;
  color: #0568ae;
  height: 44px;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: .3px;
  display: flex;
  align-items: center;
  width: 100%;
}

