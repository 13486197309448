@import "~brands/default/styles/globals";
.diagnostics_details {
  flex: 2;
  padding: 0 30px 0 70px;
  @media (max-width: 901px) {
    padding: 0;
  }

  .dd_field {
    .dd_title {
      display: flex;

      &_left {
        flex: 200px;
        min-width: 200px;
        @media (max-width: 901px) {
          flex: 80px;
          min-width: 80px;
        }
        display: flex;
        padding-left: 32px;
        justify-content: flex-end;
        flex-direction: column;

        div {
          width: 6px;
          height: 6px;
          margin-top: 4px;
          border-radius: 50%;
          opacity: 0.3;
          background-color: $color4;

          &.success {
            opacity: 1;
            background-color: $color19;
          }

          &.error {
            opacity: 1;
            background-color: $color11;
          }
        }
      }

      &_right {
        padding: 15px 30px;
        font-weight: bold;
        text-transform: uppercase;
        flex: 100%;
        border-bottom: 1px solid $color19;

        &.disabled {
          opacity: 0.3;
          border-bottom-color: $color4;
        }

        &.error {
          border-bottom-color: $color11;
        }
      }
    }

    .dd_content {
      display: flex;
      align-items: center;

      &_left {
        flex: 200px;
        min-width: 200px;
        @media (max-width: 901px) {
          flex: 80px;
          min-width: 80px;
        }

        svg {
          max-height: 80px;
          max-width: 80px;
        }

        path, circle {
          fill: $color19;
        }

        &.disabled {
          path, circle {
            fill: $color4;
            opacity: 0.3;
          }
        }

        &.error {
          path, circle {
            fill: $color11;
          }
        }
      }

      &_right {
        flex: 100%;
      }
    }
  }

  .dd_field_item {
    .dd_item_title {
      font-size: 16px;
      font-weight: bold;
      padding: 15px 30px;
      color: $color8;

      &.disabled {
        opacity: 0.3;
      }
    }

    .dd_item_content {
      align-items: center;
      margin: 0 15px 30px 15px;
      padding: 15px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: bold;
      color: $color19;
      background-color: rgba(76, 169, 12, 0.2);
      border: solid 1px $color19;

      &.disabled {
        opacity: 0.3;
        color: $color4;
        background-color: rgba(210, 210, 210, 0.2);
        border: solid 1px $color4;
      }

      &.error {
        color: $color11;
        background-color: rgba(207, 42, 42, 0.2);
        border: solid 1px $color11;
      }
    }
  }
}