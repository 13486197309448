@import "~brands/default/styles/globals";
.content_wrapper {
	padding: 20px 0 0 45px;
}

.subtitle {
	letter-spacing: 0.6px;
	color: $color8;
	margin: 0 15px 35px 0;
}

.release_version {
	display: flex;
	align-items: center;

	> div {
		margin: 15px 0 0 15px;
	}
}

.headline {
	font-family: $primary-font-medium;
	font-size: 18px;
	color: $color8;
	display: flex;
	justify-content: space-between;
	min-height: 25px;
	font-weight: 500;
	margin: 0 0 15px 0;
	align-items: center;
}

.border {
	border-bottom: 1px $color4 solid;
}

.form_container {
	width: 815px;
}

.form_section_container {
	margin: 0 0 30px 0;
}

.fields_wrapper {
	margin: 30px 0 0 0;
}

.field_container {
	display: flex;
}

.known_issues_wrapper {
	position: relative;

	.known_issue_name {
		width: 645px;
		margin: 0 15px 0 0;
	}

	.known_issue_status {
		width: 125px;
	}
}

.remove_button {
	width: 15px;
	margin: 25px 0 0 15px;

	svg {
		width: 25px;
		height: 25px;
		position: relative;
		cursor: pointer;

		path {
			fill: $color14;

			g {
				fill: $color14;
			}
		}
	}
}

.field_full_width {
	width: 100%;
}

.fixed {
	color: $color19;
	-webkit-text-fill-color: initial;
}

.enhanced {
	color: $color13;
	-webkit-text-fill-color: initial;
}

.divider {
	height: 1px;
	background: $color4;
	box-shadow: none;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
	border: 0;
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-end;
  margin: 0 15px 20px 0;
  
  :not(:last-child) {
		margin-right: 15px;
	}
}

.icon_wrapper {
	font-size: 16px;
	color: $color14;
	cursor: pointer;
	padding-bottom: 10px;
	display: table-cell;

	svg {
		width: 20px;
		height: 20px;
		position: relative;
		top: 3px;

		path {
			fill: $color14;

			g {
				fill: $color14;
			}
		}
	}
}