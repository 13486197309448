:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.recredential_wrapper {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d2d2d2;
  padding: 0 15px;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.recredential_wrapper .data {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  margin-bottom: 30px;
  width: 320px;
}

.recredential_wrapper .data>span:first-child {
  font-family: "attalecksans_w_regular";
  font-weight: 700;
  height: 14px;
  font-size: 14px;
  line-height: 1;
  color: #191919;
  margin-bottom: 19px;
}

.recredential_wrapper .data>span:nth-child(2) {
  height: 16px;
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  line-height: 1;
  text-align: left;
  color: #191919;
}

.recredential_wrapper .data>span.tooltip_data {
  position: relative;
}

.recredential_wrapper .data>span.tooltip_data .tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
}

.recredential_wrapper .data>span.tooltip_data>svg {
  width: 12px;
  margin-left: 6px;
  cursor: pointer;
}

.recredential_wrapper .data>span.tooltip_data>svg path {
  fill: #0568ae;
}

