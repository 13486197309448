:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.desktop_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop_wrapper .custom_card {
  width: 98%;
  height: 300px;
  min-height: 100px;
}

.desktop_wrapper .custom_card .card_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 40px 20px;
}

.desktop_wrapper .custom_card .card_header :first-child {
  font-size: 20px;
}

.desktop_wrapper .custom_card .card_header :last-child {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.desktop_wrapper .custom_card .card_header .refresh_icon svg {
  width: 34px;
  height: 34px;
}

.desktop_wrapper .custom_card .card_header .refresh_icon svg path {
  fill: #009fdb;
}

.desktop_wrapper .custom_card .card_body {
  display: flex;
  width: 98%;
  height: 100%;
  justify-content: space-evenly;
}

.desktop_wrapper .custom_card .card_body>div {
  width: 12%;
}

.desktop_wrapper .custom_card .card_body>div>div {
  padding: 20px 0;
}

.desktop_wrapper .custom_card .card_body>div .bottom_items {
  padding: 10px 0;
}

.desktop_wrapper .custom_card .card_body>div>span,
.desktop_wrapper .custom_card .card_body>div>div>span {
  font-size: 12px;
  color: #959595;
}

.desktop_wrapper .custom_card .card_body .in_session {
  border-right: 1px solid #959595;
  height: 75%;
}

.desktop_wrapper .custom_card .card_body .in_session .tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
  font-size: 14px;
  padding: 5px;
}

.desktop_wrapper .custom_card .card_body .in_session :first-child {
  font-size: 12px;
  color: #959595;
}

.desktop_wrapper .custom_card .card_body .in_session :last-child {
  font-size: calc(8px + 36*(100vw - 300px)/1637);
  margin-top: 20px;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.desktop_wrapper .custom_card .card_body .in_session svg {
  width: 66px;
  height: 88px;
}

.desktop_wrapper .custom_card .card_body .in_session svg path {
  fill: #009fdb;
}

.desktop_wrapper .custom_card .card_body .receiving_transmitting {
  margin: 20px 0;
  position: relative;
}

.desktop_wrapper .custom_card .card_body .receiving_transmitting .receiving_arrow {
  border-left: 10px solid rgba(0,0,0,0);
  border-right: 10px solid rgba(0,0,0,0);
  border-top: 10px solid #009fdb;
  position: absolute;
  top: 50%;
  left: -35px;
}

.desktop_wrapper .custom_card .card_body .receiving_transmitting .transmitting_arrow {
  border-left: 10px solid rgba(0,0,0,0);
  border-right: 10px solid rgba(0,0,0,0);
  border-bottom: 10px solid #009fdb;
  position: absolute;
  top: 50%;
  left: -35px;
}

.desktop_wrapper .custom_card .card_body .receiving_transmitting :nth-child(2) {
  font-size: calc(12px + 32*(100vw - 300px)/1637);
  font-weight: 500;
}

.desktop_wrapper .custom_card .card_body .receiving_transmitting :last-child {
  font-size: calc(10px + 26*(100vw - 300px)/1637);
  font-weight: 500;
}

.desktop_wrapper .custom_card .card_body .total_volume_wrapper {
  border-right: 1px solid #959595;
  padding: 20px 0 40px 0;
}

.desktop_wrapper .custom_card .card_body .total_volume_wrapper .total_volume {
  margin-top: 10px;
}

.desktop_wrapper .custom_card .card_body .total_volume_wrapper .total_volume :first-child {
  font-size: calc(20px + 40*(100vw - 300px)/1637);
  font-weight: 500;
  color: #4ca90c;
}

.desktop_wrapper .custom_card .card_body .total_volume_wrapper .total_volume :last-child {
  font-size: calc(10px + 22*(100vw - 300px)/1637);
  font-weight: 500;
  color: #4ca90c;
}

.desktop_wrapper .custom_card .card_body .session_start_wrapper {
  display: flex;
  flex-direction: column;
}

.desktop_wrapper .custom_card .card_body .session_start_wrapper .session_start {
  display: flex;
  flex-direction: column;
}

.desktop_wrapper .custom_card .card_body .session_start_wrapper .session_start :first-child {
  font-size: calc(20px + 40*(100vw - 300px)/1637);
  font-weight: 500;
}

.desktop_wrapper .custom_card .card_body .session_start_wrapper .session_start :last-child {
  font-size: 12px;
}

.desktop_wrapper .table_wrapper {
  width: 98%;
  padding: 20px 0 10px 0;
  border-radius: 10px;
  margin: 40px 0;
  box-shadow: 0 0 5px 1px #d2d2d2;
}

.desktop_wrapper .table_wrapper .table_header {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
}

.desktop_wrapper .table_wrapper .table_header .table_header_buttons {
  display: flex;
}

.desktop_wrapper .table_wrapper .table_header .table_header_buttons [data-spec=spinner-wrapper] {
  display: initial;
  width: initial;
  height: initial;
  min-height: initial;
  position: initial;
}

.desktop_wrapper .table_wrapper .table_header .table_header_buttons canvas {
  width: 30px;
  height: 30px;
}

.desktop_wrapper .table_wrapper .table_header .table_header_buttons svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.desktop_wrapper .table_wrapper .table_header .table_header_buttons svg path {
  fill: #009fdb;
}

.desktop_wrapper .table_wrapper>span {
  font-size: 20px;
  padding: 0 20px;
}

.desktop_wrapper .table_wrapper>div {
  margin-top: 20px;
}

.desktop_wrapper .table_wrapper .no_data_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desktop_wrapper .table_wrapper .no_data_wrapper .message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

