:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.details_wrapper {
  padding: 20px 0 0 45px;
}

.form_subheadline {
  font-family: "attalecksans_w_light";
  font-size: 16px;
  letter-spacing: .6px;
  color: #333;
  width: 800px;
  margin: 0 0 35px 0;
}

.section_headline {
  font-family: "attalecksans_w_medium";
  font-size: 18px;
  color: #191919;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #d2d2d2 solid;
  min-height: 25px;
  width: 800px;
  font-weight: 500;
  margin-bottom: 10px;
}

.section_headline>span {
  margin: 0 0 10px 0;
}

.field_wrapper {
  width: 245px;
  margin: 0 30px 30px 0;
  display: inline-table;
}

.label {
  max-width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: .5px;
}

.info {
  font-size: 14px;
}

.rectangle_buttons_wrapper {
  display: flex;
  margin: 0 0 35px 0;
}

.rectangle_buttons_wrapper>div:not(:last-child) {
  margin-right: 30px;
}

