.wrapper {
	background-image: linear-gradient(45deg, $gradient_default_first_color 0%, $gradient_default_second_color 100%);
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	width: 100vw;

	svg {
		width: 50%;
		height: auto;
		margin-top: 20px;
	}

	.message {
		font-family: $primary-font-medium;
		margin-top: 10%;
		color: $color1;
		text-align: center;
	}
}
