:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.wrapper {
  background-image: linear-gradient(45deg, #0568ae 0%, #009fdb 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.wrapper svg {
  width: 50%;
  height: auto;
  margin-top: 20px;
}

.wrapper .message {
  font-family: "attalecksans_w_medium";
  margin-top: 10%;
  color: #fff;
  text-align: center;
}

.wrapper svg {
  width: 120px;
  margin-top: 15%;
}

.wrapper .message {
  font-size: 21px;
  font-family: "attalecksans_w_light";
  padding: 0 40px;
}

