@import "~brands/default/styles/globals";
.feedback_window {
  padding: 30px;
  max-width: 25%;
  min-width: 25%;
  transition-duration: 0.3s;
  transition-property: transform;
  transform: translateX(500px);
  text-align: left;
  border-radius: 0.25em 0.25em 0.4em 0.4em;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  position: fixed;
  min-height: 520px;
  height: 75%;
  right: 0;
  top: 20%;
  background-color: white;
  overflow-y: auto;

  @include tablet-only {
    min-width: 50%;
    max-width: 50%;
  }
}

.opened_window {
  transform: translateX(0);
}

.feedback_container {
  font-family: $primary-font-regular;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  opacity: 0;
  right: 0;
  min-height: 500px;
  z-index: 25;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  @media only screen and (min-width: 1025px) and (max-width: 1550px) {
    padding: 20px !important;
    top: 58px !important;
  }

  @include desktop-and-below {
    top: 58px !important;
    padding: 20px !important;
  }
}

.opened {
  visibility: visible;
  opacity: 1;
  transition: opacity .3s 0s, visibility 0s 0s;
}

.title {
  font-size: 18px;
  color: $color8;

  @include desktop-and-below {
    font-size: 16px;
    margin: 0;
  }

}

.subtitle {
  font-size: 14px;
  color: $color7;
  letter-spacing: 1px;
  font-family: $primary-font-light;
  font-weight: 300;
  line-height: 1.25;
  margin-bottom: 15px;

  @include desktop-only {
    font-size: 12px;
    margin-bottom: 10px;
  }

  @include desktop-and-below {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.field_wrapper {
  margin-bottom: 15px;

  @include desktop-and-below {
    margin-top: 5px;
    margin-bottom: 0;
  }

  b {
    display: block;
    margin-bottom: 10px;

    @include desktop-and-below {
      margin-bottom: 5px;
    }
  }

  .check_left,
  .check_right {
    width: 50%;
    display: inline-block;
  }

  .additional_file {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 14px;

    .optional {
      margin-left: 3px;
      font-size: 12px;
      color: $color5;
      font-weight: normal;
    }
  }
}

.hr {
  width: 100%;
  height: 4px;
  margin-top: 10px;
  margin-bottom: 20px;
  background-image: linear-gradient(
      to right,
      $color5 33%,
      rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;

  @include desktop-and-below {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.footer {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;

  &.center {
    justify-content: center;
  }

  & > button {
    min-width: 40%;
    margin-bottom: 5px;
  }
}

.success {
  position: relative;
  min-height: 420px;

  @include desktop-only {
    min-height: 430px;
  }

  .subtitle {
    font-size: 14px;
  }

  .icon {
    min-height: 265px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop-and-below {
      min-height: 250px;
    }
  }

  h5 {
    font-weight: 300;
    color: rgba($color7, 0.7);
  }
}

.corner_button {
  height: 27px;
  position: absolute;
  width: 27px;
  right: 12px;
  top: 12px;
  border-color: rgb(149, 149, 149);
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;

  .close {
    float: none;
    height: 27px;
    background: none;
    cursor: pointer;
    position: absolute;
    right: -1px;
    top: -1px;
    width: 27px;
    margin: 0;
    -webkit-appearance: none;
    border: 0;
    font-family: "icoPrimary";

    &:focus {
      overflow: visible;
    }

    &:focus {
      outline: none;

      &::after {
        @include dsfocus();
      }
    }

    &:before {
      content: "\e922";
      color: rgb(90, 90, 90);
      display: block;
      font-size: 18px;
      height: auto;
      position: absolute;
      right: 0;
      top: -1px;
      bottom: 0;
      left: 0;
      transform: translateY(15%);
      width: auto;
      margin: 0;
      -webkit-tap-highlight-color: transparent;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
  }

  // close
}

.file_loading {
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.field_info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;

    path {
      fill: $color14;

      g {
        fill: $color14;
      }
    }
  }
}

.ticketing_link {
  margin-bottom: 60px;

  .link {
    color: $color14;
    cursor: pointer;
  }
}
