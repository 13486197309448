:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.wrapper {
  display: flex;
}

.wrapper>:first-child {
  border-radius: 25px 0 0 25px;
}

.wrapper>:not(:first-child):not(:last-child) {
  border-radius: 0;
  border-left: 0;
}

.wrapper>:last-child {
  border-radius: 0 25px 25px 0;
  border-left: 0;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column;
  }

  .wrapper>:not(:only-child):first-child {
    border-radius: 25px 25px 0 0;
    border-bottom: 0;
  }

  .wrapper>:not(:first-child):not(:last-child) {
    border-radius: 0;
    border-top: 2px solid #0568ae;
    border-bottom: 0;
    border-left: 2px solid #0568ae;
    border-right: 2px solid #0568ae;
  }

  .wrapper>:not(:only-child):last-child {
    border-radius: 0 0 25px 25px;
    border-left: 2px solid #0568ae;
  }
}

