@import "~brands/default/styles/globals";
@import '../AddTicket/AddTicket.scss';

.form_hidden {
	visibility: hidden;
	opacity: 0;
	max-height: 0;
	transition: opacity 0.5s linear;
}

.form_active {
	visibility: visible;
	opacity: 1;
	max-height: 100%;
}

.ticketing_info {
	padding: 20px 0;
}