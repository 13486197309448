:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.custom_labels_field_header {
  font-size: 12px;
  font-weight: bold;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

.content_wrapper {
  display: flex;
}

.item {
  padding-bottom: 25px;
}

.field_icon_row {
  width: 100%;
  display: inline-table;
  align-items: center;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.line {
  height: 1px;
  background-color: #d2d2d2;
  width: 100%;
  max-width: 640px;
  margin-top: 16px;
}

.cancel_button {
  margin-right: 15px;
}

.main_content {
  padding-left: 10px;
}

.content_wrapper {
  flex-direction: column;
  margin-top: 20px;
}

.field_icon_row>div {
  width: calc(100% - 10px);
}

.buttons {
  display: block;
  width: calc(100% - 10px);
}

.buttons button {
  margin-bottom: 10px;
}

