@import "~brands/default/styles/globals";
.modal_body {
	height: 65vh;
}

.instructions_body {
	margin: 0px 0 0 30px;
	padding-right: 25px;
	.modal_instructions{
		font-size: 14px;
		font-family: $primary-font-light;

		p {
			margin-top: 0px;
			margin-bottom: 25px;
			text-align: justify;
		}

		h4 {
			margin-bottom: 5px;
		}
	}
}
