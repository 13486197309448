.wrapper {
	margin: 30px 15px 0 15px;
}

.multiple_column_container {
	max-width: 764px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}

.column {
	width: 48%;
}

.column_title {
	font-size: 12px;
	text-transform: uppercase;
}

.field_wrapper {
	padding-bottom: 10px;
}

.field_label {
	margin-bottom: 10px;
	font-weight: bold;
}

.buttons_wrapper {
	margin: 15px auto;
	display: flex;
  justify-content: space-between;
  width: 764px;

  .left_button {
    margin-left: -19px;
  }

	.buttons_right {
		float: right;

		:not(:last-child) {
			margin-right: 20px;
		}
	}
}

.custom_input {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
