@import "~brands/default/styles/globals";
.button_holder{
	display: flex;
	justify-content: space-between;
	@include mobile-only {
		display: block;
	}
}
.subtitle{
	margin-top:0;
	text-transform: uppercase;
}
.modal_body_wrapper{
	margin-bottom: 20px;
}

.modal_body{
	padding: 0 30px;
}
.short_modal_body{
	padding: 0 30px;
	@include mobile-only {
		height: calc(100% - 200px);
		min-height: calc(100% - 200px);
	}
}