:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.jumbotrone {
  display: flex;
  align-items: center;
  margin: 31px 0 50px 43px;
}

.oval {
  width: 131px;
  height: 131px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.oval img {
  border-radius: 100%;
}

.oval svg {
  width: 131px;
  height: 131px;
  border-radius: 100%;
}

.company_name {
  font-size: 35px;
  margin-left: 26px;
  color: #000;
}

