:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.page_title {
  margin: 0 15px;
  padding: 12px 0 11px 0;
  border-bottom: 1px solid #d2d2d2;
  display: flex;
  justify-content: space-between;
}

.title_container {
  display: flex;
  align-items: center;
}

.title_container .push_back {
  height: 24px;
  width: 24px;
  margin: 0 5px 0 0;
  cursor: pointer;
}

.title_container .title {
  display: flex;
  align-items: center;
  font-family: "attalecksans_w_light";
  font-weight: 100;
}

.actions_container {
  display: flex;
}

.page_title {
  height: 70px;
}

.title {
  margin: 0;
  font-size: calc(32px + 6*(100vw - 300px)/1637);
}

