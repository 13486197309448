@import "~brands/default/styles/globals";
.modal_body {
	height: 250px;
}
.notes_body {
	margin: 0 0 0 30px;
	padding-right: 80px;
	
	.modal_note {
		font-size: 14px;
		font-family: $primary-font-light;
		display: flex;
	}
}

.row {
	flex-basis: 25%;
	margin-right: 10px;

	.title_row {
		font-size: 14px;
		font-family: $primary-font-medium;
		margin-bottom: 5px;
	}

	.detail_holder {
		font-size: 16px;
		font-family: $primary-font-regular;
	}

	.title {
		font-size: 12px;
		font-family: $primary-font-medium;
		margin-bottom: 20px;
		margin-top: 0;
	}
}

.description {
	margin-top: 30px;
	font-size: 16px;
	font-family: $primary-font-regular;

	pre {
		font-family: $primary-font-light;
		font-size: 14px;
	}
}
