@import "~brands/default/styles/globals";
.modal_content {
	padding-left: 30px;
	padding-right: 30px;
}

.instructions {
	margin-top: 0;
}

.block {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.column {
	max-width: 185px;
	flex: 0 0 185px;
}

.column_fields {
	max-width: 150px;
	flex: 0 0 150px;
}

.label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 14px;
}

.icon {
	width: 20px;
	height: 20px;
	g {
		fill: $color14;
		path {
			fill: $color14;
		}
	}
}

.link_button {
	padding-left: 0;
	font-size: 14px;
	font-family: $primary-font-regular;
}

.link_button_template {
	font-size: 14px;
	font-family: $primary-font-regular;
	padding-right: 3px;
}

.accounts_message {
	font-family: $primary-font-medium;
	margin-bottom: 10px;
}

.separator {
	margin-top: 10px;
	margin-bottom: 10px;
	height: 1px;
	border:none;
	background-color: $color4;
}

.remove_icon_wrapper {
	cursor: pointer;
	display: flex;
	justify-content: center;
	color: $color14;
	align-items: center;

	:first-child {
		margin-right: 5px;
	}
}