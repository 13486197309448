:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.details_wrapper {
  padding: 20px 0 30px 45px;
  max-width: 882px;
  width: 98%;
}

.details_wrapper h5 {
  margin-bottom: 15px;
}

.name_and_logo {
  display: flex;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .name_and_logo {
    flex-direction: column;
  }
}

.field_wrapper_upper {
  width: 320px;
  margin: 30px 30px 0 0;
  display: inline-table;
}

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.info {
  font-size: 14px;
}

.logo {
  width: 174px;
}

.preferred_language_info {
  max-width: 250px;
}

.button_wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.button_wrapper>:nth-child(2) {
  border-left: 1px solid #d2d2d2;
}

.button_wrapper svg {
  width: 38px;
  height: 28px;
  cursor: pointer;
}

.button_wrapper svg path {
  fill: #0568ae;
}

.icon_wrapper {
  font-size: 16px;
  color: #0568ae;
  cursor: pointer;
  padding-bottom: 10px;
  display: block;
}

.icon_wrapper svg {
  width: 25px;
  height: 25px;
  position: relative;
  top: 5px;
}

.icon_wrapper svg path {
  fill: #0568ae;
}

.icon_wrapper svg path g {
  fill: #0568ae;
}

.icon_wrapper.disabled {
  cursor: not-allowed;
  color: #959595;
}

.icon_wrapper.disabled svg path {
  fill: #959595;
}

.icon_wrapper.disabled svg path g {
  fill: #959595;
}

.support_type_wrapper {
  margin: 0 40px 30px 0;
  font-size: 16px;
  line-height: 1.4;
}

.support_type_wrapper .bold {
  font-weight: bold;
}

.account_name_wrapper {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .account_name_wrapper {
    flex-direction: column;
  }
}

.support_type_radio_buttons_wrapper {
  display: flex;
  margin-top: 34px;
}

.remove_icon_wrapper {
  position: absolute;
  right: 0;
  top: 10px;
}

.counter {
  position: relative;
  font-family: "attalecksans_w_medium";
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 25px 0;
  width: 670px;
}

.bold {
  font-weight: bold;
}

.validate_button {
  padding-top: 20px;
}

