:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.modal_body {
  padding: 10px 30px;
  height: auto;
}

.cards {
  display: flex;
  justify-content: space-between;
}

.address_card {
  margin-top: 20px;
  padding: 20px;
  border: .5px solid #959595;
  border-radius: 10px;
}

.address_card p {
  margin: 5px 0;
}

.address_card_active {
  border: 1.5px solid #0568ae;
}

.right_card {
  min-height: calc(100% - 20px);
}

.invalid_address_label {
  display: block;
  margin-top: 20px;
}

.address_card_wrapper {
  width: calc(50% - 10px);
}

.address_card_title {
  font-weight: 800;
  text-transform: uppercase;
}

.suggested_address_card_title {
  color: #007a3e;
}

.address_card_data {
  margin-top: 20px;
}

.loader {
  min-height: auto;
  height: 100%;
}

