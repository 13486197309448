:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.diagnostics_wrapper .header {
  padding: 0 30px 30px;
  border-bottom: 1px solid #d2d2d2;
}

.diagnostics_wrapper .main {
  display: flex;
  flex-flow: row wrap;
  margin: 30px 15px;
}

.diagnostics_wrapper .diagnostics_details {
  flex: 2;
  padding: 0 30px;
}

@media (max-width: 901px) {
  .diagnostics_wrapper .diagnostics_details {
    padding: 0;
  }
}

.diagnostics_wrapper .diagnostics_details .test_wrapper {
  display: flex;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper+.test_wrapper {
  margin-top: 45px;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .graphics {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .graphics .success {
  height: 80px;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .graphics .success path {
  fill: #4ca90c;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .graphics .dots {
  height: 35px;
  margin-top: 55px;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .graphics .dots circle {
  fill: #4ca90c;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .graphics .dots.dots_error circle {
  fill: #ea7400;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .graphics .error {
  height: 80px;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .graphics .error path {
  fill: #ea7400;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data {
  flex: 6;
  display: flex;
  flex-direction: column;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .title {
  text-transform: uppercase;
  font-family: "attalecksans_w_medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: .6px;
  text-align: left;
  color: #191919;
  width: 100%;
  border-bottom: 1px solid #4ca90c;
  padding: 0 28px 13px 28px;
  margin-bottom: 17px;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .title.error {
  border-bottom: 1px solid #ea7400;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_country {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  padding: 0 28px;
  margin-bottom: 15px;
  overflow: hidden;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_country span {
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  color: #191919;
  padding-right: 20px;
  background-color: #fff;
  z-index: 1;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  padding: 0 28px;
  margin-bottom: 15px;
  overflow: hidden;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name .ragDot {
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name .ragDot.g {
  background-color: #4ca90c;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name .ragDot.a {
  background-color: #ea7400;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name .ragDot.r {
  background-color: #cf2a2a;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name:before {
  content: "";
  position: absolute;
  bottom: 6px;
  width: calc(100% - 56px);
  line-height: 0;
  border-bottom: 1px dashed #959595;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name span {
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  color: #191919;
  padding-right: 20px;
  background-color: #fff;
  z-index: 1;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name svg {
  height: 18px;
  width: 28px;
  background-color: #fff;
  z-index: 1;
  padding-left: 10px;
  margin-left: auto;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name svg path {
  fill: #4ca90c;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name svg.ncheck {
  cursor: pointer;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name svg.ncheck path {
  fill: #ea7400;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name .tooltip_data {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name .tooltip_data .data {
  line-height: 1.29;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name .tooltip_data .break_lines {
  border-bottom: 1px dashed #959595;
  margin-bottom: 10px;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper .data .data_name .tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
  opacity: 1;
}

.diagnostics_wrapper .diagnostics_details .test_wrapper:last-child div:last-child {
  justify-content: center;
}

.diagnostics_wrapper .registration_details {
  flex: 1;
}

.ragWrapper {
  display: flex;
}

