:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.modal {
  overflow: visible;
}

.modal_body {
  overflow: visible;
  max-height: 760px;
  height: 100%;
  padding: 0 30px 30px 30px;
  max-width: 1200px;
  width: 100%;
}

.modal_note {
  font-size: 20px;
  font-family: "attalecksans_w_regular";
  margin-bottom: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.modal_disclaimer_wrapper {
  font-size: 12px;
  margin-bottom: 10px;
}

.modal_disclaimer_wrapper>:nth-child(1) {
  font-family: "attalecksans_w_bold";
}

.modal_disclaimer_wrapper>:nth-child(2) {
  font-family: "attalecksans_w_regular";
}

.user_actions {
  display: flex;
  flex-direction: column;
}

.user_actions svg {
  height: 22px;
  width: 22px;
  cursor: pointer;
  margin-bottom: 5px;
  margin-left: 5px;
}

.user_actions svg path {
  fill: #0568ae;
}

.modal_button_check g path {
  fill: #007a3e;
}

.modal_button_cancel g path {
  fill: #cf2a2a;
}

.loader {
  max-height: 300px;
  min-height: 200px;
  height: 40%;
}

.body_note {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.body_note svg {
  width: 200px;
  height: 150px;
  padding: 30px 0;
}

.name_description_input {
  display: flex;
}

.name_description_input .graph_input[maxlength="50"] {
  width: 380px;
}

.name_description_input .graph_input[maxlength="250"] {
  width: 1000px;
}

.name_description_label {
  display: flex;
}

