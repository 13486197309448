@import "~brands/default/styles/globals";
.form_wrapper{
  display: flex;
  flex-direction: column;
  margin-left: 150px;
  margin-top: 15px;
  justify-content: center;
  width: 100%;
}

.select_wrapper{
  display: flex;
  flex-direction: column;
  width: 250px;

  &:nth-child(2){
    margin-top: 20px;
  }

  label{
    display: inline-block;
    max-width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
  }

  div label{
    display: none;
  }
}

.custom_loader{
  min-height: 0;
}