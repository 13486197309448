@import "~brands/default/styles/globals";
.dialog_wrap {
	.modal_content {
		.batch_file_upload {
			font-family: $primary-font-regular;
			color: $gradient_default_first_color;
			font-size: 21px;
			line-height: 1.2;
			text-align: left;
			display: flex;
			align-items: center;
			width: 100%;
    }
  }
}//wrapper around modal content with dropzone (batch upload modal)

.dropzone {
	margin: 15px auto 0 auto;
	width: 100%;
	height: 120px;
	border-radius: 12px;
	border: 2px solid $color3;
	background-color: $color3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-bottom: 15px;

	.text {
		font-family: $primary-font-regular;
		color: $color5;
		font-size: 12px;

		.title {
			text-align: center;
		}//title of text block

	}//wrapper around text inside dropzone

	&.error {
		border-color: $terminate-status;
		.text {
			color: $terminate-status;
		}
	}

	.uploadIcon {
		width: 42.5px;
		height: 42.5px;
		g {
			path {
				fill: $color5;

			}//dropzone icon fill color

		}

	} //dropzone upload Icon

}//dropzone

.dropzone:hover {
	border: 2px dashed $gradient_default_first_color;

	.text {
		color: $gradient_default_first_color;
	} //dropzone title hover color

	.uploadIcon {
		g {
			path {
				fill: $gradient_default_first_color;
			} //hover upload color fill
		}
	}

} //dropzone bulk upload hocer color

.selected_action_note {
	font-family: $primary-font-regular;
	font-size: 12px;
	color: $gradient_dark_third_color;
	display: flex;
	flex-direction: row;
	font-weight: 500;
	span {
		margin-left: 3px;
		font-family: $primary-font-bold;
	}
}
.template__url {
	color: $color14;
	cursor: pointer;
	opacity: 1;
	transition: opacity 0.3s ease-out;

	&:hover {
		opacity: 0.7;
	}
}

p.sims:not(:first-of-type):last-of-type{
  padding-bottom: 0;
}

.template_and_activation_platform_container {
	display: flex;
	margin-bottom: 15px;

	li {
		margin-top: 3px;
	}

	.custom_loader {
		min-height: 150px;
	}
}
