@import "~brands/default/styles/globals";
.registration_details {
  flex: 1;
  background-color: $color3;
  align-self: flex-start;
  border-radius: 10px;
  padding: 15px;
  position: relative;

  .rd_title {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.17;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    color: $color8;
    padding-bottom: 30px;
  }

  .rd_fields {
    .rd_field {
      font-size: 14px;
      font-weight: bold;
      color: $color8;

      &_value {
        font-size: 16px;
        padding-top: 20px;
        padding-bottom: 25px;
        font-weight: normal;
      }

      &:last-child {
        .rd_field_value {
          padding-bottom: 15px;
        }
      }
    }
  }

  .tablet_icon {
    position: absolute;
    bottom: 12px;
    right: 8px;
    height: 90px;
    width: 90px;

    path {
      fill: $color4;
    }
  }
}

.actions_wrapper {
  margin: 45px 0 30px 0;

  .title {
    margin-bottom: 12px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }
}