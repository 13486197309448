@import "~brands/default/styles/globals";
.state_change {
	display: flex;
	align-items: center;
}

.state_dropdown {
	width: 210px;
	height: 38px;
}

.implementation_status {
	font-weight: bold;
	margin-right: 8px;
}

.page_title_loader {
  margin-right: 48px;
  > div {
    justify-content: unset;
  }
}