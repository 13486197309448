:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.block_wrap {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
}

.block_wrap .column_block {
  border-bottom: 1px solid #d2d2d2;
  padding-left: 0;
  margin-bottom: 15px;
}

.block_wrap .specificationLink {
  font-size: 15px;
  color: #0568ae;
  margin-bottom: 20px;
  cursor: pointer;
}

.data_wrap {
  display: flex;
  flex-direction: row;
}

.data_wrap .column {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

.data_wrap .column .data {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.data_wrap .column .data>span:first-child {
  font-family: "attalecksans_w_regular";
  font-weight: 700;
  height: 14px;
  font-size: 14px;
  line-height: 1;
  color: #191919;
  margin-bottom: 19px;
}

.data_wrap .column .data>span.status_span,
.data_wrap .column .data>span.reimsi_span {
  position: relative;
  align-self: flex-start;
}

.data_wrap .column .data>span.status_span.status_span,
.data_wrap .column .data>span.reimsi_span.status_span {
  margin-bottom: 0;
}

.data_wrap .column .data>span.status_span.status_span.entitlement,
.data_wrap .column .data>span.reimsi_span.status_span.entitlement {
  margin-bottom: 19px;
}

.data_wrap .column .data>span.status_span.reimsi_span+span,
.data_wrap .column .data>span.reimsi_span.reimsi_span+span {
  text-transform: capitalize;
}

.data_wrap .column .data>span:nth-child(2) {
  height: 16px;
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  line-height: 1;
  text-align: left;
  color: #191919;
  word-break: break-word;
}

