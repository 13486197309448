:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

:global .confirm-upload-title {
  text-transform: uppercase;
  margin-bottom: 20px !important;
}

:global .confirm-content p {
  color: #767676;
  font-size: 16px;
  margin: 0 0 5px 0;
}

:global .second-step.modal-body {
  overflow-x: unset;
  overflow-y: unset;
}

@media screen and (-ms-high-contrast: active),screen and (-ms-high-contrast: none) {
  :global .second-step.modal-body {
    overflow-y: inherit;
    overflow-x: inherit;
  }
}

:global .batch-wrapper .ds-last-step {
  padding: 0;
}

.modal_body_batch_last_step {
  margin-bottom: 60px;
  overflow: auto;
  min-height: 300px;
}

