@import "~brands/default/styles/globals";
svg.addIconPlus {
	width: 22px;
	height: 28px;
	right:5px;
	position: relative;
	g {
		fill: $color1;
		path{
			fill: $color1;
		}
	}
}

.topbar_buttons_wrapper {
	& > :nth-child(1) {
		margin-right: 5px;
	}
}

.chart_Icon {
	width: 25px;
	height: 25px;
	display: inline-block;

	path {
		fill: $color7;
	}
}

.iconTab {
	border: 1px solid transparent;
	display: inline-block;
	border-radius: 6px;
	padding: 2px;
	margin-right: 5px;
	cursor: pointer;
	margin-bottom: 10px;

	&:last-of-type {
		margin-right: 0;
	}

	&.active {
		border: 1px solid $color13;
		background: $color3;
		cursor: not-allowed;

		.iconWrapper {
			path {
				fill: $color13;
			}
		}
	}
}

.icon_wrapper {
	display: flex;
	flex-direction: row;
}

.dropmenu {
	width: 200px;
	background: $color1;
	position: absolute;
	right: 20px;
	z-index: 1;
	border-radius: 6px;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
	border: 1px solid rgba($color5, 0.2);
	font-size: $primary-font-size;
	font-family: $primary-font-regular;
	text-transform: none;
	color: $color7;

	hr {
		border-color: rgba($color5, 0.1);
	}

	h4 {
		font-weight: 300;
	}

	.menuTop,
	.menuBottom {
		padding: 15px;
		margin: 0;
		cursor: pointer;
		&__color {
			color: $color14;
		}
	}

	.menuTop {
		h4 {
			margin-top: 0;
			margin-bottom: 15px;
		}
	}

	& > :last-child {
		color: $color10;
	}
}

.modal_header_tabs {
	display: flex;
	flex-direction: column;

	& > :nth-child(1) {
		margin-top: 0;
	}
	& > h5:nth-child(2) {
		color: $color10;
		margin-top: 0;
	}
	& > :last-child {
		margin-top: 0;
	}
}
