:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.feedback_window {
  padding: 30px;
  max-width: 25%;
  min-width: 25%;
  transition-duration: .3s;
  transition-property: transform;
  transform: translateX(500px);
  text-align: left;
  border-radius: .25em .25em .4em .4em;
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  position: fixed;
  min-height: 520px;
  height: 75%;
  right: 0;
  top: 20%;
  background-color: #fff;
  overflow-y: auto;
}

@media only screen and (min-width: 481px)and (max-width: 1024px) {
  .feedback_window {
    min-width: 50%;
    max-width: 50%;
  }
}

.opened_window {
  transform: translateX(0);
}

.feedback_container {
  font-family: "attalecksans_w_regular";
  visibility: hidden;
  transition: opacity .3s 0s,visibility 0s .3s;
  opacity: 0;
  right: 0;
  min-height: 500px;
  z-index: 25;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3);
}

@media only screen and (min-width: 1025px)and (max-width: 1550px) {
  .feedback_container {
    padding: 20px !important;
    top: 58px !important;
  }
}

@media only screen and (max-width: 1382px) {
  .feedback_container {
    top: 58px !important;
    padding: 20px !important;
  }
}

.opened {
  visibility: visible;
  opacity: 1;
  transition: opacity .3s 0s,visibility 0s 0s;
}

.title {
  font-size: 18px;
  color: #191919;
}

@media only screen and (max-width: 1382px) {
  .title {
    font-size: 16px;
    margin: 0;
  }
}

.subtitle {
  font-size: 14px;
  color: #333;
  letter-spacing: 1px;
  font-family: "attalecksans_w_light";
  font-weight: 300;
  line-height: 1.25;
  margin-bottom: 15px;
}

@media only screen and (min-width: 1025px)and (max-width: 1382px) {
  .subtitle {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1382px) {
  .subtitle {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.field_wrapper {
  margin-bottom: 15px;
}

@media only screen and (max-width: 1382px) {
  .field_wrapper {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.field_wrapper b {
  display: block;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1382px) {
  .field_wrapper b {
    margin-bottom: 5px;
  }
}

.field_wrapper .check_left,
.field_wrapper .check_right {
  width: 50%;
  display: inline-block;
}

.field_wrapper .additional_file {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.field_wrapper .additional_file .optional {
  margin-left: 3px;
  font-size: 12px;
  color: #959595;
  font-weight: normal;
}

.hr {
  width: 100%;
  height: 4px;
  margin-top: 10px;
  margin-bottom: 20px;
  background-image: linear-gradient(to right, #959595 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
}

@media only screen and (max-width: 1382px) {
  .hr {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.footer {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
}

.footer.center {
  justify-content: center;
}

.footer>button {
  min-width: 40%;
  margin-bottom: 5px;
}

.success {
  position: relative;
  min-height: 420px;
}

@media only screen and (min-width: 1025px)and (max-width: 1382px) {
  .success {
    min-height: 430px;
  }
}

.success .subtitle {
  font-size: 14px;
}

.success .icon {
  min-height: 265px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1382px) {
  .success .icon {
    min-height: 250px;
  }
}

.success h5 {
  font-weight: 300;
  color: rgba(51,51,51,.7);
}

.corner_button {
  height: 27px;
  position: absolute;
  width: 27px;
  right: 12px;
  top: 12px;
  border-color: #959595;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
}

.corner_button .close {
  float: none;
  height: 27px;
  background: none;
  cursor: pointer;
  position: absolute;
  right: -1px;
  top: -1px;
  width: 27px;
  margin: 0;
  -webkit-appearance: none;
  border: 0;
  font-family: "icoPrimary";
}

.corner_button .close:focus {
  overflow: visible;
}

.corner_button .close:focus {
  outline: none;
}

.corner_button .close:focus::after {
  content: "";
  height: 145%;
  left: -5px;
  top: -5px;
  outline: 1px dotted #191919;
  position: absolute;
  width: 145%;
}

.corner_button .close:before {
  content: "";
  color: #5a5a5a;
  display: block;
  font-size: 18px;
  height: auto;
  position: absolute;
  right: 0;
  top: -1px;
  bottom: 0;
  left: 0;
  transform: translateY(15%);
  width: auto;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -moz-appearance: none;
  -webkit-appearance: none;
}

.file_loading {
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.field_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.field_info svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.field_info svg path {
  fill: #0568ae;
}

.field_info svg path g {
  fill: #0568ae;
}

.ticketing_link {
  margin-bottom: 60px;
}

.ticketing_link .link {
  color: #0568ae;
  cursor: pointer;
}

