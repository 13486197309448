:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.dashboard_modal_body {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 200px;
  padding: 0 30px;
}

.dashboard_modal_body svg {
  width: 125px;
  height: 125px;
}

.dashboard_modal_body.modal_body_only {
  margin-bottom: 100px;
}

.dashboard_modal_body .modal_title {
  font-family: "attalecksans_w_medium";
}

.dashboard_modal_body_required svg path {
  fill: #cf2a2a;
}

.prompt_body {
  width: 80%;
  padding-right: 10px;
}

.prompt_body .modal_message {
  font-size: 18px;
  font-family: "attalecksans_w_regular";
  line-height: 22px;
}

.prompt_body .modal_text_block {
  margin-top: 15px;
}

.modal_prompt_buttons {
  text-align: right;
  display: inline;
}

