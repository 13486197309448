@import "~brands/default/styles/globals";
.action_bar_wrapper {
  display: flex;
  flex-flow: column;

  .top {
    .left {
      display: flex;
			color: $color14;
			flex-wrap: wrap;
			width: 100%;

      > div {
        padding: 20px;
				width:100%;
				text-align: center;
      }

      .tab {
        cursor: pointer;

        &_active {
          background: #f2f2f2;
          cursor: pointer;
        }
      }
    }
  }
}

