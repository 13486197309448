@import "~brands/default/styles/globals";
.chart_wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;

	.main_wrapper {
		justify-content: center;
		flex-basis: 100%;
		width: 100%;
		align-items: center;
		display: flex;
	}

	.main {
		height: 70%;
		width: 90%;
		position: relative;

		.total {
			position: absolute;
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;
			justify-content: center;
			align-items: center;
			color: $color8;
			letter-spacing: 0.6px;

			&_text {
				font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1937 - 300)));
				font-weight: 300;
			}

			&_value {
				display: flex;
				flex-direction: column;
				align-items: center;

				:first-child {
					font-size: calc(15px + (35 - 15) * ((100vw - 300px) / (1937 - 300)));
				}

				:last-child {
					font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1937 - 300)));
				}
			}

			&_value_modal {
				display: flex;
				flex-direction: column;
				align-items: center;

				:first-child {
					font-size: calc(15px + (20 - 15) * ((100vw - 300px) / (1937 - 300)));
				}

				:last-child {
					font-size: calc(12px + (15 - 12) * ((100vw - 300px) / (1937 - 300)));
				}
			}
		}
	}
}

.customTooltipMain {
	background: white;
	border-radius: 2px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
	padding: 5px 9px;
}

.customTooltip {
	white-space: pre-wrap;
	display: flex;
	align-items: center;

	.customTooltipBlock {
		display: block;
		width: 12px;
		height: 12px;
		margin-right: 7px;
	}

	.analytics_tooltip {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;

		.medium_font {
			display: flex;
			justify-content: center;
			align-content: center;

			& > span:first-child {
				font-family: $primary-font-medium;
			}
		}
	}
}
