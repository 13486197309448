@import "~brands/default/styles/globals";
@import '../AddTicket/AddTicket.scss';

.full_column {
	width: 100%;
}

.label_wrapper {
	display: flex;
	color: $color14;

	span {
		display: flex;
		align-items: center;
		margin: 0 5px 0 0;

		&:first-child {
			cursor: pointer;
		}

		svg {
			width: 20px;
			height: 20px;
			position: relative;

			path {
				fill: $color14;

				g {
					fill: $color14;
				}
			}
		}
	}
}

.dropzone_trigger {
	display: none;
	&.dropzone__active {
		display: block;
	}
}