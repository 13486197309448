@import "~brands/default/styles/globals";
.custom{
  overflow: visible;
}

div {
  &.status_col {
    margin-top: 17px;
    width: 140px;
  }
}

.select_field_label {
  padding: 0 0 10px 0;
  font-weight: bold;
  text-transform: uppercase;

  &.normal_font_weight{
    font-weight: normal;
  }
}

div.custom-option {
  position: relative;
  padding-left: 18px;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    margin-top: -5px;
    left: 4px;
    background-color: $color8;
  }

  &.select-single-value {
    padding-left: 15px;
    transform: translateY(0);
  }

  &.select-single-value::before {
    top: 50%;
    margin-top: -5px;
    left: 2px;
  }

  &.active::before,
  &.activated::before {
    background-color: $active;
  }

  &.retired::before {
    background-color: $color17;
  }

  &.deactived::before,
  &.inventory::before,
  &.pause::before,
  &.inactive::before {
    background-color: $suspended;
  }

  &.test_ready::before,
  &.test::before {
    background-color: $provisioned;
  }

  &.terminated::before,
  &.terminate::before {
    background-color: $deactive;
  }

  &.purged::before {
    background-color: $color6;
  }

  &.suspend::before,
  &.suspended::before {
    background-color: $suspended;
  }

  &.deactive::before {
    background-color: $deactive;
  }

  &.provisioned::before {
    background-color: $provisioned;
  }

  &.inactive::before {
    border: 1px solid $color5;
  }
}