@import "~brands/default/styles/globals";
.account_info_overview_wrapper {
	margin: 0 0 60px 0;
}

.field_wrapper {
	width: 245px;
	margin: 0 30px 30px 0;
	display: inline-table;
}

.label {
	max-width: 100%;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 0.5px;
}

.info {
	font-size: 14px;
}

.title {
  font-family: $primary-font-medium;
  font-size: 24px;
  letter-spacing: 0.6px;
	margin: 25px 0;
	font-weight: normal;

	&.has_subtitle{
		margin: 25px 0 10px 0;
	}
}

.subtitle {
  font-family: $primary-font-light;
  font-size: 16px;
  letter-spacing: 0.6px;
	color: $color7;
	width: 800px;
	margin: 0 0 35px 0;
}