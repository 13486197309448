@import "~brands/default/styles/globals";
.wrapper {
  display: flex;

  & > :first-child {
    border-radius: 25px 0 0 25px;
  }

  & > :not(:first-child):not(:last-child) {
    border-radius: 0;
    border-left: 0;
  }

  & > :last-child {
    border-radius: 0 25px 25px 0;
    border-left: 0;
  }

  @include tablet-portrait-and-below {
    flex-direction: column;

    > :not(:only-child):first-child {
      border-radius: 25px 25px 0 0;
      border-bottom: 0;
    }

    > :not(:first-child):not(:last-child) {
      border-radius: 0;
      border-top: 2px solid $color14;
      border-bottom: 0;
      border-left: 2px solid $color14;
      border-right: 2px solid $color14;
    }

    > :not(:only-child):last-child {
      border-radius: 0 0 25px 25px;
      border-left: 2px solid $color14;
    }
  }
}