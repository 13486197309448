@import "~brands/default/styles/globals";
.form_wrapper {
	padding: 20px 0 0 45px;
}

.form_headline {
	font-family: $primary-font-medium;
	font-size: 24px;
	letter-spacing: 0.6px;
	margin: 25px 0;
	font-weight: 500;
}

.form_subheadline {
	font-family: $primary-font-light;
	font-size: 16px;
	letter-spacing: 0.6px;
	color: $color7;
	width: 800px;
	margin: 0 0 35px 0;
}

.section_headline {
	font-family: $primary-font-medium;
	font-size: 18px;
	color: $color8;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px $color4 solid;
	min-height: 25px;
	width: 787px;
	font-weight: 500;

	> span {
		margin: 0 0 10px 0;
	}
}

.validate {
	margin-top: 32px;
	font-size: $buttons-font-size;
	font-family: $buttons-font-family;
	font-weight: normal;

	&.active {
		color: $button-link-color;
		cursor: pointer;
	}

	&.disabled {
		color: $button-disabled-color;
		cursor: not-allowed;
	}
}

.field_wrapper {
	width: 320px;
	margin: 0 30px 10px 0;
	display: inline-table;

	&.validate {
		margin-top: 32px;
		font-size: $buttons-font-size;
		font-family: $buttons-font-family;
		font-weight: normal;

		&.active {
			color: $button-link-color;
			cursor: pointer;
		}

		&.disabled {
			color: $button-disabled-color;
			cursor: not-allowed;
		}
	}

	label {
		display: flex;
		max-width: 100%;
		margin-bottom: 10px;
		font-weight: bold;
		font-size: 14px;

		svg {
			width: 15px;
			height: 15px;
			position: relative;
			cursor: pointer;
			margin: 0 0 0 5px;

			g {
				path {
					fill: $color14;
				}
			}
		}
	}

	.medium_field_wrapper {
		width: 140px;
		display: inline-block;
	}

	.medium_field_wrapper:last-child:not(:first-child) {
		float: right;
	}
}

.divider {
	height: 1px;
	background: $color4;
	box-shadow: none;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
	border: 0;
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-end;
	margin: 0 15px 20px 0;

	:not(:last-child) {
		margin-right: 15px;
	}
}

.tooltip {
	box-shadow: 0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
	font-weight: normal;
}

.spinner_wrapper {
	width: 787px;
}

.error_message {
	font-weight: bold;
	color: $color10;
}

.label_tooltip{
  margin-left: 5px;
  svg {
    width: 14px;
    height: 14px;
    cursor: pointer;

    path {
      fill: $color14;
    }
  }
}