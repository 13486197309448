@import "~brands/default/styles/globals";
.select_field_wrapper {
	padding-bottom: 30px;

	&.rate_plan{
		padding-bottom: 0;
	}

	&.datepicker{
		margin-top: 14px;
	}
}

.select_field_label {
	padding: 0 0 10px 0;
	font-weight: bold;
	text-transform: uppercase;

	&.normal_font_weight{
		font-weight: normal;
	}
}

.select_field_label_text{
	font-family: $primary-font-light;
	font-size: 14px;
	color: $color8;
	line-height: 16px;
	padding-bottom: 16px;

	&.rate_plan{
		font-family: $primary-font-medium;
	}
}

.rate_plan_list{
	display: flex;

	.rate_plan_name{
		font-family: $primary-font-medium;
		font-size: 14px;
		line-height: 1;
		color: $color8;
		padding-right: 105px;
		text-transform: uppercase;
	}

	.rate_plan_id{
		font-size: 16px;
		line-height: 1;
		color: $color8;
	}
}

.custom {
	height: 360px;
	padding-right: 30px;
	overflow: visible;

	&.step_1{
		overflow-y: auto;
	}
}

.rate_plan_table {
	margin: 10px;
	position: relative;
	width: 520px;

	div {
		overflow-x: scroll;
		margin-left: 280px;
	}

	table {
		border-collapse: collapse;
		text-align: left;
	}

	th:not(:first-child) {
		border-left: 1px solid $color4;
		border-top: 1px solid $color4;
		border-bottom: 1px solid $color4;
		font-weight: normal;
		padding: 5px;
		width: 200px;
		text-align: center;
		&.selected {
			background-color: $color14;
			color: $color1;
			border-color: $color14;
			border-bottom-color: $color1;
		}
	}

	th:last-child {
		border-right: 1px solid $color4;
	}

	td {
		padding: 5px;
		min-width: 150px;
		//border-left: 1px solid black;

		&:not(:first-child) {
			border-right: 1px solid $color4;
		}

		&.selected {
			background-color: $color14;
			color: $color1;
			border-color: $color14;
		}
	}

	th:first-child {
		width: 131px;
		height: 27px;
		z-index: 1000;
		top: 5px;
		left: 0;
		position: absolute;
	}

	th:nth-child(2) {
		border-right: 1px solid $color4;
		width: 150px;
		height: 28px;
		z-index: 1000;
		top: 0;
		left: 131px;
		position: absolute;
		background-color: $color3;
		box-shadow: 7px 0 7px -7px rgba(0, 0, 0, 0.3);
	}

	td:first-child {
		border-left: 1px solid $color4;
		border-collapse: collapse;
		font-weight: bold;
		width: 132px;
		position: absolute;
		left: 0;
		z-index: 1000;
		border-right: 1px solid $color4;
		min-width: 130px;
		height: 45px;
	}

	td:nth-child(2) {
		border-left: 1px solid $color4;
		border-collapse: collapse;
		font-weight: bold;
		width: 150px;
		position: absolute;
		left: 131px;
		z-index: 1000;
		border-right: 1px solid $color4;
		min-width: 130px;
		height: 45px;
		background-color: $color3;
		box-shadow: 7px 0 7px -7px rgba(0, 0, 0, 0.3);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	td:last-child {
		border-right: 1px solid $color4;
	}

	tr:first-child {
		td:first-child {
			border-top: 1px solid $color4;
			top: 27px;
		}
		td:last-child {
			border-right: 1px solid $color4;
		}
	}

	tr:last-child {
		td:first-child {
			border-bottom: 1px solid $color4;
		}
		td:nth-child(2) {
			border-bottom: 1px solid $color4;
		}
		td:last-child {
			border-right: 1px solid $color4;
		}
		td:not(:first-child) {
			padding-bottom: 7px
		}
	}
}

.step{
	height: 405px;
	overflow: auto;
	padding-right: 30px;
	margin-right: -30px;
}
