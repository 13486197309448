@import "~brands/default/styles/globals";
.main_wrapper_blue {
  width: 100%;
  min-height: 100vh;
  background-image: url('brands/default/images/illustration.png'), linear-gradient( 45deg, $gradient_default_first_color 0%, $gradient_default_second_color 100%);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-left:101px;
  padding-top:67px;

  @media only screen and (max-width: 641px) {
    padding: 20px;
  }
}

.main_wrapper_white {
  width: 100%;
  min-height: 100vh;
  background-image: url('brands/default/images/error-bg.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-top: 35px;

  @media only screen and (max-width: 641px) {
    padding: 20px;
  }

  .title {
    color: $gradient_default_first_color;
  }
  
  .sub_title {
    color: $gradient_default_first_color;
  }
  
  .message {
    color: $gradient_default_first_color;
  }
}

.title {
  font-family: $primary-font-medium;
  font-size:80px;
  color:$gradient_light_second_color;
}

.sub_title {
  font-family: $primary-font-medium;
  font-size:12px;
  color:$gradient_light_second_color;
  text-transform: uppercase;
}

.message {
  font-family: $primary-font-regular;
  font-size:24px;
  color:$gradient_light_second_color;
  margin-top:3px;
  width: 423px;

  @media only screen and (max-width: 641px) {
    width: 100%;
  }
}

.homepage{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 205px;
  height: 53px;
  padding: 0 10px;
  background: $gradient_light_second_color;
  border-radius: 3px;
  text-decoration: none;
  align-self: flex-start;
  margin-top:43px;

  >span{
    text-transform: uppercase;
    font-family: $primary-font-medium;
    font-size:14px;
    color:$gradient_default_first_color;
  }
}