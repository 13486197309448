:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.chart_wrapper .main {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.chart_wrapper .main .data_usage_icon {
  width: 96px;
  height: 96px;
  margin-top: 70px;
  margin-bottom: 70px;
}

.chart_wrapper .main .data_usage_icon path {
  fill: #0568ae;
}

.chart_wrapper .main .content {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  font-size: 11px;
  font-weight: 300;
  color: #191919;
}

.chart_wrapper .main .content__total {
  font-size: 24px;
}

