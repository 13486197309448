:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.page_wrapper {
  margin: 0 0 0 45px;
}

@media (max-width: 480px) {
  .page_wrapper {
    margin: 0 0 0 20px;
  }
}

.form_wrapper {
  max-width: 787px;
  width: 100%;
}

.form_headline {
  font-family: "attalecksans_w_medium";
  font-size: 24px;
  letter-spacing: .6px;
  margin: 25px 0;
}

.form_logo {
  display: flex;
}

@media (max-width: 480px) {
  .form_logo {
    display: block;
  }
}

.form_logo .logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter {
  position: relative;
  font-family: "attalecksans_w_medium";
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 25px 0;
  width: 670px;
}

.section_buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-end;
  margin: 0 15px 20px 0;
}

@media (max-width: 480px) {
  .section_buttons {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.section_buttons :not(:last-child) {
  margin-right: 15px;
}

@media (max-width: 480px) {
  .section_buttons :not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.icon_wrapper {
  font-size: 16px;
  color: #0568ae;
  cursor: pointer;
  padding-bottom: 10px;
  display: block;
}

.icon_wrapper svg {
  width: 25px;
  height: 25px;
  position: relative;
  top: 5px;
}

.icon_wrapper svg path {
  fill: #0568ae;
}

.icon_wrapper svg path g {
  fill: #0568ae;
}

.icon_wrapper.disabled {
  cursor: not-allowed;
  color: #959595;
}

.icon_wrapper.disabled svg path {
  fill: #959595;
}

.icon_wrapper.disabled svg path g {
  fill: #959595;
}

.remove_icon_wrapper {
  position: absolute;
  right: 0;
  top: 10px;
}

.validate_button {
  padding-top: 20px;
}

.bold {
  font-weight: bold;
}

