@import "~brands/default/styles/globals";
.modal_body_notf{
	background: #fff;
	position: static;
	margin-bottom: 75px;
	height:auto;
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		overflow-y: inherit;
		overflow-x: inherit;
	}
	overflow-y: unset;
	overflow-x: unset;
}//wrapper around modal body

.modal_notification_wrapper{
	margin-bottom: 20px;
}

.modal{
	min-height: 620px;
	overflow: visible;
	.modal_body{
		padding: 0 30px;
		height: 100%;
		min-height: 460px;
		width: 800px;
		overflow: visible;
		
		@include tablet-portrait-and-below {
			max-width: 800px;
			width: 100%;
		}
	}
}
