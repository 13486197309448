@import "~brands/default/styles/globals";
.modal_body {
	padding: 0 30px;
}
.download_template_area{
	color: $color6;
	margin-top: 5px;
	.template_url{
		color: $color14;
		cursor: pointer;
	}
}
