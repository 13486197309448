@import "~brands/default/styles/globals";
.selected_dropdown::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: $color14;
  box-shadow: 0 1px 4.8px 0.2px rgba(0, 0, 0, .2);
  width: 5px;
  height: 800px;
}

.selected_dropdown {
  overflow: hidden;

  & .active {
    span {
      font-family: $primary-font-medium;
      color: $color8;
    }

    .icon {
      svg {
        width: 100%;

        path {
          fill: $color8;
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      background-color: $color14;
      box-shadow: 0 1px 4.8px 0.2px rgba(0, 0, 0, .2);
      width: 5px;
      height: 44px;
    }
  }
}

.selected_dropdown_collapsed::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: $color14;
  box-shadow: 0 1px 4.8px 0.2px rgba(0, 0, 0, .2);
  width: 5px;
  height: 44px;
}

.lead_div {
  display: contents;
  cursor: pointer;
}

.menu_item {
  margin: 0;
  font-family: $primary-font-light;
  text-decoration: none;
  color: $color14;
  height: 44px;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;

  & .active {
    span {
      font-family: $primary-font-medium;
      color: $color8;
    }

    .icon {
      svg {
        width: 100%;

        path {
          fill: $color8;
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      background-color: $color14;
      box-shadow: 0 1px 4.8px 0.2px rgba(0, 0, 0, .2);
      width: 5px;
      height: 44px;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 24px;
    overflow: hidden;

    svg {
      width: 100%;

      path {
        fill: $color14;
      }
    }
  }

  > span {
    margin-left: 10px;
    width: 140px;
    transition: all .5s linear;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .hidden {
    display: none;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 24px;
  overflow: hidden;

  svg {
    width: 100%;

    path {
      fill: $color14;
    }
  }
}

.submenu_indent {
  margin-right: 10px;
}

.carret_right {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-left: 3px;
  }
  margin-left: 5px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10.7px;

    path {
      fill: $color6;
    }
  }
}

.carret_right_uncollapsed {
  position: absolute;
  right: 20px;
  top: 16px;

  svg {
    width: 15px;
    height: 15px;

    path {
      fill: $color5;
    }
  }
}

.collapsed_submenu {
  background-color: $color3;
  width: 170px;
  z-index: 1020;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.12);
  min-height: 44px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  position: relative;
  transition: all .5s linear;
  flex-wrap: wrap;

  a {
    padding: 3px;
    margin: 3px;
    font-family: $primary-font-light;
    text-decoration: none;
    color: $color14;
    height: 44px;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 0.3px;
    display: flex;
    align-items: center;
    width: 100%;
  }
}