:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.modal_content {
  padding-left: 30px;
  padding-right: 30px;
}

.instructions {
  margin-top: 0;
}

.block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  max-width: 185px;
  flex: 0 0 185px;
}

.column_fields {
  max-width: 150px;
  flex: 0 0 150px;
}

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.icon {
  width: 20px;
  height: 20px;
}

.icon g {
  fill: #0568ae;
}

.icon g path {
  fill: #0568ae;
}

.link_button {
  padding-left: 0;
  font-size: 14px;
  font-family: "attalecksans_w_regular";
}

.link_button_template {
  font-size: 14px;
  font-family: "attalecksans_w_regular";
  padding-right: 3px;
}

.accounts_message {
  font-family: "attalecksans_w_medium";
  margin-bottom: 10px;
}

.separator {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  border: none;
  background-color: #d2d2d2;
}

.remove_icon_wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  color: #0568ae;
  align-items: center;
}

.remove_icon_wrapper :first-child {
  margin-right: 5px;
}

