@import "~brands/default/styles/globals";
.user_actions{
	display: flex;
	justify-content: center;
	align-items: center;

	svg{
		height: 22px;
		width: 22px;
		cursor: pointer;

		path{
			fill: $color14;
		}

		&:nth-child(2){
			margin-left: 11px;

			path{
				fill: none;

				&:nth-child(2){
					fill: $color14;
				}
			}
		}
	}
}

.rule_name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
}

.tooltip {
	box-shadow: 0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
}

.label_tooltip{
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  svg {
    width: 14px;
    height: 14px;
    cursor: pointer;

    path {
      fill: $color14;
    }
  }
}

.email_wrap {
	a {
		color: $color14;
		cursor: pointer;
		text-decoration: none;
	}
}

.rule_status {
  display: flex;
  align-items: center;
  width: 155px;
}

.circle {
  content: '';
  width: 10px !important;
  height: 10px !important;
  border-radius: 100%;
  background-color: #000000;
  margin-right: 5px;
  margin-left: 5px;
}

.pending {
  background-color: $color9;
}
.not_applicable {
  background-color: $color6;
}
.received {
  background-color: $color14;
}
.implemented {
  background-color: $color19;
}
.conflicted {
  background-color: $color10;
}
.pending_for_deletion {
  // width: 14px;
  // height: 11px;
  background-color: $color11;
}

.clickable {
  cursor: pointer;
  color:$color14;
  text-decoration: none;
}