@import "~brands/default/styles/globals";
.tabContent{
	padding-left:30px;
	padding-right:30px;
}
.modal_body_notf{
	background: $color1;
	position: static;
	height: 250px;
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		overflow-y: inherit;
		overflow-x: inherit;
	}
	overflow-y: unset;
	overflow-x: unset;
}
.modal_title{
	margin-top:0;
	margin-bottom: 5px;
	font-size:12px;
}
.modal_title_common{
	margin-top:15px;
}
.p_box{
	margin-bottom: 15px;
	span{
		display: block;
		margin-bottom: 10px;
	}
}
