:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.wrapper {
  width: 98%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
}

.wrapper .card {
  box-shadow: 0 0 5px 1px #d2d2d2;
  width: 90%;
  height: 95%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  margin: 1px 15px;
}

.wrapper .card .title {
  text-transform: uppercase;
  padding-bottom: 10px;
}

.wrapper .card .item {
  font-size: 24px;
}

.wrapper .card .item_active {
  color: #4ca90c;
}

.wrapper .card .item.deactivate {
  color: #9063cd;
}

.wrapper .card .item.test_ready,
.wrapper .card .item.test {
  color: #ffb81c;
}

.wrapper .card .item.terminate {
  color: #cf2a2a;
}

.wrapper .card .item.inactive {
  color: #d2d2d2;
}

.wrapper .card .item.retired {
  color: #702f8a;
}

.wrapper .card .item.purged {
  color: #cf2a2a;
}

.wrapper .card .item.suspend,
.wrapper .card .item.suspended {
  color: #d2d2d2;
}

.wrapper .card .item.deactive {
  color: #cf2a2a;
}

.wrapper .card .item.provisioned {
  color: #ffb81c;
}

.wrapper .card .item_connection_yes {
  color: #4ca90c;
}

.wrapper .card .item_connection_no {
  color: #cf2a2a;
}

.wrapper .card .button_title {
  text-transform: uppercase;
}

