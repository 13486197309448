:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

:global .ds-last-step {
  position: relative;
  padding: 50px 0;
}

:global .ds-last-step .last-step-header .header-icon {
  text-align: center;
  margin-bottom: 35px;
}

:global .ds-last-step .last-step-header .header-icon .svg-icn {
  margin: 20px auto;
}

:global .ds-last-step .last-step-header .header-icon .svg-icn svg {
  height: 70px;
}

:global .ds-last-step .last-step-header .header-icon .svg-icn.svg-icn-error svg path {
  fill: #cf2a2a;
}

:global .ds-last-step .last-step-header .header-icon .svg-icn.svg-icn-approval svg path {
  fill: #007a3e;
}

:global .ds-last-step .last-step-header .header-title {
  font-family: "attalecksans_w_medium";
  text-align: center;
  font-size: 1rem;
}

:global .ds-last-step .last-step-header .header-title.error {
  color: #cf2a2a;
}

:global .ds-last-step .last-step-header .header-title.approval {
  color: #007a3e;
}

:global .ds-last-step .last-step-content {
  font-family: "attalecksans_w_regular";
  text-align: center;
  max-width: 64ch;
  margin: 0 auto;
}

:global .ds-last-step .last-step-error-list {
  text-align: left;
  padding: 20px 0 0 60px;
  list-style: disc;
}

:global .ds-last-step .last-step-error-list li {
  margin-bottom: 5px;
}

:global .ds-last-step .last-step-error-list li span {
  color: #cf2a2a;
}

