@import "~brands/default/styles/globals";
.indicator_dots_wrapper {
  position: absolute;
  width: 100%;
  z-index: 100;
  bottom: 0;
  text-align: center;

  .indicator_dot {
    display: inline-block;
    height: 12px;
    width: 12px;
    border-radius: 10px;
    background-color: $color14;
    margin: 7px 5px;
    opacity: 0.3;
    transition-duration: 300ms;

    &_selected {
      opacity: 1;
    }
  }
}