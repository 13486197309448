@import "~brands/default/styles/globals";
.content_wrapper {
	padding: 0 15px;

	.cards_wrapper {
		display: flex;
		margin: 15px 0 20px 0;

		@media only screen and (max-width: 641px) {
			flex-direction: column;
		}

		.card {
			border-radius: 10px;
			border: 1px solid $color4;
			max-width: 32%;
			width: 100%;
			padding: 20px;
			margin-right: 2%;

			&:last-child {
				margin-right: 0;
			}

			@media only screen and (max-width: 641px) {
				max-width: 100%;
				margin-bottom: 20px;
				margin-right: auto;

				&:last-child {
					margin-right: auto;
				}
			}

			&:hover {
				cursor: pointer;
				background-color: $color2;
				box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.12);
			}

			.icon_holder {
				padding-top: 40px;
				text-align: center;
				padding-bottom: 60px;

				.icon {
					width: 92px;
					height: 92px;
					fill: $color13;

					path {
						fill: $color13;
					}
				}
			}

			.title {
				font-size: 22px;
				margin-bottom: 10px;
				font-family: $primary-font-regular;
				color: $color14;
			}

			.intro {
				//text-transform: uppercase;
				font-size: 14px;
				line-height: 16px;
			}
		}
	}
}

.note {
	word-break: break-all;
	word-wrap: normal;
	max-width: 250px;
	padding: 5px 0;
}

.link {
	color: $color14;
	cursor: pointer;
}

.status {
	display: block;
	position: relative;
	padding-left: 15px;

	&::before {
		content: '';
		width: 10px;
		height: 10px;
		border-radius: 100%;
		position: absolute;
		top: 3px;
		left: -3px;
		background-color: $color8;
	}

	&.confirmation::before {
		background-color: $not-success-bg;
	}

	&.error::before {
		background-color: $not-error-bg;
	}

	&.warning::before {
		background-color: $not-warning-bg;
	}

	&.info::before {
		background-color: $not-pending-bg;
	}
}
