:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

svg.addIconPlus {
  width: 22px;
  height: 28px;
  right: 5px;
  position: relative;
}

svg.addIconPlus g {
  fill: #fff;
}

svg.addIconPlus g path {
  fill: #fff;
}

.topbar_buttons_wrapper>:nth-child(1) {
  margin-right: 5px;
}

.chart_Icon {
  width: 25px;
  height: 25px;
  display: inline-block;
}

.chart_Icon path {
  fill: #333;
}

.iconTab {
  border: 1px solid rgba(0,0,0,0);
  display: inline-block;
  border-radius: 6px;
  padding: 2px;
  margin-right: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.iconTab:last-of-type {
  margin-right: 0;
}

.iconTab.active {
  border: 1px solid #009fdb;
  background: #f2f2f2;
  cursor: not-allowed;
}

.iconTab.active .iconWrapper path {
  fill: #009fdb;
}

.icon_wrapper {
  display: flex;
  flex-direction: row;
}

.dropmenu {
  width: 200px;
  background: #fff;
  position: absolute;
  right: 20px;
  z-index: 1;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,.1);
  border: 1px solid rgba(149,149,149,.2);
  font-size: 14px;
  font-family: "attalecksans_w_regular";
  text-transform: none;
  color: #333;
}

.dropmenu hr {
  border-color: rgba(149,149,149,.1);
}

.dropmenu h4 {
  font-weight: 300;
}

.dropmenu .menuTop,
.dropmenu .menuBottom {
  padding: 15px;
  margin: 0;
  cursor: pointer;
}

.dropmenu .menuTop__color,
.dropmenu .menuBottom__color {
  color: #0568ae;
}

.dropmenu .menuTop h4 {
  margin-top: 0;
  margin-bottom: 15px;
}

.dropmenu>:last-child {
  color: #cf2a2a;
}

.modal_header_tabs {
  display: flex;
  flex-direction: column;
}

.modal_header_tabs>:nth-child(1) {
  margin-top: 0;
}

.modal_header_tabs>h5:nth-child(2) {
  color: #cf2a2a;
  margin-top: 0;
}

.modal_header_tabs>:last-child {
  margin-top: 0;
}

