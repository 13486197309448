@import "~brands/default/styles/globals";
.label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 14px;

	.required-label {
		color: $color8;
		font-weight: normal;
		text-transform: lowercase;
	}
}

.mask_field_wrapper {
	display: flex;

	.component_wrapper_left {
		width: 120px;
	}

	.component_wrapper_right {
		width: calc(100% - 120px);
		position: relative;
		div {
			div {
				div:nth-of-type(2) {
					width: calc(100% + 120px);
					margin-left: -120px;
				}
			}
		}

		.custom_input {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}