@import "~brands/default/styles/globals";
.modal {
	.dashboard__modal__body {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		max-height: 220px;

		svg {
			width: 125px;
			height: 125px;
			margin-top: -50px;
		}

		.modal_title {
			font-family: $primary-font-medium;
		}

		&_required {
			svg {
				path {
					fill: $color10;
				}
			}
		}
	}
}

.prompt__body {
	padding-right: 80px;
	margin-left: 30px;
	margin-bottom: 20px;
	.modal_message {
		font-size: 18px;
		font-family: $primary-font-regular;
    line-height: 22px;
    
    .uppercase {
      text-transform: uppercase;
    }
	}
	.modal_text_block {
		margin-top: 20px;
		margin-bottom: 20px;
	}
} // prompt body
