@import "~brands/default/styles/globals";
.modal {
	height: 600px;
	overflow: visible;
	.modal_body {
		padding: 0 30px;
		height: 425px;
		width: 800px;
    overflow: visible;
    margin-bottom: 50px;
  }
}

.modal_notification_wrapper {
	margin-bottom: 20px;
}
