:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.dialog_wrap .modal_content .batch_file_upload {
  font-family: "attalecksans_w_regular";
  color: #0568ae;
  font-size: 21px;
  line-height: 1.2;
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
}

.dropzone {
  margin: 15px auto 0 auto;
  width: 100%;
  height: 120px;
  border-radius: 12px;
  border: 2px solid #f2f2f2;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}

.dropzone .text {
  font-family: "attalecksans_w_regular";
  color: #959595;
  font-size: 12px;
}

.dropzone .text .title {
  text-align: center;
}

.dropzone.error {
  border-color: #cf2a2a;
}

.dropzone.error .text {
  color: #cf2a2a;
}

.dropzone .uploadIcon {
  width: 42.5px;
  height: 42.5px;
}

.dropzone .uploadIcon g path {
  fill: #959595;
}

.dropzone:hover {
  border: 2px dashed #0568ae;
}

.dropzone:hover .text {
  color: #0568ae;
}

.dropzone:hover .uploadIcon g path {
  fill: #0568ae;
}

.selected_action_note {
  font-family: "attalecksans_w_regular";
  font-size: 12px;
  color: #5a5a5a;
  display: flex;
  flex-direction: row;
  font-weight: 500;
}

.selected_action_note span {
  margin-left: 3px;
  font-family: "attalecksans_w_bold";
}

.template__url {
  color: #0568ae;
  cursor: pointer;
  opacity: 1;
  transition: opacity .3s ease-out;
}

.template__url:hover {
  opacity: .7;
}

p.sims:not(:first-of-type):last-of-type {
  padding-bottom: 0;
}

.template_and_activation_platform_container {
  display: flex;
  margin-bottom: 15px;
}

.template_and_activation_platform_container li {
  margin-top: 3px;
}

.template_and_activation_platform_container .custom_loader {
  min-height: 150px;
}

