@import "~brands/default/styles/globals";
div.custom-option {
  position: relative;
  padding-left: 18px;
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    margin-top: -5px;
    left: 4px;
    background-color: #000000;
  }
  &.select-single-value {
    padding-left: 15px;
    transform: translateY(0);
  }
  &.select-single-value::before {
    top: 50%;
    margin-top: -5px;
    left: 2px;
  }
  &.active::before,
  &.activated::before {
    background-color: $active;
  }
  &.retired::before {
    background-color: $color17;
  }
  &.deactived::before,
  &.inventory::before,
  &.pause::before,
  &.inactive::before {
    background-color: $suspended;
  }
  &.test_ready::before,
  &.test::before {
    background-color: $provisioned;
  }
  &.terminated::before,
  &.terminate::before {
    background-color: $deactive;
  }
  &.purged::before {
    background-color: $color6;
  }
  &.suspend::before,
  &.suspended::before {
    background-color: $suspended;
  }

  &.deactive::before {
    background-color: $deactive;
  }

  &.provisioned::before {
    background-color: $provisioned;
  }
  &.inactive::before {
    border: 1px solid $color5;
  }
}