@import "~brands/default/styles/globals";
.modal {
	overflow: visible;
	.modal_body_notf {
		background: $color1;
		position: static;
		height: 400px;
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			overflow-y: inherit;
			overflow-x: inherit;
		}
    overflow-x: unset;
	}
}

.notification_margin {
  margin-bottom: 15px;
}

.modal_body_error_message {
	padding: 0 30px;
}

.modal_tab_content {
	overflow: visible;
}
