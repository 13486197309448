:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.content_wrapper {
  padding: 20px 0 0 45px;
}

.subtitle {
  letter-spacing: .6px;
  color: #191919;
  margin: 0 15px 35px 0;
}

.release_version {
  display: flex;
  align-items: center;
}

.release_version>div {
  margin: 15px 0 0 15px;
}

.headline {
  font-family: "attalecksans_w_medium";
  font-size: 18px;
  color: #191919;
  display: flex;
  justify-content: space-between;
  min-height: 25px;
  font-weight: 500;
  margin: 0 0 15px 0;
  align-items: center;
}

.border {
  border-bottom: 1px #d2d2d2 solid;
}

.form_container {
  width: 815px;
}

.form_section_container {
  margin: 0 0 30px 0;
}

.fields_wrapper {
  margin: 30px 0 0 0;
}

.field_container {
  display: flex;
}

.known_issues_wrapper {
  position: relative;
}

.known_issues_wrapper .known_issue_name {
  width: 645px;
  margin: 0 15px 0 0;
}

.known_issues_wrapper .known_issue_status {
  width: 125px;
}

.remove_button {
  width: 15px;
  margin: 25px 0 0 15px;
}

.remove_button svg {
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;
}

.remove_button svg path {
  fill: #0568ae;
}

.remove_button svg path g {
  fill: #0568ae;
}

.field_full_width {
  width: 100%;
}

.fixed {
  color: #4ca90c;
  -webkit-text-fill-color: initial;
}

.enhanced {
  color: #009fdb;
  -webkit-text-fill-color: initial;
}

.divider {
  height: 1px;
  background: #d2d2d2;
  box-shadow: none;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  border: 0;
}

.section_buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-end;
  margin: 0 15px 20px 0;
}

.section_buttons :not(:last-child) {
  margin-right: 15px;
}

.icon_wrapper {
  font-size: 16px;
  color: #0568ae;
  cursor: pointer;
  padding-bottom: 10px;
  display: table-cell;
}

.icon_wrapper svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 3px;
}

.icon_wrapper svg path {
  fill: #0568ae;
}

.icon_wrapper svg path g {
  fill: #0568ae;
}

