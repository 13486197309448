:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.dropzone {
  width: 100%;
  height: 105px;
  border-radius: 12px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto 15px;
}

@media only screen and (max-width: 1382px) {
  .dropzone {
    margin-bottom: 5px;
  }
}

.dropzone .text {
  font-family: "attalecksans_w_regular";
  color: #959595;
  font-size: 12px;
}

.dropzone .text .title {
  text-align: center;
}

.dropzone.error {
  border-color: #cf2a2a;
}

.dropzone.error .text {
  color: #cf2a2a;
}

.dropzone .uploadIcon {
  width: 42.5px;
  height: 42.5px;
}

.dropzone .uploadIcon g path {
  fill: #959595;
}

@media only screen and (max-width: 1382px) {
  p {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.dropzone:hover {
  border: 2px dashed #0568ae;
}

.dropzone:hover .text {
  color: #0568ae;
}

.dropzone:hover .uploadIcon g path {
  fill: #0568ae;
}

.error {
  color: #cf2a2a;
}

