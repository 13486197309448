@import "~brands/default/styles/globals";
@import '../CustomFieldLabels.scss';

.main_content {
	padding-left: 30px;
}

.content_wrapper {
	flex-wrap: wrap;
  flex-direction: row;
  width: 650px;
}

.item {
  width: 320px;
	&:nth-child(odd) {
		padding-right: 15px;
	}

	&:nth-child(even) {
		padding-left: 15px;
	}
}
