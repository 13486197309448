@import "~brands/default/styles/globals";
$primary-font-medium: 'attalecksans_w_medium';
$primary-font-regular: 'attalecksans_w_regular';
$primary-font-light: 'attalecksans_w_light';
$primary-font-bold: 'attalecksans_w_bold';
$primary-font-italic: 'attalecksans_w_italic';

.steps_container {
	height: 100%;
	overflow-y: auto;
	max-height: 300px;

	& > * {
		margin-bottom: 5px;
	}
}

.steps_header { 
  letter-spacing: 0.8px;
  line-height: 14px;
  font-size: 12px;
  font-family: $primary-font-medium;
}

.steps_no_data {
	padding: 10px;
}

.steps_wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  flex-wrap: wrap
}

.margin_top_bottom_10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.fetch_data_wrapper {
  display: flex;
  flex-direction: row;
	flex-wrap: wrap;
}

.m2m_account_id_loader {
	min-height: 50px;
	max-height: 50px;
}

.lastActiveFilter > div > label {
	cursor: not-allowed;
}

.date_range_picker > div > div {
	justify-content: start;
}
