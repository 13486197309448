:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.export_csv_icon {
  width: 20px;
  height: 20px;
  fill: #0568ae;
  cursor: pointer;
  margin-right: 10px;
}

.export_csv_icon svg {
  fill: #0568ae;
}

.export_csv_icon svg g {
  fill: #0568ae;
}

.export_csv_icon svg g path {
  fill: #0568ae;
}

.email_wrap {
  max-width: 150px;
  word-wrap: break-word;
}

.email_wrap a {
  color: #0568ae;
  cursor: pointer;
  text-decoration: none;
}

.highlight {
  color: #0568ae;
  cursor: pointer;
  text-transform: capitalize;
}

.status {
  padding-right: 15px;
  text-transform: capitalize;
}

.status:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #191919;
  margin-right: 10px;
}

.status.active:before {
  background-color: #4ca90c;
}

.status.paused:before {
  background-color: #ffb81c;
}

.order_status_select {
  min-width: 150px;
}

.lastName {
  text-transform: capitalize;
}

.assigned_accounts {
  max-width: 200px;
  text-transform: capitalize;
  word-break: break-all;
  padding-top: 5px;
  padding-bottom: 5px;
}

.group_name {
  text-transform: capitalize;
}

.disabled {
  cursor: not-allowed;
  color: #959595;
}

