@import "~brands/default/styles/globals";
.modal__body {
	height: 120px;
	padding: 5px 15px 0 15px;
}

.buttons {
	display: flex;
	justify-content: flex-end;
}

.confirmation_button {
	margin-left: 32px;
}



.content {
	display: flex;
	margin-left: 16px;
  margin-right: 16px;
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 16px;
  svg {
    width: 40px;
    height: 40px;
    path {
      fill: $color14;
  
      g {
        fill: $color14;
      }
    }
  }
}

.text {
	display: flex;
  flex-direction: column;
  justify-content: center;
}

.bolded_text {
  font-weight: bold;
  margin-bottom: 16px;
  font-size: 18px;
}

.checkbox_part {
  margin-top: 1.5em;
  display: flex;
  align-items: center;
}

.checkbox_text {
  margin-left: 0.5em;
}