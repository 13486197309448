:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.first_step {
  margin-bottom: 20px;
  height: 400px;
  overflow: auto;
  padding-right: 25px;
}

.select_field_label {
  padding: 0 0 10px 0;
  font-weight: bold;
  text-transform: uppercase;
}

.select_field_wrapper {
  padding-bottom: 30px;
}

.select_field_wrapper.rate_plan {
  padding-bottom: 0;
}

.select_field_wrapper.datepicker {
  margin-top: 14px;
}

.custom {
  height: 400px;
  padding-right: 30px;
  overflow: visible;
}

.custom.normal_font_weight {
  font-weight: normal;
}

.entitlements_title {
  font-size: 12px;
  font-family: "attalecksans_w_medium";
  line-height: 1.17;
  letter-spacing: .6px;
  color: #191919;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.select_field_label_text {
  font-family: "attalecksans_w_light";
  font-size: 14px;
  color: #191919;
  line-height: 16px;
  padding-bottom: 16px;
}

.select_field_label_text span {
  font-family: "attalecksans_w_medium";
}

