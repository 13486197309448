@import "~brands/default/styles/globals";
.export_csv_icon{
	width: 20px;
	height: 20px;
	fill: $color14;
	cursor: pointer;
	margin-right: 10px;
	svg{
		fill: $color14;
		g{
			fill: $color14;
			path{
				fill: $color14;
			}
		}
	}
	/* > g {
		> path {
			fill: $color14;
		}
	} */
}

.email_wrap {
	max-width: 150px;
	word-wrap: break-word;
	a {
		color: $color14;
		cursor: pointer;
		text-decoration: none;
	}
}

.highlight{
	color: $color14;
	cursor: pointer;
	text-transform: capitalize;
}

.status {
	padding-right: 15px;
	text-transform: capitalize;

	&:before {
		content: '';
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $color8;
		margin-right: 10px;
	}

	&.active:before {
		background-color: #4ca90c;
	}
	&.paused:before {
		background-color: $pending-status;
	}
}

.order_status_select {
	min-width: 150px;
}

.lastName {
	text-transform: capitalize;
}

.assigned_accounts {
	max-width: 200px;
	text-transform: capitalize;
	word-break: break-all;
	padding-top: 5px;
	padding-bottom: 5px;
}

.group_name {
	text-transform: capitalize;
}

.disabled {
	cursor: not-allowed;
	color: $color5;
}
