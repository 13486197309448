:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.type_value_wrapper {
  word-break: break-all;
  display: flex;
  align-items: center;
  height: 80%;
  overflow: hidden;
}

.order_status_select {
  min-width: 150px;
}

@media only screen and (max-width: 641px) {
  .order_status_select {
    width: 100%;
  }
}

.icon {
  width: 20px;
  fill: #0568ae;
  margin: 11px 10px 11px 0;
  cursor: pointer;
  height: 20px;
}

.icon>g>path {
  fill: #0568ae;
}

.icon:last-child {
  margin-right: 0;
}

.icon.disabled {
  cursor: not-allowed;
}

.icon.disabled path {
  fill: #959595;
}

.icon.disabled>g>path {
  fill: #959595;
}

.bottom_action_bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
  align-items: center;
  margin-top: 15px;
}

.bottom_action_bar .action_bar_active_filters {
  flex-grow: 1;
}

.bottom_action_bar .action_bar_right {
  display: flex;
  align-items: center;
}

.bottom_action_bar .action_bar_quick_search {
  display: flex;
  white-space: nowrap;
  margin-right: 15px;
}

.search_security {
  margin-right: 10px;
  display: flex;
}

.tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
}

.switch_wrapper {
  display: inline-block;
}

.actions {
  align-items: center;
  margin-top: 11px;
  display: flex;
  justify-content: flex-end;
}

div.custom_option {
  position: relative;
  padding-left: 11px;
}

div.custom_option::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: 3px;
  left: -3px;
  background-color: #191919;
}

div.custom_option.select_single_value {
  position: absolute;
  padding-left: 20px;
}

div.custom_option.select_single_value::before {
  top: 50%;
  margin-top: -5px;
  left: 6px;
}

div.pending::before {
  background-color: #ffb81c;
}

div.failed::before {
  background-color: #cf2a2a;
}

div.successful::before {
  background-color: #4ca90c;
}

