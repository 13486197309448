@import "~brands/default/styles/globals";
.highlight {
  color: $color14;
  cursor: pointer;
}

.status {
	padding-right: 15px;
	text-transform: capitalize;

	&:before {
		content: '';
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $color8;
		margin-right: 10px;
	}

	&.active:before {
		background-color: #4ca90c;
	}
	&.paused:before {
		background-color: $pending-status;
	}
}

.order_status_select {
	min-width: 150px;
}