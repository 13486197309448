:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.diagnostics_wrapper .header {
  padding: 0 30px 30px;
  border-bottom: 1px solid #d2d2d2;
}

.diagnostics_wrapper .main {
  display: flex;
  flex-flow: row wrap;
  margin: 30px 15px;
}

.diagnostics_wrapper .diagnostics_details {
  flex: 2;
  padding: 0 30px 0 70px;
}

@media (max-width: 901px) {
  .diagnostics_wrapper .diagnostics_details {
    padding: 0;
  }
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_title {
  display: flex;
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_title_left {
  flex: 200px;
  min-width: 200px;
  display: flex;
  padding-left: 32px;
  justify-content: flex-end;
  flex-direction: column;
}

@media (max-width: 901px) {
  .diagnostics_wrapper .diagnostics_details .dd_field .dd_title_left {
    flex: 80px;
    min-width: 80px;
  }
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_title_left div {
  width: 6px;
  height: 6px;
  margin-top: 4px;
  border-radius: 50%;
  opacity: .3;
  background-color: #d2d2d2;
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_title_left div.success {
  opacity: 1;
  background-color: #4ca90c;
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_title_left div.error {
  opacity: 1;
  background-color: #ea7400;
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_title_right {
  padding: 15px 30px;
  font-weight: bold;
  text-transform: uppercase;
  flex: 100%;
  border-bottom: 1px solid #4ca90c;
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_title_right.disabled {
  opacity: .3;
  border-bottom-color: #d2d2d2;
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_title_right.error {
  border-bottom-color: #ea7400;
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_content {
  display: flex;
  align-items: center;
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_content_left {
  flex: 200px;
  min-width: 200px;
}

@media (max-width: 901px) {
  .diagnostics_wrapper .diagnostics_details .dd_field .dd_content_left {
    flex: 80px;
    min-width: 80px;
  }
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_content_left svg {
  max-height: 80px;
  max-width: 80px;
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_content_left path,
.diagnostics_wrapper .diagnostics_details .dd_field .dd_content_left circle {
  fill: #4ca90c;
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_content_left.disabled path,
.diagnostics_wrapper .diagnostics_details .dd_field .dd_content_left.disabled circle {
  fill: #d2d2d2;
  opacity: .3;
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_content_left.error path,
.diagnostics_wrapper .diagnostics_details .dd_field .dd_content_left.error circle {
  fill: #ea7400;
}

.diagnostics_wrapper .diagnostics_details .dd_field .dd_content_right {
  flex: 100%;
}

.diagnostics_wrapper .diagnostics_details .dd_field_item .dd_item_title {
  font-size: 16px;
  font-weight: bold;
  padding: 15px 30px;
  color: #191919;
}

.diagnostics_wrapper .diagnostics_details .dd_field_item .dd_item_title.disabled {
  opacity: .3;
}

.diagnostics_wrapper .diagnostics_details .dd_field_item .dd_item_content {
  align-items: center;
  margin: 0 15px 30px 15px;
  padding: 15px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #4ca90c;
  background-color: rgba(76,169,12,.2);
  border: solid 1px #4ca90c;
}

.diagnostics_wrapper .diagnostics_details .dd_field_item .dd_item_content.disabled {
  opacity: .3;
  color: #d2d2d2;
  background-color: rgba(210,210,210,.2);
  border: solid 1px #d2d2d2;
}

.diagnostics_wrapper .diagnostics_details .dd_field_item .dd_item_content.error {
  color: #ea7400;
  background-color: rgba(207,42,42,.2);
  border: solid 1px #ea7400;
}

.diagnostics_wrapper .registration_details {
  flex: 1;
  background-color: #f2f2f2;
  align-self: flex-start;
  border-radius: 10px;
  padding: 15px;
  position: relative;
}

.diagnostics_wrapper .registration_details .rd_title {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.17;
  text-transform: uppercase;
  letter-spacing: .6px;
  color: #191919;
  padding-bottom: 30px;
}

.diagnostics_wrapper .registration_details .rd_fields .rd_field {
  font-size: 14px;
  font-weight: bold;
  color: #191919;
}

.diagnostics_wrapper .registration_details .rd_fields .rd_field_value {
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 25px;
  font-weight: normal;
}

.diagnostics_wrapper .registration_details .rd_fields .rd_field:last-child .rd_field_value {
  padding-bottom: 15px;
}

.diagnostics_wrapper .registration_details .tablet_icon {
  position: absolute;
  bottom: 12px;
  right: 8px;
  height: 90px;
  width: 90px;
}

.diagnostics_wrapper .registration_details .tablet_icon path {
  fill: #d2d2d2;
}

.actions_wrapper {
  margin: 45px 0 30px 0;
}

.actions_wrapper .title {
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.button_wrapper>Button {
  margin-right: 5px;
}

