:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.form_wrapper {
  padding: 20px 0 0 45px;
}

.form_headline {
  font-family: "attalecksans_w_medium";
  font-size: 24px;
  letter-spacing: .6px;
  margin: 25px 0;
  font-weight: 500;
}

.form_subheadline {
  font-family: "attalecksans_w_light";
  font-size: 16px;
  letter-spacing: .6px;
  color: #333;
  width: 800px;
  margin: 0 0 35px 0;
}

.section_headline {
  font-family: "attalecksans_w_medium";
  font-size: 18px;
  color: #191919;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #d2d2d2 solid;
  min-height: 25px;
  width: 787px;
  font-weight: 500;
}

.section_headline>span {
  margin: 0 0 10px 0;
}

.validate {
  margin-top: 32px;
  font-size: 18px;
  font-family: "attalecksans_w_medium";
  font-weight: normal;
}

.validate.active {
  color: #0568ae;
  cursor: pointer;
}

.validate.disabled {
  color: #767676;
  cursor: not-allowed;
}

.field_wrapper {
  width: 320px;
  margin: 0 30px 10px 0;
  display: inline-table;
}

.field_wrapper.validate {
  margin-top: 32px;
  font-size: 18px;
  font-family: "attalecksans_w_medium";
  font-weight: normal;
}

.field_wrapper.validate.active {
  color: #0568ae;
  cursor: pointer;
}

.field_wrapper.validate.disabled {
  color: #767676;
  cursor: not-allowed;
}

.field_wrapper label {
  display: flex;
  max-width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.field_wrapper label svg {
  width: 15px;
  height: 15px;
  position: relative;
  cursor: pointer;
  margin: 0 0 0 5px;
}

.field_wrapper label svg g path {
  fill: #0568ae;
}

.field_wrapper .medium_field_wrapper {
  width: 140px;
  display: inline-block;
}

.field_wrapper .medium_field_wrapper:last-child:not(:first-child) {
  float: right;
}

.divider {
  height: 1px;
  background: #d2d2d2;
  box-shadow: none;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  border: 0;
}

.section_buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-end;
  margin: 0 15px 20px 0;
}

.section_buttons :not(:last-child) {
  margin-right: 15px;
}

.tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
  font-weight: normal;
}

.spinner_wrapper {
  width: 787px;
}

.error_message {
  font-weight: bold;
  color: #cf2a2a;
}

.label_tooltip {
  margin-left: 5px;
}

.label_tooltip svg {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.label_tooltip svg path {
  fill: #0568ae;
}

