@import "~brands/default/styles/globals";
.super_user_mode {
  z-index: 999;
  background-color: $color10;
  max-width: 30%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 5px;
  justify-content: center;
  border-radius: 0 0 5px 5px;
}

.super_user_header {
  margin: 0 5px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: $color2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.super_user_button {
  color: $color2;
  background-color: $color10;
  border: 1px solid $color2;
  height: 35px;
  min-width: 50px;
  font-size: 15px;
}