:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.diagnostic_hlr_details {
  width: 100%;
}

.header {
  padding: 0 30px 30px;
  border-bottom: 1px solid #d2d2d2;
}

.main {
  display: flex;
  flex-flow: row wrap;
  margin: 30px 15px;
}

.h3 {
  color: #191919;
  font-family: "attalecksans_w_regular";
  font-size: 18px;
  margin-bottom: 27px;
}

.profile_data,
.apn_list_data {
  display: flex;
  max-width: 809px;
  flex-wrap: wrap;
}

.apn_list_data {
  background-color: #f8f8f8;
  padding: 19px 21px 0px;
  max-width: 845px;
  margin-bottom: 20px;
}

.data {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 225px;
  margin-right: 40px;
}

.data>span:first-child {
  font-family: "attalecksans_w_regular";
  font-weight: 700;
  height: 14px;
  font-size: 14px;
  line-height: 1;
  color: #191919;
  margin-bottom: 19px;
}

.data>span:nth-child(2) {
  height: 16px;
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  line-height: 1;
  text-align: left;
  color: #191919;
  word-break: break-all;
  word-break: break-word;
}

.data.apn:nth-child(3n+1) {
  margin-right: 20px;
}

