@import "~brands/default/styles/globals";
.export_csv_icon{
	width: 20px;
	height: 20px;
	fill: $color14;
	cursor: pointer;
	svg{
		fill: $color14;
		g{
			fill: $color14;
			path{
				fill: $color14;
			}
		}
	}
	/* > g {
		> path {
			fill: $color14;
		}
	} */
}

.disabled {
	width: 20px;
	height: 20px;
	fill: $color5;
	cursor: not-allowed;

	path {
		fill: $color5;
	}

	> g {
		> path {
			fill: $color5;
		}
	}
	svg{
		fill: $color5;
		g{
			fill: $color5;
			path{
				fill: $color5;
			}
		}
	}
}

.ticket_id {
  cursor: pointer;
  color: $color14;
}

.icon {
	width: $icon-lg;
	fill: $color14;
	margin: 11px 10px 11px 0;
	cursor: pointer;
	height: 20px;

	> g {
		> path {
			fill: $color14;
		}
	}

	&:last-child {
		margin-right: 0;
	}

	&.disabled {
		cursor: not-allowed;

		path {
			fill: $color5;
		}

		> g {
			> path {
				fill: $color5;
			}
		}
	}
}

.tooltip {
	box-shadow: 0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
}

.view_note{
  cursor: pointer;
  color: $color14;
  word-break: break-all;
}

.severity_status {
	font-weight: bold;
 	&.critical {
		color: $color10;
  }
  &.minor {
    color: $color19;
  }
  &.major {
    color: $color11;
	}
}

.pie_chart_wrapper {
	width: 100%;
	min-width: 50%;
	display: flex;
	justify-content: center;
	@media only screen and (max-width: 768px) {
		flex-wrap: wrap;
	}

	& > * {
		height: 375px;
		margin: 20px 20px 5px 20px;
	}

	main[data-spec="pie-chart-main"] {
		height: 99%;
		width: 100%;

		& > :first-child {
			height: 75%;
		}
	}

	strong {
		display: none;
	}
}

svg.add_icon{
  width: 20px;
  height: 20px;
  fill: $color1;
  margin-right: 5px;

  path{
    fill: $color1;
  }
}
