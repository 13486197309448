@import "~brands/default/styles/globals";
.request_form {
	width: 100%;
}

.form_subtitle {
	margin-bottom: 25px;
	color: $color8;
	font-size: 13px;
	margin-top: 2px;
	font-family: $primary-font-medium;
}

.divider {
	margin-top: 25px;
}

.title_background {
	height: 100vh;
	padding-top: 15px;
	background-image: url('brands/default/images/MNC_login_screen.png');
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover;

	.notification_wrapper {
		width: 100%;
    max-width: 473px;
		min-height: 287px;
		margin-left: 10px;
		margin-right: 10px;
		border-radius: 10px;
		background-color: $color1;
		box-shadow: 0 2px 4px 0 rgba(87, 86, 86, 0.14);
  	border: 0.3px solid $color5;
		
		.notification_content {
			padding: 27px;
		}
	}
}

.header {
	padding: 27px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	.icon {
		margin-right: 22px;
	}
	.description {
		font-size: 24px;
		font-weight: 500;
		color: $color1;
	}
}

.content {
	padding: 27px;
}

.contact_text {
	margin-top: 20px;
	margin-bottom: 30px;
	span {
	font-weight: 700;
	font-size: 14px;
	}
}

.title {
	height: 90px;
	display: flex;
	align-items: center;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.title_success {
	background-color: $color19;
}
.title_error {
	background-color: $color10;
}

.button { 
	width: 100%;
}

.homepage {
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	height: 42px;
	padding: 0 10px;
	border: 1px solid $color14;
	border-radius: 30px;
	text-decoration: none;
	-ms-flex-item-align: start;
	align-self: flex-start;
	color: $color14;

	> span {
		font-family: $primary-font-medium;
		font-size: 18px;
		color: $color14;
	}
}

.inner_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 100px;
}