@import "~brands/default/styles/globals";
.details_wrapper {
	padding: 20px 0 0 45px;
}

.form_subheadline {
	font-family: $primary-font-light;
	font-size: 16px;
	letter-spacing: 0.6px;
	color: $color7;
	width: 800px;
	margin: 0 0 35px 0;
}

.section_headline {
	font-family: $primary-font-medium;
	font-size: 18px;
	color: $color8;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px $color4 solid;
	min-height: 25px;
	width: 800px;
	font-weight: 500;
	margin-bottom: 10px;

	> span {
		margin: 0 0 10px 0;
	}
}

.field_wrapper {
	width: 245px;
	margin: 0 30px 30px 0;
	display: inline-table;
}

.label {
	max-width: 100%;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 0.5px;
}

.info {
	font-size: 14px;
}

.rectangle_buttons_wrapper {
	display: flex;
	margin: 0 0 35px 0;

	> div:not(:last-child) {
		margin-right: 30px;
	}
}
