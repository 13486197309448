:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.export_csv_icon {
  width: 20px;
  height: 20px;
  fill: #0568ae;
  cursor: pointer;
}

.export_csv_icon svg {
  fill: #0568ae;
}

.export_csv_icon svg g {
  fill: #0568ae;
}

.export_csv_icon svg g path {
  fill: #0568ae;
}

.disabled {
  width: 20px;
  height: 20px;
  fill: #959595;
  cursor: not-allowed;
}

.disabled path {
  fill: #959595;
}

.disabled>g>path {
  fill: #959595;
}

.disabled svg {
  fill: #959595;
}

.disabled svg g {
  fill: #959595;
}

.disabled svg g path {
  fill: #959595;
}

.ticket_id {
  cursor: pointer;
  color: #0568ae;
}

.icon {
  width: 20px;
  fill: #0568ae;
  margin: 11px 10px 11px 0;
  cursor: pointer;
  height: 20px;
}

.icon>g>path {
  fill: #0568ae;
}

.icon:last-child {
  margin-right: 0;
}

.icon.disabled {
  cursor: not-allowed;
}

.icon.disabled path {
  fill: #959595;
}

.icon.disabled>g>path {
  fill: #959595;
}

.tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
}

.view_note {
  cursor: pointer;
  color: #0568ae;
  word-break: break-all;
}

.severity_status {
  font-weight: bold;
}

.severity_status.critical {
  color: #cf2a2a;
}

.severity_status.minor {
  color: #4ca90c;
}

.severity_status.major {
  color: #ea7400;
}

.pie_chart_wrapper {
  width: 100%;
  min-width: 50%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .pie_chart_wrapper {
    flex-wrap: wrap;
  }
}

.pie_chart_wrapper>* {
  height: 375px;
  margin: 20px 20px 5px 20px;
}

.pie_chart_wrapper main[data-spec=pie-chart-main] {
  height: 99%;
  width: 100%;
}

.pie_chart_wrapper main[data-spec=pie-chart-main]>:first-child {
  height: 75%;
}

.pie_chart_wrapper strong {
  display: none;
}

svg.add_icon {
  width: 20px;
  height: 20px;
  fill: #fff;
  margin-right: 5px;
}

svg.add_icon path {
  fill: #fff;
}

