:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.modal_body {
  height: 150px;
}

.dropzone_trigger {
  display: none;
}

.dropzone_trigger.dropzone__active {
  display: block;
}

.label_wrapper {
  color: #0568ae;
  cursor: pointer;
}

.label_wrapper span {
  display: flex;
  align-items: center;
}

.label_wrapper span svg {
  width: 20px;
  height: 20px;
  position: relative;
}

.label_wrapper span svg path {
  fill: #0568ae;
}

.label_wrapper span svg path g {
  fill: #0568ae;
}

.existing_files {
  height: 25px;
  display: flex;
  justify-content: space-between;
  color: #0568ae;
}

.existing_files .existing_files_name {
  display: flex;
}

.existing_files .existing_files_name svg {
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 5px;
}

.existing_files .existing_files_button svg {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.existing_files .existing_files_button svg path {
  fill: #0568ae;
}

.existing_files .existing_files_button svg path g {
  fill: #0568ae;
}

.attachment_wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.attachment_wrapper .attachment {
  font-size: 16px;
  font-weight: bold;
}

.invoice_note {
  cursor: pointer;
}

.remove_icon_wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  color: #0568ae;
  align-items: center;
}

.remove_icon_wrapper :first-child {
  margin-right: 5px;
}

