:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.entitlement_info {
  padding: 0 0 30px;
}

.entitlement_info .current_network_entitlement {
  font-size: 12px;
  color: #4ca90c;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.entitlement_info .current_network_entitlement svg {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.entitlement_info .current_network_entitlement svg path {
  fill: #4ca90c;
}

.entitlements_details {
  border-top: 1px solid #d2d2d2;
  padding-top: 25px;
  display: flex;
}

.entitlements_details .entitlements_details_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.entitlements_details .entitlements_details_container:nth-child(2) {
  margin-left: 31px;
}

.entitlements_details_item {
  display: flex;
  margin-bottom: 17px;
}

.entitlements_details_item .entitlements_item_title {
  font-size: 14px;
  font-weight: 500;
  font-family: "attalecksans_w_medium";
  line-height: 14px;
  text-align: left;
  color: #191919;
  width: 100%;
}

.entitlements_details_item .grayed_out {
  opacity: .4;
}

.entitlements_details_item .entitlements_item_data {
  font-size: 16px;
  font-family: "attalecksans_w_regular";
  line-height: 16px;
  text-align: right;
  color: #191919;
  width: 100%;
}

.apns {
  display: block;
}

.apns .apns_container:not(:first-child) {
  padding-top: 25px;
}

.apns .apns_container:nth-child(2) {
  margin-left: auto;
}

.apns .apns_container:not(:last-child) {
  border-bottom: 1px solid #d2d2d2;
}

