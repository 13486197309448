@import "~brands/default/styles/globals";
.modal__body {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 450px;
	max-width: 80%;
  padding: 0 30px 20px;
  margin: 0 auto;

  & div {
    margin: 0 auto;
  }

	img {
		max-width: 100%;
		max-height: 400px;
    object-fit: contain;
	}
}
