:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.label .required-label {
  color: #191919;
  font-weight: normal;
  text-transform: lowercase;
}

.mask_field_wrapper {
  display: flex;
}

.mask_field_wrapper .component_wrapper_left {
  width: 120px;
}

.mask_field_wrapper .component_wrapper_right {
  width: calc(100% - 120px);
  position: relative;
}

.mask_field_wrapper .component_wrapper_right div div div:nth-of-type(2) {
  width: calc(100% + 120px);
  margin-left: -120px;
}

.mask_field_wrapper .component_wrapper_right .custom_input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

