.no_data_wrapper {
	margin: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.icon_wrapper {
	display: inline-block;
	vertical-align: middle;
	width: 50px;
	height: 50px;
	margin-bottom: 15px;

	svg {
		width: 50px;
		height: 50px;
	}
}

.message {
	text-align: center;
}

.title {
	font-family: $primary-font-medium;
	font-size: 20px;
	margin-bottom: 10px;
}

.subtitle{
	font-family: $primary-font-light;
	font-size: 14px;
}