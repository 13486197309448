:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.level {
  display: flex;
  align-items: center;
  height: 71px;
  transition: max-height .7s linear;
  background: #f8f8f8;
}

.level:not(:last-child) {
  border-bottom: 1px solid #e2e2e2;
}

.level .data {
  display: flex;
  flex-direction: column;
}

.level .data>span:first-child {
  font-family: "attalecksans_w_medium";
  font-weight: 500;
  height: 14px;
  font-size: 16px;
  line-height: 1;
  color: #191919;
  margin-bottom: 7px;
}

.level .data>span:nth-child(2) {
  height: 16px;
  font-family: "attalecksans_w_regular";
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: #191919;
  word-break: break-all;
  word-break: break-word;
}

.level .divider {
  width: 1px;
  height: 45px;
  background-color: #e2e2e2;
  margin: 0 33px;
}

.level .trigger__icon {
  height: 2px;
  width: 10px;
  background: #0568ae;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.level .trigger__icon:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  width: 20px;
  height: 20px;
}

.level .trigger__icon:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #0568ae;
  transform: rotate(90deg);
}

.level .trigger__icon.no_subplatforms {
  pointer-events: none;
  background: rgba(0,0,0,0);
}

.level .trigger__icon.no_subplatforms:after {
  background: rgba(0,0,0,0);
}

.level.open::after {
  transform: rotate(0deg);
}

.level .trigger__icon.open::after {
  transform: rotate(0deg);
}

.select_wrapper {
  width: 130px;
  margin: 0 50px 0 auto;
}

