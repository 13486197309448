:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.chart_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.chart_wrapper .main_wrapper {
  justify-content: center;
  flex-basis: 100%;
  width: 100%;
  align-items: center;
  display: flex;
}

.chart_wrapper .main {
  height: 70%;
  width: 90%;
  position: relative;
}

.chart_wrapper .main .total {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #191919;
  letter-spacing: .6px;
}

.chart_wrapper .main .total_text {
  font-size: calc(14px + 6*(100vw - 300px)/1637);
  font-weight: 300;
}

.chart_wrapper .main .total_value {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart_wrapper .main .total_value :first-child {
  font-size: calc(15px + 20*(100vw - 300px)/1637);
}

.chart_wrapper .main .total_value :last-child {
  font-size: calc(14px + 6*(100vw - 300px)/1637);
}

.chart_wrapper .main .total_value_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart_wrapper .main .total_value_modal :first-child {
  font-size: calc(15px + 5*(100vw - 300px)/1637);
}

.chart_wrapper .main .total_value_modal :last-child {
  font-size: calc(12px + 3*(100vw - 300px)/1637);
}

.customTooltipMain {
  background: #fff;
  border-radius: 2px;
  box-shadow: rgba(0,0,0,.25) 0px 1px 2px;
  padding: 5px 9px;
}

.customTooltip {
  white-space: pre-wrap;
  display: flex;
  align-items: center;
}

.customTooltip .customTooltipBlock {
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 7px;
}

.customTooltip .analytics_tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.customTooltip .analytics_tooltip .medium_font {
  display: flex;
  justify-content: center;
  align-content: center;
}

.customTooltip .analytics_tooltip .medium_font>span:first-child {
  font-family: "attalecksans_w_medium";
}

