:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.action_bar_wrapper {
  display: flex;
  flex-flow: column;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
  padding: 0 15px;
  margin: 9px 0 0 0;
  flex-wrap: wrap-reverse;
}

.top>:not(:first-child) {
  margin-bottom: 5px;
}

@media only screen and (max-width: 641px) {
  .top {
    border-bottom: none;
  }
}

.bottom {
  padding: 15px;
}

