@import "~brands/default/styles/globals";
.sim_ordering_wrapper {
	padding: 20px 0 0 45px;
}

.container {
	max-width: 700px;
	width: 100%;
	position: relative;
}

.section_headline {
	font-family: $primary-font-medium;
	font-size: 18px;
	color: $color8;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px $color4 solid;
	min-height: 25px;
	font-weight: 500;
	margin: 0 0 10px 0;
}

.fields_wrapper {
	display: flex;
	position: relative;
}

.select_wrapper {
	width: 320px;
	margin: 0 30px 10px 0;
	display: inline-table;
}

.divider {
	height: 1px;
	background: $color4;
	box-shadow: none;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
	border: 0;
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-end;
	margin: 0 0 20px 0;

	:not(:last-child) {
		margin-right: 15px;
	}
}

.contract_restraints {
	margin: 30px 0;
}

.bottom_line {
	width: 97%;
	margin: 0 auto;
	border-bottom: 1px $color4 solid;
}

.add_sim_sku_button {
  margin-bottom: 10px;
  float: right;
}
