@import "~brands/default/styles/globals";
/* .modal_body {
	max-height: 250px;
	max-width: 610px;
} */

.modal_body_content {
	padding-left: 30px;
	padding-right: 30px;
}

.delete_section {
	max-width: 380px;
}

.delete {
	color: #c82b35;
	text-transform: uppercase;
}

.notice {
	font-size: 16px;
	line-height: 22px;
}

.field_holder{
	margin-top:20px;
}

.message {
	font-family: $primary-font-medium;
	color: $color8;
	margin-bottom: 5px;
}