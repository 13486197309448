:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.subtitle {
  letter-spacing: .6px;
  color: #191919;
  margin: 0 0 35px 0;
  margin: 20px 15px 35px 15px;
}

.release_version {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.release_version>div {
  margin: 0 0 0 15px;
}

.headline_action {
  margin: 10px 0;
}

.headline_action .action_buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-end;
}

.headline_action .action_buttons :not(:last-child) {
  margin-right: 15px;
}

.border {
  border-bottom: 1px #d2d2d2 solid;
  margin: 15px 15px 20px 15px;
}

.section_buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: space-between;
  margin: 0 15px 20px 0;
}

