:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

svg.add_icon_plus {
  width: 22px;
  height: 28px;
  right: 5px;
  position: relative;
  cursor: pointer;
}

svg.add_icon_plus g {
  fill: #fff;
}

svg.add_icon_plus g path {
  fill: #fff;
}

.content_wrapper {
  padding: 20px 20px 0 45px;
}

.content_wrapper article {
  margin: 15px 0 20px 0;
}

.content_wrapper ul>li {
  list-style-type: disc;
  margin: 0 0 0 45px;
  padding: 0 0 0 10px;
}

.subtitle {
  letter-spacing: .6px;
  color: #191919;
  margin: 0 0 35px 0;
  margin: 20px 15px 35px 45px;
}

.headline {
  font-family: "attalecksans_w_medium";
  font-size: 18px;
  color: #191919;
  display: flex;
  justify-content: space-between;
  min-height: 25px;
  font-weight: 500;
  margin: 0 15px 10px 0;
  align-items: center;
}

.release_version {
  display: flex;
  align-items: center;
}

.release_version>div {
  margin: 0 0 0 15px;
}

.release_title {
  font-family: "attalecksans_w_medium";
  font-size: 18px;
  color: #0568ae;
  font-weight: 500;
  margin: 25px 0 30px 0;
}

.headline_action {
  margin: 10px 0;
}

.headline_action .action_buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-end;
}

.headline_action .action_buttons :not(:last-child) {
  margin-right: 15px;
}

.border {
  border-bottom: 1px #d2d2d2 solid;
  margin: 15px 15px 20px 45px;
}

.known_issues {
  margin: 0 0 20px 0;
}

.known_issues .issue_type {
  font-family: "attalecksans_w_medium";
  font-style: italic;
  margin: 0 0 0 10px;
}

.known_issues .issue_type::before {
  content: " - ";
}

.known_issues .issue_type.fixed {
  color: #4ca90c;
}

.known_issues .issue_type.enhanced {
  color: #009fdb;
}

.section_buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-end;
  margin: 0 15px 20px 0;
}

.section_buttons :not(:last-child) {
  margin-right: 15px;
}

.button_width {
  min-width: 300px;
}

