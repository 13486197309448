:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.header {
  padding: 20px;
  font-size: 20px;
}

.field_body {
  padding: 0 20px;
}

.field_body .invalid {
  margin-bottom: 20px;
}

.field_body .field_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
}

.field_body .field_button>button {
  z-index: 50;
}

.field_body .field_button .field {
  width: 95%;
  height: 60px;
  margin-right: -3%;
  min-height: 60px;
}

.field_body .remaining {
  color: #959595;
  font-style: italic;
  font-size: 16px;
}

.table {
  padding: 20px 0 10px 0;
  border-radius: 10px;
  margin: 15px;
  box-shadow: 0 0 5px 1px #d2d2d2;
}

.table .table_header {
  padding: 0 20px 10px 20px;
  display: flex;
  flex-direction: column;
}

.table .table_header :first-child {
  font-size: 20px;
  padding-bottom: 10px;
}

