@import "~brands/default/styles/globals";
.modal{
	overflow: visible;

	.modal_body_notf{
		background: $color1;
		position: static;
		height: 400px;
		@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			overflow-y: inherit;
			overflow-x: inherit;
		}
		overflow-y: unset;
		overflow-x: unset;
	}//wrapper around modal body
}
.modal_notification_wrapper{
  margin:0 -15px 20px -15px;
}

.modal_body_error_message{
  padding: 0 30px;
}

.modal_tab_content{
	overflow: visible;

	@include tablet-only {
		overflow: auto;
	}
}