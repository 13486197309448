:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.user_actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_actions svg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.user_actions svg path {
  fill: #0568ae;
}

.user_actions svg:nth-child(2) {
  margin-left: 11px;
}

.user_actions svg:nth-child(2) path {
  fill: none;
}

.user_actions svg:nth-child(2) path:nth-child(2) {
  fill: #0568ae;
}

.rule_name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
}

.tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
}

.label_tooltip {
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
}

.label_tooltip svg {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.label_tooltip svg path {
  fill: #0568ae;
}

.email_wrap a {
  color: #0568ae;
  cursor: pointer;
  text-decoration: none;
}

.rule_status {
  display: flex;
  align-items: center;
  width: 155px;
}

.circle {
  content: "";
  width: 10px !important;
  height: 10px !important;
  border-radius: 100%;
  background-color: #000;
  margin-right: 5px;
  margin-left: 5px;
}

.pending {
  background-color: #ffb81c;
}

.not_applicable {
  background-color: #5a5a5a;
}

.received {
  background-color: #0568ae;
}

.implemented {
  background-color: #4ca90c;
}

.conflicted {
  background-color: #cf2a2a;
}

.pending_for_deletion {
  background-color: #ea7400;
}

.clickable {
  cursor: pointer;
  color: #0568ae;
  text-decoration: none;
}

