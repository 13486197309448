@import "~brands/default/styles/globals";
.grid_item{
	touch-action: auto !important;
	> span {
		bottom: 5px;
		right: 5px;
		z-index: 105;
	}
}

.grid_layout_no_settings {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4rem 2rem;
	min-height: 500px;

	.no_data_icon {
		margin-bottom: 25px;

		svg {
			width: 100px;

			path {
				fill: $dashboard-no-charts-icon-color;
			}
		}
	}

	.no_data_text {
		text-transform: uppercase;
		font-size: 12px;
		color: $dashboard-no-charts-text-color;
	}
}
