@import "~brands/default/styles/globals";
.title {
  font-size: 12px;
  font-family: $primary-font-medium;
  line-height: 1.17;
  letter-spacing: 0.6px;
  color: $color8;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.aside_padding {
  padding-left: 30px;
}

.content_wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.item {
  width: 50%;
  padding-bottom: 25px;

  &:nth-child(odd) {
	padding-right: 15px;
  }

  &:nth-child(even) {
	padding-left: 15px;
  }
}

.number_of_columns {
  color: $color14;
}

.clear_all {
  float: left;
}


.modal{
  .modalBody{
    height: 350px;
    width: 850px;
  }
}