@import "~brands/default/styles/globals";
.details_wrapper {
	padding: 20px 0 30px 45px;
	max-width: 882px;
	width: 98%;

	h5 {
		margin-bottom: 15px;
	}
}
.name_and_logo {
	display: flex;
	position: relative;

	@include tablet-portrait-and-below {
		flex-direction: column;
	}
}

.field_wrapper_upper {
	width: 320px;
	margin: 30px 30px 0 0;
	display: inline-table;
}

.label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 14px;
}

.info {
	font-size: 14px;
}

.logo {
	width: 174px;
}

.preferred_language_info {
	max-width: 250px;
}

.button_wrapper {
	position: absolute;
	top: 0;
	right: 0;

	> :nth-child(2) {
		border-left: 1px solid $color4
	}

	svg {
		width: 38px;
		height: 28px;
		cursor: pointer;

		path {
			fill: $color14;
		}
	}
}

.icon_wrapper {
	font-size: 16px;
	color: $color14;
	cursor: pointer;
	padding-bottom: 10px;
	display: block;

	svg {
		width: 25px;
		height: 25px;
		position: relative;
		top: 5px;

		path {
			fill: $color14;

			g {
				fill: $color14;
			}
		}
	}

	&.disabled {
		cursor: not-allowed;
		color: $color5;

		svg {
			path {
				fill: $color5;

				g {
					fill: $color5;
				}
			}
		}
	}
}

.support_type_wrapper {
	margin: 0 40px 30px 0;
	font-size: 16px;
  line-height: 1.4;
  
  .bold {
    font-weight: bold;
  }
}

.account_name_wrapper {
	display: flex;

	@include tablet-portrait-and-below {
		flex-direction: column;
	}
}

.support_type_radio_buttons_wrapper {
  display: flex;
  margin-top: 34px;
}

.remove_icon_wrapper {
	position: absolute;
	right: 0;
	top: 10px;
}

.counter {
	position: relative;
	font-family: $primary-font-medium;
	font-size: 16px;
	font-weight: 500;
	margin: 0 0 25px 0;
	width: 670px;
}

.bold {
  font-weight: bold;
}

.validate_button {
	padding-top: 20px;
}