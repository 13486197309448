@import "~brands/default/styles/globals";
@import '../MainLayout.scss';

body {
  font-size: $primary-font-size;
  font-family: $primary-font-regular;
  overflow-x: hidden;
}

.logo {
  position: relative;
  display: flex;
  align-items: center;
  height: 58px;
  cursor: pointer;
  width: 30px;

  > path {
    fill: $color13;
  }
}

.profile_avatar {
  width: 36px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    fill: none;
    width: 24px;
    height: 24px;

    path {
      fill: white;
      stroke: white;
    }
  }
}

.global_container {
  display: flex;
  margin-top: 58px;

  .main_right {
    background-color: $color1;
    min-height: 100vh;
    width: 100%;
    transition: all 0.5s linear;

    @media (max-width: 640px) {
      width: 100%;
    }
    position: relative;

    &.no_sidebar {
      width: 100%;
    }

    &.collapsed {
      width: calc(100% - 85px);
    }
  }
}

.link_icon {
  width: 16px;
  height: 16px;
  fill: $color4;
  position: relative;
  top: 7px;

  path {
    fill: $color4;
  }
}

.additional_offset {
  margin-top: 116px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}