@import "~brands/default/styles/globals";
.form_wrapper {
	padding: 20px 0 0 45px;
}

.form_headline {
	font-family: $primary-font-medium;
	font-size: 24px;
	letter-spacing: 0.6px;
	margin: 25px 0;
	font-weight: 500;
}

.section_headline {
	font-family: $primary-font-medium;
	font-size: 18px;
	color: $color8;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px $color4 solid;
	min-height: 25px;
	width: 787px;
	font-weight: 500;

	> span {
		margin: 0 0 10px 0;
	}
}

.fields_wrapper {
	display: flex;
	position: relative;
}

.required_field {
	label::after {
		content: '*';
		font-weight: bold;
		color: $color10;
		margin-left: 2px;
	}
}

.field_wrapper {
	width: 320px;
	margin: 0 30px 10px 0;
  display: inline-table;
  
  &.static {
    margin: 0 0 30px 0;
  }


	label {
		display: flex;
		max-width: 100%;
		margin-bottom: 10px;
		font-weight: bold;
		font-size: 14px;
	}

}

.divider {
	height: 1px;
	background: $color4;
	box-shadow: none;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
	border: 0;
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-end;
	margin: 0 15px 20px 0;

	:not(:last-child) {
		margin-right: 15px;
	}
}

.label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 14px;
}

.info {
	font-size: 14px;
}

.button_width {
  width: 320px;
}