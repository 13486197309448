@import "~brands/default/styles/globals";
.diagnostic_hlr_details {
  width: 100%;
}

.header {
  padding: 0 30px 30px;
  border-bottom: 1px solid $color4;
}

.main {
  display: flex;
  flex-flow: row wrap;
  margin: 30px 15px;
}

.h3 {
  color: $color8;
  font-family: $primary-font-regular;
  font-size: 18px;
  margin-bottom: 27px;
}

.profile_data {
  display: flex;
  max-width: 809px;
  flex-wrap: wrap;
}

.apn_list_data {
  @extend .profile_data;
  background-color: $color21;
  padding: 19px 21px 0px;
  max-width: 845px;
  margin-bottom: 20px;
}

.data {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 225px;
  margin-right: 40px;

  > span {

    &:first-child {
      font-family: $primary-font-regular;
      font-weight: 700;
      height: 14px;
      font-size: 14px;
      line-height: 1;
      color: $color8;
      margin-bottom: 19px;
    }

    &:nth-child(2) {
      height: 16px;
      font-family: $primary-font-regular;
      font-size: 16px;
      line-height: 1;
      text-align: left;
      color: $color8;
      word-break: break-all;
      word-break: break-word;
    }
  }

  &.apn {
    &:nth-child(3n+1) {
      margin-right: 20px;
    }
  }
}