@import "~brands/default/styles/globals";
.sidebar {
  width: 220px;
  transition: width .5s linear;
  overflow: hidden;
  position: sticky;
  top: 58px;

	.top_dropdown {
		width: 100%;
		height: 70px;
		display: flex;
		align-items: center;
		padding: 6px 10px;
    border-bottom: 1px solid $color4;
    cursor: pointer;

		.account_detail {
      position: relative;
			display: flex;
			justify-content: center;
      opacity: 1;
      transition: all .2s linear;
      min-width: 120px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        transition: all 0s linear;
      }
      cursor: pointer;

      .account_name {
        display: flex;
        flex-direction: column;
        justify-content: center;

        >span {
          &:first-child {
            font-family: $primary-font-medium;
            font-size: 9px;
            letter-spacing: 0.5px;
            color: $color8;
            text-transform: uppercase;
            line-height: 10px;
          }
  
          &:nth-child(2) {
            font-family: $primary-font-regular;
            font-size: 16px;
            line-height: 13px;
            color: $color6;
            width: 120px;
            text-transform: capitalize;
            word-wrap: break-word;
          }
        }
      }
    }

		.carret {
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				margin-left: 3px;
			}
			margin-left: auto;
			display: flex;
			justify-content: center;
			align-items: center;
      cursor: pointer;

			svg {
        width: 16px;
				height: 16px;

				path {
					fill: $color6;
				}
			}
		}
  }

	.menu_wrap {

		.menu_list {

			>li, >li >ul >li, >li .menu_item .collapsed_submenu {
				min-height: 44px;
				display: flex;
				align-items: center;
				padding: 0 14px;
        position: relative;
        transition: all .5s linear;
        flex-wrap: wrap;

				>a, .menu_item {
					font-family: $primary-font-light;
					text-decoration: none;
					color: $color14;
					height: 44px;
					font-size: 14px;
					line-height: 12px;
					letter-spacing: 0.3px;
					display: flex;
          align-items: center;

					&.active {
						font-family: $primary-font-medium;
            color: $color8;

						.icon {

							svg {
                width: 100%;

								path {
									fill: $color8;
								}
							}
            }

						&::before {
							content: "";
							position: absolute;
              left: 0;
							background-color: $color14;
							box-shadow: 0 1px 4.8px 0.2px rgba(0, 0, 0, .2);
							width: 5px;
							height: 44px;
						}
          }

					.icon {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 23px;
            height: 24px;
            overflow: hidden;

						svg {
              width: 100%;

							path {
								fill: $color14;
							}
						}
          }

					>span {
            margin-left: 10px;
            width: 150px;
            transition: all .5s linear;
            overflow-wrap: break-word;
            word-wrap: break-word;
					}
        }

        // &.super_user{
        //   border-bottom: 2px solid #d2d2d2;
        // }
      }
      
      >li, >li >ul >li{
        >a, .menu_item {
					&.active {
            &::before {
              top: 0;
						}
          }
        }
      }

      >li .menu_item .hidden{
        display: none;
      }
		}
  }

	.sidebar_toggle {
		width: 219px;
    height: 40px;
    margin-top:50px;
		background-color: $color3;
    border-top: 1px solid $color4;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    transition: all .5s linear;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      transition: all 0s linear;
    }

    .collapsed_text {
      visibility: hidden;
    }

    > span{
      font-family: $primary-font-light;
      font-size: 14px;
      line-height: 12px;
      color: $color6;
      letter-spacing: 0.3px;
      width: 107px;
      display: inline-block;
      transition: all .5s linear;
    }

		.icon {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .5s linear;

			svg {
        width: 100%;

				path {
					fill: $color6;
				}
			}
		}
  }

  &.collapsed{
    width: 85px;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			width: 85px !important;
		}
		overflow: visible;
		z-index: 1;

    .top_dropdown{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .account_detail{
        position: absolute;
        opacity: 0;
        left: 60px;
      }

      .carret {
        margin: 0;
      }

      .account_title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        .company_logo {
          height: 40px;
          width: 40px;
          margin-right: 0;

          svg {
            height: 40px;
            width: 40px;
          }
        }
      }
    }

    .account_dropdown{
      position: absolute;
      width: 220px;
      transition: width .5s linear, height .2s linear;

      &.show{

        .account_dropdown_item{
          padding-left: 15px;

          .account_detail{
            opacity: 1;
          }
        }

        .add_account_text{
					display: flex;
					font-size: 14px;
					padding: 10px 0;
					color: $color14;
					transition: all .5s linear;
					cursor: pointer;
					justify-content: center;
        }
      }
    }

    .menu_wrap {
      overflow: visible;

      .menu_list {

        >li {
          padding-left: 31px;

          >a {

            > span{
              transform: translate(100%);
            }
          }

          .submenu{
            margin-left: -31px;
            transition: all 0s linear;

            li{
              padding-left: 0;
            }
          }
        }
      }
    }

    .sidebar_toggle {
      margin-left: -135px;

      .icon{
        transform: rotate(180deg);
        margin-left: -27px;
      }
    }
  }
}

.account_dropdown {
  position: absolute;
  width: 220px;
  height: 0;
  padding: 0 10px;
  border-radius: 6px;
  background-color: $color1;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.18);
  transition: width .5s linear, height .3s linear;

	.companies_wrapper{
		max-height: 285px;
		overflow:auto;
		display: none;
	}

  .account_dropdown_item {
    display: flex;
    transition: all .1s linear;
    padding: 10px 0;
    cursor: pointer;

    &.active{
      display: none;
    }

    .account_detail {
      display: none;

      .account_name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        >span {
          &:first-child {
            font-family: $primary-font-medium;
            font-size: 9px;
            letter-spacing: 0.5px;
            color: $color8;
            text-transform: uppercase;
            line-height: 10px;
          }
  
          &:nth-child(2) {
            font-family: $primary-font-regular;
            font-size: 16px;
            line-height: 13px;
            color: $color6;
            width: 120px;
            text-transform: capitalize;
            word-wrap: break-word;
          }
        }
      }
    }
  }

  &.show{
    position: absolute;
    width: 220px;
    height: auto;
    border-radius: 6px;
    background-color: $color1;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.18);
    border: solid 0.3px $color5;
    z-index: 2;
    padding: 10px;
		transition: width .5s linear, height .3s linear;

		.companies_wrapper{
			max-height: 285px;
			overflow:auto;
			display: block;
		}

    .account_dropdown_item{
      display: flex;
      padding: 10px 0;
      transition: all .3s linear;
      cursor: pointer;

      &.active{
        width: 100%;
        height: 70px;
        border-radius: 6px;
        background-color: $color3
      }

      .account_detail {
        position: relative;
        display: flex;
        justify-content: center;
        opacity: 1;
        transition: all .3s linear;
        min-width: 120px;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          transition: all 0s linear;
        }

        >span {
          &:first-child {
            font-family: $primary-font-medium;
            font-size: 9px;
            letter-spacing: 0.5px;
            color: $color8;
            text-transform: uppercase;
            line-height: 10px;
          }

          &:nth-child(2) {
            font-family: $primary-font-regular;
            font-size: 16px;
            line-height: 13px;
            color: $color6;
            width: 120px;
            text-transform: capitalize
          }
        }
      }
    }

    .divider{
      height: 1px;
      background: $color4;
      box-shadow: none;
      width:100%;
      border:0;
    }

    .add_account_text{
      display: flex;
      font-size: 14px;
      padding: 10px 0;
      color: $color14;
      transition: all .5s linear;
      cursor: pointer;
			justify-content: center;
		}
  }
}

.divider{
  height: 0;
  border: 0;
}

.add_account_text{
  display: none;
}

.specificLogo{
	width:30px;
}

.link_im{
	cursor: pointer;
}

.link_with_submenu{
  cursor: pointer;
}

.submenu{
  margin-left: -14px;
  margin-top:15px;
  max-height: 0;
  transition: all .3s linear;
  overflow: hidden;

  &.active{
    max-height: 88px;
  }
}

.custom_menu_icon{
  margin-left: 3px;
  margin-right: 3px;
}

.account_title{
  font-family: $primary-font-medium;
  font-size: 9px;
  letter-spacing: 0.5px;
  color: $color8;
  text-transform: uppercase;
  line-height: 10px;
}

.company_logo {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  border-radius: 50%;
  transition: all .6s linear;

  svg {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
}