:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.button_holder {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 480px) {
  .button_holder {
    display: block;
  }
}

.subtitle {
  margin-top: 0;
  text-transform: uppercase;
}

.modal_body_wrapper {
  margin-bottom: 20px;
}

.modal_body {
  padding: 0 30px;
}

.short_modal_body {
  padding: 0 30px;
}

@media (max-width: 480px) {
  .short_modal_body {
    height: calc(100% - 200px);
    min-height: calc(100% - 200px);
  }
}

