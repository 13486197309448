@import "~brands/default/styles/globals";
.logo_wrapper {
	.logo_image {
		width: 174px;
		height: 174px;
		object-fit: contain;
		border-radius: 50%;
	}

	.file_upload_wrapper {
		text-align: center;
		margin-top: 10px;

		.custom_file_upload {
			display: inline-flex;
			cursor: pointer;
			font-size: 18px;
			color: $color14;
			font-family: $primary-font-medium;
			padding: 0 19px;
			border: 2px solid $color14;
			height: 46px;
			border-radius: 30px;
			align-items: center;
		}

		input[type='file'] {
			display: none;
		}
	}
}