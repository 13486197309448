:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.content_block {
  margin-left: 45px;
  margin-right: 45px;
}

.section_title {
  text-transform: uppercase;
  font-family: "attalecksans_w_medium";
  color: #8a8b8a;
  font-size: 16px;
  padding-bottom: 13px;
  border-bottom: 1px solid #d2d2d2;
  margin: 24px 0 20px 0px;
}

.section_title:first-of-type {
  border-bottom: 0px;
  margin-bottom: 0px;
}

.section_title:not(:first-of-type):last-of-type {
  padding-bottom: 13px;
}

.columns_holder {
  display: flex;
}

.columns_holder .column:first-of-type {
  max-width: 175px;
}

.columns_holder .column:last-of-type {
  margin-left: 70px;
  width: calc(100% - 175px);
}

.row {
  display: flex;
}

.row .row_column {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  flex: 0 0 280px;
}

.preferred_language_info {
  display: flex;
  margin-top: 10px;
}

.preferred_language_info svg {
  margin-right: 5px;
}

.image_holder {
  width: 174px;
  height: 174px;
  border-radius: 50%;
}

.data_block {
  margin-bottom: 15px;
  font-size: 16px;
  font-family: "attalecksans_w_light";
}

.data_block .label {
  text-transform: capitalize;
}

.horizontal_data_block {
  margin-bottom: 5px;
  font-size: 16px;
  font-family: "attalecksans_w_light";
  display: flex;
}

.horizontal_data_block label {
  margin-right: 5px;
  text-transform: capitalize;
}

.username {
  font-size: 36px;
  font-family: "attalecksans_w_light";
  margin-top: 0;
  margin-bottom: 15px;
}

.address_columns_holder {
  display: flex;
}

.address_columns_holder .address_column {
  flex: 0 0 250px;
}

.address_columns_holder .label {
  text-transform: uppercase;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.label .primary_address {
  margin-left: 5px;
  text-transform: capitalize;
  font-size: 14px;
}

.reset_password_block {
  margin-top: calc(50% - 89px);
  font-size: 16px;
  margin-bottom: 20px;
}

.reset_password {
  color: #0568ae;
  margin-bottom: 5px;
  cursor: pointer;
}

.reset_icon {
  margin-right: 5px;
}

.data {
  margin-bottom: 5px;
}

.assigned_group_head {
  font-family: "attalecksans_w_medium";
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
}

.assigned_user_group {
  display: flex;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "attalecksans_w_light";
  margin-bottom: 5px;
}

.assigned_user_group .group_name {
  min-width: 170px;
}

.horizontal_separator {
  margin-top: 30px;
  background-color: #d2d2d2;
  border: 0px;
  height: 1px;
}

.manage_permissions {
  font-size: 16px;
  font-family: "attalecksans_w_regular";
  color: #0568ae;
}

.vertical_separator {
  margin-left: 10px;
  margin-right: 10px;
}

.pointer {
  cursor: pointer;
}

.tooltip {
  background-color: #0568ae !important;
  color: #fff !important;
}

.tooltip:after {
  border-top-color: #0568ae !important;
}

.view_icon {
  padding-left: 10px;
}

.status {
  padding-right: 15px;
  text-transform: capitalize;
}

.status:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #191919;
  margin-right: 10px;
}

.status.active:before {
  background-color: #4ca90c;
}

.status.paused:before {
  background-color: #ffb81c;
}

.no_address_message {
  font-size: 16px;
}

.no_address_message span {
  color: #0568ae;
  cursor: pointer;
}

.legal_information {
  font-size: 16px;
}

.highlight_licence {
  color: #0568ae;
  cursor: pointer;
}

.assigned_account {
  margin-top: 5px;
  text-transform: capitalize;
}

.assigned_account:first-of-type {
  margin-top: 0;
}

.disabled {
  cursor: not-allowed;
  color: #959595;
}

.disabled svg path {
  fill: #959595;
}

.disabled svg path g {
  fill: #959595;
}

.domain_message {
  font-weight: bold;
  margin-top: 5px;
}

