:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.super_user_mode {
  z-index: 999;
  background-color: #cf2a2a;
  max-width: 30%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 5px;
  justify-content: center;
  border-radius: 0 0 5px 5px;
}

.super_user_header {
  margin: 0 5px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: .4px;
  color: #fafafa;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.super_user_button {
  color: #fafafa;
  background-color: #cf2a2a;
  border: 1px solid #fafafa;
  height: 35px;
  min-width: 50px;
  font-size: 15px;
}

