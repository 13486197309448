:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

body {
  font-family: "attalecksans_w_regular";
}

.title {
  width: 311px;
  height: 44px;
  font-size: 34px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  padding-top: 7%;
  margin: auto;
}

.form {
  margin-bottom: 15px;
  width: 80%;
  padding: 20% 0 25% 0;
  margin: auto;
}

.error_form {
  margin-bottom: 15px;
  width: 80%;
  padding: 5% 0 25% 0;
  margin: auto;
}

.error_form .error_notification {
  margin: 10% 0;
}

