:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.divider {
  background-color: #d2d2d2;
  border: 0;
  height: 1px;
  margin: 20px 20px 30px 20px;
}

.row_wrapper {
  margin: 15px;
  display: flex;
}

.border_right {
  border-right: 1px solid #d2d2d2;
}

.row {
  flex-basis: 25%;
  margin-right: 10px;
  padding-left: 5px;
  min-width: 200px;
}

.row .title_row {
  font-size: 14px;
  font-family: "attalecksans_w_medium";
  margin-bottom: 5px;
}

.row .detail_holder {
  font-size: 16px;
  font-family: "attalecksans_w_regular";
}

.row .title {
  font-size: 12px;
  font-family: "attalecksans_w_medium";
  margin-bottom: 20px;
  margin-top: 0;
}

.row .to_uppercase {
  text-transform: uppercase;
}

.row .title_margin {
  margin-bottom: 15px;
}

.row .title_edit {
  display: flex;
  justify-content: space-between;
}

.row .title_edit svg {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  margin-right: 5px;
}

.row .title_edit svg path {
  fill: #0568ae;
}

.row .title_edit svg path g {
  fill: #0568ae;
}

.block_message {
  margin-bottom: 15px;
  padding-right: 15px;
}

.block_message .block_title {
  font-weight: bold;
  margin-bottom: 10px;
}

.block_message .block_data {
  font-size: 16px;
}

.block_message>div {
  word-break: break-word;
  word-wrap: break-word;
  width: 100%;
}

.column {
  min-width: 150px;
}

.notes_column {
  color: #0568ae;
  min-width: 150px;
  cursor: pointer;
}

.notes_column pre {
  background-color: inherit;
  padding: 0;
  border: 0;
  margin-top: 10px;
  font-size: 14px;
  font-family: "attalecksans_w_regular";
}

.section_buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.dropzone_trigger {
  display: none;
}

.dropzone_trigger.dropzone__active {
  display: block;
}

.existing_files {
  height: 25px;
  display: flex;
  justify-content: space-between;
  color: #0568ae;
}

.existing_files .existing_files_name {
  display: flex;
  cursor: pointer;
}

.existing_files .existing_files_name svg {
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 5px;
}

.existing_files .existing_files_button svg {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.existing_files .existing_files_button svg path {
  fill: #0568ae;
}

.existing_files .existing_files_button svg path g {
  fill: #0568ae;
}

.tooltip_wrapper .tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
  font-weight: normal;
}

.tooltip_wrapper svg {
  width: 15px;
  height: 15px;
  position: relative;
  cursor: pointer;
  margin: 0 0 0 5px;
}

.tooltip_wrapper svg g path {
  fill: #0568ae;
}

