:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.trigger__icon {
  height: 2px;
  width: 10px;
  background: #0568ae;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.trigger__icon:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  width: 20px;
  height: 20px;
}

.trigger__icon:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #0568ae;
  transform: rotate(90deg);
}

.trigger__icon.no_subpermissions {
  pointer-events: none;
  background: rgba(0,0,0,0);
}

.trigger__icon.no_subpermissions:after {
  background: rgba(0,0,0,0);
}

.trigger__icon.open::after {
  transform: rotate(0deg);
}

.name_container {
  display: flex;
  align-items: center;
}

.level_one_indent {
  padding: 0 0 0 20px;
}

.level_one_indent_no_children {
  padding: 0 0 0 40px;
}

.level_two_indent {
  padding: 0 0 0 60px;
  font-weight: normal;
}

.gray_row {
  background-color: #f8f8f8;
}

.permissions_table {
  width: 100%;
  border-collapse: collapse;
}

.permissions_table thead {
  background-color: #f2f2f2;
}

.permissions_table thead tr {
  height: 60px;
  font-size: 12px;
}

.permissions_table thead tr :first-child {
  text-align: left;
  width: 50%;
  padding-left: 15px;
}

.permissions_table thead th {
  border-top: 1px solid #d2d2d2;
  width: 15%;
  text-transform: uppercase;
}

.permissions_table tr {
  height: 48px;
}

.permissions_table th,
.permissions_table td {
  border-bottom: 1px solid #d2d2d2;
  border-collapse: collapse;
  text-align: center;
  font-weight: bold;
}

.permissions_table th:not(:first-child),
.permissions_table td:not(:first-child) {
  padding: 0;
}

.permissions_table td:not(:first-child) {
  border-left: 1px solid #d2d2d2;
}

.success_icon {
  width: 31px;
  height: 31px;
}

