@import "~brands/default/styles/globals";
.modal {
	.modal_body {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		max-height: 200px;
    padding: 0 30px;

		svg {
			width: 125px;
			height: 125px;
		}

		.modal_title {
			font-family: $primary-font-medium;
		}

		.prompt__body {
			padding-right: 10px;

			.modal_text_block {
				margin-top: 15px;

				.modal_message {
					font-size: 18px;
					font-family: $primary-font-regular;
					line-height: 22px;

					span {
						font-weight: bold;
					}

					> p.report_name {
						padding-bottom: 0;
					}

					p {
						margin-top: 0px;
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}
