:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.wrapper {
  margin: 30px 15px 0 15px;
}

.multiple_column_container {
  max-width: 764px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.column {
  width: 48%;
}

.column_title {
  font-size: 12px;
  text-transform: uppercase;
}

.field_wrapper {
  padding-bottom: 10px;
}

.field_label {
  margin-bottom: 10px;
  font-weight: bold;
}

.buttons_wrapper {
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  width: 764px;
}

.buttons_wrapper .left_button {
  margin-left: -19px;
}

.buttons_wrapper .buttons_right {
  float: right;
}

.buttons_wrapper .buttons_right :not(:last-child) {
  margin-right: 20px;
}

.custom_input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.summary {
  margin: 15px;
}

.field_root {
  width: 100%;
  display: flex;
}

.field_root .field_wrapper_column {
  width: 320px;
  margin: 0 30px 0 0;
  display: inline-table;
  min-height: 86px;
}

.field_root .field_wrapper_column_email {
  width: 320px;
  margin: 0 30px 0 0;
  display: inline-table;
  min-height: 86px;
  margin-left: 6.3%;
}

.mask_field_wrapper {
  display: flex;
  padding-bottom: 15px;
}

.mask_field_wrapper .component_wrapper_left {
  width: 165px;
}

.mask_field_wrapper .component_wrapper_right {
  width: calc(100% - 165px);
  position: relative;
}

.mask_field_wrapper .component_wrapper_right div div div:nth-of-type(2) {
  position: absolute;
  width: calc(100% + 165px);
  margin-left: -165px;
}

.mask_field_wrapper .component_wrapper_right .custom_input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

