@import "~brands/default/styles/globals";
.email_wrap {
	max-width: 150px;
	word-wrap: break-word;
	a {
		color: $color14;
		cursor: pointer;
		text-decoration: none;
	}
}

.order_status_select{
	min-width: 150px;
}

.assigned_accounts {
	max-width: 200px;
	text-transform: capitalize;
	word-break: break-all;
	padding-top: 5px;
	padding-bottom: 5px;
}

.status {
	padding-right: 15px;
	text-transform: capitalize;

	&:before {
		content: '';
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $color8;
		margin-right: 10px;
	}

	&.invite_sent:before {
		background-color: $test-status;
	}
	&.access_requested:before {
		background-color: $pending-status;
	}
}

.highlight{
	color: $color14;
	text-transform: capitalize;
}
