@import "~brands/default/styles/globals";
.action_bar_wrapper {
	display: flex;
	flex-flow: column;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color4;
  padding: 0 15px;
  margin: 9px 0 0 0;
  flex-wrap: wrap-reverse;

  & > :not(:first-child) {
    margin-bottom: 5px;
  }

  @media only screen and (max-width: 641px) {
    border-bottom: none;
  }
  
}

.bottom {
  padding: 15px;
}
