@import "~brands/default/styles/globals";
.top_container {
	padding: 20px;
}

.add_buttons_container {
	padding-bottom: 10px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid $color4;
}

svg.add_icon_plus {
	width: 22px;
	height: 28px;
	right: 5px;
	position: relative;
	g {
		fill: $color14;
		path {
			fill: $color14;
		}
	}
}

.button_container {
	display: flex;
	align-items: center;
	> button:not(:last-child) {
		margin-right: 20px;
	}
}

.add_container {
	display: flex;
	align-items: center;
	&:hover {
		cursor: pointer;
	}
}

.add_container_disabled {
  display: flex;
	align-items: center;
	&:hover {
		cursor: not-allowed;
	}
}

.add_text {
	color: $color14;
	font-size: 18px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 0.94;
	letter-spacing: normal;
}