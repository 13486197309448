@import "~brands/default/styles/globals";
.success {
    color: $color19;
}

.pending {
    color: $color9;
}

.error {
    color: $color10
}