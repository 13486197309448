:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.page_title {
  padding: 10px 0;
}

.page_title,
.description,
.form {
  margin-left: 80px;
}

.page_title {
  margin-top: 100px;
  font-size: 36px;
}

.description {
  font-size: 16px;
  margin-bottom: 22px;
}

.form {
  width: 20%;
}

.title {
  height: 30%;
  font-size: 34px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
  color: #1d2329;
}

