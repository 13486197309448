@import "~brands/default/styles/globals";
.super_user_mode {
  z-index: 999;
  background-color: $color10;
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  padding: 0 20px 0 20px;
}

.super_user_header {
  margin: 0;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: $color2;
}

.super_user_button {
  height: 42px;
  min-width: 80px;
  width: 80px;
  font-size: 15px;
  background-color: $color10;
  color: $color2;
  border: 1px solid $color2;
}