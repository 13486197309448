@import "~brands/default/styles/globals";
.modal_body {
	height: 500px;
}
.notes_body {
	margin: 30px 0 0 30px;
	padding-right: 80px;
	
	.modal_note {
		font-size: 14px;
		font-family: $primary-font-light;
		white-space: pre-wrap;
	}
}
