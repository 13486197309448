:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.request_form {
  width: 100%;
}

.form_subtitle {
  margin-bottom: 25px;
  color: #191919;
  font-size: 13px;
  margin-top: 2px;
  font-family: "attalecksans_w_medium";
}

.divider {
  margin-top: 25px;
}

.title_background {
  height: 100vh;
  padding-top: 15px;
  background-image: url("../../../../brands/default/images/MNC_login_screen.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title_background .notification_wrapper {
  width: 100%;
  max-width: 473px;
  min-height: 287px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(87,86,86,.14);
  border: .3px solid #959595;
}

.title_background .notification_wrapper .notification_content {
  padding: 27px;
}

.header {
  padding: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .icon {
  margin-right: 22px;
}

.header .description {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}

.content {
  padding: 27px;
}

.contact_text {
  margin-top: 20px;
  margin-bottom: 30px;
}

.contact_text span {
  font-weight: 700;
  font-size: 14px;
}

.title {
  height: 90px;
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.title_success {
  background-color: #4ca90c;
}

.title_error {
  background-color: #cf2a2a;
}

.button {
  width: 100%;
}

.homepage {
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 42px;
  padding: 0 10px;
  border: 1px solid #0568ae;
  border-radius: 30px;
  text-decoration: none;
  -ms-flex-item-align: start;
  align-self: flex-start;
  color: #0568ae;
}

.homepage>span {
  font-family: "attalecksans_w_medium";
  font-size: 18px;
  color: #0568ae;
}

.inner_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

