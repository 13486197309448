@import "~brands/default/styles/globals";
.modal_body_content {
	padding-left: 30px;
	padding-right: 30px;
}

.heading {
	font-family: $primary-font-light;
	font-size: 14px;
	margin-top: 0;
	margin-bottom: 20px;
}

.field_wrap {
	max-width: 312px;
}

.paragraph {
	font-family: $primary-font-italic;
	padding-bottom: 0;
}

.modal_body {
	height: 250px;
	width: 610px;
}
