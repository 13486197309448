:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.data_wrap {
  display: flex;
  flex-direction: row;
}

.data_wrap.overview {
  flex-direction: column;
}

.data_wrap .row {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  margin-right: 15px;
  flex-wrap: wrap;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 30px;
}

.data_wrap .row .data {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 320px;
  margin-right: 40px;
}

.data_wrap .row .data>span:first-child {
  font-family: "attalecksans_w_regular";
  font-weight: 700;
  height: 14px;
  font-size: 14px;
  line-height: 1;
  color: #191919;
  margin-bottom: 19px;
}

.data_wrap .row .data>span.status_span,
.data_wrap .row .data>span.reimsi_span {
  position: relative;
  align-self: flex-start;
}

.data_wrap .row .data>span.status_span.status_span,
.data_wrap .row .data>span.reimsi_span.status_span {
  margin-bottom: 0;
}

.data_wrap .row .data>span.status_span.status_span.entitlement,
.data_wrap .row .data>span.reimsi_span.status_span.entitlement {
  margin-bottom: 19px;
  font-family: "attalecksans_w_regular";
  font-weight: 700;
}

.data_wrap .row .data>span.status_span.reimsi_span+span,
.data_wrap .row .data>span.reimsi_span.reimsi_span+span {
  text-transform: capitalize;
}

.data_wrap .row .data>span:nth-child(2) {
  height: 16px;
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  line-height: 1;
  text-align: left;
  color: #191919;
  word-break: break-all;
  word-break: break-word;
}

.data_wrap .row.field_column_block {
  padding-right: 15px;
}

@media only screen and (max-width: 768px) {
  .data_wrap .row.field_column_block {
    padding-left: 0;
    padding-right: 0;
  }
}

.data_wrap .lastrow {
  border-bottom: none;
}

.data_wrap .column {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

.data_wrap .column .data {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.data_wrap .column .data>span:first-child {
  font-family: "attalecksans_w_regular";
  font-weight: 700;
  height: 14px;
  font-size: 14px;
  line-height: 1;
  color: #191919;
  margin-bottom: 19px;
}

.data_wrap .column .data>span.status_span,
.data_wrap .column .data>span.reimsi_span {
  position: relative;
  align-self: flex-start;
}

.data_wrap .column .data>span.status_span.status_span,
.data_wrap .column .data>span.reimsi_span.status_span {
  margin-bottom: 0;
}

.data_wrap .column .data>span.status_span.status_span.entitlement,
.data_wrap .column .data>span.reimsi_span.status_span.entitlement {
  margin-bottom: 19px;
}

.data_wrap .column .data>span.status_span.reimsi_span+span,
.data_wrap .column .data>span.reimsi_span.reimsi_span+span {
  text-transform: capitalize;
}

.data_wrap .column .data>span:nth-child(2) {
  height: 16px;
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  line-height: 1;
  text-align: left;
  color: #191919;
  word-break: break-all;
  word-break: break-word;
}

.data_wrap .column.field_column_block {
  padding-right: 15px;
}

@media only screen and (max-width: 768px) {
  .data_wrap .column.field_column_block {
    padding-left: 0;
    padding-right: 0;
  }
}

.data_wrap .remove_left_padding {
  padding-left: 0;
}

.data_wrap .inline_column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.data_wrap .inline_column .inline_input_wrapper {
  width: 300px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.entitlement_span {
  display: flex;
  flex-direction: row;
}

.entitlement_icon_wrap {
  display: flex;
  align-items: center;
  position: relative;
}

.entitlement_icon {
  height: 24px;
  width: 24px;
  margin-left: 5px;
  cursor: pointer;
}

.entitlement_icon path {
  fill: #0568ae;
}

.entitlement_icon_disabled {
  height: 24px;
  margin-left: 5px;
  cursor: not-allowed;
}

.entitlement_icon_disabled path {
  fill: #d2d2d2;
}

div.status_col {
  text-transform: capitalize;
  min-width: 100px;
  width: 166px;
  padding-left: 4px;
}

@media only screen and (max-width: 1382px) {
  div.status_col {
    min-width: 115px;
  }
}

div.status_col.status_col::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  position: relative;
  top: 10px;
  z-index: 1;
  top: 26px;
  left: 7px;
  background-color: #191919;
  visibility: hidden;
}

div.status_col.status_col.active::before,
div.status_col.status_col.activated::before {
  background-color: #4ca90c;
}

div.status_col.status_col.retired::before {
  background-color: #702f8a;
}

div.status_col.status_col.deactived::before,
div.status_col.status_col.inventory::before,
div.status_col.status_col.pause::before,
div.status_col.status_col.inactive::before {
  background-color: #d2d2d2;
}

div.status_col.status_col.terminated::before,
div.status_col.status_col.terminate::before {
  background-color: #cf2a2a;
}

div.status_col.status_col.purged::before {
  background-color: #5a5a5a;
}

div.status_col.status_col.suspend::before,
div.status_col.status_col.suspended::before {
  background-color: #d2d2d2;
}

div.status_col.status_col.deactive::before {
  background-color: #cf2a2a;
}

div.status_col.status_col.provisioned::before,
div.status_col.status_col.test_ready::before,
div.status_col.status_col.test::before {
  background-color: #ffb81c;
}

div.status_col.status_col.inactive::before {
  border: 1px solid #959595;
}

.pending {
  position: absolute;
  top: -2px;
  right: -20px;
  height: 14px;
  width: 14px;
}

.pending svg {
  width: 100%;
  cursor: pointer;
}

.pending svg path {
  fill: #ffb81c;
}

.oem_tooltip {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-left: 10px;
}

.oem_tooltip svg {
  width: 100%;
  cursor: pointer;
}

.oem_tooltip svg path {
  fill: #ffb81c;
}

.refreshPendingStatus {
  position: absolute;
  top: -2px;
  right: -116px;
  height: 14px;
  width: 14px;
}

.refreshPendingStatus svg {
  width: 100%;
  cursor: pointer;
}

.refreshPendingStatus svg path {
  fill: #0568ae;
}

.entitlement_tooltip {
  position: absolute;
  top: 0px;
  height: 14px;
  width: 14px;
}

.entitlement_tooltip svg {
  width: 100%;
  cursor: pointer;
}

.entitlement_tooltip svg path {
  fill: #ffb81c;
}

.input_wrapper {
  width: 255px;
  position: relative;
}

.input_wrapper .action {
  width: 35px;
  height: 34px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #f2f2f2;
  border-left: 1px solid #d2d2d2;
  position: absolute;
  right: 1px;
  top: 27px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_wrapper .action svg {
  width: 20px;
  height: 20px;
}

.input_wrapper .action svg path:nth-child(2) {
  fill: #cf2a2a;
}

.input_wrapper .action_check svg path:nth-child(2) {
  fill: green;
}

.input_wrapper .action_save {
  opacity: .4;
  cursor: default;
}

.position_relative {
  position: relative;
  display: inline-block;
}

.tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
}

input::-ms-clear {
  display: none;
}

