:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.add_invoice {
  display: flex;
  flex-direction: column;
  margin-left: 3em;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-right: 3em;
  height: 100%;
}

.add_invoice_body {
  width: 620px;
}

.titles {
  width: 620px;
  margin-bottom: 3em;
  display: flex;
  flex-direction: column;
}

.confirmation_text {
  margin-bottom: 1em;
  font-family: "attalecksans_w_medium";
  font-size: 24px;
  letter-spacing: .6px;
}

.subtitle {
  font-weight: 500;
  font-family: "attalecksans_w_medium";
  font-size: 18px;
  color: #191919;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: .5em;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 3em;
  border-top: 1px solid #d2d2d2;
  padding-top: 2em;
}

.left_button {
  margin-right: 20px;
}

.add-invoice-content {
  display: flex;
  flex-direction: column;
}

