:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.endpoints_wrapper {
  font-family: attalecksans_w_regular;
}

.table_wrapper {
  max-width: 100%;
  overflow-x: auto;
}

.table_wrapper table th:first-child,
.table_wrapper table td:first-child {
  background-color: #f2f2f2;
  display: table-cell;
}

.data {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.data .message {
  display: block;
  width: 6em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 1550px) {
  .data .message {
    width: inherit;
  }
}

.tooltip {
  box-shadow: 0 5px 12px .2px rgba(0,0,0,.2);
}

.bottom_action_bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
  align-items: center;
}

.bottom_action_bar .action_bar_active_filters {
  width: 100%;
}

.bottom_action_bar .action_bar_right {
  display: flex;
  align-items: center;
}

.bottom_action_bar .icon {
  width: 20px;
  fill: #0568ae;
  margin: 0 10px 0 0;
  cursor: pointer;
  height: 20px;
}

.bottom_action_bar .icon>g>path {
  fill: #0568ae;
}

.bottom_action_bar .icon:last-child {
  margin-right: 0;
}

.bottom_action_bar .icon.disabled {
  cursor: not-allowed;
}

.bottom_action_bar .icon.disabled path {
  fill: #959595;
}

.bottom_action_bar .icon.disabled>g>path {
  fill: #959595;
}

.bottom_action_bar .refreshPageIcon svg {
  width: 100%;
  cursor: pointer;
}

.bottom_action_bar .refreshPageIcon svg path {
  fill: #0568ae;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 15px;
}

.right .mr10 {
  margin-right: 10px;
}

.switch_wrapper {
  display: inline-block;
}

.add_icon {
  fill: #0568ae;
  width: 24px;
  height: 24px;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
}

.tabs .tabs_bar {
  border-bottom: none;
}

@media only screen and (max-width: 768px) {
  .tabs {
    border-bottom: none;
  }
}

