:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.color {
  display: flex;
  align-items: center;
  color: #0568ae;
  cursor: pointer;
}

.color.disabled {
  color: #959595;
  cursor: not-allowed;
}

@media only screen and (max-width: 641px) {
  .color {
    width: 50%;
    padding: 10px;
  }
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  fill: #0568ae;
}

.icon.disabled {
  color: #959595;
}

.table_wrapper div div[data-spec=table-wrapper] {
  overflow: visible;
}

