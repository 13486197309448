@import "~brands/default/styles/globals";
.form_container {
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

.row_container {
	display: flex;
	margin-top: 15px;
}

.input_container {
	margin: 10px 0;
	flex: 0.5;
	height: 35px;

	&:nth-child(odd) {
		margin-right: 20px;
	}
}

.input_container_one {
	margin: 10px 0;
	margin-left: 16px;
	margin-right: 32px;

	flex: 0.5;
	height: 35px;
}

.input_container_checkbox {
	margin: 10px 0;
	margin-right: 32px;

	flex: 0.5;
	height: 35px;
}

.dropdown_label {
	margin-bottom: 10px;
}

.field_array_edit {
	margin: 0 16px;
	padding: 10px 0;
	border-bottom: 1px solid $color4;

	&:last-child {
		border-bottom: none;
	}
}

.field_array_add {
	margin: 0 16px;
}
