.notification_bar {
	margin: 20px 0 0 0;
}

svg.add_icon {
  width: 20px;
  height: 20px;
  fill: $color1;
  margin-right: 5px;

  path {
    fill: $color1;
  }
}

.view_all {
	padding: 20px 0 20px 20px;
	font-size: 18px;
	color: $color14;

	> span {
		cursor: pointer;
	}
}

.note {
	word-break: break-all;
	word-wrap: normal;
	max-width: 250px;
	padding: 5px 0;
}

.link {
	color: $color14;
	cursor: pointer;
}

.status {
	display: block;
	position: relative;
	padding-left: 15px;

	&::before {
		content: '';
		width: 10px;
		height: 10px;
		border-radius: 100%;
		position: absolute;
		top: 3px;
		left: -3px;
		background-color: $color8;
	}

	&.confirmation::before {
		background-color: $not-success-bg;
	}

	&.error::before {
		background-color: $not-error-bg;
	}

	&.warning::before {
		background-color: $not-warning-bg;
	}

	&.info::before {
		background-color: $not-pending-bg;
	}
}

.button_width {
  min-width: 250px;
}
