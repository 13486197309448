@import "~brands/default/styles/globals";
.main_wrapper {
	width: 100%;
	min-height: 100vh;
	background-image: url('brands/default/images/illustration.png'),
		linear-gradient(
			45deg,
			$gradient_default_first_color 0%,
			$gradient_default_second_color 100%
		);
	background-repeat: no-repeat;
	background-position: left top;
	background-size: cover;
	display: flex;
	flex-direction: column;
	padding-left: 101px;
	padding-top: 67px;

	@media only screen and (max-width: 641px) {
		padding: 20px;
	}
}

.message {
	font-family: $primary-font-regular;
	font-size: 24px;
	color: $gradient_light_second_color;
	margin-top: 3px;
	width: 423px;

	@media only screen and (max-width: 641px) {
		width: 100%;
	}
}