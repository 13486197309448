@import "~brands/default/styles/globals";
@import '../AddTicket/AddTicket.scss';

.summary {
	margin: 15px;
}

.field_root {
	width: 100%;
	display: flex;

	.field_wrapper_column {
		width: 320px;
		margin: 0 30px 0 0;
		display: inline-table;
		min-height: 86px;
	}

	.field_wrapper_column_email {
		width: 320px;
		margin: 0 30px 0 0;
		display: inline-table;
		min-height: 86px;
		margin-left: 6.3%;
	}
}

.mask_field_wrapper {
	display: flex;
	padding-bottom: 15px;

	.component_wrapper_left {
		width: 165px;
	}

	.component_wrapper_right {
		width: calc(100% - 165px);
		position: relative;
		div {
			div {
				div:nth-of-type(2) {
					position: absolute;
					width: calc(100% + 165px);
					margin-left: -165px;
				}
			}
    }
    
		.custom_input {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}

.label{
	display: inline-block;
	max-width: 100%;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 14px;
}