@import "~brands/default/styles/globals";
.modal_body_content {
	padding-left: 30px;
	padding-right: 30px;
}

.alert_message {
	font-weight: normal;
	font-size: 24px;
	display: flex;
}

.notice {
	font-size: 16px;
	max-width: 600px;
}

.text_area{
	max-width: 480px;
	margin-top: 20px;
}
