@import "~brands/default/styles/globals";
$trigger-icon-height: 2px;
$trigger-icon-width: 10px;

.trigger__icon {
	height: $trigger-icon-height;
	width: $trigger-icon-width;
	background: $color14;
	position: relative;
	margin-right: 10px;
	cursor: pointer;

	&:before {
		content: ' ';
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -10px;
		margin-top: -10px;
		width: 20px;
		height: 20px;
	}

	&:after {
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: $color14;
		transform: rotate(90deg);
	}

	&.no_subpermissions {
		pointer-events: none;
		background: transparent;

		&:after {
			background: transparent;
		}
	}

	&.open::after {
		transform: rotate(0deg);
	}
}

.name_container {
	display: flex;
	align-items: center;
}

.level_one_indent {
	padding: 0 0 0 20px;
}

.level_one_indent_no_children {
	padding: 0 0 0 40px;
}

.level_two_indent {
	padding: 0 0 0 60px;
	font-weight: normal;
}

.gray_row {
	background-color: $color21;
}

.permissions_table {
	width: 100%;

	border-collapse: collapse;

	thead {
		background-color: $color3;

		tr {
			height: 60px;
			font-size: 12px;

			:first-child {
				text-align: left;
				width: 50%;
				padding-left: 15px;
			}
		}
		th {
			border-top: 1px solid $color4;
			width: 15%;
			text-transform: uppercase;
		}
	}

	tr {
		height: 48px;
	}

	th,
	td {
		border-bottom: 1px solid $color4;
		border-collapse: collapse;
		text-align: center;
		font-weight: bold;

		&:not(:first-child) {
			padding: 5px 0 0 0;
		}
	}
}

.success_icon {
	width: 31px;
	height: 31px;
}

.blacklisted_permissions_wrapper {
	margin: 15px 0 0;
}

.active_permission {
	width: 200px;
	span {
    justify-content: center !important;
		color: $color20 !important;
	}
}

.request_permission {
	width: 200px;
	> span {
    justify-content: center !important;
		border: 1px solid $color14 !important;
		> span {
			color: $color14 !important;
		}
	}
}

.cancel_permission {
	width: 200px;
	> span {
    justify-content: center !important;
		border: 1px solid #e6782c !important;
		> span {
			color: #e6782c !important;
		}
	}
}
