:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.history_status {
  position: relative;
  padding-right: 15px;
  text-transform: capitalize;
}

.history_status:before {
  content: "";
  display: inline-block;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #191919;
  position: relative;
  margin-right: 15px;
}

.history_status.error:before {
  background-color: #cf2a2a;
}

.history_status.completed:before {
  background-color: #009fdb;
}

.history_status.submitted:before {
  background-color: #702f8a;
}

.history_status.in_progress:before {
  background-color: #4ca90c;
}

.history_status.scheduled:before {
  background-color: #5a5a5a;
}

.history_status>span.tooltip_data {
  position: relative;
}

.history_status>span.tooltip_data>svg {
  width: 12px;
  height: 12px;
  margin-left: 6px;
  cursor: pointer;
}

.history_status>span.tooltip_data>svg path {
  fill: #ffb81c;
}

.history_status>span.tooltip_data .tooltip {
  box-shadow: 0 3px 4px 3px rgba(0,0,0,.15);
}

.color {
  display: flex;
  align-items: center;
  color: #0568ae;
  cursor: pointer;
}

.color.disabled {
  color: #959595;
  cursor: not-allowed;
}

@media only screen and (max-width: 641px) {
  .color {
    width: 50%;
    padding: 10px;
  }
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  fill: #0568ae;
}

.icon.disabled {
  color: #959595;
}

