@import "~brands/default/styles/globals";
.entitlements_title {
  font-size: 12px;
  font-family: $primary-font-medium;
  line-height: 1.17;
  letter-spacing: 0.6px;
  color: $color8;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.entitlements_details {
  border-top: 1px solid $color4;
  padding-top: 25px;
  display: flex;

  .entitlements_details_container {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:nth-child(2) {
      margin-left: 31px;
    }
  }

  &.apns{
    flex-wrap: wrap;
    padding-bottom: 25px;

    .entitlements_details_container {

      &:not(:last-child){
        border-bottom: 1px solid $color4;
      }

      &:not(:first-child){
        padding-top: 25px;
      }

      &:nth-child(2) {
        margin-left: auto;
      }
    }
  }


}

.entitlements_apn {
  border-top: 1px solid $color4;
  padding-top: 25px;
}

.entitlements_details_item {
  display: flex;
  margin-bottom: 17px;
}

.entitlements_item_title {
  font-size: 14px;
  font-weight: 500;
  font-family: $primary-font-medium;
  line-height: 14px;
  text-align: left;
  color: $color8;
  width: 100%;
}

.entitlements_item_data {
  font-size: 16px;
  font-family: $primary-font-regular;
  line-height: 16px;
  text-align: right;
  color: $color8;
  width: 100%;
}

.modal{
  height: 600px;
  overflow: visible;
  .modal_body_custom {
    background: $color1;
    position: static;
    height: 445px;
    overflow: visible;
  }
}

.select_field_wrapper {
  padding-bottom: 30px;

  &.rate_plan{
    padding-bottom: 0;
  }

  &.datepicker{
    margin-top: 14px;
  }
}

.select_field_label {
  padding: 0 0 10px 0;
  font-weight: bold;
  text-transform: uppercase;

  &.normal_font_weight{
    font-weight: normal;
  }
}

.select_field_label_text{
  font-family: $primary-font-light;
  font-size: 14px;
  color: $color8;
  line-height: 16px;
  padding-bottom: 16px;

  &.rate_plan{
    font-family: $primary-font-medium;
  }
}

.rate_plan_list{
  display: flex;

  .rate_plan_name{
    font-family: $primary-font-medium;
    font-size: 14px;
    line-height: 1;
    color: $color8;
    padding-right: 105px;
    text-transform: uppercase;
  }

  .rate_plan_id{
    font-size: 16px;
    line-height: 1;
    color: $color8;
  }
}

.custom {
  height: 400px;
  overflow: visible;

  &.step_1{
    overflow-y: auto;
  }
}

.entitlement_info {
  padding-bottom: 30px
}

.current_network_entitlement {
  font-size: 12px;
  color: $color19;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  svg {
	width: 15px;
	height: 15px;
	margin-right: 5px;

	path {
	  fill: $color19;
	}
  }
}

.schedule_initiate_message {
  padding: 0 0 30px 0;

  span:nth-child(2) {
	font-weight: bold;
  }
}

.step_first{

  text-transform: uppercase;

  h2{
    font-family: $primary-font-medium;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
    letter-spacing: 0.6px;
    text-align: left;
    color: $color8;
  }
}

.customFieldLabelsContainer{
  padding: 0 30px;
  overflow: auto;
}

.modal_body_error_message{
  padding: 0 30px;
}


.modal_tab_content{
  overflow: visible;
}