@import "~brands/default/styles/globals";
.field_wrapper {
	width: 320px;
	margin: 0 30px 30px 0;
	display: inline-block;
	position: relative;
	vertical-align: top;
}

.required_field {
	label::after {
		position: absolute;
		content: '*';
		font-size: 22px;
		font-weight: normal;
		color: $color10;
		margin-left: 2px;
		margin-top: -5px;
	}
}

.single_field {
	display: block;
}

.label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 14px;
}

.info {
	font-size: 14px;
}
