@import "~brands/default/styles/globals";
.table_wrapper {
	margin-top: 5px;
}

svg.addIconPlus {
	width: 22px;
	height: 28px;
	right:5px;
	position: relative;
	cursor: pointer;

	g {
		fill: $color1;
		path{
			fill: $color1;
		}
	}
}

svg.deleteIcon {
	width: 22px;
	height: 28px;
	right:5px;
	position: relative;
	cursor: pointer;

	g {
		fill: $color14;
		path:nth-child(2){
			fill: $color14;
		}
	}
}
