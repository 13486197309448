:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.right {
  display: flex;
  align-items: center;
}

.right>div {
  height: 35px;
  display: flex;
  align-items: center;
}

.right .search_left {
  border: 1px solid #0568ae;
  padding: 0 20px;
  border-bottom-left-radius: 18px;
  border-top-left-radius: 18px;
  cursor: pointer;
  position: relative;
  min-width: 155px;
  display: flex;
  justify-content: space-between;
  font-family: "attalecksans_w_regular";
  line-height: 1;
  color: #0568ae;
  font-size: 16px;
}

.right .search_left>div>span:nth-child(2) {
  display: inline-block;
  padding-right: 5px;
}

.right .search_left.opened {
  background-color: #f2f2f2;
}

.right .search_left.opened .down_carret {
  transform: rotate(180deg);
}

.right .search_left .down_carret {
  height: 16px;
  transition: all .1s linear;
  width: 20px;
}

.right .search_left .menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,.1);
  border: 1px solid #d2d2d2;
  padding: 15px;
  margin-top: 5px;
  z-index: 10;
}

.right .search_left .menu li {
  font-family: "attalecksans_w_light";
  line-height: .86;
  color: #333;
  font-size: 14px;
}

.right .search_left .menu li:not(:first-child) {
  margin-top: 20px;
}

.right .search_left .menu li:hover {
  color: #0568ae;
}

.right .search_left .menu li.selected {
  font-family: "attalecksans_w_regular";
  color: #0568ae;
}

.right .search_right {
  position: relative;
}

.right .search_right input {
  height: 35px;
  border-bottom-right-radius: 18px;
  border-top-right-radius: 18px;
  width: 155px;
  padding: 0 27px 0 10px;
  border: 1px solid gray;
  border-left: none;
  font-family: "attalecksans_w_regular";
  font-size: 16px;
  line-height: 1;
  color: #191919;
}

.right .search_right input::placeholder {
  font-family: "attalecksans_w_italic";
  font-size: 16px;
  line-height: 1;
  color: #959595;
}

.right .search_right input::-moz-placeholder {
  line-height: 31px;
}

.right .search_right input:-moz-placeholder {
  line-height: 31px;
}

.right .search_right input:focus {
  outline: none;
}

.right .search_right .search_icon {
  position: absolute;
  top: 7px;
  right: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right .search_right .search_icon svg {
  width: 23px;
  height: 21px;
}

.right .search_right .search_icon svg path {
  fill: #0568ae;
}

