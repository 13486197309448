:export {
  color1: #fff;
  color2: #fafafa;
  color3: #f2f2f2;
  color4: #d2d2d2;
  color5: #959595;
  color6: #5a5a5a;
  color7: #333;
  color8: #191919;
  color9: #ffb81c;
  color10: #cf2a2a;
  color11: #ea7400;
  color12: #71c5e8;
  color13: #009fdb;
  color14: #0568ae;
  color15: #caa2dd;
  color16: #9063cd;
  color17: #702f8a;
  color18: #b5bd00;
  color19: #4ca90c;
  color20: #007a3e;
}

.content_wrapper {
  padding: 0 15px;
}

.content_wrapper .cards_wrapper {
  display: flex;
  margin: 15px 0 20px 0;
}

@media only screen and (max-width: 641px) {
  .content_wrapper .cards_wrapper {
    flex-direction: column;
  }
}

.content_wrapper .cards_wrapper .card {
  border-radius: 10px;
  border: 1px solid #d2d2d2;
  max-width: 32%;
  width: 100%;
  padding: 20px;
  margin-right: 2%;
}

.content_wrapper .cards_wrapper .card:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 641px) {
  .content_wrapper .cards_wrapper .card {
    max-width: 100%;
    margin-bottom: 20px;
    margin-right: auto;
  }

  .content_wrapper .cards_wrapper .card:last-child {
    margin-right: auto;
  }
}

.content_wrapper .cards_wrapper .card:hover {
  cursor: pointer;
  background-color: #fafafa;
  box-shadow: 0 2px 13px 0 rgba(0,0,0,.12);
}

.content_wrapper .cards_wrapper .card .icon_holder {
  padding-top: 40px;
  text-align: center;
  padding-bottom: 60px;
}

.content_wrapper .cards_wrapper .card .icon_holder .icon {
  width: 92px;
  height: 92px;
  fill: #009fdb;
}

.content_wrapper .cards_wrapper .card .icon_holder .icon path {
  fill: #009fdb;
}

.content_wrapper .cards_wrapper .card .title {
  font-size: 22px;
  margin-bottom: 10px;
  font-family: "attalecksans_w_regular";
  color: #0568ae;
}

.content_wrapper .cards_wrapper .card .intro {
  font-size: 14px;
  line-height: 16px;
}

.note {
  word-break: break-all;
  word-wrap: normal;
  max-width: 250px;
  padding: 5px 0;
}

.link {
  color: #0568ae;
  cursor: pointer;
}

.status {
  display: block;
  position: relative;
  padding-left: 15px;
}

.status::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: 3px;
  left: -3px;
  background-color: #191919;
}

.status.confirmation::before {
  background-color: #4ca90c;
}

.status.error::before {
  background-color: #cf2a2a;
}

.status.warning::before {
  background-color: #ffb81c;
}

.status.info::before {
  background-color: #009fdb;
}

