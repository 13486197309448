@import "~brands/default/styles/globals";
.diagnostics_wrapper{

	.header {
		padding: 0 30px 30px;
		border-bottom: 1px solid $color4;
	}

	.main {
		display: flex;
		flex-flow: row wrap;
		margin: 30px 15px;
	}

	.diagnostics_details {
		flex: 2;
		padding: 0 30px;
		@media (max-width: 901px) {
			padding: 0;
		}

		.test_wrapper{
			display: flex;

			& + .test_wrapper{
				margin-top: 45px;
			}

			.graphics{
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.success{
					height: 80px;

					path{
						fill: $color19;
					}
				}

				.dots{
					height: 35px;
					margin-top:55px;

					circle{
						fill: $color19;
					}

					&.dots_error{

						circle{
							fill: $color11;
						}
					}
				}

				.error{
					height: 80px;

					path{
						fill: $color11;
					}
				}
			}

			.data{
				flex: 6;
				display: flex;
				flex-direction: column;

				.title{
					text-transform: uppercase;
					font-family: $primary-font-medium;
					font-size: 12px;
					font-weight: 500;
					line-height: 1.17;
					letter-spacing: 0.6px;
					text-align: left;
					color: $color8;
					width: 100%;
					border-bottom: 1px solid $color19;
					padding: 0 28px 13px 28px;
					margin-bottom: 17px;

					&.error{
						border-bottom: 1px solid $color11;
					}
				}

				.data_country{
					display: flex;
					width: 100%;
					justify-content: space-between;
					position: relative;
					padding:0 28px;
					margin-bottom: 15px;
					overflow: hidden;

					span{
						font-family: $primary-font-regular;
						font-size: 16px;
						color: $color8;
						padding-right: 20px;
						background-color: $color1;
						z-index: 1;
					}
				}

				.data_name{
					display: flex;
					width: 100%;
					justify-content: space-between;
					position: relative;
					padding:0 28px;
					margin-bottom: 15px;
					overflow: hidden;

					.ragDot{
						height: 20px;
						width: 20px;
						background-color: $color1;
						border-radius: 50%;
						display: inline-block;
						margin-left: 10px;

						&.g {
							background-color: $color19;
						}

						&.a {
							background-color: $color11;
						}

						&.r {
							background-color: $color10;
						}
					}

					&:before{
						content: '';
						position: absolute;
						bottom: 6px;
						width: calc(100% - 56px);
						line-height: 0;
						border-bottom: 1px dashed $color5;
					}

					span{
						font-family: $primary-font-regular;
						font-size: 16px;
						color: $color8;
						padding-right: 20px;
						background-color: $color1;
						z-index: 1;
					}

					svg{
						height: 18px;
						width: 28px;
						background-color: $color1;
						z-index: 1;
						padding-left: 10px;
						margin-left: auto;

						path{
							fill: $color19;
						}

						&.ncheck{
							cursor: pointer;

							path{
								fill: $color11;
							}
						}
					}

					.tooltip_data{
						display: flex;
						width: 100%;
						flex-direction: column;

						.data{
							line-height: 1.29;
						}

						.break_lines{
							border-bottom: 1px dashed $color5;
							margin-bottom: 10px;
						}
					}

					.tooltip {
						box-shadow:0 5px 12px 0.2px rgba(0, 0, 0, 0.2);
						opacity: 1;
					}
				}
			}

			&:last-child{
				div:last-child {
					justify-content: center;
				}
			}
		}
	}

	.registration_details {
		flex: 1;
	}
}

.ragWrapper{
	display: flex;
}
