@import "~brands/default/styles/globals";
.apn_list {
	width: 100%;
	margin-bottom: 20px;

	.apn_details {
		margin-bottom: 30px;
	}
}

.apn_section {
	max-height: 0;
	transition: all 0.3s ease-in-out;
	overflow: hidden;

	&.show {
		max-height: 500vh;
	}
}

.link {
	color: $color14;
	font-size: 16px;
	line-height: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-top: 72px;
	transition: visibility 0.3s ease-in-out;

	svg {
		width: 15px;
		height: 15px;
		margin-left: 10px;

		path {
			fill: $color14;

			g {
				fill: $color14;
			}
		}
	}

	&.link_margin {
		margin-top: 42px;
	}
}

.headline {
	font-family: $primary-font-medium;
	font-size: 18px;
	color: $color8;
	display: flex;
	justify-content: space-between;
	min-height: 25px;
	font-weight: 500;
	margin: 40px 15px 30px 0;
	align-items: center;
	width: 787px;
	padding-bottom: 11px;
	border-bottom: 1px $color4 solid;

	&.extend_margin {
		margin-top: 70px;
	}

	&.apns_margin {
		margin-top: 20px;
	}

	&.off_state {
		margin-top: 100px;
	}
}

.field_wrapper {
	width: 320px;
	margin: 0 30px 10px 0;
	display: inline-table;
}

.left_content {
  width: 320px;
  margin-right: 30px;
  display: inline-table;
  
  &.no_margin {
    margin: 0;
  }
}

.checkbox_margin {
	margin-bottom: 20px;

	&.extend {
		margin-bottom: 30px;
	}
}

.off_state_apns {
	margin-bottom: 100px;
}

.sub_field_wrapper {
	width: 160px;
	margin: 0 30px 10px 0;
	display: inline-table;
}

.checkbox_wrapper {
  margin: 20px 0;
}

.divider {
	height: 1px;
	background-color: $color5;
	margin-bottom: 30px;
}

.section_buttons {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	justify-content: flex-end;
	margin: 0 30px 20px 0;

	:not(:last-child) {
		margin-right: 15px;
	}
}
